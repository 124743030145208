import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "/src/hooks/navigation";
import Box from "@material-ui/core/Box";
import NoteListComponent from "../../../components/customers-owner/note-list";
import StepButtons from "../../../components/step-buttons";
import { useQuery } from "../../../utils";
import LayoutDashboard from "../../../components/layout-dashboard";
import BackBtn from "../../../components/customers-owner/back-btn";
import api from "../../../api";

const NotesList = ({ custumerId, onGoNext, onGoBack }) => {
  const [companyNotes, setCompanyNotes] = useState([]);
  const [bookingNotes, setBookingNotes] = useState({});
  const [companyNotesStatus, setCompanyNotesStatus] = useState("initial");
  const query = useQuery();
  const selectedBooking = Number(query.get("selected-booking")) || 0;
  const { navigate } = useNavigate();

  const customerSearch = useSelector((state) => state.companies.search);

  const getNotes = async () => {
    setCompanyNotesStatus("loading");
    const res = await api.getCompanyRatings({
      companyId: custumerId,
    });
    if (res.status === 200) {
      const filterEmptyNotes = res.data?.payload?.ratings?.filter((cr) => cr.booking_notes > 0) || [];
      setCompanyNotes(filterEmptyNotes);
      setCompanyNotesStatus("success");
    } else {
      setCompanyNotesStatus("error");
    }
  };

  const getBookingNotes = async (id) => {
    setBookingNotes((bn) => ({
      ...bn,
      [id]: { status: "loading", notes: [] },
    }));
    const res = await api.getBookingNotes({ bookingId: id });
    if (res.status === 200) {
      setBookingNotes((bn) => ({
        ...bn,
        [id]: { status: "success", notes: res.data?.payload?.data },
      }));
    } else {
      setBookingNotes((bn) => ({
        ...bn,
        [id]: { status: "error", notes: [] },
      }));
    }
  };

  const handleBack = () => {
    navigate(customerSearch ? `/administrator/clients?search=${customerSearch}` : "/administrator/clients");
  };

  useEffect(() => {
    getNotes();
  }, []);

  return (
    <>
      <LayoutDashboard>
        <NoteListComponent
          selectedBooking={selectedBooking}
          getBookingNotes={getBookingNotes}
          companyNotes={companyNotes}
          bookingNotes={bookingNotes}
          companyNotesStatus={companyNotesStatus}
        />
      </LayoutDashboard>
      <StepButtons
        stepButtonComponent={
          <Box display="flex" flexDirection="row">
            <BackBtn onBack={handleBack} />
          </Box>
        }
      />
    </>
  );
};

export default NotesList;
