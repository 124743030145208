import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useLingui } from "@lingui/react";
import Typography from "@material-ui/core/Typography";
import { Trans, t } from "@lingui/macro";
import { ValidatorForm } from "react-material-ui-form-validator";
import AddIcon from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress";
import Image from "material-ui-image";
import Link from "@material-ui/core/Link";
import Backdrop from "@material-ui/core/Backdrop";
import { Grid } from "@material-ui/core";
import theme from "../../../theme";
import TextField from "../../text-field";
import FileUpload from "../../file-upload/multiple";
import CloseIcon from "../../../icons/close-icon";
import StepButtons from "../../step-buttons";
import DecideDialog from "../../decide-dialog-owner";

const useStyles = makeStyles((th) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    color: th.palette.text.primary,
    paddingLeft: th.spacing(2),
  },
  validatorContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    height: "100%",
    width: "100%",
  },
  inputRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
  },
  inputs: {
    width: "217px",
  },
  selects: {
    display: "flex",
    flexDirection: "row",
  },
  attachmentIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: th.spacing(1 / 2),
    borderRadius: "5px",
    width: 80,
    height: 80,
    backgroundColor: th.palette.grayC4,
    cursor: "pointer",
    position: "relative",
  },
  attachmentImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: th.spacing(1 / 2),
    borderRadius: "5px",
    width: 80,
    height: 80,
    cursor: "pointer",
    position: "relative",
  },
  closeIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "22px",
    height: "22px",
    position: "absolute",
    borderRadius: "50%",
    backgroundColor: th.palette.grayC4,
    zIndex: 999,
    left: "66px",
    top: "-8px",
    cursor: "pointer",
  },
  continueButton: {
    width: 190,
    height: 42,
    [th.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  typeRadioButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    marginRight: th.spacing(4),
  },
  typeRadioButtonSuccess: {
    color: th.palette.primary.main,
  },
  label: {
    fontSize: "13px",
    fontWeight: "bold",
  },
  backdrop: {
    zIndex: th.zIndex.drawer + 1,
    color: th.palette.white,
  },
}));

const Information = ({
  asset = {},
  status = "initial",
  submit = () => {},
  submitStatus = "initial",
  resetSubmitStatus,
}) => {
  const classes = useStyles(theme);

  const [values, setValues] = useState(asset);
  const [valuesHasChanged, setValuesHasChanged] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [imageUploadStatus, setImageUploadStatus] = useState("initial");

  const { i18n } = useLingui();

  const MAX_MB = 2;

  const handleStartUpload = () => setImageUploadStatus("loading");
  const handleErrorUpload = () => setImageUploadStatus("error");
  const handleUploadImage = (results) => {
    setValues((v) => ({ ...v, thumb_url: encodeURI(results[0]?.url) }));
    setImageUploadStatus("initial");
  };

  const handleDeleteImage = () => {
    setValues((v) => ({ ...v, thumb_url: "" }));
  };

  const handleFinish = () => {
    submit(values);
  };

  const handleCancel = () => {
    setValues(asset);
  };

  const handleInputChange =
    (field) =>
    ({ target }) => {
      setValues((v) => ({ ...v, [field]: target.value }));
    };

  useEffect(() => {
    if (asset) {
      setValues(asset);
    }
  }, [asset]);

  useEffect(() => {
    setValuesHasChanged(!(JSON.stringify(asset) === JSON.stringify(values)));
  }, [values]);

  return (
    <>
      <Box className={classes.container}>
        <DecideDialog
          open={openDialog}
          status={submitStatus}
          onAccept={handleFinish}
          title={<Trans>Existen cambios sin guardar</Trans>}
          desc={
            <Trans>
              Al editar un campo debes guardar los cambios. ¿Desea guardar los cambios realizados antes de continuar?
            </Trans>
          }
          acceptText={<Trans>Guardar y continuar</Trans>}
          rejectText={<Trans>Continuar sin guardar</Trans>}
          onClose={() => setOpenDialog(false)}
        />
        <Backdrop className={classes.backdrop} open={status === "loading"}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar open={submitStatus === "error"} autoHideDuration={3000}>
          <MuiAlert elevation={6} variant="filled" severity="error">
            <Trans>Ha ocurrido un error. Por favor intente de nuevo.</Trans>
          </MuiAlert>
        </Snackbar>
        <ValidatorForm className={classes.validatorContainer} onSubmit={() => {}}>
          <Grid container>
            <Grid item xs={6}>
              <TextField
                multiline
                rows={6}
                description={`${i18n._(t`Descripción del centro comercial*`)}`}
                validators={["required"]}
                errorMessages={[""]}
                value={values?.description}
                onChange={handleInputChange("description")}
                labelClassNames={classes.label}
                fullWidth
              />
            </Grid>
          </Grid>
          <Box mt={1}>
            <Typography variant="subtitle1" className={classes.label}>
              <Trans>Fotografía*</Trans>
            </Typography>
            <Box mb={1}>
              <Typography variant="body1">
                <Trans>Foto principal. Máx. 2mb</Trans>
              </Typography>
            </Box>
          </Box>
          {imageUploadStatus === "loading" && (
            <Box display="flex" alignItems="center" justifyContent="center" width={65} height={65} mb={3 / 2}>
              <CircularProgress />
            </Box>
          )}
          {values?.thumb_url && imageUploadStatus !== "loading" && (
            <Box position="relative" width={80}>
              <Box onClick={handleDeleteImage} className={classes.closeIcon}>
                <CloseIcon width="12px" height="12px" />
              </Box>
              <Link href={values?.thumb_url} target="_blank">
                <Box className={classes.attachmentImage}>
                  <Image
                    src={values?.thumb_url}
                    style={{
                      height: "100%",
                      padding: "0",
                      width: "100%",
                    }}
                    imageStyle={{
                      objectFit: "cover",
                      height: "100%",
                      width: "100%",
                      borderRadius: "5px",
                    }}
                  />
                </Box>
              </Link>
            </Box>
          )}
          {!values?.thumb_url && imageUploadStatus !== "loading" && (
            <Box display="flex" flexDirection="row">
              <label className={classes.attachmentIcon} htmlFor="upload">
                <FileUpload
                  id="upload"
                  name="upload"
                  maxSize={MAX_MB * 1024 * 1024} // 2MB
                  accept="image/jpg,image/jpeg,image/png"
                  style={{ display: "none" }}
                  onComplete={handleUploadImage}
                  onStart={handleStartUpload}
                  onError={handleErrorUpload}
                />
                <Box className={classes.pointer}>
                  <AddIcon />
                </Box>
              </label>
            </Box>
          )}
        </ValidatorForm>
      </Box>
      <StepButtons
        continueButtonLabel={<Trans>Guardar</Trans>}
        backButtonLabel={<Trans>Olvidar cambios</Trans>}
        handleContinue={handleFinish}
        handleBack={handleCancel}
        disableContinue={!valuesHasChanged}
        disableBack={!valuesHasChanged}
      />
    </>
  );
};

export default Information;
