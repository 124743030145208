import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, DialogActions, DialogContent, Grid, makeStyles, Typography } from "@material-ui/core";
import theme from "../../../theme-admin";
import SecondaryButton from "../../secondary-button";
import OwnerModal from "../../owner-modal";
import TextField from "../../text-field";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
    textAlign: "center",
  },
  dialogContent: {
    paddingLeft: 0,
    paddingRight: theme.spacing(1),
  },
}));

const INITIAL_USER_FORM = {
  name: "",
  email: "",
  phone: "",
  lastname: "",
};

const DialogInvitationAndEdition = ({
  open = false,
  isEdit = false,
  valueEdit = {},
  onClose = () => {},
  onInviteCustomer,
  onUserInviteErrors,
  onInviteUserStatus,
  onUpdateFieldUser,
  updateFieldUserStatus,
}) => {
  const classes = useStyles({
    ...theme,
  });
  const { i18n } = useLingui();

  const [inputErrors, setInputErrors] = useState({});
  const [values, setValues] = useState({});

  const fieldsMandatory = ["name", "email", "lastname", "phone"];

  const handleOnInputChange =
    (field) =>
    ({ target }) => {
      setValues((v) => ({ ...v, [field]: target.value }));
    };

  const canInviteCustomer = () => {
    let isFieldsFilled = true;
    fieldsMandatory.forEach((e) => {
      if (values[e]?.length <= 0) {
        isFieldsFilled = false;
      }
    });
    return !(isFieldsFilled && onInviteUserStatus !== "loading" && updateFieldUserStatus !== "loading");
  };

  const handleSaveCustomer = () => (isEdit ? onUpdateFieldUser({ ...values }) : onInviteCustomer({ ...values }));

  useEffect(() => {
    setInputErrors(onUserInviteErrors);
  }, [onUserInviteErrors]);

  useEffect(() => {
    if (onInviteUserStatus === "success" || updateFieldUserStatus === "success") {
      setValues(INITIAL_USER_FORM);
      onClose();
    }
  }, [onInviteUserStatus, updateFieldUserStatus]);

  useEffect(() => {
    if (isEdit) {
      setValues(valueEdit);
    } else {
      setValues(INITIAL_USER_FORM);
    }
  }, [isEdit]);

  return (
    <OwnerModal
      title={isEdit ? <Trans>Editar usuario</Trans> : <Trans>Invitar usuario</Trans>}
      open={open}
      width={800}
      onClose={onClose}
    >
      <DialogContent className={classes.dialogContent}>
        <Box width="100%" pt={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                value={values.name}
                style={{
                  width: "100%",
                }}
                label={`${i18n._(t`Nombre`)} *`}
                validators={["required"]}
                InputProps={{
                  placeholder: `${i18n._(t`Nombre`)}`,
                }}
                onChange={handleOnInputChange("name")}
                textFieldClassNames={classes.field}
                inputClassNames={`${classes.fieldInput} ${inputErrors?.name ? classes.inputError : ""}`}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={values.lastname}
                style={{
                  width: "100%",
                }}
                label={`${i18n._(t`Apellido`)} *`}
                validators={["required"]}
                InputProps={{
                  placeholder: `${i18n._(t`Apellido`)}`,
                }}
                onChange={handleOnInputChange("lastname")}
                textFieldClassNames={classes.field}
                inputClassNames={`${classes.fieldInput} ${inputErrors?.lastname ? classes.inputError : ""}`}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={values.email}
                label={`${i18n._(t`Email`)} *`}
                style={{ width: "100%" }}
                onChange={handleOnInputChange("email")}
                textFieldClassNames={classes.field}
                inputClassNames={`${classes.fieldInput} ${inputErrors?.email ? classes.inputError : ""}`}
                type="email"
                InputProps={{
                  placeholder: `${i18n._(t`Mail`)}`,
                }}
                validators={["required", "isEmail"]}
                disabled={isEdit}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={values.phone}
                label={`${i18n._(t`Teléfono`)} *`}
                placeholder={`${i18n._(t`Teléfono`)}`}
                onChange={handleOnInputChange("phone")}
                textFieldClassNames={classes.field}
                inputClassNames={`${classes.fieldInput} ${inputErrors?.phone ? classes.inputError : ""}`}
                fullWidth
                type="tel"
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          mt={3}
          mb={2}
        >
          <Box>
            {Object?.keys(inputErrors || {})?.length > 0 && (
              <Box>
                {Object?.keys(inputErrors || {})?.map((e) => (
                  <Typography variant="body1" style={{ color: theme.palette.red }}>
                    <Trans>{inputErrors[e][0]}</Trans>
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
          <Box>
            <SecondaryButton onClick={onClose}>
              <Trans>Cancelar</Trans>
            </SecondaryButton>
            <Button variant="contained" color="primary" onClick={handleSaveCustomer} disabled={canInviteCustomer()}>
              {isEdit ? <Trans>Guardar</Trans> : <Trans>Invitar y guardar</Trans>}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </OwnerModal>
  );
};

export default DialogInvitationAndEdition;
