import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import CustomTabs from "../custom-tabs";

const useStyles = makeStyles((th) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    alignItems: "center",
  },
  wrapper: {
    width: "100%",
    height: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    marginBottom: 0,
  },
  continueButton: {
    width: 190,
    height: 42,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  stepText: {
    textTransform: "uppercase",
    "& .MuiStepLabel-root.Mui-disabled": {
      cursor: "pointer",
    },
  },
  backButton: {
    top: th.spacing(3),
    left: 0,
    whiteSpace: "nowrap",
  },
  stepper: {
    width: "100%",
    overflow: "hidden",
  },
  stepperRoot: {
    paddingLeft: th.spacing(0),
    paddingRight: th.spacing(0),
  },
}));

const AssetForm = ({ steps = [], activeStep = 0, onStepClick = () => {}, children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <CustomTabs steps={steps} activeStep={steps[activeStep]} onStepClick={onStepClick} />
      </Box>
      {children}
    </Box>
  );
};

export default AssetForm;
