import numbro from "numbro";

export default function PriceMarker(gmaps) {
  return class PriceMarker extends gmaps.OverlayView {
    constructor(map, position, value, id, className) {
      super();
      const priceValue = value ? numbro(parseFloat(value)).formatCurrency() : ""
      this.map = map;
      this.position = position;

      this.anchor = document.createElement("div");
      this.anchor.className = className;
      this.anchor.id = `marker-${id}`;

      this.anchor.innerHTML = `<span id="marker-span-${id}" class="price-marker"><div>${priceValue}</div></span>`;

      PriceMarker.preventMapHitsAndGesturesFrom(this.anchor);
    }
    getDraggable() {
      return false;
    }
    getPosition() {
      return this.position;
    }
    /** Called when the popup is added to the map. */
    onAdd() {
      this.getPanes().floatPane.appendChild(this.anchor);
    }
    /** Called when the popup is removed from the map. */
    onRemove() {
      if (this.anchor.parentElement) {
        this.anchor.parentElement.removeChild(this.anchor);
      }
    }
    /** Called each frame when the popup needs to draw itself. */
    draw() {
      var divPosition = this.getProjection().fromLatLngToDivPixel(
        this.position
      );
      var display = "block";

      if (display === "block") {
        this.anchor.style.left = divPosition.x + "px";
        this.anchor.style.top = divPosition.y + "px";
      }
      if (this.anchor.style.display !== display) {
        this.anchor.style.display = display;
      }
    }
  };
}
