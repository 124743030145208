import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import numbro from "numbro";
import Widget from "./small-widget";

const useStyles = makeStyles((theme) => ({
  divider: {
    width: "100%",
    borderBottom: "1px solid black",
  },
  list: {
    marginLeft: theme.spacing(2),
    paddingLeft: theme.spacing(0),
  },
  listInfo: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderTop: theme.borders[6],
  },
}));

function Info() {
  const classes = useStyles();

  return (
    <Box width="324px">
      <Typography variant="body1">
        <Trans>Resultados del período seleccionado:</Trans>
      </Typography>
      <Box mt={1} mb={1} className={classes.divider} />
      <ul className={classes.list}>
        <li>
          <Typography variant="body1">
            <Trans>Importe total facturado</Trans>
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            <Trans>Reservas efectivizadas</Trans>
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            <Trans>Facturación promedio por reserva efectivizada</Trans>
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            <Trans>Cantidad de reservas efectivizadas sobre la cantidad de solicitudes generadas</Trans>
          </Typography>
        </li>
      </ul>
    </Box>
  );
}

function PeriodInformationWidget({ totalBilling = 0, bookingsMade = 0, averageRent = 0, conversionRate = 0, period }) {
  const classes = useStyles();

  return (
    <Widget title={<Trans>Información del período</Trans>} period={period} info={<Info />} width="30%">
      <Box mt={2} p={2} width="100%">
        <Box className={classes.listInfo} display="flex" flexDirection="row" alignItems="center">
          <Typography variant="subtitle1">
            <Trans>Facturación total</Trans>:
          </Typography>
          <Box ml={1 / 2}>
            <Typography variant="subtitle1" color="primary">
              {numbro(totalBilling).formatCurrency({ thousandSeparated: true, mantissa: 0 })}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.listInfo} display="flex" flexDirection="row" alignItems="center">
          <Typography variant="subtitle1">
            <Trans>Renta Media</Trans>:
          </Typography>
          <Box ml={1 / 2}>
            <Typography variant="subtitle1" color="primary">
              {isNaN(averageRent) ? "-" : numbro(averageRent).formatCurrency({ thousandSeparated: true, mantissa: 0 })}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.listInfo} display="flex" flexDirection="row" alignItems="center">
          <Typography variant="subtitle1">
            <Trans>Reservas</Trans>:
          </Typography>
          <Box ml={1 / 2}>
            <Typography variant="subtitle1" color="primary">
              {bookingsMade}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Widget>
  );
}

export default PeriodInformationWidget;
