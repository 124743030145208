import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "@lingui/macro";
import { getUsersListThunk } from "../../redux/actions/users-actions";
import { setDashboardTitle } from "../../redux/actions/misc-actions";
import { downloadBlobFiles, getBiggerRowsFromWindowSize, filterColumnsDisplayTableToString } from "../../utils";
import UsersReport from "../../components/users-report";
import api from "../../api";

const UsersReportContainer = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const status = useSelector((state) => state.users.status);
  const pagination = useSelector((state) => state.users.pagination);
  const [sort, setSort] = useState();
  const [columns, setColumns] = useState("");

  useEffect(() => {
    dispatch(setDashboardTitle(t`Centros & Propietarios`));
    dispatch(
      getUsersListThunk({
        /** this is a fix becuse of the extra height provided by the tabs. * */
        /** When refactoring this view, remove this height fix * */
        pageSize: getBiggerRowsFromWindowSize(window.innerHeight - 350),
        page: 1,
        sort,
      }),
    );
  }, [sort, dispatch]);

  const handleChangePage = (evt, page) => {
    dispatch(
      getUsersListThunk({
        pageSize: getBiggerRowsFromWindowSize(window.innerHeight - 350),
        page,
        sort,
      }),
    );
  };

  const handleTableChange = (action, tableState) => {
    const columnsDisplay = filterColumnsDisplayTableToString(tableState);
    setColumns(columnsDisplay);
    switch (action) {
      case "sort":
        setSort(tableState.sortOrder);
        break;
      default:
    }
  };

  const downloadUsersReport = async (format) => {
    const res = await api.downloadUsersListData({
      exportFormat: format,
      sort,
      columns,
    });
    downloadBlobFiles(res, `Users.${format}`);
  };

  return (
    <UsersReport
      users={users}
      pagination={pagination}
      onChangePage={handleChangePage}
      onTableChange={handleTableChange}
      status={status}
      downloadUsersReport={downloadUsersReport}
    />
  );
};

export default UsersReportContainer;
