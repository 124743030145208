/* eslint-disable import/prefer-default-export */
import React from "react";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseModalIcon from "../../../icons/close-modal-icon";

// theme
import theme from "../../../theme";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    width: ({ width }) => width,
  },
  title: {
    marginBottom: theme.spacing(1.5),
    display: "flex",
    flexDirection: "column",
  },
  closeIcon: {
    position: "absolute",
    right: "10px",
    top: "10px",
  },
  divider: {
    borderBottom: "1px solid black",
  },
}));

export const SeasonBaseModal = ({
  open = false,
  onEnter = () => {},
  onClose = () => {},
  title = "",
  children,
  width = 750,
  hasDivider = true,
  ...rest
}) => {
  const classes = useStyles({
    ...theme,
    width,
  });
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth="lg"
      open={open}
      aria-labelledby="responsive-dialog-title"
      onClose={onClose}
      TransitionProps={{
        onEnter,
      }}
      {...rest}
    >
      <Box className={classes.closeIcon}>
        <CloseModalIcon
          width={20}
          height={20}
          onClick={onClose}
          style={{
            cursor: "pointer",
          }}
        />
      </Box>
      <Box className={classes.container}>
        {title !== "" && (
          <Box className={classes.title}>
            <Typography variant="h1" component="div">
              {title}
            </Typography>
            {hasDivider && (
              <Box mt={0.5}>
                <Box className={classes.divider} />
              </Box>
            )}
          </Box>
        )}
        {children}
      </Box>
    </Dialog>
  );
};
