import { Box } from "@material-ui/core";
import React from "react";

const LayoutDashboard = ({ children }) => (
  <Box display="flex" mt={1} mx={2} height="100%" overflow="auto">
    {children}
  </Box>
);

export default LayoutDashboard;
