import { Box, Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import Chip from "@material-ui/core/Chip";
import { Trans, t } from "@lingui/macro";
// theme
import { useLingui } from "@lingui/react";
import theme from "../../../theme";
// My Components
import SimpleDialog from "../../simple-dialog";
import { formatDateUtc, isBefore } from "../../../utils/dateUtils";

const useStyles = makeStyles((th) => ({
  blockDates: {
    backgroundColor: th.palette.lightGray,
    marginRight: th.spacing(1),
    height: 48,
  },
  errorMessageContainer: {
    marginTop: th.spacing(2),
    minHeight: 36,
  },
  divider: {
    borderTop: "1px solid black",
  },
  dateChip: {
    padding: th.spacing(2),
  },
}));

const UnavailableDateRangesList = ({ unavailableDatesRange, unavailableDateRangesStatus, unblockDates }) => {
  const classes = useStyles(theme);
  const { i18n } = useLingui();

  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [openMessageDialog, setOpenMessageDialog] = useState(false);

  const handleUnblockDates = (id) => {
    unblockDates(id);
  };

  return (
    <>
      <SimpleDialog
        open={openMessageDialog}
        title={dialogTitle}
        text={dialogMessage}
        onAccept={() => setOpenMessageDialog(false)}
        acceptText={i18n._(t`Entendido`)}
      />
      <Box width="100%" height="100%">
        <Box display="flex" justifyContent="center" width="100%">
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            <Trans>Fechas Bloqueadas</Trans>
          </Typography>
        </Box>
        <Box mt={1} className={classes.divider} />
        <Box width="100%" height="250px" overflow="auto">
          {unavailableDateRangesStatus === "loading" && (
            <Box mt={2} display="flex" justifyContent="center">
              <LinearProgress style={{ height: 4, width: "60%" }} color="primary" />
            </Box>
          )}
          {unavailableDatesRange.map((d) => {
            return (
              <Box mr={1} mt={1} key={d.id}>
                {d.blockType === "booking" && (
                  <Chip
                    label={`${formatDateUtc(d.start, "DD/MM/YYYY")} -> ${formatDateUtc(d.end, "DD/MM/YYYY")}`}
                    color="default"
                    clickable
                    className={classes.dateChip}
                    onClick={() => {
                      setDialogTitle(i18n._(t`Lo sentimos`));
                      setDialogMessage(
                        i18n._(t`Este bloqueo pertenece a una booking, no es posible cancelarla por este medio`),
                      );
                      setOpenMessageDialog(true);
                    }}
                  />
                )}
                {d.blockType === "blockedDateRange" && (
                  <Chip
                    label={`${formatDateUtc(d.start, "DD/MM/YYYY")} -> ${formatDateUtc(d.end, "DD/MM/YYYY")}`}
                    color="primary"
                    clickable
                    className={classes.dateChip}
                    onClick={() => {
                      setDialogTitle(i18n._(t`Descripción`));
                      setDialogMessage(d.description);
                      setOpenMessageDialog(true);
                    }}
                    onDelete={() => {
                      handleUnblockDates(d.id);
                    }}
                  />
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default UnavailableDateRangesList;
