import React, { useEffect } from "react";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import { Box, Typography, Tooltip } from "@material-ui/core";
// theme
import Table from "../table";
import TableFooter from "../table-footer";
import Toolbar from "./toolbar";
import { limitStringChars } from "../../utils";

function ImportsList({ imports, status, pagination, onTableChange, onChangePage, onSearch }) {
  const { i18n } = useLingui();

  const memorizedToolbar = React.useMemo(() => {
    return <Toolbar onTableChange={onTableChange} onSearch={onSearch} />;
  }, [onTableChange, onSearch]);

  const memorizedTableFooter = React.useMemo(() => {
    return <TableFooter pagination={pagination} onChangePage={onChangePage} canCreate={false} />;
  }, [pagination, onChangePage]);

  useEffect(() => {
    onTableChange("changePage", { page: 0 });
  }, []);

  const presentErrors = (errors) => {
    const mensajes = errors.map((error) => {
      return `Import of line ${error.index}: ${error.error}`;
    });

    return mensajes.join("\n");
  };

  const handleTableData = () => {
    const data = imports.map((c) => {
      return {
        id: c.id,
        filename: c.filename,
        finished: c.finished,
        failed: c.failed,
        pending: c.pending,
        total: c.total,
        progress: c.progress,
        error: c.error_bag ? (
          <Tooltip
            placement="bottom-start"
            title={<Typography variant="body1">{i18n._(t`${presentErrors(c.error_bag)}`)}</Typography>}
          >
            <Typography variant="subtitle2">
              {i18n._(t`${limitStringChars(presentErrors(c.error_bag), 30)}`)}
            </Typography>
          </Tooltip>
        ) : (
          "-"
        ),
      };
    });

    return data;
  };

  const columns = [
    { label: "ID", name: "id" },
    { label: `${i18n._(t`Nombre del archivo`)}`, name: "filename" },
    { label: `${i18n._(t`Exitosos`)}`, name: "finished" },
    { label: `${i18n._(t`Fallidos`)}`, name: "failed" },
    { label: `${i18n._(t`Pendientes`)}`, name: "pending" },
    { label: `${i18n._(t`Total`)}`, name: "total" },
    { label: `${i18n._(t`Progreso`)}`, name: "progress" },
    { label: `${i18n._(t`Error`)}`, name: "error", options: { sort: false } },
  ];

  return (
    <Box pl={2} pr={2} height="100%">
      <Table
        data={handleTableData()}
        columns={columns}
        isLoading={status === "loading"}
        options={{
          download: false,
          onTableChange,
          customToolbar: () => memorizedToolbar,
          customFooter: () => memorizedTableFooter,
        }}
      />
    </Box>
  );
}

export default ImportsList;
