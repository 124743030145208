import React, { useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Box from "@material-ui/core/Box";
import config from "../../config";

const PDFViewer = ({ fileUrl }) => {
  const [numPages, setNumPages] = React.useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = config.PDF_WORKER;
  }, []);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
      <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </Box>
  );
};

export default PDFViewer;
