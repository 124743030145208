import React, { useState, useRef } from "react";
import { Box, Typography, MenuItem, Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import Loadable from "react-loadable";
import Select from "../../select";
import theme from "../../../theme-admin";
import Loader from "../../utils/loader";

const LoadableEdit = Loadable({
  loader: () => import("../../rich-text-editor"),
  loading: Loader,
});

const useStyles = makeStyles(() => ({
  content: {
    "&.MuiDialogContent-root": {
      maxHeight: "100%",
    },
  },
}));

function TextContract({ contractModels, contractModel, contractContent, updateContractModel, updateContractContent }) {
  const [isEditingContract, setIsEditingContract] = useState(false);

  const handleCloseEditor = () => {
    setIsEditingContract(false);
    updateContractContent(contractContent);
  };

  return (
    <>
      <ContractSelect
        contractModels={contractModels}
        contractModel={contractModel}
        onChangeContract={updateContractModel}
        openTextEditor={() => setIsEditingContract(true)}
      />
      <ContractEdit
        isEditingContract={isEditingContract}
        contractContent={contractContent}
        setContractContent={updateContractContent}
        onClose={handleCloseEditor}
      />
    </>
  );
}

const ContractSelect = ({ contractModels, contractModel, onChangeContract, openTextEditor }) => {
  const { i18n } = useLingui();

  return (
    <>
      <Typography variant="subtitle1">
        <Trans>Modelo de contrato</Trans>
      </Typography>
      <Box display="flex" flexDirection="row" alignItems="center" mt={1}>
        <Box width="250px">
          <Select
            label=""
            placeholder={`${i18n._(t`Modelo de contrato`)}`}
            value={contractModel?.id}
            onChange={onChangeContract}
          >
            {contractModels?.map((model) => (
              <MenuItem key={model.id} value={model.id}>
                {model.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box ml={1}>
          <Button
            variant="contained"
            color="primary"
            style={{ minWidth: "40px", height: "27px" }}
            onClick={openTextEditor}
            disabled={!contractModel}
          >
            <Trans>Editar contrato</Trans>
          </Button>
        </Box>
      </Box>
    </>
  );
};

const ContractEdit = ({ isEditingContract, contractContent, setContractContent, onClose }) => {
  const editorRef = useRef();
  const classes = useStyles(theme);

  return (
    <Dialog fullScreen open={isEditingContract}>
      <DialogContent className={classes.content}>
        <Box ref={editorRef} />
        <LoadableEdit
          data={contractContent}
          onReady={(editor) => {
            editorRef?.current?.appendChild(editor.ui.view.toolbar.element);
            editor?.editing?.view?.change((writer) => {
              writer?.setStyle(
                {
                  height: `${window.innerHeight - 110}px`,
                },
                editor.editing.view.document.getRoot(),
              );
            });
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setContractContent(data);
          }}
          onBlur={(event, editor) => {
            setContractContent(editor.getData());
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          <Trans>Guardar</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TextContract;
