import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatDateUtc } from "../../utils/dateUtils";
import { t } from "@lingui/macro";
import { setDashboardTitle } from "../../redux/actions/misc-actions";

import PerformanceAnalyzer from "../../components/performance-analyzer";
import { getPerformanceDashboardThunk } from "../../redux/actions/performance-analyzer-actions";
import api from "../../api";
import { resetAllAdSpaces } from "../../redux/actions/adspaces-actions";
import { resetAllSpaces } from "../../redux/actions/spaces-owner-actions";

const PerformanceAnalyzerContainer = () => {
  const dispatch = useDispatch();

  const flagShowAssetsOwnersFilter = useSelector((state) => state.misc.featureFlags["flag.exp.show_assets_owners"]);
  const filters = useSelector((state) => state.performanceAnalyzer.filters);
  const userManagedAssets = useSelector((state) => state.user.data.managedAssets);
  const dashboardStatus = useSelector((state) => state.performanceAnalyzer.dashboardStatus);
  const dashboardError = useSelector((state) => state.performanceAnalyzer.dashboardError);
  const dashboard = useSelector((state) => state.performanceAnalyzer.data);

  const [goals, setGoals] = useState({ status: "initial" });
  const [owners, setOwners] = useState({ status: "initial", data: []});

  const getAssetOwners = async () => {
    const res = await api.getOwnersWithAssets();
    if (res.status === 200) {
      setOwners({
        data: Object.values(res.data?.payload) || [],
        status: "success",
      });
    } else {
      setOwners({
        status: "error",
      });
    }
  };

  const getAnnualGoals = async (filters) => {
    const res = await api.getAnnualGoals(filters);
    if (res.status === 200) {
      setGoals({
        data: res.data?.payload ? Object.keys(res.data.payload).map((k) => res.data.payload[k]) : [],
        status: "success",
      });
    } else {
      setGoals({
        status: "error",
      });
    }
  };

  const dateFilterFormatted = (filters) => {
    return `${formatDateUtc(filters.start, "DD/MM/YYYY")} - ${formatDateUtc(filters.end, "DD/MM/YYYY")}`;
  };

  const handleChangeFilters = (filters) => {
    dispatch(getPerformanceDashboardThunk(filters));
    getAnnualGoals(filters);
  };

  const getPerformanceFilters = () => {
    const assets = JSON.parse(localStorage.getItem("performanceAssets")) || [];
    const clients = flagShowAssetsOwnersFilter ? JSON.parse(localStorage.getItem("performanceClients")) : [];
    const dates = JSON.parse(localStorage.getItem("performanceDates")) || filters;
    handleChangeFilters({ ...dates, assets, clients });
    getAssetOwners();
  };

  useEffect(() => {
    dispatch(setDashboardTitle(t`Rendimiento`));
    getPerformanceFilters();
    dispatch(resetAllAdSpaces());
    dispatch(resetAllSpaces());
  }, []);

  return (
    <PerformanceAnalyzer
      dashboard={dashboard}
      filters={filters}
      filtersOptions={{
        assets: userManagedAssets,
        clients: owners.data,
      }}
      loading={dashboardStatus === "loading" || goals.status === "loading"}
      filteredDate={dateFilterFormatted(filters)}
      error={dashboardError}
      goalsData={goals.data}
      onChangeFilters={handleChangeFilters}
      showAssetsOwnersFilter={flagShowAssetsOwnersFilter}
    />
  );
};

export default PerformanceAnalyzerContainer;
