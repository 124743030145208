import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Trans } from "@lingui/macro";
import { Box, Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AddSharp, RemoveSharp } from "@material-ui/icons";
import Note from "../../notes-modal/note";
// theme
import theme from "../../../theme-admin";

const CONTENT_MAX_HEIGHT = "280px";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
  },
  accordionContainer: {
    borderBottom: theme.borders[6],
    minHeight: theme.spacing(4.5),
    display: "flex",
    alignContent: "center",
    alignItems: "center",
  },
  customAccordion: {
    boxShadow: "none",
    width: "100%",
    height: "100%",
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "32px",
      height: "32px",
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
    "& .MuiAccordionSummary-root": {
      height: "100%",
      padding: "0px 8px",
      minHeight: theme.spacing(3),
    },
  },
  title: {
    borderBottom: theme.borders[1],
    padding: theme.spacing(1),
  },
}));

const NoteList = ({
  selectedBooking = 0,
  getBookingNotes = () => {},
  bookingNotes = {},
  companyNotes = [],
  companyNotesStatus,
}) => {
  const classes = useStyles(theme);
  const [sortedBookingNotes, setSortedBookingNotes] = useState([]);
  const [isNoteExpanded, setIsNoteExpanded] = useState({});

  useEffect(() => {
    if (selectedBooking > 0 && companyNotes.length > 0) {
      const compRating = [...companyNotes];
      const index = compRating.findIndex((cr) => cr.booking_id === selectedBooking);
      const elem = compRating.find((cr) => cr.booking_id === selectedBooking);
      compRating.splice(index, 1);
      compRating.unshift(elem);
      setSortedBookingNotes(compRating);
    }
  }, [selectedBooking, companyNotes]);

  useEffect(() => {
    if (selectedBooking) {
      getBookingNotes(selectedBooking);
    }
  }, [selectedBooking]);

  const handleOpenAccordion = (id) => (evt, exp) => {
    if (exp) {
      getBookingNotes(id);
    }
  };

  const compRatings = selectedBooking > 0 ? sortedBookingNotes : companyNotes;

  const handleOnClickRow = (bookindId) => {
    isNoteExpanded[bookindId] = !isNoteExpanded[bookindId];
    setIsNoteExpanded({ ...isNoteExpanded });
  };

  useEffect(() => {
    const isExpanded = {};
    compRatings.map((cr) => {
      isExpanded[cr.booking_id] = cr.booking_id === selectedBooking;
    });
    setIsNoteExpanded(isExpanded);
  }, [compRatings]);

  return (
    <Box className={classes.container}>
      {companyNotesStatus === "loading" ? (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" height={CONTENT_MAX_HEIGHT} mt={1}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {compRatings && compRatings.length ? (
            <>
              <Box className={classes.title}>
                <Typography variant="body2" color="textPrimary" style={{ textTransform: "uppercase", fontWeight: 700 }}>
                  <Trans>Reserva</Trans>
                </Typography>
              </Box>
              {compRatings.map((cr, index) => (
                <Box key={`${index}-note`} className={classes.accordionContainer}>
                  <Accordion
                    defaultExpanded={cr.booking_id === selectedBooking}
                    onChange={() => handleOpenAccordion(cr.booking_id)}
                    className={classes.customAccordion}
                    expanded={isNoteExpanded[cr.booking_id] ? isNoteExpanded[cr.booking_id] : false}
                  >
                    <AccordionSummary onClick={() => handleOnClickRow(cr.booking_id)}>
                      <Box width="100%">
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <Box display="flex" alignItems="center" mr={1}>
                            {isNoteExpanded[cr.booking_id] ? (
                              <RemoveSharp fontSize="small" />
                            ) : (
                              <AddSharp fontSize="small" />
                            )}
                          </Box>
                          <Typography variant="body2" style={{ fontWeight: 700 }}>
                            #{cr.booking_id} {cr.project_name} | {cr.brand_name}
                          </Typography>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {bookingNotes[cr.booking_id]?.status === "loading" && (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          width="100%"
                          height={CONTENT_MAX_HEIGHT}
                          mt={1}
                        >
                          <CircularProgress />
                        </Box>
                      )}
                      {bookingNotes[cr.booking_id]?.status === "success" && (
                        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                          {bookingNotes[cr.booking_id]?.notes?.map((n) => (
                            <Note
                              key={n.created_at}
                              author={n.author}
                              title={n.title}
                              note={n.note}
                              date={n.created_at}
                            />
                          ))}
                        </Box>
                      )}
                      {bookingNotes[cr.booking_id]?.status === "error" && (
                        <Box mt={1} display="flex" justifyContent="center" width="100%">
                          <Typography variant="body1" color="error">
                            <Trans>Ha ocurrido un error. Por favor intente de nuevo.</Trans>
                          </Typography>
                        </Box>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ))}
            </>
          ) : (
            <Box display="flex" width="100%" justifyContent="center" mt={3} mb={3}>
              <Typography variant="h4" color="primary">
                <Trans>No hay notas para mostrar</Trans>
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default NoteList;
