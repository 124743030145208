import React, { useState } from "react";
import { Box, Grid, Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { ValidatorForm } from "react-material-ui-form-validator";
import theme from "../../../theme";

import Calendar from "../../calendar";
import { formatDateUtc } from "../../../utils/dateUtils";
import UnavailableDateRangesList from "../../listing-form/calendar-step/unavailable-date-ranges-list";
import BlockDatesDialog from "../../listing-form/calendar-step/block-dates-dialog";
import AdSpaceStepButtons from "../ad-space-step-buttons";
import FinishDialog from "../../../containers/ad-spaces-form/finish-dialog";

const useStyles = makeStyles((th) => ({
  container: {
    height: "100%",
    width: "100%",
    color: th.palette.black,
    marginTop: th.spacing(2),
  },
  blockButton: {
    marginRight: th.spacing(1),
    width: 150,
    height: 42,
  },
  continueButton: {
    width: 190,
    height: 42,
    [th.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  validatorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    height: "100%",
    width: "100%",
  },
}));

const CalendarStep = ({
  bookingsBlocked = [],
  blockDates = () => {},
  unblockDate = () => {},
  getInProgressBookings = () => {},
  finish = () => {},
  adSpaceUnavailableDates = {},
  adSpaceUnavailableDatesRange = [],
  blockedDatesStatus = "initial",
  blockDatesError = "",
  status = "",
  findingBookingsStatus = "loading",
  onSetOpenDelete,
  isNew,
}) => {
  const classes = useStyles(theme);

  const { i18n } = useLingui();

  const [open, setOpen] = useState(false);
  const [datesSelected, setDatesSelected] = useState(["", ""]);
  const [openBlockDatesDialog, setOpenBlockDatesDialog] = useState(false);
  const [blockDescription, setBlockDescription] = useState("");

  const handleSelectDates = (dates) => {
    setDatesSelected(dates);
  };

  const handleBlockDates = () => {
    blockDates(
      formatDateUtc(datesSelected[0], "YYYY-MM-DD"),
      formatDateUtc(datesSelected[1], "YYYY-MM-DD"),
      blockDescription,
    );
    setDatesSelected(["", ""]);
  };

  const handleIsTileDisabled = ({ date }) => {
    const formattedDate = formatDateUtc(date, "YYYY-MM-DD");

    return adSpaceUnavailableDates[formattedDate] !== undefined;
  };

  const getErrorText = (error) => {
    switch (error) {
      case "space_not_available":
        return i18n._(t`El espacio no se encuentra disponible. Revisa las fechas elegidas.`);
      default:
        return i18n._(t`No fue posible completar la acción.`);
    }
  };

  const handleSubmit = () => {
    getInProgressBookings(datesSelected[0] || null, datesSelected[1] || null);
    setOpenBlockDatesDialog(true);
  };

  const disableSubmit = datesSelected[0] === "" || datesSelected[1] === "";

  return (
    <Box className={classes.container}>
      <FinishDialog
        open={open}
        onSubmit={finish}
        onClose={() => {
          setOpen(false);
        }}
      />
      <BlockDatesDialog
        open={openBlockDatesDialog}
        desc={blockDescription}
        bookingsBlocked={bookingsBlocked}
        onDescChange={setBlockDescription}
        onAccept={handleBlockDates}
        onClose={() => {
          setOpenBlockDatesDialog(false);
        }}
      />
      <ValidatorForm className={classes.validatorContainer} onSubmit={() => {}}>
        <Container sx={{ overflowY: "auto" }}>
          <Grid container spacing={2}>
            <Grid item sm={6} md={8}>
              <Box display="flex" flexDirection="column" mb={1}>
                <Typography variant="body1" color="secondary" style={{ fontWeight: "bold" }}>
                  <Trans>
                    Muestra la disponibilidad de tus espacios publicitario y bloquea el calendario si tienes reservas
                    anteriores
                  </Trans>
                </Typography>
              </Box>
              <Calendar
                startDate={datesSelected[0] || null}
                endDate={datesSelected[1] || null}
                tileDisabled={handleIsTileDisabled}
                onChange={handleSelectDates}
                isDouble
              />
              {blockDatesError && (
                <Typography variant="body1" color="error">
                  {getErrorText(blockDatesError)}
                </Typography>
              )}
              {findingBookingsStatus === "error" && (
                <Typography variant="body1" style={{ color: theme.palette.error.main }}>
                  <Trans>Ha ocurrido un error. Por favor intente de nuevo.</Trans>
                </Typography>
              )}
            </Grid>
            <Grid item sm={6} md={4}>
              <UnavailableDateRangesList
                unavailableDatesRange={adSpaceUnavailableDatesRange}
                unavailableDateRangesStatus={blockedDatesStatus}
                unblockDates={unblockDate}
              />
            </Grid>
          </Grid>
        </Container>
        <AdSpaceStepButtons
          labelSubmit={<Trans>Bloquear fechas</Trans>}
          handleSubmit={handleSubmit}
          disableSubmit={disableSubmit}
          disableContinue={status === "loading"}
          onSetOpenDelete={onSetOpenDelete}
          showDeleteAdSpace={!isNew}
          widthNext={150}
        />
      </ValidatorForm>
    </Box>
  );
};

export default CalendarStep;
