import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// icons
import React, { useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
// theme
// local components
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HeaderWizardInfo from "./header-wizard-info";
import WizardFooter from "./footer";
import TextField from "../text-field";
import theme from "../../theme";
import { currencySymbol } from "../../utils/currencyUtils";
import { MIN_REQUIRED_DAYS_MONTHLY_PAYMENT, MIN_REQUIRED_DAYS_WEEKLY_PAYMENT } from "../../redux/reducers";
import { formatDateUtc } from "../../utils/dateUtils";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: theme.spacing(1),
  },
  divider: {
    borderBottom: theme.borders[6],
  },
  button: {
    width: "107px",
    height: "35px",
  },
  priceInput: {
    width: "100%",
    height: "33px",
    borderRadius: 0,
    border: theme.borders[1],
    paddingTop: theme.spacing(1 / 2),
    paddingBottom: theme.spacing(1 / 2),
    paddingLeft: theme.spacing(2),
  },
}));

const StepPriceComponent = ({ onSubmit, onBack, onMakeSpecialOffer, daysCount, values }) => {
  const classes = useStyles(theme);

  const { i18n } = useLingui();
  const [newPrice, setNewPrice] = useState("");

  const handleChangePaymentPlan = ({ target }) => {
    onMakeSpecialOffer(newPrice, target.value);
  };

  const handleMakeSpecialOffer = ({ target }) => {
    setNewPrice(target.value);
    onMakeSpecialOffer(target.value, values.paymentPlan);
  };

  const canSubmit = values.paymentPlan && values.price > 0;
  const canSelectMonthly = daysCount >= MIN_REQUIRED_DAYS_MONTHLY_PAYMENT || values.longTerm;
  const canSelectWeekly = daysCount >= MIN_REQUIRED_DAYS_WEEKLY_PAYMENT || values.longTerm;

  return (
    <>
      <Box>
        {values.paymentPlan && values.price > 0 && (
          <Box mb={2}>
            <HeaderWizardInfo
              startDate={formatDateUtc(values.startDate)}
              endDate={formatDateUtc(values.endDate)}
              periodPrice={values.periodPrice}
              bookingPrice={values.price}
              paymentPlan={values.paymentPlan}
              longTerm={values.longTerm}
            />
          </Box>
        )}

        <Box mb={1}>
          <Typography variant="subtitle1">
            <Trans>Seleccione la forma de pago*</Trans>
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between" mb={1}>
          <Box mt={1 / 2}>
            <RadioGroup
              row
              aria-label="payment_plan"
              name="payment_plan"
              onChange={handleChangePaymentPlan}
              value={values.paymentPlan}
            >
              <FormControlLabel
                value="Total"
                disabled={values.longTerm}
                control={<Radio color="primary" />}
                label={i18n._(t`Total`)}
                labelPlacement="end"
              />
              <Box ml={2}>
                <FormControlLabel
                  value="Monthly"
                  disabled={!canSelectMonthly}
                  control={<Radio color="primary" />}
                  label={i18n._(t`Mensual`)}
                  labelPlacement="end"
                />
              </Box>
              <Box ml={2}>
                <FormControlLabel
                  value="Weekly"
                  disabled={!canSelectWeekly}
                  control={<Radio color="primary" />}
                  label={i18n._(t`Semanal`)}
                  labelPlacement="end"
                />
              </Box>
            </RadioGroup>
          </Box>
        </Box>
        <Box mt={1} className={classes.divider} />
        {values.paymentPlan && (
          <>
            <Box mt={2} display="flex" flexDirection="row" alignItems="center">
              <ValidatorForm style={{ width: "100%" }} onSubmit={onSubmit}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  alignContent="center"
                  width="100%"
                >
                  <Box flex={2} mr={2}>
                    <Box display="flex" flexDirection="row" mb={2} alignItems="center">
                      <Typography variant="subtitle1">
                        {values.paymentPlan === "Total" && <Trans>Precio total</Trans>}
                        {values.paymentPlan === "Monthly" && <Trans>Cuota mensual</Trans>}
                        {values.paymentPlan === "Weekly" && <Trans>Cuota semanal</Trans>}
                      </Typography>
                    </Box>
                    <TextField
                      type="number"
                      placeholder={i18n._(t`Importe`)}
                      value={newPrice}
                      style={{ width: "100%" }}
                      onChange={handleMakeSpecialOffer}
                      InputProps={{
                        className: `${classes.priceInput}`,
                        startAdornment: <InputAdornment position="start">{currencySymbol()}</InputAdornment>,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className={classes.input}
                    />
                  </Box>
                </Box>
              </ValidatorForm>
            </Box>
            <Box mt={2} className={classes.divider} />
          </>
        )}
      </Box>
      <WizardFooter step={3} onNext={onSubmit} canSubmit={canSubmit} onBack={onBack} />
    </>
  );
};

export default StepPriceComponent;
