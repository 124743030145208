import React from "react";
import { VisibilityOffSharp, VisibilitySharp } from "@material-ui/icons";
import SecondaryButton from "../../secondary-button";
import { makeStyles } from "@material-ui/core/styles";
import { Trans } from "@lingui/macro";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import theme from "../../../theme";
import CloseIcon from "../../../icons/close-modal-icon";
import Switch from "../../custom-switch";

const useStyles = makeStyles((theme) => ({
  divider: {
    width: "100%",
    borderBottom: `1.5px solid ${theme.palette.black}`,
  },
  button: {
    width: "114px",
    height: "40px",
  },
  closeIcon: {
    cursor: "pointer",
    position: "absolute",
    width: 15,
    height: 15,
    zIndex: 999,
    right: 18,
    top: 10,
  },
}));

const FinishDialog = ({
  open = false,
  status = "initial",
  hidden = false,
  publishSpace = () => {},
  onClose = () => {},
  onSubmit = () => {},
}) => {
  const classes = useStyles(theme);

  return (
    <Dialog
      PaperProps={{
        style: {
          maxWidth: "400px",
          width: "400px",
          borderRadius: "19px",
        },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <Box className={classes.closeIcon} onClick={onClose}>
          <CloseIcon fill={theme.palette.black} width="18px" height="18px" />
        </Box>
        <Typography variant="h1">
          <Trans>Espacio</Trans>
        </Typography>
        <Box mt={1} className={classes.divider}></Box>
        <Box mt={2}>
          <Typography variant="subtitle1">
            <Trans>Tu espacio está listo. ¿Quieres hacerlo visible?</Trans>
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          width="100%"
          mt={2}
        >
          <Box mr={1}>
            <Typography variant="subtitle1">
              <Trans>Mostrar</Trans>
            </Typography>
          </Box>
          <Switch checked={!hidden} onChange={publishSpace} />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          width="100%"
          mt={1}
          mb={1}
        >
          <Box mr={1}>
            <SecondaryButton disabled={status === "loading"} onClick={onClose}>
              <Trans>Volver</Trans>
            </SecondaryButton>
          </Box>
          <Button
            disabled={status === "loading"}
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={onSubmit}
          >
            <Trans>Continuar</Trans>
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default FinishDialog;
