/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { Trans } from "@lingui/macro";
import { Box, Typography } from "@material-ui/core";
import { AddSharp } from "@material-ui/icons";
import { MAX_MB_ATTACHMENTS } from "../../../redux/reducers";
import SecondaryButton from "../../secondary-button";
import InputFileUpload from "../../file-upload/input-file-upload";

const AddAttachmentBtn = ({ onStartUpdateFile, onUpdateFile, onErrorUpdateFile, company }) => {
  return (
    <Box display="flex" alignItems="center" width="100%" whiteSpace="nowrap">
      <SecondaryButton>
        <label
          htmlFor="asset-attachment"
          style={{ cursor: "pointer", display: "flex", alignContent: "center", alignItems: "center" }}
        >
          <InputFileUpload
            id="asset-attachment"
            name="asset-attachment"
            maxSize={MAX_MB_ATTACHMENTS * 1024 * 1024} // 30MB
            accept="image/jpg,image/jpeg,image/png,application/pdf"
            style={{ display: "none" }}
            onStart={onStartUpdateFile}
            onComplete={onUpdateFile}
            onError={onErrorUpdateFile}
            model="company"
            companyId={company.id}
          />
          <Trans>Añadir archivo</Trans>
          <AddSharp />
        </label>
      </SecondaryButton>
      <Box ml={1}>
        <Typography
          variant="subtitle1"
          style={{
            color: "rgba(0, 0, 0, 0.26)",
            textAlign: "center",
          }}
        >
          <Trans>Max.</Trans> {MAX_MB_ATTACHMENTS}MB
        </Typography>
      </Box>
    </Box>
  );
};

export default AddAttachmentBtn;
