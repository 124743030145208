import { Box, Container, Grid, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
// Local components
import { ValidatorForm } from "react-material-ui-form-validator";
// theme
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import theme from "../../../theme";
import Select from "../../select/select-validator";
// My Components
import StepButtons from "../step-buttons";
import BaseStep from "../step-base";
import Map from "../../google-maps";

import config from "../../../config";
import ConfirmationModal from "./confirmation-modal";

const useStyles = makeStyles((th) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    width: "100%",
  },
  inputs: {
    width: "100%",
    borderRadius: 0,
    border: th.borders[2],
    paddingTop: th.spacing(0.5),
    paddingBottom: th.spacing(0.5),
    paddingLeft: th.spacing(2),
  },
  inputLabel: {
    fontSize: th.typography.body2.fontSize,
    transform: "translate(0, -5px) scale(1)",
    fontWeight: "bold",
  },
  inputError: {
    color: th.palette.red,
    borderColor: th.palette.red,
  },
  locationError: {
    border: `2px solid ${th.palette.red}`,
  },
  selectMargin: {
    marginTop: 0,
  },
}));

const INITIAL_INPUT_STATUS = {
  asset: "initial",
  level: "initial",
  location: "initial",
};

const StepContent = ({
  space,
  handleBack,
  handleNext,
  onSubmit,
  cities,
  assets,
  status,
  maps,
  onSetOpenDelete,
  isNew,
}) => {
  const [values, setValues] = useState(space);
  const [zoom, setZoom] = useState(13);
  const [submited, setSubmited] = useState(false);
  // TECHDEBT: bad practice
  // we need this to avoid selecting first option when selecting other shopping
  const [notUpdateCity, setNotUpdateCity] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [isLocationSelected, setIsLocationSelected] = useState(false);
  const [inputStatus, setInputStatus] = useState(INITIAL_INPUT_STATUS);
  const [paintMarker, setPaintMarker] = useState(false);
  const [mapCenter, setMapCenter] = useState();
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const disabledInputs = parseInt(space.id) > 0;

  const classes = useStyles({ theme, inputStatus });
  const markerRef = useRef();
  const mapRef = useRef();
  const labelNext = isNew ? <Trans>Crear</Trans> : <Trans>Guardar</Trans>;

  useEffect(() => {
    if (disabledInputs) {
      setValues(space);
    }
  }, [space, assets]);

  useEffect(() => {
    if (mapRef.current && !markerRef.current && !mapCenter) {
      if (!paintMarker) {
        setPaintMarker(true);
      } else {
        setTimeout(() => setCurrentMarker(mapRef.current, mapRef.current?.latitude, mapRef.current?.longitude), 1000);
      }
      setTimeout(() => {
        mapRef.current.setZoom(16);
      }, 1000);
    }
    if (!notUpdateCity && !space.id && cities?.length && mapRef.current) {
      setNotUpdateCity(true);
    }
  }, [mapRef.current, markerRef.current, cities, space]);

  useEffect(() => {
    if (submited && status === "success" && isNew) {
      setOpenModalConfirmation(true);
    }
  }, [submited, status]);

  useEffect(() => {
    if (paintMarker) {
      setMapCenter({ lat: space.latitude, lng: space.longitude });
      setTimeout(() => {
        setCurrentMarker(mapRef.current, space.latitude, space.longitude);
        setIsLocationSelected(true);
      }, 1000);
    }
  }, [paintMarker, space]);

  const handleSubmit = () => {
    setInputStatus(INITIAL_INPUT_STATUS);
    let canContinue = true;
    if (!values.asset.id) {
      setInputStatus((sts) => ({ ...sts, asset: "error" }));
      canContinue = false;
    }

    const availableLevels = config.LEVEL_NAMES?.length ? config.LEVEL_NAMES : ["-1", "0", "1", "2"];
    if (availableLevels.indexOf(values.level) < 0) {
      setInputStatus((sts) => ({ ...sts, level: "error" }));
      canContinue = false;
    }
    if (!isLocationSelected) {
      setInputStatus((sts) => ({ ...sts, location: "error" }));
      canContinue = false;
    }
    if (canContinue) {
      const { latitude, longitude } = markerRef.current;

      onSubmit({
        ...values,
        latitude,
        longitude,
      });
      setSubmited(true);
    }
  };

  const setCurrentMarker = (map, latitude, longitude) => {
    if (!maps) return;

    const newMarker = new maps.Marker({
      position: { lat: latitude, lng: longitude },
      map,
    });

    if (markerRef.current) markerRef.current.marker.setMap(null);

    markerRef.current = {
      longitude,
      latitude,
      marker: newMarker,
    };
  };

  const handleChangeAsset = ({ target }) => {
    const asset = getAsset(target.value);

    if (!asset) return;

    setValues((state) => ({
      ...state,
      asset,
      latitude: asset.latitude,
      longitude: asset.longitude,
    }));
    setZoom(16);
    setMapCenter({ lat: asset.latitude, lng: asset.longitude });
  };

  const handleChangeLevel = ({ target }) => {
    setValues((state) => ({
      ...state,
      level: target.value,
    }));
  };

  const handleClickMap = (map, evt) => {
    const latitude = evt.latLng.lat();
    const longitude = evt.latLng.lng();

    setValues((v) => ({ ...v, latitude, longitude }));
    setIsLocationSelected(true);

    setCurrentMarker(map, latitude, longitude);
  };

  const checkInputError = (input) => {
    return inputStatus[input] === "error" ? classes.inputError : "";
  };

  const getAsset = (id) => {
    return assets.find((a) => a.id === id);
  };

  const { i18n } = useLingui();

  const somethingHasChanged = JSON.stringify(values) !== JSON.stringify(space);
  const handleResetChanges = () => setValues(space);

  const disableContinue = status === "loading" || (!isNew && !somethingHasChanged);

  return (
    <ValidatorForm className={classes.container} onSubmit={handleSubmit}>
      <Box px={2} pt={2} sx={{ overflowY: "auto" }}>
        {assets.length ? (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Select
                label={`${i18n._(t`Centro Comercial`)}`}
                placeholder={`${i18n._(t`Ej: Chamberi`)}`}
                InputLabelProps={{
                  className: `${classes.inputLabel} ${checkInputError("asset")}`,
                }}
                InputProps={{
                  className: `${classes.inputs} ${checkInputError("asset")}`,
                }}
                value={values.asset?.id ? values.asset?.id : ""}
                onChange={handleChangeAsset}
                disabled={disabledInputs}
                fullWidth
                selectClassName={classes.selectMargin}
              >
                {assets.map((a) => (
                  <MenuItem key={a.id} value={a.id}>
                    {a.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                label={`${i18n._(t`Planta`)}`}
                placeholder={`${i18n._(t`Ej: Primer planta`)}`}
                value={values.level ? values.level : ""}
                InputLabelProps={{
                  className: `${classes.inputLabel} ${checkInputError("level")}`,
                }}
                InputProps={{
                  className: `${classes.inputs} ${checkInputError("level")}`,
                }}
                onChange={handleChangeLevel}
                fullWidth
                selectClassName={classes.selectMargin}
              >
                {config.LEVEL_NAMES?.length
                  ? config.LEVEL_NAMES.map((lvl) => (
                      <MenuItem key={lvl} value={lvl} style={{ fontSize: 12 }}>
                        {i18n._(t`${lvl}`)}
                      </MenuItem>
                    ))
                  : ["-1", "0", "1", "2"].map((lvl) => (
                      <MenuItem key={lvl} value={lvl} style={{ fontSize: 12 }}>
                        {lvl}
                      </MenuItem>
                    ))}
              </Select>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        <Box mt={3}>
          <Typography
            style={{
              color: inputStatus.location === "error" ? theme.palette.red : theme.palette.black,
            }}
            variant="subtitle1"
          >
            <Trans>Ubicación</Trans>
          </Typography>
        </Box>
        <Box width="100%" mt={1}>
          <Typography variant="body1">
            <Trans>Para seleccionar la ubicación del espacio en el asset, simplemente haz click en el mapa</Trans>
          </Typography>
          <Box className={inputStatus.location === "error" ? classes.locationError : ""}>
            <Map
              height="278px"
              width="100%"
              center={mapCenter}
              mapRef={mapRef}
              onClickMap={handleClickMap}
              zoom={zoom}
            />
          </Box>
        </Box>
        <Box display="flex" style={{ float: "right", marginBottom: theme.spacing(3) }}>
          {validationError && (
            <Typography variant="body1" color="error">
              {`${i18n._(t`Por favor complete todos los campos.`)}`}
            </Typography>
          )}
        </Box>
      </Box>
      <StepButtons
        hasBack={isNew}
        handleBack={handleBack}
        labelNext={labelNext}
        disableShowSpace={isNew}
        hasNext
        disableContinue={disableContinue}
        onSetOpenDelete={onSetOpenDelete}
        hasResetChanges={!isNew}
        onResetChanges={handleResetChanges}
        disableResetChanges={!somethingHasChanged}
      />
      <ConfirmationModal open={openModalConfirmation} onGoToNewSpace={handleNext} title={space.title} />
    </ValidatorForm>
  );
};

StepContent.propTypes = {
  space: PropTypes.object.isRequired,
  handleNext: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  cities: PropTypes.array.isRequired,
  assets: PropTypes.array.isRequired,
};

const AddressStep = (props) => {
  const { i18n } = useLingui();

  const Helper = () => (
    <>
      <Typography variant="body2">
        <Trans>La dirección exacta no estará publicada</Trans>.
      </Typography>
      <Typography variant="body2">
        <Trans>
          Una vez que un cliente coordine una visita o reserve el espacio, los datos de contacto serán compartidos
        </Trans>
        .
      </Typography>
    </>
  );

  return (
    <BaseStep mainContent={<StepContent {...props} />} helpContent={<Helper />} title={`${i18n._(t`Ubicación`)}`} />
  );
};

export default AddressStep;
