import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "@reach/router";
import { t } from "@lingui/macro";
import AdSpaceDashboardComponent from "../../components/ad-spaces-form";
import { useNavigate } from "/src/hooks/navigation";
import { getAdSpaceThunk } from "../../redux/actions/adspaces-actions";
import { setDashboardTitle } from "../../redux/actions/misc-actions";
import api from "../../api";
import { resetAllSpaces } from "../../redux/actions/spaces-owner-actions";

const AdSpacesDashboard = () => {
  const { navigate } = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const isNew = params.adSpaceId === "new";
  const STEPS = isNew ? ["title", "location"] : ["title", "location", "information", "calendar"];

  const [activeStep, setActiveStep] = useState(0);
  const [deleteStatus, setDeleteStatus] = useState("initial");

  const adSpaceTitle = useSelector((state) => state.adspaces.adSpace.title);
  const adspace = useSelector((state) => state.adspaces.adSpace);

  const handleGoBackToAdSpaceList = () => {
    navigate("/administrator/adspaces");
  };

  const handleStepClick = (step) => {
    navigate(`/administrator/adspaces/${params.adSpaceId}/${step}`);
  };

  const handleDeleteAdSpace = async () => {
    setDeleteStatus("loading");
    const res = await api.deleteAdSpace(params.adSpaceId);
    if (res.status === 200) {
      setDeleteStatus("success");
    } else {
      setDeleteStatus("error");
    }
  };

  useEffect(() => {
    const title = params.adSpaceId === "new" || !adSpaceTitle ? `${t`Crear`}` : `${t`Editar`}`;
    dispatch(setDashboardTitle(`${t`Espacios Publicitarios`} - ${title}: ${adSpaceTitle}`));
    dispatch(resetAllSpaces());
  }, [adSpaceTitle, params.adSpaceId]);

  useEffect(() => {
    setActiveStep(STEPS.indexOf(params.stepName));
    if (params.adSpaceId > 0 && adspace.id === -1) {
      dispatch(getAdSpaceThunk({ adSpaceId: params.adSpaceId }));
    }
  }, [params.stepName, params.adSpaceId]);

  const resolveStep = () => {
    switch (params.stepName) {
      case "title":
        return 0;
      case "location":
        return 1;
      case "information":
        return 2;
      case "calendar":
        return 3;
      default:
        return 0;
    }
  };

  return (
    <AdSpaceDashboardComponent
      isNew={isNew}
      step={resolveStep()}
      adSpaceId={params.adSpaceId}
      steps={STEPS}
      activeStep={activeStep}
      goBackToAdSpaceList={handleGoBackToAdSpaceList}
      deleteAdSpace={handleDeleteAdSpace}
      deleteStatus={deleteStatus}
      adSpaceTitle={adSpaceTitle}
      onStepClick={handleStepClick}
    />
  );
};

export default AdSpacesDashboard;
