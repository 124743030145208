import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const BigStarIcon = ({ fill = "#F2C94C", width = 29, height = 27, ...rest }) => (
  <SvgIcon width={width} height={height} viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M14.5 0L19.3751 7.79001L28.2903 10.0193L22.3881 17.063L23.0229 26.2307L14.5 22.794L5.97711 26.2307L6.61194 17.063L0.709681 10.0193L9.62491 7.79001L14.5 0Z"
      fill={fill}
    />
  </SvgIcon>
);

export default BigStarIcon;
