import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { makeStyles } from "@material-ui/core/styles";
import SecondaryButton from "../secondary-button";
import VisibilitySwitch from "../../containers/listing-form/visibility-switch";
import theme from "../../theme";
import TrashIcon from "../../icons/trash-icon";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "sticky",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    bottom: "0px",
    boxShadow: "0px -4px 11px rgba(0, 0, 0, 0.25)",
    backgroundColor: theme.palette.white,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    zIndex: 999,
  },
  backButton: {
    marginRight: theme.spacing(1),
    width: 150,
    height: 42,
  },
  continueButton: {
    height: 34,
  },
}));

function StepButtons({
  handleContinue = () => {},
  handleBack = () => {},
  onSetOpenDelete = () => {},
  disableBack = false,
  disableContinue = false,
  disableShowSpace = false,
  hasNext = false,
  hasBack = false,
  labelNext,
  labelBack,
  disableResetChanges = false,
  onResetChanges,
  hasResetChanges = true,
  widthNext = 114,
}) {
  const classes = useStyles(theme);

  return (
    <Box className={classes.container}>
      {!disableShowSpace ? (
        <Box display="flex">
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" mr={1}>
            <Typography variant="body1">
              <Trans>Mostrar espacio</Trans>
            </Typography>
            <VisibilitySwitch />
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center" sx={{ borderLeft: theme.borders[6] }}>
            <Button
              variant="text"
              onClick={() => onSetOpenDelete(true)}
              className={classes.headerButton}
              style={{ color: theme.palette.grayLabel }}
            >
              <TrashIcon fill={theme.palette.grayLabel} style={{ width: "13px", marginRight: theme.spacing(1 / 2) }} />
              <Trans>Eliminar espacio</Trans>
            </Button>
          </Box>
        </Box>
      ) : (
        <Box />
      )}
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box>
          {hasBack && (
            <SecondaryButton disabled={disableBack} onClick={handleBack}>
              {labelBack ? <>{labelBack}</> : <Trans>Atrás</Trans>}
            </SecondaryButton>
          )}
        </Box>
        <Box>
          {hasResetChanges && (
            <SecondaryButton disabled={disableResetChanges} onClick={onResetChanges}>
              <Trans>Olvidar cambios</Trans>
            </SecondaryButton>
          )}
        </Box>
        <Box>
          {hasNext && (
            <Button
              onClick={handleContinue}
              disabled={disableContinue}
              type="submit"
              variant="contained"
              color="primary"
              className={classes.continueButton}
              sx={{ width: widthNext }}
            >
              {labelNext ? <>{labelNext}</> : <Trans>Continuar</Trans>}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default StepButtons;
