import React, { useState } from "react";
import { Box, Grid, Typography, Button, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { getFileNameFromUrl } from "../../utils";
import { isSameOrBeforeDate } from "../../utils/dateUtils";
import { MAX_MB_ATTACHMENTS } from "../../redux/reducers";
import theme from "../../theme";
import LoadingBar from "../loading-bar";
import WizardFooter from "./footer";
import FileUploadButton from "../file-upload";
import TrashIcon from "../../icons/trash-icon";
import DownloadIcon from "../../icons/download-icon";
import TextContract from "../../containers/create-booking-wizard/contract-data/text-contract";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
    height: "60px",
    width: "100%",
  },
  divider: {
    borderBottom: theme.borders[6],
    width: "100%",
  },
  rightContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  uploadSuccessFile: {
    display: "flex",
    alignItems: "center",
    background: theme.palette.successGreen,
    width: "100%",
    height: 38,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    cursor: "pointer",
    borderRadius: 5,
  },
  fileName: {
    flex: 5,
    color: theme.palette.text.primary,
    fontWeight: 700,
    marginRight: theme.spacing(1),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  fileLink: {
    textDecoration: "none",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  downloadIcon: {
    width: 18,
    height: 18,
    marginRight: theme.spacing(3),
  },
}));

const ContractModeRadioButton = ({ canUpdateContract, contractMode, setContractMode, isFinalizedBooking }) => {
  const { i18n } = useLingui();

  return (
    <RadioGroup
      value={contractMode}
      onChange={(e) => {
        setContractMode(e.target.value);
      }}
    >
      <Box display="flex" flexDirection="column">
        <FormControlLabel
          value="file-contract"
          disabled={!canUpdateContract}
          label={i18n._(t`Subir contrato completo en PDF`)}
          control={<Radio color="primary" style={{ transform: "translateX(1px)" }} />}
          style={{ marginRight: theme.spacing(0) }}
        />
        <FormControlLabel
          value="text-contract"
          disabled={!canUpdateContract || isFinalizedBooking}
          label={i18n._(t`Editar modelo de contrato`)}
          control={<Radio color="primary" style={{ transform: "translateX(1px)" }} />}
          style={{ marginRight: theme.spacing(0) }}
        />
      </Box>
    </RadioGroup>
  );
};

const FileContractUpdate = ({
  values,
  uploadStatus,
  onStartAttaching,
  onUploadAttachment,
  onErrorAttaching,
  onResetFile,
}) => {
  const classes = useStyles(theme);
  const { i18n } = useLingui();
  return (
    <Grid style={{ justifyContent: "center" }} container>
      <Box className={classes.row}>
        <Grid item xs={6}>
          <Typography variant="subtitle1">
            <Trans>Contrato</Trans>
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.rightContainer}>
          {!values.contract && (
            <Button variant="contained" color="primary" disabled={uploadStatus.contract === "loading"}>
              <label htmlFor="contract-upload">
                <FileUploadButton
                  id="contract-upload"
                  name="contract-upload"
                  maxSize={MAX_MB_ATTACHMENTS * 1024 * 1024} // 30MB
                  accept="application/pdf"
                  style={{ display: "none" }}
                  onStart={onStartAttaching("contract")}
                  onComplete={onUploadAttachment("contract")}
                  onError={() => {
                    onErrorAttaching("contract");
                  }}
                />
                <Trans>Subir contrato</Trans>
              </label>
            </Button>
          )}
        </Grid>
      </Box>
      {values.contract && (
        <a href={values.contract} target="_blank" className={classes.fileLink} rel="noreferrer">
          <Box className={classes.uploadSuccessFile} title={getFileNameFromUrl(values.contract)}>
            <Typography variant="body1" className={classes.fileName}>
              {getFileNameFromUrl(values.contract)}
            </Typography>
            <DownloadIcon className={classes.downloadIcon} />
            <TrashIcon onClick={onResetFile("contract")} />
          </Box>
        </a>
      )}
      {uploadStatus.contract === "loading" && (
        <Box mb={3} width="100%">
          <LoadingBar height="10px" mt={2}>
            <Trans>Subiendo contrato....</Trans>
          </LoadingBar>
        </Box>
      )}
      <Box className={classes.divider} />
      <Box mt={1} display="flex" width="100%" justifyContent="flex-end">
        <Typography variant="body1">{`${i18n._(t`Tamaño Máximo`)} ${MAX_MB_ATTACHMENTS}MB`}</Typography>
      </Box>
    </Grid>
  );
};

const ContractMode = ({
  mode,
  contractModel,
  contractContent,
  updateContractModel,
  updateContractContent,
  ...rest
}) => {
  if (mode === "file-contract") return <FileContractUpdate {...rest} />;
  if (mode === "text-contract")
    return (
      <TextContract
        contractModel={contractModel}
        contractContent={contractContent}
        updateContractModel={updateContractModel}
        updateContractContent={updateContractContent}
      />
    );
};

const StepAttachmentsComponent = ({
  values,
  uploadStatus,
  onStartAttaching,
  onErrorAttaching,
  onUploadAttachment,
  onResetFile,
  canUpdateContract,
  onSubmit,
  onBack,
}) => {
  const classes = useStyles(theme);
  const [contractMode, setContractMode] = useState("file-contract");
  const [contractModel, setContractModel] = useState();
  const [contractContent, setContractContent] = useState("");
  const canSubmit = values.contract || (contractMode === "text-contract" && contractContent);

  const isFinalizedBooking =
    isSameOrBeforeDate(values.startDate, Date.now()) && isSameOrBeforeDate(values.endDate, Date.now());

  const handleSubmit = async () => {
    onSubmit(contractMode, contractModel, contractContent);
  };

  return (
    <>
      <Box className={classes.container}>
        <Typography variant="subtitle1" style={{ marginBottom: 5 }}>
          <Trans>
            Elige entre subir directamente el contrato en formato PDF o editar un modelo de contrato y guardar el
            archivo
          </Trans>
        </Typography>
        <ContractModeRadioButton
          canUpdateContract={canUpdateContract}
          contractMode={contractMode}
          setContractMode={setContractMode}
          isFinalizedBooking={isFinalizedBooking}
        />
        <Box className={classes.divider} mb={3 / 2} mt={3 / 2} />
        <ContractMode
          mode={contractMode}
          values={values}
          uploadStatus={uploadStatus}
          onStartAttaching={onStartAttaching}
          onUploadAttachment={onUploadAttachment}
          onErrorAttaching={onErrorAttaching}
          onResetFile={onResetFile}
          contractModel={contractModel}
          contractContent={contractContent}
          updateContractModel={(model) => setContractModel(model)}
          updateContractContent={(content) => setContractContent(content)}
        />
      </Box>
      <WizardFooter step={5} onNext={handleSubmit} canSubmit={canSubmit} onBack={onBack} />
    </>
  );
};

export default StepAttachmentsComponent;
