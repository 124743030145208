import { Box, Grid, InputLabel, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

// theme
import theme from "../../../theme";
// My Components
import TextField from "../../text-field";

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
  label: {
    fontSize: theme.typography.body1.fontSize,
    transform: "translate(0, -5px) scale(1)",
    fontWeight: "bold",
    color: theme.palette.black,
  },
  descriptionLabel: {
    fontSize: theme.typography.body1.fontSize,
    transform: "translate(0, -5px) scale(1)",
    fontWeight: "bold",
    color: theme.palette.gray,
  },
  fieldInput: {
    width: 200,
    marginTop: "2px",
  },
  inputsContainer: {
    marginTop: theme.spacing(2),
  },
}));

const DurationInput = ({ onChange = () => {}, onBlur = () => {}, label = "", value = "" }) => {
  const classes = useStyles(theme);

  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <InputLabel className={classes.descriptionLabel}>{label}</InputLabel>
      </Box>
      <Box display="flex">
        <Box>
          <TextField
            type="number"
            placeholder="Ej: 1"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            InputLabelProps={{
              shrink: true,
              className: classes.descriptionLabel,
            }}
            className={classes.fieldInput}
          />
        </Box>
      </Box>
    </Box>
  );
};

const DurationInputs = ({
  onChangeMinimum = () => {},
  onChangeMaximum = () => {},
  onBlurMinimum = () => {},
  onBlurMaximum = () => {},
  submitedWithErrors = false,
  value,
}) => {
  const classes = useStyles(theme);

  const { i18n } = useLingui();

  return (
    <Box className={classes.field}>
      <InputLabel className={classes.label} error={submitedWithErrors}>
        <Trans>¿Cuánto tiempo te interesa alquilar tu espacio?*</Trans>
      </InputLabel>
      <Grid container className={classes.inputsContainer}>
        <Grid item xs={3}>
          <DurationInput
            label={`${i18n._(t`DURACIÓN MÍNIMA (DÍAS)`)}`}
            value={value.min_stay}
            onChange={onChangeMinimum}
            onBlur={onBlurMinimum}
          />
        </Grid>
        <Grid item xs={3}>
          <DurationInput
            label={`${i18n._(t`DURACIÓN MÁXIMA (DÍAS)`)}`}
            value={value.max_stay}
            onChange={onChangeMaximum}
            onBlur={onBlurMaximum}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

DurationInputs.prototype = {
  onChangeMinimum: PropTypes.func,
  onChangeMaximum: PropTypes.func,
  submitedWithErrors: PropTypes.bool,
  value: PropTypes.object.isRequired,
};

export default DurationInputs;
