import React, { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "/src/hooks/navigation";
import OwnerDashboardComponent from "../../components/owner-dashboard";

const OwnerDashboard = () => {
  const isAdmin = useSelector((state) => state.user.data.isAdmin);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  const { navigate } = useNavigate();

  useLayoutEffect(() => {
    if (!isAdmin && isAuthenticated) {
      navigate("/tenant/inprogress");
    }
  }, [isAdmin, isAuthenticated]);

  return <OwnerDashboardComponent />;
};

export default OwnerDashboard;
