import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Typography } from "@material-ui/core";
import { Trans, t } from "@lingui/macro";
import { useDispatch, useSelector } from "react-redux";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Image from "material-ui-image";
import TextField from "@material-ui/core/TextField";
import { useLingui } from "@lingui/react";
import { getAssetsThunk } from "../../redux/actions/misc-actions";
import OwnerModal from "../owner-modal";
import SecondaryButton from "../secondary-button";
import CheckIcon from "../../icons/success-circle-icon";
import WarningIcon from "../../icons/warning-icon";
import theme from "../../theme";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  divider: {
    width: "100%",
    borderBottom: `1px solid ${theme.palette.black}`,
  },
}));

const AssetOption = ({ name = "", image = "" }) => (
  <Box display="flex" flexDirection="row" alignItems="center" width="100%" height="44px">
    <Image
      disableSpinner
      src={image}
      style={{
        width: "48px",
        height: "48px",
        padding: 0,
      }}
      imageStyle={{
        objectFit: "cover",
        width: "100%",
        height: "100%",
        borderRadius: "5px",
      }}
    />
    <Box ml={2}>
      <Typography variant="body1">{name}</Typography>
    </Box>
  </Box>
);

function ImportModal({
  open = false,
  assetsSelect,
  importStatus,
  title,
  onSubmit,
  onDownloadSample,
  onClose = () => {},
}) {
  const { i18n } = useLingui();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const assets = useSelector((state) => state.misc.assets);
  const [importFile, setImportFile] = useState(null);
  const [openAssetsBar, setOpenAssetsBar] = useState(false);
  const [assetId, setAssetId] = useState(null);

  const handleFileChange = ({ target }) => {
    const file = target.files.length && target.files[0];
    if (file) {
      setImportFile(file);
    }
  };

  const handleOnAssetChange = (e, assetSelected) => {
    if (assetSelected) {
      setAssetId(assetSelected.id);
    }
  };

  useEffect(() => {
    if (assetsSelect) {
      dispatch(getAssetsThunk({ numberPerPage: "" }));
    }
  }, [dispatch, assetsSelect]);

  return (
    <OwnerModal open={open} onClose={onClose} width="450px" title={title}>
      <Box className={classes.container}>
        {importStatus !== "success" && importStatus !== "error" && (
          <>
            <Typography variant="body1" gutterBottom>
              <strong>
                <Trans>Información</Trans>
              </strong>
              <br />- <Trans>No se deben alterar los nombres de los encabezados.</Trans>
              <br />-{" "}
              <Trans>Se debe comenzar a cargar datos a partir de la línea 3, dejando las 2 primeras sin alterar.</Trans>
              <br />-{" "}
              <Trans>
                Los campos de fechas pueden tener cualquier formato, siempre que sean declarados como fecha.
              </Trans>
            </Typography>
            <Box mt={2} alignSelf="flex-start">
              <Button variant="contained" color="secondary" onClick={onDownloadSample}>
                <Trans>Descargar modelo</Trans>
              </Button>
            </Box>
            <Box mt={2} className={classes.divider} />

            {assetsSelect && (
              <Box
                style={{ width: "100%" }}
                mt={2}
                onClick={() => setOpenAssetsBar((isOpened) => !isOpened)}
                onBlur={() => setOpenAssetsBar(() => false)}
              >
                <Autocomplete
                  open={openAssetsBar}
                  loading={assets.length === 0}
                  options={assets}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props) => AssetOption({ name: props.name, image: props.thumb_url })}
                  onChange={handleOnAssetChange}
                  popupIcon={
                    <ArrowForwardIosIcon style={{ width: "16px", height: "16px", transform: "rotate(90deg)" }} />
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder={i18n._(t`Selecciona el centro comercial`)} />
                  )}
                />
              </Box>
            )}

            <Box mt={2} alignSelf="flex-start">
              <Typography variant="body1" gutterBottom>
                <strong>
                  <Trans>Subir archivo</Trans>:
                </strong>
              </Typography>
              <input type="file" placeholder={title} accept=".xls" onChange={handleFileChange} />
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" alignSelf="flex-end" mt={3}>
              <SecondaryButton onClick={onClose}>
                <Trans>Cancelar</Trans>
              </SecondaryButton>
              <Button
                onClick={() => onSubmit(importFile, assetId)}
                disabled={importStatus === "loading" || !importFile || (assetsSelect && !assetId)}
                variant="contained"
                color="primary"
              >
                <Trans>Importar</Trans>
              </Button>
            </Box>
          </>
        )}

        {importStatus === "success" && (
          <Box display="flex" flexDirection="column" alignItems="center" width={400} p={2}>
            <CheckIcon fill={theme.palette.successGreen} width={64} height={64} />
            <Box mb={3}>
              <Typography variant="subtitle1" style={{ color: theme.palette.successGreen }}>
                <Trans>El proceso de importación ha iniciado exitosamente.</Trans>
              </Typography>
            </Box>
            <Button onClick={onClose} variant="contained" color="primary" style={{ width: 150 }}>
              <Trans>Aceptar</Trans>
            </Button>
          </Box>
        )}

        {importStatus === "error" && (
          <Box display="flex" flexDirection="column" alignItems="center" width={400} p={2}>
            <WarningIcon fill={theme.palette.error.main} width={64} height={64} />
            <Box mb={3}>
              <Typography variant="subtitle1" style={{ color: theme.palette.error.main }}>
                <Trans>Ups hubo un error al realizar la importación, intente nuevamente más tarde!</Trans>
              </Typography>
            </Box>
            <Button onClick={onClose} variant="contained" color="primary" style={{ width: 150 }}>
              <Trans>Aceptar</Trans>
            </Button>
          </Box>
        )}
      </Box>
    </OwnerModal>
  );
}

export default ImportModal;
