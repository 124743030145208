import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Box, Typography } from "@material-ui/core";
import DateRangeFilter from "../date-range-filter";
import PeriodInformationWidget from "./period-information-widget";
import GlobalOccupationWidget from "./global-occupation-widget";
import TenantRevenueWidget from "./tenant-revenue-widget";
import MonthlyIncomeWidget from "./monthly-income-widget";
import AnnualGoalsWidget from "./annual-goals-widget";
// theme
import theme from "../../theme";

import MultiselectSearchFilter from "../multiselect-search-filter";
import Assets from "../../icons/sass-icono-assets-info";
import Clients from "../../icons/sass-icono-clientes";
import LoadingBar from "../loading-bar";

const useStyles = makeStyles((th) => ({
  container2: {
    color: th.palette.black,
    position: "relative",
    flex: 1,
    height: "100%",
    marginTop: th.spacing(2),
  },
  loadingMask: {
    background: "rgba(255, 255, 255, 0.4)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 9,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: 32,
    height: 32,
  },
  filters: {
    overflow: "auto",
    paddingBottom: 5,
    "& > button": {
      marginRight: th.spacing(1),
    },
  },
}));

export default function PerformanceAnalyzer({
  dashboard = {},
  goalsData,
  filters = {},
  filtersOptions = {},
  loading,
  filteredDate,
  onChangeFilters,
  showAssetsOwnersFilter,
}) {
  const classes = useStyles(theme);
  const { i18n } = useLingui();

  const handleFilterDatesChange = ({ start, end }) =>
    onChangeFilters({
      ...filters,
      start,
      end,
    });

  const handleFilterChange = (field) => (values) => {
    onChangeFilters({
      ...filters,
      [field]: values,
    });
  };

  return (
    <Box className={classes.container2} pr={2} pl={1}>
      {loading && (
        <Box className={classes.loadingMask}>
          <LoadingBar height="10px" width="400px">
            <Trans>Cargando información...</Trans>
          </LoadingBar>
        </Box>
      )}
      <Box mb={2}>
        <Box mb={2} ml={2} className={classes.filters}>
          <DateRangeFilter
            title={i18n._(t`Fechas`)}
            startValues={{
              start: filters.start instanceof Date ? filters.start : new Date(filters.start),
              end: filters.end instanceof Date ? filters.end : new Date(filters.end),
            }}
            onSelect={handleFilterDatesChange}
            minDate={null}
          />
          <MultiselectSearchFilter
            title={i18n._(t`Activos`)}
            localStorageName="performanceAssets"
            icon={() => <Assets className={classes.icon} fill={theme.palette.black} />}
            options={filtersOptions.assets}
            onSelect={handleFilterChange("assets")}
          />

          {showAssetsOwnersFilter && (
            <MultiselectSearchFilter
              title={i18n._(t`Portfolio`)}
              localStorageName="performanceClients"
              icon={() => <Clients className={classes.icon} fill={theme.palette.black} />}
              options={filtersOptions.clients}
              onSelect={handleFilterChange("clients")}
            />
          )}
        </Box>
        <Box flex={3} display="flex">
          <Box flex={2.5} display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              <PeriodInformationWidget
                period={filteredDate}
                totalBilling={dashboard.totalBilling}
                bookingsMade={dashboard.paidBookingsCount}
                averageRent={dashboard.totalBilling / dashboard.activeBookingsCount}
                conversionRate={dashboard.conversionRate || 0}
              />
              <TenantRevenueWidget period={filteredDate} topBillingCompanies={dashboard.topBillingCompanies} />
            </Box>

            <Box display="flex" flexDirection="row" flexWrap="wrap">
              <GlobalOccupationWidget
                period={filteredDate}
                globalOcupation={(dashboard.bookedDaysCount * 100) / dashboard.bookableDaysCount || ""}
              />
              <MonthlyIncomeWidget period={filteredDate} monthlyIncome={dashboard.monthlyIncome} />
            </Box>
          </Box>

          <Box flex={1} display="flex" flexDirection="row">
            <AnnualGoalsWidget period={filteredDate} annualGoals={goalsData} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
