import React from "react";
import { useSelector } from "react-redux";
import TextContractComponent from "../../../components/create-booking-wizard/contract-data/text-contract";
import api from "../../../api";

function TextContract({ contractModel, contractContent, updateContractModel, updateContractContent }) {
  const contractModels = useSelector((state) => state.misc.contractModels);

  const getContractTemplate = async (id) => {
    const res = await api.getContractModel(id);
    if (res.status === 200) {
      return res?.data?.payload;
    }
    return { error: true };
  };

  const handleUpdateContractModel = async ({ target }) => {
    const contract = contractModels.find((cm) => cm.id === target.value);
    updateContractModel(contract);
    const contractTemplate = await getContractTemplate(contract.id);
    updateContractContent(contractTemplate?.content);
  };

  return (
    <TextContractComponent
      contractModels={contractModels}
      contractModel={contractModel}
      contractContent={contractContent}
      updateContractModel={handleUpdateContractModel}
      updateContractContent={updateContractContent}
    />
  );
}

export default TextContract;
