import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "@reach/router";
import { t } from "@lingui/macro";
import { useNavigate } from "../../hooks/navigation";
import { getAssetThunk, resetAsset } from "../../redux/actions/asset-actions";
import { setDashboardTitle } from "../../redux/actions/misc-actions";
import AssetFormComponent from "../../components/asset-form";
import Information from "./information/index";
import Prices from "./prices";
import Proforma from "./proforma";
import Invoicing from "./invoicing";
import Representative from "./representative";
import SeasonalCalendar from "./seasonal-calendar";
import { resetAllAdSpaces } from "../../redux/actions/adspaces-actions";
import { resetAllSpaces } from "../../redux/actions/spaces-owner-actions";

const AssetForm = () => {
  const { navigate } = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const assetName = useSelector((state) => state.asset.data.name);
  const assetModule = useSelector((state) => state.asset.data.module);
  const flagRepresentative = useSelector((state) => state.misc.featureFlags["flag.ops.allow_asset_meta_data"]);

  const { assetId } = params;
  const { stepName } = params;

  // translations
  t({ id: "information", message: "Información" });
  t({ id: "prices", message: "Precios" });
  t({ id: "proforma", message: "Proforma" });
  t({ id: "invoicing", message: "Facturación" });
  t({ id: "representative", message: "Representante" });
  t({ id: "seasonal-calendar", message: "Calendario estacional" });

  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(0);

  const handleGoBackToAdSpaceList = () => {
    navigate("/administrator/assets-landlords/assets");
  };

  const handleStepClick = (step) => {
    navigate(`/administrator/assets-landlords/assets/${params.assetId}/${step}`);
  };

  useEffect(() => {
    if (assetModule === "bac_direct" || assetModule === "mri") {
      setSteps(["information", "prices", "proforma"]);
    } else {
      setSteps(["information", "prices", "proforma", "invoicing"]);
    }

    if (flagRepresentative) {
      setSteps((currentSteps) => [...currentSteps, "representative"]);
    }

    setSteps((currentSteps) => [...currentSteps, "seasonal-calendar"]);
  }, [assetModule, flagRepresentative]);

  useEffect(() => {
    if (assetName) {
      dispatch(setDashboardTitle(`${t`Asset`} - ${t`Editar`}: ${assetName}`));
      dispatch(resetAllAdSpaces());
      dispatch(resetAllSpaces());
    }
  }, [assetName, dispatch]);

  useEffect(() => {
    if (assetId) {
      dispatch(getAssetThunk(assetId));
    }
    return () => dispatch(resetAsset());
  }, [assetId, dispatch]);

  useEffect(() => {
    setActiveStep(steps.indexOf(stepName));
  }, [stepName, steps]);

  function getStepComponent() {
    switch (stepName) {
      case "information":
        return <Information />;
      case "prices":
        return <Prices />;
      case "proforma":
        return <Proforma />;
      case "invoicing":
        return <Invoicing />;
      case "representative":
        return <Representative />;
      case "seasonal-calendar":
        return <SeasonalCalendar />;
      default:
        return navigate(`/administrator/assets-landlords/assets/${params.assetId}/information`);
    }
  }

  return (
    <AssetFormComponent
      steps={steps}
      activeStep={activeStep}
      goBackToAdSpaceList={handleGoBackToAdSpaceList}
      onStepClick={handleStepClick}
    >
      {getStepComponent()}
    </AssetFormComponent>
  );
};

export default AssetForm;
