import { Box, Grid, Typography, Chip, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
// Local components
import { ValidatorForm } from "react-material-ui-form-validator";
// theme
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import theme from "../../../theme";
// My Components
import BaseStep from "../step-base";
import StepButtons from "../step-buttons";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    height: "100%",
    width: "100%",
    overflowY: "auto",
  },
  errorMessageContainer: {
    minHeight: 36,
  },
}));

const StepContent = ({ space, handleNext, handleBack, onSubmit, status, features, onSetOpenDelete }) => {
  const classes = useStyles(theme);
  const [values, setValues] = useState({});
  const [submited, setSubmited] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [featuresSelected, setFeaturesSelected] = useState([]);

  useEffect(() => {
    if (space) {
      setValues(space);
      setFeaturesSelected(space.features);
    }
  }, [space]);

  const handleSubmit = () => {
    onSubmit({ ...space, features: featuresSelected });
    setSubmited(true);
  };

  const handleClickFeature = (selected) => {
    if (featuresSelected.find((f) => f.id === selected.id)) {
      setFeaturesSelected(featuresSelected.filter((us) => us.id !== selected.id));
    } else {
      setFeaturesSelected([...featuresSelected, selected]);
    }
  };

  const handleIsSelected = (arr, e) => {
    if (arr.find((a) => a.id === e.id)) {
      return {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white,
      };
    }
    return {
      backgroundColor: theme.palette.lightGray,
      color: theme.palette.black,
    };
  };

  const { i18n } = useLingui();

  const somethingHasChanged = JSON.stringify(space.features) !== JSON.stringify(featuresSelected);
  const handleResetChanges = () => {
    setValues(space);
    setFeaturesSelected(space.features);
  };
  const disableContinue = featuresSelected.length === 0 || status === "loading" || !somethingHasChanged;

  return (
    <Box width="100%">
      <ValidatorForm className={classes.container} onSubmit={handleSubmit}>
        <Box px={2} pt={2} style={{ overflowY: "auto" }}>
          <Grid container>
            <Grid item xs={12}>
              <Box>
                <Typography variant="subtitle1">
                  <Trans>¿Qué características incluye tu espacio?</Trans>
                </Typography>
                <Box mt={1}>
                  <Typography variant="body1">
                    <Trans>Seleccione todas las que correspondan</Trans>
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Grid container spacing={2}>
                    {features.map((f) => (
                      <Grid item key={f.id}>
                        <Chip
                          label={<Typography variant="body1">{i18n._(t`${f.name}`)}</Typography>}
                          onClick={() => {
                            handleClickFeature(f);
                          }}
                          style={{
                            ...handleIsSelected(featuresSelected, f),
                            padding: theme.spacing(2),
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} className={classes.errorMessageContainer}>
              <Box display="flex" style={{ float: "right" }}>
                {validationError && (
                  <Typography variant="body1" color="error">
                    {`${i18n._(t`Por favor complete todos los campos.`)}`}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <StepButtons
          labelNext={<Trans>Guardar</Trans>}
          disableBack={status === "loading"}
          disableContinue={disableContinue}
          hasNext
          onSetOpenDelete={onSetOpenDelete}
          onResetChanges={handleResetChanges}
          disableResetChanges={!somethingHasChanged}
        />
      </ValidatorForm>
    </Box>
  );
};

const CharacteristicsStep = (props) => {
  const { i18n } = useLingui();

  return <BaseStep mainContent={<StepContent {...props} />} title={`${i18n._(t`CARACTERÍSTICAS`)}`} />;
};

export default CharacteristicsStep;
