import { Box, Button, Typography, MenuItem, ListSubheader } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// icons
import AttachFileSharpIcon from "@material-ui/icons/AttachFileSharp";
import { object } from "prop-types";
import React from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
// theme
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import theme from "../../theme";
// local components
import Select from "../select/select-validator";
import TextField from "../text-field";

import "../space-features-translations";
import WizardFooter from "./footer";

const propTypes = {
  space: object,
  booking: object,
  user: object,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  submit: {},
  description: {
    marginTop: theme.spacing(2),
  },
  selectField: {
    marginTop: theme.spacing(0),
    width: "100%",
  },
  selectInput: {
    borderRadius: 0,
    border: theme.borders[1],
    paddingTop: theme.spacing(1 / 2),
    paddingBottom: theme.spacing(1 / 2),
    width: "100%",
  },
  subHeader: {
    marginBottom: theme.spacing(1),
  },
  divider: {
    width: "100%",
    borderBottom: theme.borders[6],
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  selects: {
    marginTop: theme.spacing(1),
  },
  fields: {
    marginTop: theme.spacing(0),
  },
  fieldContainer: {
    marginBottom: theme.spacing(0),
  },
}));

const StepProjectDataComponent = ({
  canShowSpaceActivity = false,
  spaceActivities = [],
  spaceActivitiesCategories = [],
  onSubmit,
  onBack,
  onInputChange,
  onSelect,
  values,
}) => {
  const classes = useStyles(theme);
  // Techdebt: this is repeated from first-contact component
  const renderSelectWithGroups = (group) => {
    const selectComponent = [];

    selectComponent.push(
      <ListSubheader>
        <Typography
          variant="subtitle1"
          style={{
            color: theme.palette.black,
            textTransform: "capitalize",
          }}
        >
          {i18n._(t`${group}`)}
        </Typography>
        <Box className={classes.divider} />
      </ListSubheader>,
    );

    selectComponent.push(
      spaceActivities
        .filter((sa) => sa.category === group)
        .map((sa) => (
          <MenuItem key={sa.id} value={sa.id}>
            <Box pl={2}>{i18n._(t`${sa.space_activity}`)}</Box>
          </MenuItem>
        )),
    );

    return selectComponent;
  };

  const { i18n } = useLingui();
  const canSubmit =
    values.brandname &&
    values.projectname &&
    (canShowSpaceActivity ? values.spaceActivityCoefficient : true) &&
    values.description?.length >= 1;

  return (
    <>
      <ValidatorForm onSubmit={onSubmit}>
        <Box display="flex" flexDirection="column" mt={1}>
          <Typography variant="subtitle1">
            <Trans>Nombre de la marca*</Trans>
          </Typography>
          <TextField
            inputContainerClassNames={classes.fieldContainer}
            textFieldClassNames={classes.fields}
            placeholder={`${i18n._(t`Nombre de la marca`)}`}
            value={values.brandname}
            onChange={onInputChange("brandname")}
            validators={["minStringLength:1", "maxStringLength:500"]}
            errorMessages={[`${i18n._(t`Por favor describe tu proyecto`)}`, `${i18n._(t`Máximo de 500 caracteres`)}`]}
            fullWidth
          />
          <Box mt={1}>
            <Typography variant="subtitle1">
              <Trans>Nombre de producto o proyecto*</Trans>
            </Typography>
          </Box>
          <TextField
            inputContainerClassNames={classes.fieldContainer}
            textFieldClassNames={classes.fields}
            placeholder={`${i18n._(t`Nombre de producto o proyecto`)}`}
            value={values.projectname}
            onChange={onInputChange("projectname")}
            validators={["required", "maxStringLength:40"]}
            errorMessages={[`${i18n._(t`Campo requerido`)}`, `${i18n._(t`Nombre demasiado extenso`)}`]}
            fullWidth
          />
          {canShowSpaceActivity && (
            <Box className={classes.selects}>
              <Typography variant="subtitle1">
                <Trans>Actividad a realizar*</Trans>
              </Typography>
              <Select
                value={values.spaceActivityCoefficient ? values.spaceActivityCoefficient.id : ""}
                onChange={onSelect("spaceActivityCoefficient")}
                validators={["required"]}
                errorMessages={[`${i18n._(t`Elige la actividad a realizar por favor`)}`]}
                InputProps={{
                  className: classes.selectInput,
                }}
                className={classes.selectField}
              >
                {spaceActivitiesCategories.map((sac) => renderSelectWithGroups(sac))}
              </Select>
            </Box>
          )}
          <Box mt={1}>
            <Typography variant="subtitle1">
              <Trans>Descripción*</Trans>
            </Typography>
          </Box>
          <TextField
            multiline
            rows={4}
            textFieldClassNames={classes.fields}
            validators={["minStringLength:1", "maxStringLength:500"]}
            errorMessages={[`${i18n._(t`Por favor describe tu proyecto`)}`, `${i18n._(t`Máximo de 500 caracteres`)}`]}
            value={values.description}
            onChange={onInputChange("description")}
            fullWidth
          />
        </Box>
        <WizardFooter step={2} onNext={onSubmit} canSubmit={canSubmit} onBack={onBack} />
      </ValidatorForm>
    </>
  );
};

export default StepProjectDataComponent;
