import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { publishSpaceThunk } from "../../redux/actions/space-actions";
import VisibilityButtonComponent from "../../components/listing-form/visibility-switch";

const VisibilityButton = () => {
  const dispatch = useDispatch();

  const spaceId = useSelector((state) => state.listing.data.id);
  const searchable = useSelector((state) => state.listing.data.searchable);

  const handleClickPublish = () =>
    dispatch(publishSpaceThunk({ listingId: spaceId, hide: searchable }));

  return (
    <VisibilityButtonComponent
      spaceId={spaceId}
      searchable={!!searchable}
      onClickPublish={handleClickPublish}
    />
  );
};

export default VisibilityButton;
