/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from "react";
import { useNavigate } from "/src/hooks/navigation";
import { Typography } from "@material-ui/core";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Table from "../table";
import TableFooter from "../table-footer";
import TableDataDownload from "../table-data-download";
import theme from "../../theme";

const useStyles = makeStyles((theme) => ({
  tableHeader: { whiteSpace: "nowrap", backgroundColor: theme.palette.white },
  tableCell: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "normal",
    maxWidth: "40vw",
    minHeight: "44px",
    width: "max-content",
    textAlign: "left",
  },
}));

function UsersReport({ users = [], pagination, onChangePage, onTableChange, status, downloadUsersReport }) {
  const classes = useStyles(theme);
  const { navigate } = useNavigate();
  const { i18n } = useLingui();
  const [openTableDataDownload, setOpenTableDataDownload] = useState(false);

  const columns = [
    { label: `${i18n._(t`Nombre`)}`, name: "name" },
    { label: `${i18n._(t`Email`)}`, name: "email" },
    {
      label: `${i18n._(t`Último inicio de sesión`)}`,
      name: "lastLoginDate",
      options: {
        customHeadRender: (value) => {
          return <th className={`${classes.tableHeader} MuiTableCell-root MuiTableCell-head`}>{value.label}</th>;
        },
      },
    },
    { label: `${i18n._(t`Rol`)}`, name: "role" },
    {
      label: `${i18n._(t`IDs de espacios`)}`,
      name: "propertyIdsAccess",
      options: {
        customBodyRender: (value) => {
          return <div className={classes.tableCell}>{value}</div>;
        },
        sort: false,
      },
    },
  ];

  const handleTableData = () => {
    const data = users.map((u) => {
      return {
        name: `${u.name || ""} ${u.lastname || ""}`,
        email: u.email,
        lastLoginDate: u.last_login_time,
        role: u.profile?.role,
        propertyIdsAccess: u.profile.managed_assets?.map((asset) => asset?.property_code).join(", ") || "",
      };
    });

    return data;
  };

  const handleOpenDownloadModal = () => {
    setOpenTableDataDownload(true);
    return false;
  };

  const handleGoToAssets = () => {
    navigate("/administrator/assets-landlords/assets");
  };

  const handleGoToLandlords = () => {
    navigate("/administrator/assets-landlords/landlords");
  };

  return (
    <Box pr={2} pl={2}>
      <TableDataDownload
        open={openTableDataDownload}
        onClose={() => setOpenTableDataDownload(false)}
        onDownload={downloadUsersReport}
      />
      <Tabs value="UsersReport" indicatorColor="primary" textColor="primary" variant="fullWidth" centered>
        <Tab
          onClick={handleGoToAssets}
          value="Assets"
          label={
            <Typography variant="subtitle1">
              <Trans>Centros Comerciales</Trans>
            </Typography>
          }
        />
        <Tab
          value="Landlords"
          label={
            <Typography variant="subtitle1">
              <Trans>Propietarios</Trans>
            </Typography>
          }
          onClick={handleGoToLandlords}
        />
        <Tab
          value="UsersReport"
          label={
            <Typography variant="subtitle1">
              <Trans>Reporte de usuarios</Trans>
            </Typography>
          }
        />
      </Tabs>

      <Box height={window.innerHeight - 190}>
        <Table
          data={handleTableData()}
          columns={columns}
          isLoading={status === "loading"}
          onDownload={handleOpenDownloadModal}
          options={{
            onTableChange,
            customFooter: () => {
              return <TableFooter pagination={pagination} onChangePage={onChangePage} canCreate={false} />;
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default UsersReport;
