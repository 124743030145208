import { Box, Button, Grid, Typography, Container } from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// Local components
import { ValidatorForm } from "react-material-ui-form-validator";
// theme
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import theme from "../../../theme";
// My Components
import BaseStep from "../step-base";
import StepButtons from "../step-buttons";
import Gallery from "./gallery";
import SamplesModal from "./samples-modal";

import { leadingZero } from "../../../utils";
// config
import config from "../../../config.js";

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginRight: theme.spacing(1),
    width: 150,
    height: 42,
  },
  continueButton: {
    width: 190,
    height: 42,
  },
  errorMessageContainer: {
    marginTop: theme.spacing(2),
    minHeight: 36,
  },
  icons: {
    float: "left",
    padding: 2,
  },
  text: {
    fontWeight: "bold",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    height: "100%",
    width: "100%",
  },
}));

const HelpSection = styled(Box)({
  marginBottom: theme.spacing(1),
});

const StepContent = ({
  space,
  handleNext,
  handleBack,
  onSubmit,
  onAttachFile,
  status,
  attachStatus,
  onSetOpenDelete,
}) => {
  const { i18n } = useLingui();
  const classes = useStyles(theme);

  const [values, setValues] = useState({});
  const [submited, setSubmited] = useState(false);
  const [validationError, setValidationError] = useState(false);

  useEffect(() => {
    setValues(space);
  }, [space]);

  const handleChangeValues = (field) => (values) => {
    setValues((state) => ({
      ...state,
      [field]: values, // need to assign the proper role to all
    }));
    // onAttachFile(file);
    // setValidationError(false);
  };

  const somethingHasChanged = JSON.stringify(values) !== JSON.stringify(space);
  const handleResetChanges = () => setValues(space);

  const disableContinue =
    !values.images?.length || attachStatus === "loading" || status === "loading" || !somethingHasChanged;

  const handleSubmit = () => {
    if (!disableContinue) {
      const newList = [...values.images, ...values.plans].map((v, index) => ({
        ...v,
        role: `${v.type}-${leadingZero(index)}`,
      }));

      onSubmit(newList);
      setSubmited(true);
    } else {
      setValidationError(true);
    }
  };

  return (
    <Box width="100%" mt={2}>
      <ValidatorForm className={classes.container} onSubmit={handleSubmit}>
        <Box px={2} style={{ overflowY: "auto" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={10}>
              <Box>
                <Typography variant="body1" className={classes.text}>
                  <Trans>
                    Las fotos son las que ayudarán a tu posible cliente a entender la disposición y dimensiones del
                    espacio. Esmérate para que sean lo más claras posibles y que tengan un orden coherente
                  </Trans>
                  .
                </Typography>
                <Box mt={1}>
                  <Typography variant="body1">
                    <Trans>Se permite un máximo de 6 imágenes</Trans>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <SamplesModal
                images={[
                  {
                    title: i18n._(t`Escaparate`),
                    url: `${config.HOME_URL}/images/escaparate.png`,
                  },
                  {
                    title: i18n._(t`Escaparate desde el interior`),
                    url: `${config.HOME_URL}/images/escaparate-interior.png`,
                  },
                  {
                    title: i18n._(t`Vista de la calle`),
                    url: `${config.HOME_URL}/images/vista-calle.png`,
                  },
                  {
                    title: i18n._(t`Ángulo izquierdo`),
                    url: `${config.HOME_URL}/images/angulo-izquierdo.png`,
                  },
                  {
                    title: i18n._(t`Ángulo derecho`),
                    url: `${config.HOME_URL}/images/angulo-derecho.png`,
                  },
                  {
                    title: i18n._(t`Interior`),
                    url: `${config.HOME_URL}/images/interior.png`,
                  },
                ]}
              />
              <Gallery type="image" min={5} values={values.images} onChangeValues={handleChangeValues("images")} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.text}>
                <Trans>Sube aquí el plano de la propiedad si lo deseas</Trans>
              </Typography>
              <Gallery type="plan" values={values.plans} onChangeValues={handleChangeValues("plans")} />
            </Grid>
            <Grid item xs={12} className={classes.errorMessageContainer}>
              <Box display="flex" style={{ float: "right" }}>
                {validationError && (
                  <Typography variant="body1" color="error">
                    <Trans>Por favor complete todos los campos</Trans>.
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <StepButtons
          hasNext
          labelNext={<Trans>Guardar</Trans>}
          disableBack={status === "loading"}
          disableContinue={disableContinue}
          onSetOpenDelete={onSetOpenDelete}
          onResetChanges={handleResetChanges}
          disableResetChanges={!somethingHasChanged}
        />
      </ValidatorForm>
    </Box>
  );
};

StepContent.propTypes = {
  space: PropTypes.object.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

const ImagesStep = (props) => {
  const classes = useStyles(theme);

  const { i18n } = useLingui();

  const Helper = () => (
    <>
      <HelpSection>
        <Typography variant="body2">
          <Trans>Sube por lo menos 5 fotos de tu espacio y el plano, si lo tienes</Trans>.
        </Typography>
      </HelpSection>
      <HelpSection>
        <Typography variant="body2">
          <Trans>Prueba ángulos amplios desde el interior y exterior, escaparate, y vista de la calle</Trans>.
        </Typography>
      </HelpSection>
      <HelpSection>
        <Box display="flex">
          <CheckIcon fontSize="small" className={classes.icons} />
          <Typography variant="body2" component="label">
            <Trans>Luz del día</Trans>
          </Typography>
        </Box>
        <Box display="flex">
          <CheckIcon fontSize="small" className={classes.icons} />
          <Typography variant="body2" component="label">
            <Trans>Sin personas</Trans>
          </Typography>
        </Box>
        <Box display="flex">
          <CheckIcon fontSize="small" className={classes.icons} />
          <Typography variant="body2" component="label">
            <Trans>Espacio vacío</Trans>
          </Typography>
        </Box>
        <Box display="flex">
          <ClearIcon fontSize="small" className={classes.icons} />
          <Typography variant="body2" component="label">
            <Trans>Detalles</Trans>
          </Typography>
        </Box>
        <Box display="flex">
          <ClearIcon fontSize="small" className={classes.icons} />
          <Typography variant="body2" component="label">
            <Trans>Desorden</Trans>
          </Typography>
        </Box>
        <Box display="flex">
          <ClearIcon fontSize="small" className={classes.icons} />
          <Typography variant="body2" component="label">
            <Trans>Ángulo cerrado</Trans>
          </Typography>
        </Box>
      </HelpSection>
      <HelpSection style={{ marginBottom: 0 }}>
        <Typography variant="body2">
          <Trans>
            Las fotos horizontales de 1000x750 o más, tendrán una mejor calidad. Intenta que no sean superiores a 200kb.
            La primera foto se tomará como principal. Si deseas otro orden, arrastra las fotos de una posición a otra
            para conseguir el que más te guste
          </Trans>
          .
        </Typography>
      </HelpSection>
    </>
  );

  return <BaseStep mainContent={<StepContent {...props} />} helpContent={<Helper />} title={`${i18n._(t`FOTOS`)}`} />;
};

export default ImagesStep;
