import React from "react";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Typography, Box, CircularProgress } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import OwnerTableModal from "../owner-table-modal";

const TableInformation = ({ status, selectedLandlord }) => (
  <OwnerTableModal>
    <MuiTable size="small">
      <TableHead>
        <TableRow>
          <TableCell align="left">
            <Typography variant="h4" style={{ fontSize: "11px" }}>
              <Trans>ID</Trans>
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="h4" style={{ fontSize: "11px" }}>
              <Trans>Centro comercial</Trans>
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="h4" style={{ fontSize: "11px" }}>
              <Trans>Ciudad</Trans>
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      {status === "loading" ? (
        <Box
          display="flex"
          position="absolute"
          alignItems="center"
          justifyContent="center"
          width="85%"
          height="150px"
          mt={2}
          mb={2}
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableBody>
          {selectedLandlord.asset?.map((c) => (
            <TableRow key={c.id}>
              <TableCell variant="body" align="left">
                <Typography variant="body2">{c.id}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{c.name}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{c.city?.name}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      )}
    </MuiTable>
  </OwnerTableModal>
);

export default TableInformation;
