import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "@lingui/macro";
import { setDashboardTitle } from "../../redux/actions/misc-actions";
import { getImportsThunk } from "../../redux/actions/imports-actions";
import ImportsListComponent from "../../components/imports-list";

const ImportsListContainer = ({ children }) => {
  const dispatch = useDispatch();
  const imports = useSelector((state) => state.imports.imports);
  const pagination = useSelector((state) => state.imports.pagination);
  const status = useSelector((state) => state.imports.status);
  const [sort, setSort] = useState({});

  useEffect(() => {
    dispatch(setDashboardTitle(t`Importaciones`));
    dispatch(
      getImportsThunk({
        page: 1,
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(
      getImportsThunk({
        page: pagination.page,
        sort,
      }),
    );
  }, [sort]);

  const handleTableChange = (action, tableState) => {
    switch (action) {
      case "sort":
        setSort(tableState.sortOrder);
        break;
      default:
        console.log("Action not handled.");
    }
  };

  const handleChangePage = (evt, page) => {
    dispatch(
      getImportsThunk({
        page,
        sort,
      }),
    );
  };

  const handleSearch = (search) => {
    dispatch(
      getImportsThunk({
        page: 1,
        sort,
        search,
      }),
    );
  };

  return (
    <>
      <ImportsListComponent
        imports={imports}
        status={status}
        pagination={pagination}
        onTableChange={handleTableChange}
        onChangePage={handleChangePage}
        onSearch={handleSearch}
      />
      {children}
    </>
  );
};

export default ImportsListContainer;
