import React from "react";
import { Box, Typography } from "@material-ui/core";
import theme from "./../../theme";

const DashboardTitle = ({ dashboardTitle, customComponentNextToTitle }) => (
  <Box display="flex" alignContent="center" alignItems="center" style={{ maxWidth: "80%" }}>
    <Typography
      variant={"h1"}
      data-testid="admin-dashboard-title"
      style={{
        paddingLeft: theme.spacing(3),
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {dashboardTitle}
    </Typography>
    {customComponentNextToTitle && <Box ml={1}>{customComponentNextToTitle}</Box>}
  </Box>
);

export default DashboardTitle;
