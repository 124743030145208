import React, { useEffect, useState } from "react";
import LandlordsToolbarComponent from "../../components/landlords/landlords-toolbar";
import { useDispatch, useSelector } from "react-redux";
import {
  setLandlordsFilters,
  getLandlordsThunk,
} from "../../redux/actions/landlords-actions";
import { getCountriesThunk } from "../../redux/actions/misc-actions";

const LandlordsToolbar = ({ onRefresh }) => {
  const countries = useSelector((state) => state.misc.countries);

  const dispatch = useDispatch();

  const getLandlordsFilters = () => {
    const countries =
      JSON.parse(localStorage.getItem("landlordCountries")) || [];

    handleFilterSpaces({ countries: countries.map((c) => c.id) });
  };

  useEffect(() => {
    dispatch(getCountriesThunk());
    getLandlordsFilters();
  }, []);

  const handleFilterSpaces = (filters) => {
    dispatch(setLandlordsFilters(filters));
  };

  return (
    <LandlordsToolbarComponent
      onRefresh={onRefresh}
      countries={countries}
      handleFilterSpaces={handleFilterSpaces}
    />
  );
};

export default LandlordsToolbar;
