import React, { useState } from "react";
import { useParams } from "@reach/router";
import { useNavigate } from "/src/hooks/navigation";
import { useSelector, useDispatch } from "react-redux";
import ProformaComponent from "../../../components/asset-form/proforma";
import api from "../../../api";
import { getAssetThunk } from "../../../redux/actions/asset-actions";

const Proforma = () => {
  const dispatch = useDispatch();
  const { navigate } = useNavigate();
  const params = useParams();

  const [submitStatus, setSubmitStatus] = useState();

  const { assetId } = params;

  const asset = useSelector((state) => state.asset.data);
  const assetConfigs = useSelector((state) => state.asset.data.config);

  const assetConfigsAdpted = {
    account: assetConfigs?.account || false,
    warranty: assetConfigs?.warranty || false,
    irpf: assetConfigs?.irpf_percentage || false,
    iva: assetConfigs?.iva_percentage || false,
    generateProforma: assetConfigs?.generate_proforma || false,
  };

  const handleSubmit = async ({ account, warranty, irpf, iva, generateProforma }) => {
    setSubmitStatus("loading");
    const configToSave = {
      ...assetConfigs,
      account,
      warranty,
      irpf_percentage: irpf,
      iva_percentage: iva,
      generate_proforma: generateProforma,
    };
    const res = await api.updateAsset(assetId, { ...asset, config: configToSave });
    if (res.status === 200) {
      setSubmitStatus("success");
      dispatch(getAssetThunk(assetId));
    } else {
      setSubmitStatus("error");
    }
  };

  const resetSubmitStatus = () => {
    setSubmitStatus("initial");
  };

  const goNext = () => {
    navigate(`/administrator/assets-landlords/assets`);
  };

  const goBack = () => {
    navigate(`/administrator/assets-landlords/assets/${assetId}/prices`);
  };

  return (
    <ProformaComponent
      assetConfigs={assetConfigsAdpted}
      module={asset.module}
      submit={handleSubmit}
      submitStatus={submitStatus}
      resetSubmitStatus={resetSubmitStatus}
      goNext={goNext}
      goBack={goBack}
    />
  );
};

export default Proforma;
