import { Box, Button, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import numbro from "numbro";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import VisibilityOn from "../../icons/visibility-on-icon";
import VisibilityOff from "../../icons/visibility-off-icon";
import GoToPageIcon from "../../icons/go-to-page-icon";
// theme
import theme from "../../theme-admin";
import Table from "../table";
import TableFooter from "../table-footer";
import TableDataDownload from "../table-data-download";
import AdSpacesToolbar from "../../containers/ad-spaces-owner/adspace-toolbar";
import Link from "/src/components/link";
import ImportModal from "../import-modal";
import api from "../../api";

const useStyles = makeStyles((theme) => ({
  addButton: {
    position: "absolute",
    right: 50,
    bottom: 50,
  },
  notification: {
    color: theme.palette.primary.main,
  },
  filters: {
    height: 25,
    textTransform: "none",
    display: "flex",
    justifyContent: "flex-start",
    marginRight: theme.spacing(1),
    borderRadius: 4,
  },
  visbilityButton: {
    opacity: 0.4,
  },
}));

function AdSpacesOwner({
  adSpaces,
  status,
  onTableChange,
  onClickAdd,
  onRowClick,
  onEyeClick = () => {},
  publishAdSpace = () => {},
  visibilityStatus = {},
  pagination,
  onChangePage,
  downloadTableData,
  canEdit,
}) {
  const classes = useStyles(theme);
  const [openImportAdSpacesModal, setOpenImportAdSpacesModal] = useState(false);
  const [importStatus, setImportStatus] = useState("initial");

  const [openDownloadModal, setOpenDownloadModal] = useState();
  /*
  const classes = useStyles(theme);
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };
  */

  const importAdSpaces = async (file, assetId) => {
    setImportStatus("loading");
    const res = await api.importAdspaces({ file, assetId });
    if (res.status === 200) {
      setImportStatus("success");
    } else {
      setImportStatus("error");
    }
  };

  const downloadAdSpacesImportSample = async () => {
    await api.getAdspacesImportSample();
  };

  const viewListing = (dataIndex) => (e) => {
    e.stopPropagation();
    onEyeClick(adSpaces[dataIndex]);
  };

  const handleRowClick = (dataIndex) => {
    if (canEdit) {
      const adspace = adSpaces.find((s) => s.id === dataIndex[0]);
      onRowClick(adspace);
    }
  };

  const handleEyeAction = (e, dataIndex) => {
    e.stopPropagation();
    publishAdSpace(adSpaces[dataIndex]?.id, adSpaces[dataIndex]?.searchable);
  };

  useEffect(() => {
    onTableChange("changePage", { page: 0 });
  }, []);

  const { i18n } = useLingui();

  const handleTableData = () => {
    const data = adSpaces?.map((s) => {
      return {
        id: s.id,
        title: s.title,
        asset: s.asset.name,
        city: s.asset.city.name,
        type: `${i18n._(t`${s.adspace_type}`)}`,
        location: `${i18n._(t`${s.asset.type}`)}`,
        price: numbro(s.display_price || 0).formatCurrency(),
        searchable: s.searchable,
      };
    });

    return data;
  };

  const columns = [
    { label: "ID", name: "id" },
    {
      label: `${i18n._(t`Espacio publicitario`)}`,
      name: "title",
      options: {
        customBodyRenderLite: (dataIndex) => <Box title={adSpaces[dataIndex].title}>{adSpaces[dataIndex].title}</Box>,
        setCellProps: (value) => {
          return {
            style: {
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: 140,
            },
          };
        },
      },
    },
    {
      label: `${i18n._(t`Centro Comercial`)}`,
      name: "asset",
    },
    {
      label: `${i18n._(t`Ciudad`)}`,
      name: "city",
    },
    {
      label: `${i18n._(t`Ubicación`)}`,
      name: "type",
    },
    {
      label: `${i18n._(t`Precio`)}`,
      name: "price",
    },
    /* ,
    { label: "Perf.", name: "", options: {
      customBodyRenderLite: (dataIndex) => (
        <BarChartSharp className={classes.notification} />
      ),
      setCellProps: value => ({
        style: {
          width: 50
        }
      })
    }}, */
  ];

  const addActionColumns = () => {
    if (canEdit) {
      columns.push({
        label: `${i18n._(t`Acciones`)}`,
        name: "searchable",
        options: {
          customBodyRenderLite: (dataIndex) => (
            <Box display="flex" flexDirection="row" width="100%" justifyContent="center">
              <Tooltip
                title={
                  adSpaces[dataIndex].searchable === 0 || adSpaces[dataIndex].searchable === false ? (
                    <Trans>Hacer visible</Trans>
                  ) : (
                    <Trans>Hacer invisible</Trans>
                  )
                }
              >
                <Box onClick={(e) => handleEyeAction(e, dataIndex)} mr={1 / 2}>
                  <Box
                    className={`${
                      visibilityStatus[adSpaces[dataIndex].id] === "loading" ? classes.visbilityButton : ""
                    }`}
                  >
                    {adSpaces[dataIndex].searchable === 0 || adSpaces[dataIndex].searchable === false ? (
                      <VisibilityOff fill={theme.palette.black} />
                    ) : (
                      <VisibilityOn fill={theme.palette.black} />
                    )}
                  </Box>
                </Box>
              </Tooltip>
              <Tooltip title={<Trans>Abrir espacio en otra pestaña</Trans>}>
                <Box onClick={viewListing(dataIndex)}>
                  <GoToPageIcon fill={theme.palette.black} />
                </Box>
              </Tooltip>
            </Box>
          ),
          setCellProps: (value) => ({
            style: {
              width: 50,
            },
          }),
          // TODO: enable sort after BAC-1433
          sort: false,
        },
      });
    }
  };

  addActionColumns();

  const handleOpenDownloadModal = () => {
    setOpenDownloadModal(true);
    return false;
  };

  const FooterActionButtons = () => (
    <Link to="/administrator/adspaces/occupation/booking/adspaces/all">
      <Button className={classes.filters} variant="contained">
        <Trans>Ocupación de espacios</Trans>
      </Button>
    </Link>
  );

  return (
    <>
      {openImportAdSpacesModal && (
        <ImportModal
          open={openImportAdSpacesModal}
          importStatus={importStatus}
          title={i18n._(t`Importar espacios publicitarios`)}
          onSubmit={(file, assetId) => importAdSpaces(file, assetId)}
          onDownloadSample={downloadAdSpacesImportSample}
          onClose={() => setOpenImportAdSpacesModal(false)}
          assetsSelect
        />
      )}

      <Box pl={2} pr={2} height="100%">
        <TableDataDownload
          open={openDownloadModal}
          onClose={() => setOpenDownloadModal(false)}
          onDownload={downloadTableData}
        />
        <Table
          data={handleTableData()}
          columns={columns}
          isLoading={status === "loading"}
          onDownload={handleOpenDownloadModal}
          options={{
            onTableChange,
            onRowClick: handleRowClick,
            customToolbar: () => {
              return <AdSpacesToolbar />;
            },
            customFooter: () => {
              return (
                <TableFooter
                  actionButtons={FooterActionButtons}
                  pagination={pagination}
                  onChangePage={onChangePage}
                  primaryButton={i18n._(t`Crear nuevo`)}
                  secondaryButton={i18n._(t`Importar espacios publicitarios`)}
                  onPrimaryButton={onClickAdd}
                  onSecondaryButton={() => setOpenImportAdSpacesModal(true)}
                  />
              );
            },
          }}
        />
      </Box>
    </>
  );
}

AdSpacesOwner.propTypes = {
  spaces: PropTypes.array.isRequired,
  currentUserId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
};

export default AdSpacesOwner;
