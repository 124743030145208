import { Box, Grid, Typography, MenuItem, FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// Local components
import { ValidatorForm } from "react-material-ui-form-validator";
import InputAdornment from "@material-ui/core/InputAdornment";
// theme
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import theme from "../../../theme";
import TextField from "../../text-field";
// My Components
import Switch from "../../custom-switch";
import SizeIcon from "/src/icons/size-icon";
import AforoIcon from "/src/icons/aforo-icon";
import DimensionIcon from "../../../icons/dimensions-icon";
import BaseStep from "../step-base";
import StepButtons from "../step-buttons";
import SelectCategory from "./select-category";
import SelectMultipleCategory from "./select-multiple-category";
import MySelect from "../../select/select-validator";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    height: "100%",
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
    width: 150,
    height: 42,
  },
  continueButton: {
    width: 190,
    height: 42,
  },
  errorMessageContainer: {
    marginTop: theme.spacing(2),
    minHeight: 36,
  },
  switch: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    right: "8px",
    top: "-10px",
    zIndex: 1,
  },
  customSelect: {
    marginTop: "55px",
    marginRight: "0.25rem",
    width: "100px",
  },
  customInputPropsStyle: {
    height: "40px",
    marginTop: "2px",
    border: "1px solid black",
    paddingTop: "8px",
    paddingLeft: "16px",
    borderRadius: "0",
    paddingBottom: "8px",
    marginRight: "1rem",
  },
  customAutocomplete: {
    width: "auto",
    minWidth: "475px",
    height: "auto",
    padding: 0,
    display: "flex",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingTop: 0,
    },
  },
  icon: {
    height: 25,
    width: 25,
  },
}));

const StepContent = ({
  spotSpaceCategories,
  space,
  spaceTypes,
  spaceSubTypes,
  handleNext,
  handleBack,
  onSubmit,
  status,
  onSetOpenDelete,
  isNew,
  areaUnits,
  spaceUnit,
  hasSpaceDimensionsFeature,
  showOverlapField,
}) => {
  const classes = useStyles(theme);
  const [values, setValues] = useState(space);
  const [submited, setSubmited] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [showMaxCapacity, setShowMaxCapacity] = useState(false);
  const [spaceUnitSelector, setSpaceUnitSelector] = useState(""); // 'Meters, Feet'

  useEffect(() => {
    setValues(space);
    if (space.capacity > 0) {
      setShowMaxCapacity(true);
    } else {
      setShowMaxCapacity(false);
    }
  }, [space]);

  const getAbreviationFromUnit = (unit) => {
    for (const elem in areaUnits) {
      if (elem === unit) {
        return areaUnits[elem];
      }
    }
  };

  const handleSubmit = () => {
    if (values.type && values.subtype) {
      onSubmit({ ...values, capacity: showMaxCapacity ? values.capacity : 0 });
      setSubmited(true);
    } else {
      setValidationError(true);
    }
  };

  const handleInputChange =
    (field) =>
    ({ target }) => {
      setValues((state) => ({ ...state, [field]: target.value }));
    };

  const handleSelectInput = (field) => (value) => {
    setValues((state) => ({ ...state, [field]: value }));
  };

  const validatorListener = (result) => {
    setValidationError(!result);
  };

  const handleOnSwitchClicked = () => {
    setShowMaxCapacity((smc) => !smc);
  };

  const handleOnCapacityInputBlur = () => {
    if (Number(values.capacity) === 0) {
      setShowMaxCapacity(false);
    } else {
      setShowMaxCapacity(true);
    }
  };

  const handleChangeSpaceUnit = (e) => {
    setSpaceUnitSelector(e.target.value);
    handleSelectInput(`area_unit`)(getAbreviationFromUnit(e.target.value));
  };

  const handleSpaceCategory = (selectedCategorie) => {
    const newTags = [...values.tags];
    const index = newTags.findIndex((tag) => tag.name === selectedCategorie.name);
    if (index !== -1) {
      newTags.splice(index, 1);
    } else {
      newTags.push(selectedCategorie);
    }

    setValues((state) => ({ ...state, tags: newTags }));
  };

  const { i18n } = useLingui();

  const somethingHasChanged = JSON.stringify(values) !== JSON.stringify(space);
  const handleResetChanges = () => setValues(space);

  const disableContinue =
    (!values.type?.id && !values.subtype?.id && values.size < 0 && values.capacity <= 0) ||
    status === "loading" ||
    !somethingHasChanged;

  return (
    <Box width="100%" height="100%">
      <ValidatorForm className={classes.container} onSubmit={handleSubmit}>
        <Box
          px={2}
          pt={2}
          style={{
            overflowY: "auto",
          }}
        >
          <Box>
            <Grid container>
              <Grid item xs={12}>
                <SelectCategory
                  onSelect={handleSelectInput(`type`)}
                  value={values.type?.id}
                  values={spaceTypes}
                  label={`${i18n._(t`¿Dónde se encuentra?*`)}`}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Box mt={4}>
                  <SelectCategory
                    onSelect={handleSelectInput(`subtype`)}
                    value={values.subtype?.id}
                    values={spaceSubTypes}
                    label={`${i18n._(t`¿Qué tipo de espacio es?*`)}`}
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box mt={4}>
                  <SelectMultipleCategory
                    onSelect={handleSpaceCategory}
                    value={values.tags?.length ? values.tags : []}
                    values={spotSpaceCategories}
                    label={`${i18n._(t`Seleccione categoría de espacio`)}`}
                    fullWidth
                  />
                </Box>
              </Grid>
            </Grid>

            <Box mt={4}>
              <Grid container spacing={2}>
                <Grid container item xs={12} md={6} spacing={3}>
                  <Grid item xs={8} md={6}>
                    <TextField
                      type="text"
                      label={`${i18n._(t`¿Cuál es el tamaño en m2?*`)}`}
                      placeholder={`${i18n._(t`Ej: 2x2 m2`)}`}
                      validators={["required"]}
                      errorMessages={[""]}
                      value={values.size || ""}
                      onChange={handleInputChange("size")}
                      validatorListener={validatorListener}
                      fullWidth
                      min={0}
                      InputProps={{
                        placeholder: "32",
                        startAdornment: (
                          <InputAdornment position="start">
                            <SizeIcon />
                          </InputAdornment>
                        ),
                      }}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      inputProps={{ maxLength: 9 }}
                    />
                  </Grid>

                  {/* válido para el cliente en uk */}
                  {hasSpaceDimensionsFeature ? (
                    <>
                      <FormControl>
                        <Grid item xs={4} style={{ marginTop: "-29px" }}>
                          <MySelect
                            value={spaceUnitSelector === "" ? spaceUnit : spaceUnitSelector}
                            placeholder="m2"
                            InputLabelProps={{
                              className: `${classes.inputLabel} }`,
                            }}
                            InputProps={{
                              className: `${classes.customInputPropsStyle}`,
                            }}
                            onChange={handleChangeSpaceUnit}
                            selectClassName={classes.customSelect}
                          >
                            {Object.keys(areaUnits).map((a) => (
                              <MenuItem value={a} key={Math.random()}>
                                {a}
                              </MenuItem>
                            ))}
                          </MySelect>
                        </Grid>
                      </FormControl>

                      <Grid item xs={4}>
                        <TextField
                          type="text"
                          label="Space dimensions"
                          placeholder="2x2"
                          errorMessages={["Use the format 3x3"]}
                          value={values.dimensions}
                          onChange={handleInputChange("dimensions")}
                          validatorListener={validatorListener}
                          InputProps={{
                            placeholder: "2x2",
                            startAdornment: (
                              <InputAdornment position="start" style={{ marginBottom: "5px" }}>
                                <DimensionIcon />
                              </InputAdornment>
                            ),
                          }}
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          inputProps={{ maxLength: 9 }}
                        />
                      </Grid>

                      {showOverlapField && (
                        <Grid item xs={4}>
                          <TextField
                            type="number"
                            label={`${i18n._(t`Superposición`)}`}
                            value={values.overlap}
                            onChange={handleInputChange("overlap")}
                            validatorListener={validatorListener}
                            inputProps={{ maxLength: 2 }}
                          />
                        </Grid>
                      )}
                    </>
                  ) : null}
                </Grid>
                <Grid style={{ position: "relative" }} item xs={12} md={6}>
                  <Box className={classes.switch}>
                    <Box mr={1}>
                      <Typography variant="body1">
                        <Trans>Mostrar</Trans>
                      </Typography>
                    </Box>
                    <Switch checked={showMaxCapacity} onChange={handleOnSwitchClicked} />
                  </Box>
                  <TextField
                    type="number"
                    label={`${i18n._(t`¿Cuál es el aforo máximo?*`)}`}
                    placeholder={`${i18n._(t`Ej: 100`)}`}
                    validators={["minNumber:0"]}
                    errorMessages={[""]}
                    value={values.capacity || ""}
                    onChange={handleInputChange("capacity")}
                    onBlur={handleOnCapacityInputBlur}
                    validatorListener={validatorListener}
                    disabled={!showMaxCapacity}
                    fullWidth
                    InputProps={{
                      placeholder: `100`,
                      startAdornment: (
                        <InputAdornment position="start">
                          <AforoIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} className={classes.errorMessageContainer}>
                  <Box display="flex" style={{ float: "right" }}>
                    {validationError && (
                      <Typography variant="body1" color="error">
                        {`${i18n._(t`Por favor complete todos los campos.`)}`}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <StepButtons
          hasNext
          labelNext={<Trans>Guardar</Trans>}
          disableShowSpace={isNew}
          disableBack={status === "loading"}
          disableContinue={disableContinue}
          onSetOpenDelete={onSetOpenDelete}
          onResetChanges={handleResetChanges}
          disableResetChanges={!somethingHasChanged}
        />
      </ValidatorForm>
    </Box>
  );
};

StepContent.propTypes = {
  space: PropTypes.object.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

const TypeStep = (props) => {
  const { i18n } = useLingui();

  const Helper = () => (
    <Typography variant="body2">
      <Trans>La categoria elegida nos ayuda a posicionar tu construcción de la manera adecuada</Trans>.
    </Typography>
  );

  return (
    <BaseStep mainContent={<StepContent {...props} />} helpContent={<Helper />} title={`${i18n._(t`Ubicación`)}`} />
  );
};

export default TypeStep;
