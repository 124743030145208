import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import MultiselectSearchFilter from "../multiselect-search-filter";
import SearchBoxFilter from "../search-box-filter";
import Assets from "../../icons/sass-icono-assets-info";
import SpacesIcon from "../../icons/sass-icono-espacios";
import CountryIcon from "../../icons/country-icon";
import CityIcon from "./../../icons/city-icon";
// theme
import theme from "../../theme-admin";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 32,
    height: 32,
  },
  filters: {
    overflow: "auto",
    paddingBottom: 5,
    "& > .MuiAutocomplete-root": {
      marginRight: theme.spacing(1),
    },
  },
}));

const SpacesToolbar = ({
  assets = [],
  types = [],
  countries = [],
  cities = [],
  filters = {},
  handleFilterSpaces = () => {},
  handleSearch = () => {},
}) => {
  const classes = useStyles(theme);

  const { i18n } = useLingui();

  const handleOnSelectFilters = (filter) => (newValue) => {
    handleFilterSpaces({ ...filters, [filter]: [...newValue] });
  };

  const handleOnNameFilter = (newValue) => {
    handleSearch(newValue);
  };

  return (
    <Box
      display={"flex"}
      alignItems={"stretch"}
      justifyContent={"flex-start"}
      width="100%"
      className={classes.filters}
      gridGap={3}
    >
      <MultiselectSearchFilter
        title={i18n._(t`Activos`)}
        localStorageName={"spaceAssets"}
        icon={() => <Assets className={classes.icon} fill={theme.palette.black} />}
        options={assets}
        onSelect={handleOnSelectFilters("assets")}
      />
      <MultiselectSearchFilter
        title={i18n._(t`Tipo`)}
        localStorageName={"spaceTypes"}
        icon={() => <SpacesIcon className={classes.icon} fill={theme.palette.black} />}
        options={types}
        onSelect={handleOnSelectFilters("types")}
      />
      <MultiselectSearchFilter
        title={i18n._(t`Ciudad`)}
        localStorageName={"spaceCities"}
        icon={() => <CityIcon className={classes.icon} fill={theme.palette.black} />}
        options={cities}
        onSelect={handleOnSelectFilters("cities")}
      />
      <MultiselectSearchFilter
        title={i18n._(t`País`)}
        localStorageName={"spaceCountries"}
        icon={() => <CountryIcon className={classes.icon} fill={theme.palette.black} />}
        options={countries}
        onSelect={handleOnSelectFilters("countries")}
      />
      <SearchBoxFilter placeholder={i18n._(t`Buscar`)} onSearch={handleOnNameFilter} />
    </Box>
  );
};

export default SpacesToolbar;
