import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdSpaceThunk, updateAdSpaceData } from "../../../redux/actions/adspaces-actions";
import FinishDialogComponent from "../../../components/listing-form/calendar-step/finish-dialog";
import api from "../../../api";

const FinishDialog = ({ open = false, status = "initial", onClose = () => {}, onSubmit = () => {} }) => {
  const dispatch = useDispatch();

  const adspaceId = useSelector((state) => state.adspaces.adSpace?.id);
  const searchable = useSelector((state) => state.adspaces.adSpace?.searchable);

  const handlePublishAdSpace = async () => {
    if (adspaceId > 0) {
      searchable ? await api.hideAdSpace(adspaceId) : await api.publishAdSpace(adspaceId);
      dispatch(getAdSpaceThunk({ adSpaceId: adspaceId }));
    } else {
      dispatch(updateAdSpaceData({ searchable: !searchable }));
    }
  };

  return (
    <FinishDialogComponent
      open={open}
      status={status}
      hidden={!searchable}
      publishSpace={handlePublishAdSpace}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export default FinishDialog;
