import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminMenuComponent from "../../components/owner-dashboard/admin-menu";
import { logoutThunk } from "../../redux/actions/auth-actions";
import { useNavigate } from "/src/hooks/navigation";

const AdminMenu = () => {
  const dispatch = useDispatch();
  const { navigate } = useNavigate();
  const userAvatar = useSelector((state) => state.user.data.avatar);
  const userName = useSelector((state) => state.user.data.name);

  const handleLogout = (redirect) => {
    dispatch(logoutThunk(() => navigate("/")));
  };

  return <AdminMenuComponent
      logout={handleLogout}
      userAvatar={userAvatar}
      userName={userName}
    />
};

export default AdminMenu;
