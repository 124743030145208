import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Menu, Avatar, IconButton, MenuItem, Typography } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import Link from "../link";
// theme
import theme from "../../theme";
import LocaleSelector from "../../containers/locale-selector";
import HelpVideos from "./help-videos";
import PrivacyPolicy from "../privacy-policy";

const useStyles = makeStyles((theme) => ({
  AdminMenu: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
}));

const AdminMenu = ({ logout = () => {}, userAvatar, userName }) => {
  const classes = useStyles({
    ...theme,
  });

  const showHelpVideos = useSelector((state) => state.misc.featureFlags["flag.exp.show_help_videos"]);
  const [helpVideosModal, setHelpVideosModal] = useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleLogout = () => logout();

  return (
    <>
      {showHelpVideos && <HelpVideos open={helpVideosModal} onClose={() => setHelpVideosModal(false)} />}
      <PrivacyPolicy open={openPrivacyPolicy} onClose={() => setOpenPrivacyPolicy(false)} />
      {showHelpVideos && (
        <IconButton edge="end" onClick={() => setHelpVideosModal(true)} color="inherit" style={{ marginRight: 16 }}>
          <PlayCircleOutlineIcon style={{ color: theme.palette.gray96, width: 38, height: 38 }} />
        </IconButton>
      )}
      <LocaleSelector />
      <IconButton edge="end" onClick={handleMenuOpen} color="inherit" style={{ marginRight: 10 }}>
        <Avatar src={userAvatar} style={{ width: 38, height: 38 }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="user-menu"
        open={isMenuOpen}
        onClose={handleMenuClose}
        className={classes.AdminMenu}
      >
        <Box p={2}>
          <Typography variant="subtitle1">{userName}</Typography>
        </Box>
        <Box>
          <Link to="/" target="_blank">
            <MenuItem onClick={handleMenuClose}>
              <Trans>Ir al home</Trans>
            </MenuItem>
          </Link>
        </Box>
        <Box>
          <Link to="/profile/">
            <MenuItem onClick={handleMenuClose}>
              <Trans>Perfil</Trans>
            </MenuItem>
          </Link>
        </Box>
        <Box onClick={() => setOpenPrivacyPolicy(true)}>
          <MenuItem onClick={handleMenuClose}>
            <Trans>Política de privacidad</Trans>
          </MenuItem>
        </Box>
        <MenuItem
          onClick={() => {
            handleLogout();
          }}
        >
          <Trans>Salir</Trans>
        </MenuItem>
      </Menu>
    </>
  );
};
export default AdminMenu;
