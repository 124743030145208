import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  initGoogleMapsThunk,
  initMarkerClusterThunk,
  setSelectedSpaceThunk,
  setSelectedAdSpaceThunk,
} from "../../redux/actions/google-maps-actions";
import Box from "@material-ui/core/Box";
import PriceMarker from "./price-marker";
import { useStyles } from "./price-marker-styles";
import theme from "./../../theme";

function GoogleMaps({
  height = "100%",
  width = "100%",
  MapCard = () => <></>,
  priceConfig,
  spot,
  center = { lat: -5.8116375, lng: -35.2186123 },
  zoom = 16,
  priceMarkers = [{}],
  isAdSpace = false,
  onClickMap,
  mapRef,
}) {
  const dispatch = useDispatch();
  const classes = useStyles(theme);
  const MAP_ID = Math.random().toString(36).substring(7);

  const maps = useSelector((state) => state.googleMaps.maps);
  const clusterMarker = useSelector((state) => state.googleMaps.clusterMarker);

  const mapsStatus = useSelector((state) => state.googleMaps.mapsStatus);
  const clusterMarkerStatus = useSelector(
    (state) => state.googleMaps.clusterMarkerStatus
  );

  useEffect(() => {
    if (mapsStatus === "success" && clusterMarkerStatus === "success") {
      const map = new maps.Map(document.getElementById("gmaps-" + MAP_ID), {
        zoom: zoom,
        center: center,
      });

      if (mapRef) mapRef.current = map;

      const PriceMarkerClass = PriceMarker(maps);
      const priceMarkersArray = priceMarkers.map((m) => {
        const marker = new PriceMarkerClass(
          map,
          new maps.LatLng(parseFloat(m.latitude), parseFloat(m.longitude)),
          m.display_price,
          m.id,
          classes.priceMarker
        );
        marker.anchor.onclick = () => {
          if (isAdSpace) {
            dispatch(setSelectedAdSpaceThunk(m.id));
          } else {
            dispatch(setSelectedSpaceThunk(m.id));
          }
          const marker = document.getElementById("map-card");
          if (marker) {
            marker.style.bottom = "5%";
            marker.style.display = "flex";
          }
        };
        return marker;
      });
      new clusterMarker(map, priceMarkersArray, {
        imagePath:
          "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        maxZoom: 18,
      });
      // adding click listener if needed
      if (onClickMap) {
        map.addListener("click", (evt) => onClickMap(map, evt));
      }
    } else if (mapsStatus === "initial") {
      dispatch(initGoogleMapsThunk());
    } else if (clusterMarkerStatus === "initial") {
      dispatch(initMarkerClusterThunk());
    }
  }, [mapsStatus, clusterMarkerStatus, center]);

  return (
    <Box position="relative" width="100%" className={classes.mapContainer}>
      <Box
        position="relative"
        id={"gmaps-" + MAP_ID}
        height={height}
        width={width}
      />
      <MapCard spot={spot} priceConfig={priceConfig} />
    </Box>
  );
}

// TEchdebt: google maps refactor
export default React.memo(
  GoogleMaps,
  (prev, next) =>
    prev.center?.lat === next.center?.lat &&
    prev.priceMarkers?.length === next.priceMarkers?.length
);
