import React from "react";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { Trans } from "@lingui/macro";

import SecondaryButton from "../secondary-button";
import OwnerModal from "../owner-modal";
import theme from "../../theme";

const useStyles = makeStyles((th) => ({
  divider: {
    borderBottom: "1px solid black",
  },
}));

function TableDataDownload({ open = false, onClose = () => {}, onDownload = () => {} }) {
  const formats = ["xls", "xlsx", "csv", "tsv", "ods"];

  const classes = useStyles(theme);

  const handleDownload = (format) => {
    onDownload(format);
    onClose();
  };

  return (
    <OwnerModal
      title={<Trans>Seleccionar formato de archivo</Trans>}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      width={450}
    >
      <List>
        {formats.map((fmt) => (
          <ListItem key={fmt} onClick={() => handleDownload(fmt)} autoFocus button>
            <ListItemText primary={fmt} />
          </ListItem>
        ))}
      </List>
      <Box className={classes.divider} mt={1} mb={1} />
      <Box display="flex" justifyContent="flex-end" width="100%" mb={1} pr={1}>
        <SecondaryButton onClick={onClose}>
          <Trans>Volver</Trans>
        </SecondaryButton>
      </Box>
    </OwnerModal>
  );
}

export default TableDataDownload;
