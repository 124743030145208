import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useLingui } from "@lingui/react";
import Typography from "@material-ui/core/Typography";
import Backdrop from "@material-ui/core/Backdrop";
import { Trans, t } from "@lingui/macro";
import { ValidatorForm } from "react-material-ui-form-validator";
import CircularProgress from "@material-ui/core/CircularProgress";
import StepButtons from "../../step-buttons";
import CheckBox from "../../custom-checkbox/custom-checkbox-alternative-3";
import DecideDialog from "../../decide-dialog-owner";
import theme from "../../../theme";
import TextField from "../../text-field";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    color: theme.palette.text.primary,
    paddingLeft: theme.spacing(3),
    overflowY: "hidden",
  },
  validatorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    height: "100%",
    width: "100%",
    overflowY: "auto",
  },
  continueButton: {
    width: 190,
    height: 42,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.white,
  },
  fields: {
    marginTop: theme.spacing(1),
  },
}));

const INIT_VALUES = {
  account: null,
  warranty: null,
  irpf: null,
  iva: null,
  generateProforma: null,
};

const Proforma = ({
  assetConfigs,
  status = "initial",
  submitStatus = "initial",
  submit = () => {},
  resetSubmitStatus,
}) => {
  const classes = useStyles(theme);

  const [openDialog, setOpenDialog] = useState(false);
  const [values, setValues] = useState(INIT_VALUES);
  const [valuesHasChanged, setValuesHasChanged] = useState();

  const { i18n } = useLingui();

  const handleOpenModal = () => {
    setOpenDialog(true);
  };

  const handleCloseModal = () => {
    resetSubmitStatus();
    setOpenDialog(false);
  };

  const handleOnChangeCheckbox = (param) => (e, checked) => {
    setValues((v) => ({ ...v, [param]: checked ? "" : null }));
  };

  const handleOnChangeTextField =
    (param) =>
    ({ target }) => {
      setValues((v) => ({ ...v, [param]: target.value }));
    };

  const handleFinish = () => {
    submit(values);
  };

  const handleCancel = () => {
    setValues({ ...assetConfigs });
  };

  useEffect(() => {
    if (!openDialog) {
      setValues((v) => ({ ...v, ...assetConfigs }));
    }
  }, [assetConfigs]);

  useEffect(() => {
    const hasChanged = JSON.stringify(values) != JSON.stringify(assetConfigs);
    if (hasChanged !== valuesHasChanged) {
      setValuesHasChanged(hasChanged);
    }
  }, [values, assetConfigs]);

  return (
    <>
      <Box className={classes.container}>
        <DecideDialog
          open={openDialog}
          status={submitStatus}
          onAccept={handleFinish}
          title={<Trans>Existen cambios sin guardar</Trans>}
          desc={
            <Trans>
              Al editar un campo debes guardar los cambios. ¿Desea guardar los cambios realizados antes de continuar?
            </Trans>
          }
          acceptText={<Trans>Guardar y continuar</Trans>}
          rejectText={<Trans>Continuar sin guardar</Trans>}
          onClose={() => setOpenDialog(false)}
        />
        <Backdrop className={classes.backdrop} open={status === "loading" || submitStatus === "loading"}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar open={submitStatus === "error"} autoHideDuration={3000}>
          <MuiAlert elevation={6} variant="filled" severity="error">
            <Trans>Ha ocurrido un error. Por favor intente de nuevo.</Trans>
          </MuiAlert>
        </Snackbar>
        <ValidatorForm className={classes.validatorContainer} onSubmit={() => {}}>
          <Box mt={1}>
            <Typography variant="subtitle1">
              <Trans>Seleccione y detalle aquellos datos que desee incluir en la proforma.*</Trans>
            </Typography>
            <Box mt={2}>
              <Typography variant="subtitle2" style={{ fontWeight: "bold", color: theme.palette.grayLabel }}>
                <Trans>Datos bancarios</Trans>
              </Typography>
              <Box mt={1} display="flex" flexDirection="row" alignItems="center">
                <CheckBox
                  checked={values.account !== null}
                  onChange={handleOnChangeCheckbox("account")}
                  style={{ padding: theme.spacing(0) }}
                />
                <Box ml={1}>
                  <Trans>Número de cuenta*</Trans>
                </Box>
              </Box>
              <Box mt={1}>
                <TextField
                  value={values.account || ""}
                  disabled={values.account === null}
                  placeholder={i18n._(t`N°`)}
                  style={{ width: "200px" }}
                  textFieldClassNames={classes.fields}
                  type="number"
                  validators={["required"]}
                  onChange={handleOnChangeTextField("account")}
                  errorMessages={[""]}
                />
              </Box>
            </Box>
            <Box mt={3}>
              <Typography variant="subtitle2" style={{ fontWeight: "bold", color: theme.palette.grayLabel }}>
                <Trans>GARANTÍA</Trans>
              </Typography>
              <Box mt={1} display="flex" flexDirection="row" alignItems="center">
                <CheckBox
                  checked={values.warranty}
                  onChange={(e, checked) => setValues((v) => ({ ...v, warranty: checked }))}
                  style={{ padding: theme.spacing(0) }}
                />
                <Box ml={1}>
                  <Trans>Solicitar garantía</Trans>
                </Box>
              </Box>
            </Box>
            {module === "bac" ? (
              <Box mt={3}>
                <Typography variant="subtitle2" style={{ fontWeight: "bold", color: theme.palette.grayLabel }}>
                  <Trans>IMPUESTOS</Trans>
                </Typography>
                <Box display="flex" flexDirection="row">
                  <Box>
                    <Box mt={1} display="flex" flexDirection="row" alignItems="center">
                      <CheckBox
                        checked={values.irpf !== null}
                        onChange={handleOnChangeCheckbox("irpf")}
                        style={{ padding: theme.spacing(0) }}
                      />
                      <Box ml={1}>
                        <Trans>IRPF*</Trans>
                      </Box>
                    </Box>
                    <Box mt={1}>
                      <TextField
                        value={values.irpf || ""}
                        disabled={values.irpf === null}
                        placeholder={i18n._(t`%`)}
                        style={{ width: "200px", marginBottom: theme.spacing(2) }}
                        textFieldClassNames={classes.fields}
                        type="number"
                        validators={["required"]}
                        onChange={handleOnChangeTextField("irpf")}
                        errorMessages={[""]}
                      />
                    </Box>
                  </Box>
                  <Box ml={2}>
                    <Box mt={1} display="flex" flexDirection="row" alignItems="center">
                      <CheckBox
                        checked={values.iva !== null}
                        onChange={handleOnChangeCheckbox("iva")}
                        style={{ padding: theme.spacing(0) }}
                      />
                      <Box ml={1}>
                        <Trans>IVA*</Trans>
                      </Box>
                    </Box>
                    <Box mt={1}>
                      <TextField
                        value={values.iva || ""}
                        disabled={values.iva === null}
                        placeholder={i18n._(t`%`)}
                        style={{ width: "200px", marginBottom: theme.spacing(2) }}
                        textFieldClassNames={classes.fields}
                        type="number"
                        validators={["required"]}
                        onChange={handleOnChangeTextField("iva")}
                        errorMessages={[""]}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              <></>
            )}
            <Box mt={3}>
              <Typography variant="subtitle2" style={{ fontWeight: "bold", color: theme.palette.grayLabel }}>
                <Trans>Habilitar proforma</Trans>
              </Typography>
              <Box mt={1} display="flex" flexDirection="row" alignItems="center">
                <CheckBox
                  checked={values?.generateProforma}
                  onChange={(e, checked) => setValues((v) => ({ ...v, generateProforma: checked }))}
                  style={{ padding: theme.spacing(0) }}
                />
                <Box ml={1}>
                  <Trans>Habilitar generar proforma</Trans>
                </Box>
              </Box>
            </Box>
          </Box>
        </ValidatorForm>
      </Box>
      <StepButtons
        continueButtonLabel={<Trans>Guardar</Trans>}
        backButtonLabel={<Trans>Olvidar cambios</Trans>}
        handleContinue={handleFinish}
        handleBack={handleCancel}
        disableContinue={!valuesHasChanged}
        disableBack={!valuesHasChanged}
      />
    </>
  );
};

export default Proforma;
