import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function CountryIcon({ fill = "#E5E5E5", ...rest }) {
  return (
    <SvgIcon width="13" height="16" viewBox="0 0 13 16" fill={fill} {...rest}>
      <path
        d="M6.11426 0.000305016L6.11013 0.00384607L-2.1659e-05 5.78058L0.885165 6.72359L5.37837 2.46703L5.36893 11.585L6.82595 11.5874L6.8348 2.46939L11.3197 6.73363L12.2079 5.79474L6.11013 0.00384607L6.10541 0.000305015L6.02338 0.0846926"
        fill={fill}
      />
      <path
        d="M0.00182915 15.2305L12.2103 15.2305L12.2103 14.2343L0.00182923 14.2343L0.00182915 15.2305Z"
        fill={fill}
      />
    </SvgIcon>
  );
}
