import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "/src/hooks/navigation";
import { useParams } from "@reach/router";
// Component
import TypeComponent from "../../components/listing-form/type-step";
// Actions
import {
  getSpaceThunk,
  postSpaceThunk,
  putSpaceThunk,
  getSpotsCategoriesThunk,
  postSpaceCategoriesThunk,
} from "../../redux/actions/space-actions";
import { getSpaceTypesThunk } from "../../redux/actions/misc-actions";

const TypeStepContainer = ({ onSetOpenDelete }) => {
  const dispatch = useDispatch();

  const { navigate } = useNavigate();
  const params = useParams();
  const [shouldReload, setShouldReload] = useState(false);

  const id = useSelector((state) => state.listing.data.id);
  const status = useSelector((state) => state.listing.status);
  const spaceTypes = useSelector((state) => state.misc.spaceTypes);
  const spaceSubTypes = useSelector((state) => state.misc.spaceSubTypes);
  const space = useSelector((state) => state.listing.data);
  const user = useSelector((state) => state.user.data.id);
  const isNew = params.spaceId === "new";
  const areaUnits = useSelector((state) => state.misc.settings.area_unit);
  const hasSpaceDimensionsFeature = useSelector((state) => state.misc.featureFlags["flag.ops.space_dimensions"]);
  const spotSpaceCategories = useSelector((state) =>
    state.listing.spacesCategories?.filter((spaceCategory) => spaceCategory.data.type === "space"),
  );
  const showOverlapField = useSelector((state) => state.misc.featureFlags["flag.exp.show_overlap_field"]);

  // Esto es para testear si se llega a hacer el get de settings.area_unit
  const hardcodedAreaUnits = {
    Feet: "ft2",
    Meters: "m2",
  };
  const getPayloadByKey = () => {
    if (areaUnits === undefined) {
      return Object.keys(hardcodedAreaUnits).find((key) => hardcodedAreaUnits[key] === space.area_unit);
    }
    if (space.area_unit === null || space.area_unit === undefined) {
      return Object.keys(areaUnits).find((key) => areaUnits[key] === "m2");
    }
    return Object.keys(areaUnits).find((key) => areaUnits[key] === space.area_unit);
  };

  const spaceUnit = getPayloadByKey();

  const handleSubmit = (data) => {
    if (id) {
      dispatch(putSpaceThunk(id, data));
    } else {
      dispatch(postSpaceThunk(data, user));
      setShouldReload(true);
    }

    if (JSON.stringify(space.tags) !== JSON.stringify(data.tags)) {
      const tags = data.tags?.map((tag) => tag.id).join(",") || "";
      dispatch(postSpaceCategoriesThunk(id, tags));
    }
  };

  useEffect(() => {
    // otherwise this kept calling on edit space
    if (id > 0 && shouldReload) {
      dispatch(getSpaceThunk(id));
      setShouldReload(false);
    }
  }, [id, shouldReload]);

  useEffect(() => {
    dispatch(getSpaceTypesThunk());
  }, []);

  useEffect(() => {
    if (space.asset_id > 0) {
      dispatch(getSpotsCategoriesThunk(space.asset_id));
    }
  }, [space.asset_id]);

  return (
    <TypeComponent
      spotSpaceCategories={spotSpaceCategories}
      isNew={isNew}
      onSetOpenDelete={onSetOpenDelete}
      handleNext={() => navigate(`/administrator/spaces/${id}/characteristics`)}
      handleBack={() => navigate(`/administrator/spaces/${id}/address`)}
      onSubmit={handleSubmit}
      status={status}
      spaceTypes={spaceTypes}
      spaceSubTypes={spaceSubTypes}
      space={space}
      areaUnits={areaUnits === undefined ? hardcodedAreaUnits : areaUnits}
      spaceUnit={spaceUnit}
      hasSpaceDimensionsFeature={hasSpaceDimensionsFeature}
      showOverlapField={showOverlapField}
    />
  );
};

export default TypeStepContainer;
