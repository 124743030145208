import React, { useEffect, useState } from "react";
import { useParams } from "@reach/router";
import { useSelector, useDispatch } from "react-redux";
import InvoicingComponent from "../../../components/asset-form/invoicing";
import { getAssetThunk } from "../../../redux/actions/asset-actions";
import api from "../../../api";

const Information = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [patchRequest, setPatchRequest] = useState({ status: "initial", error: "" });

  const { assetId } = params;
  const asset = useSelector((state) => state.asset.data);

  useEffect(() => {
    return () => {
      setPatchRequest({ status: "initial" });
    };
  }, []);

  const handleSubmit = async (values) => {
    setPatchRequest({ status: "loading" });
    const res = await api.updateAssetInvoiceOptions(assetId, values);
    if (res.status === 200) {
      dispatch(getAssetThunk(assetId));
      setPatchRequest({ status: "success" });
    } else {
      setPatchRequest({ status: "error", error: res.data });
    }
  };

  const resetSubmitStatus = () => {
    setPatchRequest("initial");
  };

  return (
    <InvoicingComponent
      asset={asset}
      submitStatus={patchRequest.status}
      resetSubmitStatus={resetSubmitStatus}
      submit={handleSubmit}
    />
  );
};

export default Information;
