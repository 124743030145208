import { t } from "@lingui/macro";

// space activity coefficients

t({ id: "alimentacion-274", message: "Alimentacion" });
t({ id: "servicios-125", message: "Servicios" });
t({ id: "otros-632", message: "Otros" });
t({ id: "stand-kiosko-venta-captacion", message: "Stand Kiosko Venta / Capacitación" });
t({ id: "acciones-promocionales-eventos-roadshow", message: "Acciones promocionales eventos / Roadshow" });
t({ id: "maquinas-servicios-vending-cajeros", message: "Máquinas servicios de vending / Cajeros" });
t({ id: "maquinas-vending-cajeros", message: "Máquinas vending y cajeros" });
t({ id: "marcas-nacionales-internacionales", message: "Marcas nacionales e internacionales" });
t({ id: "marcas-locales", message: "Marcas locales" });
t({ id: "actividades-infantiles", message: "Actividades infantiles" });

t({ id: "captacion", message: "Captación" });
t({ id: "alimentacion", message: "Alimentación" });
t({ id: "restauracion", message: "Restauración" });
t({ id: "complementos", message: "Complementos" });
t({ id: "servicios", message: "Servicios" });
t({ id: "estetica", message: "Estética" });
t({ id: "otros", message: "Otros" });

t({ id: "brand_awareness_build_brand", message: "Conocimiento de marca" });
t({ id: "new_product_launch", message: "Lanzamiento de nuevo producto" });
t({ id: "product_sales", message: "Venta de productos" });
t({ id: "stock_clearance", message: "Limpieza de stock" });
t({ id: "membership_drive", message: "Campaña de membresía" });
t({ id: "fundraising", message: "Recaudación de fondos" });

t({ id: "space_activity_group_00", message: "Grupo de actividad comercial 00" });
t({ id: "space_activity_group_01", message: "Grupo de actividad comercial 01" });
t({ id: "space_activity_group_02", message: "Grupo de actividad comercial 02" });
t({ id: "space_activity_group_03", message: "Grupo de actividad comercial 03" });
t({ id: "space_activity_group_04", message: "Grupo de actividad comercial 04" });
t({ id: "space_activity_group_05", message: "Grupo de actividad comercial 05" });

t({ id: "space_activity_00", message: "Actividad comercial 00" });
t({ id: "space_activity_01", message: "Actividad comercial 01" });
t({ id: "space_activity_02", message: "Actividad comercial 02" });
t({ id: "space_activity_03", message: "Actividad comercial 03" });
t({ id: "space_activity_04", message: "Actividad comercial 04" });
t({ id: "space_activity_05", message: "Actividad comercial 05" });
t({ id: "space_activity_06", message: "Actividad comercial 06" });
t({ id: "space_activity_07", message: "Actividad comercial 07" });
t({ id: "space_activity_08", message: "Actividad comercial 08" });
t({ id: "space_activity_08", message: "Actividad comercial 08" });
t({ id: "space_activity_10", message: "Actividad comercial 10" });
t({ id: "space_activity_11", message: "Actividad comercial 11" });
t({ id: "space_activity_12", message: "Actividad comercial 12" });
t({ id: "space_activity_13", message: "Actividad comercial 13" });
t({ id: "space_activity_14", message: "Actividad comercial 14" });
t({ id: "space_activity_15", message: "Actividad comercial 15" });
t({ id: "space_activity_16", message: "Actividad comercial 16" });
t({ id: "space_activity_17", message: "Actividad comercial 17" });
t({ id: "space_activity_18", message: "Actividad comercial 18" });
t({ id: "space_activity_19", message: "Actividad comercial 19" });
t({ id: "space_activity_20", message: "Actividad comercial 20" });
t({ id: "space_activity_21", message: "Actividad comercial 21" });
t({ id: "space_activity_22", message: "Actividad comercial 22" });
t({ id: "space_activity_23", message: "Actividad comercial 23" });
t({ id: "space_activity_24", message: "Actividad comercial 24" });
t({ id: "space_activity_25", message: "Actividad comercial 25" });
t({ id: "space_activity_26", message: "Actividad comercial 26" });
t({ id: "space_activity_27", message: "Actividad comercial 27" });
t({ id: "space_activity_28", message: "Actividad comercial 28" });
t({ id: "space_activity_29", message: "Actividad comercial 29" });
t({ id: "space_activity_230", message: "Actividad comercial 30" });

// Space activity coefficients - CBRE AU
t({ id: "retail-sales", message: "Retail – Sales" });
t({ id: "retail-experiential", message: "Retail – Experiential" });
t({ id: "retail-display", message: "Retail – Display" });
t({ id: "retail-lead-generation", message: "Retail – Lead Generation" });
t({ id: "not-for-profit-acquisition", message: "Not for Profit – Acquisition" });
t({ id: "community-and-fundraising", message: "Community & Fundraising" });
t({ id: "centre-marketing-and-events", message: "Centre Marketing & Events" });
