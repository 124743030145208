import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideMenuComponent from "../../components/owner-dashboard/side-menu";
import { getNotificationsUserThunk, getUserThunk } from "../../redux/actions/user-actions";

const SideMenu = ({ currentSection }) => {
  const dispatch = useDispatch();
  const userId = typeof localStorage !== "undefined" && localStorage.getItem("user_id");
  const bookingConfirmedNews = useSelector((state) => state.user.bookingConfirmedNews);
  const bookingProgressNews = useSelector((state) => state.user.bookingProgressNews);

  useEffect(() => {
    if (userId > 0) {
      dispatch(getUserThunk({ isAdmin: true }));
      dispatch(getNotificationsUserThunk());
    }
  }, [userId]);

  return (
    <SideMenuComponent
      currentSection={currentSection}
      bookingConfirmedNews={bookingConfirmedNews}
      bookingProgressNews={bookingProgressNews}
    />
  );
};

export default SideMenu;
