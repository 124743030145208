import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function AforoIcon({ fill = "#333333", ...rest }) {
  return (
    <SvgIcon
      width="169"
      height="112"
      viewBox="0 0 169 112"
      fill="none"
      {...rest}
    >
      <path
        d="M154.023 29.0867C157.146 25.9633 159.098 21.6686 159.098 16.9835C159.098 7.6133 151.485 0 142.115 0C132.745 0 125.131 7.6133 125.131 16.9835C125.131 21.6686 127.083 25.9633 130.207 29.0867C121.617 33.3814 115.761 42.3612 115.761 52.5122V79.2563H128.84V97.8015C128.84 105.22 134.892 111.076 142.115 111.076C149.533 111.076 155.389 105.024 155.389 97.8015V79.2563H168.469V52.7074C168.469 42.3611 162.612 33.5766 154.023 29.0867ZM141.92 7.80851C146.995 7.80851 151.095 11.908 151.095 16.9835C151.095 22.059 146.995 26.3537 141.92 26.3537C136.844 26.3537 132.745 22.2542 132.745 17.1787C132.745 12.1032 136.844 7.80851 141.92 7.80851ZM160.66 71.6431H147.581V97.9968C147.581 100.925 145.043 103.463 142.115 103.463C139.187 103.463 136.649 100.925 136.649 97.9968V71.6431H123.57V52.7074C123.57 42.3611 131.964 34.1622 142.115 34.1622C152.461 34.1622 160.66 42.5564 160.66 52.7074V71.6431Z"
        fill={fill}
      />
      <path
        d="M96.0446 29.0867C99.168 25.9633 101.12 21.6686 101.12 16.9835C101.12 7.6133 93.5069 0 84.1367 0C74.7665 0 67.1532 7.6133 67.1532 16.9835C67.1532 21.6686 69.1053 25.9633 72.2287 29.0867C63.6393 33.3814 57.783 42.3612 57.783 52.5122V79.2563H70.8622V97.8015C70.8622 105.22 76.9138 111.076 84.1367 111.076C91.3595 111.076 97.4111 105.024 97.4111 97.8015V79.2563H110.49V52.7074C110.49 42.3611 104.634 33.5766 96.0446 29.0867ZM74.9617 16.9835C74.9617 11.908 79.0611 7.80851 84.1367 7.80851C89.2122 7.80851 93.3117 11.908 93.3117 16.9835C93.3117 22.059 89.2122 26.3537 84.1367 26.3537C79.0611 26.3537 74.9617 22.059 74.9617 16.9835ZM102.682 71.6431H89.6026V97.9968C89.6026 100.925 87.0649 103.463 84.1367 103.463C81.2085 103.463 78.6707 100.925 78.6707 97.9968V71.6431H65.5915V52.7074C65.5915 42.3611 73.9856 34.1622 84.1367 34.1622C94.2877 34.1622 102.682 42.5564 102.682 52.7074V71.6431Z"
        fill={fill}
      />
      <path
        d="M38.2617 29.0867C41.3851 25.9633 43.3372 21.6686 43.3372 16.9835C43.3372 7.6133 35.7239 0 26.3537 0C16.9835 0 9.37021 7.6133 9.37021 16.9835C9.37021 21.6686 11.3223 25.9633 14.4457 29.0867C5.85638 33.3814 0 42.3612 0 52.5122V79.2563H13.0792V97.8015C13.0792 105.22 19.1308 111.076 26.3537 111.076C33.7718 111.076 39.6282 105.024 39.6282 97.8015V79.2563H52.7074V52.7074C52.7074 42.3611 46.851 33.5766 38.2617 29.0867ZM16.9835 16.9835C16.9835 11.908 21.083 7.80851 26.1585 7.80851C31.234 7.80851 35.3335 11.908 35.3335 16.9835C35.3335 22.059 31.4292 26.3537 26.3537 26.3537C21.2782 26.3537 16.9835 22.059 16.9835 16.9835ZM44.8989 71.6431H31.8197V97.9968C31.8197 100.925 29.2819 103.463 26.3537 103.463C23.4255 103.463 20.8878 100.925 20.8878 97.9968V71.6431H7.80851V52.7074C7.80851 42.3611 16.2027 34.1622 26.3537 34.1622C36.7 34.1622 44.8989 42.5564 44.8989 52.7074V71.6431Z"
        fill={fill}
      />
    </SvgIcon>
  );
}
