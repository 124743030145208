import { makeStyles } from "@material-ui/core/styles"
import React from "react"

export const useStyles = makeStyles((theme) => ({
  "@keyframes glow": {
    "0%": { transform: "scale(1)" },
    "50%": { transform: "scale(1.1)", opacity: 0.9 },
    "100%": { transform: "scale(1)" },
  },
  mapContainer: {
    "& .cluster": {
      height: "30px !important",
      width: "30px !important",
      "& > img": {
        display: "none"
      },
      "& > div": {
        animation: `$glow 3s ${theme.transitions.easing.easeInOut} infinite`,
        background: theme.palette.primary.main,
        color: `${theme.palette.primary.contrastText} !important`,
        height: "30px !important",
        width: "30px !important",
        lineHeight: "30px !important",
        borderRadius: "50%"
      }
    }
  },
  priceMarker: {
    position: "absolute",    
    borderRadius: "50% 50% 50% 0",
    border: "4px solid " + theme.palette.primary.main,
    width: "20px",
    height: "20px",
    transform: "rotate(-45deg)",
  
    transition: "0.3s transform",
    "&:hover, &.price-marker-hover": {
      transform: "rotate(-45deg) scale(1.3)",
      zIndex: "9999",
    },
    "&::after": {
      position: "absolute",
      content: "''",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      top: "50%",
      left: "50%",
      marginLeft: "-5px",
      marginTop: "-5px",
      backgroundColor: "#fff",
    },
    "& div": {
      position: "absolute",
      bottom: "10px",
      left: "5px",
      transform: "rotate(45deg)",
    }
  }
}))
