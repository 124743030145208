import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { SelectValidator } from "react-material-ui-form-validator";
// theme
import theme from "../../theme";

const useStyles = makeStyles((th) => ({
  field: {
    marginTop: th.spacing(3),
  },
  label: {
    fontSize: th.typography.body1.fontSize,
    transform: "translate(0, -5px) scale(1)",
    fontWeight: "bold",
  },
  input: {
    borderRadius: 0,
    border: th.borders[2],
    paddingTop: th.spacing(1),
    paddingBottom: th.spacing(1),
    paddingLeft: th.spacing(2),
  },
}));

const MySelectField = ({ InputProps, InputLabelProps, label, selectClassName, ...rest }) => {
  const classes = useStyles(theme);
  return (
    <Box>
      {label && (
        <Box mb={1}>
          <Typography style={{ textAlign: "start" }} variant="subtitle1">
            {label}
          </Typography>
        </Box>
      )}
      <SelectValidator
        className={`${classes.field} ${selectClassName}`}
        InputLabelProps={{
          shrink: true,
          className: classes.label,
          ...InputLabelProps,
        }}
        InputProps={{
          className: classes.input,
          variant: "outlined",
          ...InputProps,
        }}
        {...rest}
      />
    </Box>
  );
};

export default MySelectField;
