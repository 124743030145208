import { Box, Grid, InputLabel, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
// theme
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import theme from "../../../theme";

import getSpaceIcon from "../../../utils/get-space-icon";

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
  label: {
    fontSize: theme.typography.body1.fontSize,
    transform: "translate(0, -5px) scale(1)",
    fontWeight: "bold",
    color: theme.palette.black,
  },
  iconContainer: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "75px",
    borderRadius: "5px",
    cursor: ({ disabled }) => (disabled ? "auto" : "pointer"),
  },
  itemContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "55px",
    height: "64px",
    borderRadius: "5px",
    backgroundColor: theme.palette.lighterGray,
  },
  selectedItem: {
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    height: 40,
    width: 40,
    opacity: ({ disabled }) => (disabled ? 0.4 : 1),
  },
  categoryLabel: {
    opacity: ({ disabled }) => (disabled ? 0.4 : 1),
    textAlign: "center",
    marginTop: theme.spacing(1 / 2),
  },
  optionsContainer: {
    marginTop: theme.spacing(1),
  },
}));

const SelectCategory = ({ onSelect, label, value, values, disabled }) => {
  const classes = useStyles({
    ...theme,
    disabled,
  });

  const { i18n } = useLingui();

  return (
    <Box className={classes.field}>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <Grid container spacing={2} className={classes.optionsContainer}>
        {values?.map((v) => (
          <Grid item key={v.id}>
            <Box className={`${classes.iconContainer}`} onClick={() => !disabled && onSelect(v)}>
              <Box className={`${classes.itemContainer} ${value === v.id ? classes.selectedItem : ""}`}>
                {getSpaceIcon(v.name)({
                  className: classes.icon,
                  fill: value === v.id ? theme.palette.white : theme.palette.text.secondary,
                })}
              </Box>
              <Typography
                variant="body2"
                className={classes.categoryLabel}
                style={{
                  color: value === v.id ? theme.palette.primary.main : theme.palette.black,
                }}
              >
                {i18n._(t`${v.name}`)}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

SelectCategory.prototype = {
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
};

export default SelectCategory;
