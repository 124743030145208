import { Trans } from "@lingui/macro";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
// theme
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import theme from "../../../theme-admin";
import LoadingBar from "../../loading-bar";
import { formatDateUtc } from "../../../utils/dateUtils";
import TrashIcon from "../../../icons/trash-icon";
import DownloadIcon from "../../../icons/download-icon";
import TableRowDelete from "../../table-row-delete";
import OwnerTableModal from "../../owner-table-modal";
import DateSelectModal from "../../date-select-modal/date-select-single-date-modal";

const CONTENT_MAX_HEIGHT = "280px";

const useStyles = makeStyles((th) => ({
  container: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
  },
  customTableHead: {
    "& .MuiTableHead-root": {
      borderTop: "0px",
    },
    "& .MuiTableCell-sizeSmall": {
      minWidth: "100px",
      "&:nth-child(1)": {
        width: "80%",
      },
    },
  },
  tableHead: {
    "& .MuiTableCell-sizeSmall": {
      textTransform: "uppercase",
    },
  },
  columnAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  editIcon: {
    "& > svg": {
      display: "flex",
      width: "12px",
      height: "12px",
    },
    borderBottom: "1px solid black",
    marginLeft: th.spacing(1),
    cursor: "pointer",
    right: "2px",
    position: "absolute",
  },
}));

const AttachmentList = ({
  fileUploadStatus,
  filesByClient = [],
  filesByClientStatus,
  deleteFileByClient,
  deleteFileStatus,
  onUpdateExpirationDate,
  updateExpirationDateStatus,
  canEdit,
}) => {
  const classes = useStyles({ ...theme });
  const [showRowDelete, setShowRowDelete] = useState(-1);
  const [openDateSelect, setOpenDateSelect] = useState(false);
  const [attachmentSelected, setAttachmentSelected] = useState(-1);

  const handleClickEditExpiration = (c) => () => {
    setOpenDateSelect(true);
    setAttachmentSelected(c);
  };

  return (
    <Box className={classes.container}>
      <DateSelectModal
        open={openDateSelect}
        onClose={() => {
          setOpenDateSelect(false);
          setAttachmentSelected(-1);
        }}
        onSelectDate={(date) => {
          onUpdateExpirationDate(attachmentSelected.id, date);
        }}
        date={attachmentSelected.expire_at || Date.now()}
        calendarProps={{
          maxDate: null,
          minDate: null,
          isDouble: true,
        }}
        showCleanDates={false}
        showCancel
        title={
          <Trans>
            A continuación, selecciona la fecha de vencimiento del archivo adjunto. Te informaremos por email cuando se
            encuentre próximo a expirar.
          </Trans>
        }
        textButton={<Trans>Aceptar</Trans>}
        hasAcctionAfterApply
        acctionStatusAfterApply={updateExpirationDateStatus}
      />
      <OwnerTableModal>
        {filesByClientStatus === "loading" ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height={CONTENT_MAX_HEIGHT}
            mt={1}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box mb={2} className={classes.customTableHead}>
            {filesByClient.length ? (
              <MuiTable size="small">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell className={classes.tableRoot} align="left">
                      <Trans>archivo</Trans>
                    </TableCell>
                    <TableCell align="center">
                      <Trans>añadido</Trans>
                    </TableCell>
                    {!!canEdit && (
                      <>
                        <TableCell align="center">
                          <Trans>vencimiento</Trans>
                        </TableCell>
                        <TableCell align="center">
                          <Trans>acciones</Trans>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filesByClient.map((c) => {
                    const fileName = c.file_url.split("/");
                    return (
                      <TableRow key={c.id}>
                        {showRowDelete !== c.id ? (
                          <>
                            <TableCell align="left" variant="body">
                              {`${fileName[fileName.length - 1]}`}
                            </TableCell>
                            <TableCell variant="body" style={{ width: "100px" }}>
                              {formatDateUtc(c.created_at, "DD-MM-YYYY")}
                            </TableCell>
                            {!!canEdit && (
                              <>
                                <TableCell>
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    position="relative"
                                  >
                                    {c.expire_at ? formatDateUtc(c.expire_at, "DD/MM/YYYY") : "-"}
                                    <Box
                                      className={classes.editIcon}
                                      style={{ right: "-16px" }}
                                      onClick={handleClickEditExpiration(c)}
                                    >
                                      <EditOutlinedIcon />
                                    </Box>
                                  </Box>
                                </TableCell>
                                <TableCell variant="body">
                                  <Box className={classes.columnAction}>
                                    <Box pr={0.5} style={{ cursor: "pointer" }}>
                                      <a href={c.file_url} target="_blank" rel="noreferrer" style={{ display: "flex" }}>
                                        <DownloadIcon width="13px" height="13px" fill="#000000" />
                                      </a>
                                    </Box>
                                    <Box
                                      pl={0.5}
                                      onClick={() => setShowRowDelete(c.id)}
                                      style={{ cursor: "pointer", display: "flex" }}
                                    >
                                      <TrashIcon style={{ width: "13px", height: "13px" }} />
                                    </Box>
                                  </Box>
                                </TableCell>
                              </>
                            )}
                          </>
                        ) : (
                          <TableRowDelete
                            colSpan={3}
                            deleteStatus={deleteFileStatus}
                            onDelete={() => deleteFileByClient(c)}
                            setShowRowDelete={setShowRowDelete}
                          />
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </MuiTable>
            ) : (
              <Box display="flex" width="100%" justifyContent="center" mt={3} mb={3}>
                <Typography variant="h4" color="primary">
                  <Trans>No hay archivos para mostrar</Trans>
                </Typography>
              </Box>
            )}
            {fileUploadStatus.status === "loading" && (
              <Box style={{ borderBottom: "1px solid #E5E5E5", height: 40 }}>
                <LoadingBar height="8px" style={{ display: "flex", alignItems: "center" }} />
              </Box>
            )}
          </Box>
        )}
      </OwnerTableModal>

      {fileUploadStatus.status === "error" && (
        <Typography variant="body1" style={{ color: theme.palette.error.main }}>
          <Trans>Ha ocurrido un error. Por favor intente de nuevo!</Trans>
        </Typography>
      )}
    </Box>
  );
};

export default AttachmentList;
