import { Trans } from "@lingui/macro";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import CloseModalIcon from "../../icons/close-modal-icon";
import SecondaryButton from "../secondary-button";
import SuccessIcon from "../../icons/success-icon";
import RejectIcon from "../../icons/error-circle-icon";

// theme
import theme from "../../theme";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(0),
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
    textAlign: "center",
  },
  content: {
    textAlign: "left",
    padding: theme.spacing(3),
  },
  closeIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    cursor: "pointer",
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  divider: {
    borderBottom: "1px solid black",
  },
}));

export default ({
  open,
  onEnter,
  onClose,
  onAccept,
  onSuccessAccept,
  status = "initial",
  canAccept = true,
  title,
  questionText,
  successText,
  errorText,
  isNewStyle = false,
}) => {
  const classes = useStyles(theme);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    onClose();
    if (status === "success") {
      onSuccessAccept();
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      TransitionProps={{
        onEnter,
      }}
      PaperProps={{
        style: {
          width: "380px",
          borderRadius: isNewStyle ? "" : "19px",

          borderBox: "box-size",
          minHeight: "180px",
        },
      }}
    >
      <Box className={classes.closeIcon} onClick={handleClose}>
        <CloseModalIcon />
      </Box>
      <Box className={classes.container}>
        {status === "loading" && (
          <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="150px">
            <CircularProgress size="48px" />
          </Box>
        )}
        {status === "initial" && (
          <>
            {isNewStyle ? (
              <>
                {title && (
                  <Box pb={0.5} sx={{ borderBottom: "1px solid black" }}>
                    <Typography variant="h1">{title}</Typography>
                  </Box>
                )}
                <Box mt={1} pt={1} pb={2} sx={{ borderBottom: "1px solid black" }}>
                  <Typography variant="body1">{questionText}</Typography>
                </Box>{" "}
              </>
            ) : (
              <Box mt={1} pt={1} pb={1}>
                <Typography variant="h1">{title}</Typography>
                <Typography variant="subtitle1">{questionText}</Typography>
              </Box>
            )}
            <Box mt={2} display="flex" flexDirection="row" sx={{ justifyContent: isNewStyle ? "flex-end" : "center" }}>
              <SecondaryButton onClick={handleClose} style={{ width: "80px", height: isNewStyle ? "32px" : "38px" }}>
                <Typography variant="button">
                  <Trans>Cancelar</Trans>
                </Typography>
              </SecondaryButton>
              <Box ml={2}>
                <Button
                  variant="contained"
                  onClick={onAccept}
                  color="primary"
                  disabled={!canAccept || status === "loading"}
                  style={{ height: isNewStyle ? "32px" : "38px" }}
                >
                  <Typography variant="button">
                    <Trans>Sí, estoy seguro</Trans>
                  </Typography>
                </Button>
              </Box>
            </Box>
          </>
        )}
        {status === "success" && (
          <>
            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
              <Box mb={1}>
                <SuccessIcon
                  fill={theme.palette.successGreen}
                  fillBorder={theme.palette.successGreen}
                  style={{
                    width: 48,
                    height: 48,
                    background: theme.palette.white,
                    borderRadius: "50%",
                  }}
                />
              </Box>
              <Typography
                variant="subtitle1"
                style={{
                  color: theme.palette.successGreen,
                  textAlign: "center",
                }}
              >
                {successText}
              </Typography>
            </Box>
            <Box mt={2} display="flex" flexDirection="row" justifyContent="center">
              <Button
                variant="contained"
                onClick={handleClose}
                color="primary"
                disabled={!canAccept || status === "loading"}
                style={{ height: isNewStyle ? "32px" : "38px" }}
              >
                <Typography variant="button">
                  <Trans>Aceptar</Trans>
                </Typography>
              </Button>
            </Box>
          </>
        )}
        {status === "error" && (
          <>
            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
              <Box mb={1}>
                <RejectIcon
                  fill={theme.palette.red}
                  style={{
                    width: 48,
                    height: 48,
                  }}
                />
              </Box>
              <Typography
                variant="subtitle1"
                style={{
                  color: theme.palette.red,
                  textAlign: "center",
                }}
              >
                {errorText}
              </Typography>
            </Box>
            <Box mt={2} display="flex" flexDirection="row" justifyContent="center">
              <Button
                variant="contained"
                onClick={handleClose}
                color="primary"
                disabled={!canAccept || status === "loading"}
                style={{ height: isNewStyle ? "32px" : "38px" }}
              >
                <Typography variant="button">
                  <Trans>Volver</Trans>
                </Typography>
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  );
};
