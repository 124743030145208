import React from "react";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Typography, Box, CircularProgress } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import OwnerTableModal from "../owner-table-modal";
import theme from "../../theme";

const TableContact = ({ status, selectedLandlord }) => (
  <OwnerTableModal>
    <MuiTable size="small">
      <TableHead>
        <TableRow>
          <TableCell align="left">
            <Typography variant="h4" style={{ fontSize: "11px" }}>
              <Trans>Nombre</Trans>
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="h4" style={{ fontSize: "11px" }}>
              <Trans>Email</Trans>
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="h4" style={{ fontSize: "11px" }}>
              <Trans>Teléfono</Trans>
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      {status === "loading" ? (
        <Box
          display="flex"
          position="absolute"
          alignItems="center"
          justifyContent="center"
          width="85%"
          height="150px"
          mt={2}
          mb={2}
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableBody>
          <TableRow>
            <TableCell variant="body" align="left">
              <Typography variant="body2">{selectedLandlord.contact?.name}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">
                <a style={{ color: theme.palette.secondary.main }} href={`mailto:${selectedLandlord.contact?.email}`}>
                  {selectedLandlord.contact?.email}
                </a>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{selectedLandlord.contact?.phone}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      )}
    </MuiTable>
  </OwnerTableModal>
);

export default TableContact;
