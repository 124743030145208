import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function GoToPageIcon({ fill = "#FFF", ...rest }) {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...rest}>
      <path
        d="M14.2188 13.8281C14.2188 14.0443 14.0443 14.2188 13.8281 14.2188H6.17188C5.95573 14.2188 5.78125 14.0443 5.78125 13.8281V6.17188C5.78125 5.95573 5.95573 5.78125 6.17188 5.78125H9.60938V5H6.17188C5.52604 5 5 5.52604 5 6.17188V13.8281C5 14.474 5.52604 15 6.17188 15H13.8281C14.474 15 15 14.474 15 13.8281V10.3906H14.2188V13.8281Z"
        fill={fill}
      />
      <path
        d="M11.0938 5V5.78125H13.6667L7.38022 12.0677L7.9323 12.6198L14.2188 6.33333V8.90625H15V5H11.0938Z"
        fill={fill}
      />
    </SvgIcon>
  );
}
