import {
  Box,
  Divider,
  Hidden,
  Paper,
  Popover,
  Typography,
} from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import PropTypes from "prop-types";
import React, { useState } from "react";
import LampIcon from "../../../icons/lamp-icon";
// theme
import theme from "../../../theme";
import { Trans } from "@lingui/macro"

const MyDivider = styled(Divider)({
  border: "1px dashed #000000",
});

const useStyles = makeStyles((theme) => ({
  helpIcon: {
    color: theme.palette.primary.main,
    fontSize: "31px",
  },
  sidebar: {
    padding: "21px",
    display: "flex",
    flexDirection: "column",
    maxWidth: "373px",
  },
  closeIcon: {
    position: "absolute",
    top: 5,
    right: 5,
  },
  footerContainer: {
    border: `1px solid ${theme.palette.primary.main}`,
    alignItems: "center",
  },
  lampIcon: {
    width: 70,
    height: 70,
  },
}));

const HelpSectionPopoverComponent = ({ content }) => {
  const classes = useStyles(theme);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <HelpOutlineIcon onClick={handleClick} className={classes.helpIcon} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Paper className={classes.sidebar} elevation={5} square>
          <CloseIcon onClick={handleClose} className={classes.closeIcon} />
        </Paper>
      </Popover>
    </>
  );
};

HelpSectionPopoverComponent.prototype = {
  content: PropTypes.object.isRequired,
};

export default HelpSectionPopoverComponent;
