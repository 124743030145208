import { Box, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { RefreshSharp } from "@material-ui/icons";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import React from "react";
// theme
import theme from "../../theme-admin";
import SearchBoxFilter from "../search-box-filter";

const useStyles = makeStyles((theme) => ({
  icon: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

const CustomersToolbar = ({ onTableChange, onSearch }) => {
  const classes = useStyles(theme);
  const { i18n } = useLingui();

  const handleRefresh = () => onTableChange("changePage", { page: 0 });

  return (
    <Box flex={5} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
      <IconButton onClick={handleRefresh} className={classes.icon}>
        <RefreshSharp />
      </IconButton>
      <Box ml={1 / 2}>
        <SearchBoxFilter onSearch={onSearch} placeholder={i18n._(t`Buscar`)} />
      </Box>
    </Box>
  );
};

export default CustomersToolbar;
