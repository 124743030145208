import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "/src/hooks/navigation";
// import theme
import { useParams } from "@reach/router";
import DescriptionComponent from "../../components/listing-form/description-step";
// Actions
import { putSpaceThunk, updateDataNewSpaceSuccess } from "../../redux/actions/space-actions";

const DescriptionStepContainer = ({ onSetOpenDelete }) => {
  const dispatch = useDispatch();
  const { navigate } = useNavigate();
  const params = useParams();
  const isNew = params.spaceId === "new";

  const id = useSelector((state) => state.listing.data.id);
  const status = useSelector((state) => state.listing.status);
  const space = useSelector((state) => state.listing.data);

  const handleSubmit = (data) => {
    if (isNew) {
      dispatch(updateDataNewSpaceSuccess(data));
    } else {
      dispatch(putSpaceThunk(id, data));
    }
  };

  return (
    <DescriptionComponent
      isNew={isNew}
      onSetOpenDelete={onSetOpenDelete}
      handleNext={() => navigate(`/administrator/spaces/${params.spaceId}/location`)}
      onSubmit={handleSubmit}
      status={status}
      space={space}
    />
  );
};

export default DescriptionStepContainer;
