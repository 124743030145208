import { Box, IconButton, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { RefreshSharp } from "@material-ui/icons";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import React, { useState, useEffect } from "react";
// theme
import theme from "../../theme-admin";
import SearchBoxFilter from "../search-box-filter";
import MultiselectSearchFilter from "../multiselect-search-filter";
import TenantsIcon from "../../icons/sass-icono-assigne";
import Assets from "../../icons/sass-icono-assets-info";

const useStyles = makeStyles((th) => ({
  icon: {
    width: 32,
    height: 32,
    "&:hover": {
      color: th.palette.primary.main,
    },
  },
  filters: {
    height: 25,
    textTransform: "none",
    width: 150,
    display: "flex",
    justifyContent: "flex-start",
    borderRadius: 4,
  },
  switch: {
    "& .MuiButtonBase-root": {
      marginTop: 4,
    },
  },
  select: {
    height: 36,
    minWidth: 214,
    marginRight: th.spacing(1),
    "& .MuiInputBase-input": {
      display: "flex",
      justifyContent: "flex-start",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "3px",
    },
  },
  notificator: {
    background: th.palette.primary.main,
    width: 5,
    height: 5,
    borderRadius: "50%",
    position: "absolute",
    right: 10,
    top: 2,
  },
}));

// bookingState represents inprogress, confirmed.
const ConsultsToolbar = ({
  onTableChange,
  onSearch,
  filtersOptions,
  onFilterChange = () => {},
  onVirtualStateChange = () => {},
  virtualStates = [],
}) => {
  const classes = useStyles(theme);

  const { i18n } = useLingui();

  const [selectedBookingState, setSelectedBookingState] = useState("");

  const virtualStatesArray = Object.keys(virtualStates);
  const handleRefresh = () => onTableChange("refresh");

  const handleOnChange = ({ target }) => {
    setSelectedBookingState(target.value);
    onVirtualStateChange(target.value);
  };

  useEffect(() => {
    const bookingFilterState = localStorage.getItem("BookingVirtualState");
    if (bookingFilterState && virtualStatesArray.includes(bookingFilterState)) {
      setSelectedBookingState(bookingFilterState);
    }
  }, []);

  return (
    <Box flex={5} display="flex" alignItems="center" justifyContent="flex-start">
      <IconButton onClick={handleRefresh} className={classes.icon}>
        <RefreshSharp />
      </IconButton>
      <Box display="flex" flexDirection="row" position="relative">
        {virtualStatesArray.length ? (
          <Select
            value={selectedBookingState || Object.keys(virtualStates)[0]}
            onChange={handleOnChange}
            variant="outlined"
            className={classes.select}
          >
            {virtualStatesArray.map((vs) => (
              <MenuItem key={vs} value={vs}>
                {virtualStates[vs]}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <></>
        )}
        <Box ml={1 / 2} pl={1 / 2} sx={{ borderLeft: `1px solid ${theme.palette.grayC4}` }}>
          <SearchBoxFilter onSearch={onSearch} placeholder={i18n._(t`Buscar`)} />
        </Box>

        <Box display="flex" alignItems="center" ml={1 / 2} pl={1 / 2} gridGap={3}>
          <MultiselectSearchFilter
            title={i18n._(t`Activos`)}
            localStorageName="consultsAssets"
            icon={() => <Assets className={classes.icon} fill={theme.palette.black} />}
            options={filtersOptions.assets}
            onSelect={onFilterChange("assets")}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ConsultsToolbar;
