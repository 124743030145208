import { Box, Grid, Typography, Badge } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
// theme
import theme from "../../theme";
import Link from "./../link";
import OPTIONS from "./../owner-dashboard/options";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  optionsContainer: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
  },
  container: {
    width: "auto",
    textAlign: "start",
    color: "black",
    margin: theme.spacing(1),
  },
  text: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary.main,
    width: "100%",
    transition: "0.3s all ease-in",
    "&:hover": {
      background: theme.palette.lightGray,
    },
  },
  icon: {
    width: 55,
    height: 55,
    marginLeft: -8,
    [theme.breakpoints.down("md")]: {
      width: 40,
      height: 40,
      marginLeft: -5,
    },
  },
  badge: {
    right: -3,
    top: 13,
    marginBottom: 5,
  },
}));

const ItemHomeOwner = ({
  icon,
  title,
  description,
  linkUrl,
  linkText,
  news = 0,
}) => {
  const classes = useStyles(theme);
  return (
    <Link to={linkUrl} className={classes.link}>
      <Box className={classes.container}>
        <Badge className={classes.badge} badgeContent={news} color="primary">
          {icon({
            className: classes.icon,
            color: "primary",
            fill: theme.palette.primary.main,
          })}
        </Badge>
        <Typography variant={"h4"} className={classes.text}>
          {" "}
          {title}{" "}
        </Typography>
        <Typography variant={"body2"} className={classes.text}>
          {" "}
          {description}{" "}
        </Typography>
        <Typography className={classes.link}>{linkText}</Typography>
      </Box>
    </Link>
  );
};

const HomeOwnerComponent = ({
  bookingProgressNews,
  bookingConfirmedNews,
}) => {
  const classes = useStyles(theme);
  const { i18n } = useLingui();

  const path = "/administrator";

  // En caso de usar notificaciones en mas options,
  // revisar esta función.
  const getNewsForKey = (key) => {
    switch (key) {
      case "consultas":
        return bookingProgressNews;
      case "reservas":
        return bookingConfirmedNews;
      default:
        return 0;
    }
  };

  return (
    <Box display={"flex"} pl={2} pr={2} flexWrap={"wrap"}>
      <Grid
        container
        direction="row"
        spacing={2}
        className={classes.optionsContainer}
      >
        {Object.keys(OPTIONS)
          .splice(1)
          .map((key) => (
            <Grid container item xs={3} key={key}>
              <ItemHomeOwner
                icon={OPTIONS[key].icon}
                title={i18n._(t`${OPTIONS[key].text}`)}
                description={OPTIONS[key].description}
                linkUrl={`${path}/${OPTIONS[key].pathUrl}`}
                linkText={OPTIONS[key].linkText}
                news={getNewsForKey(key)}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default HomeOwnerComponent;
