import { Box, Divider, Hidden, Paper, Typography } from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import LampIcon from "../../../icons/lamp-icon";
// theme
import theme from "../../../theme";
import { Trans } from "@lingui/macro"

const MyDivider = styled(Divider)({
  border: "1px dashed #000000",
});

const useStyles = makeStyles((theme) => ({
  sidebar: {
    padding: "21px",
    display: "flex",
    flexDirection: "column",
    width: "336px",
  },
  footerContainer: {
    border: `1px solid ${theme.palette.primary.main}`,
    alignItems: "center",
  },
  lampIcon: {
    width: 70,
    height: 70,
  },
}));

const HelpSectionFixedComponent = ({ content }) => {
  const classes = useStyles(theme);

  const getContent = () => {
    return (
      <React.Fragment>
        <Box mt={2} mb={3}>
          {content}
        </Box>
        <MyDivider />
        <Box
          mt={3}
          mb={2}
          p={1}
          display="flex"
          className={classes.footerContainer}
        >
          <Box p={0}>
            <LampIcon
              className={classes.lampIcon}
              fill={theme.palette.primary.main}
            />
          </Box>
          <Box p={1} flexGrow={1}>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              <Trans>Auto-guardado activado</Trans>
            </Typography>
            <Typography variant="body2">
              <Trans>Si necesitas terminarlo luego, puedes retomarlo donde lo has
              dejado</Trans>.
            </Typography>
          </Box>
        </Box>
      </React.Fragment>
    );
  };

  return (
    <Hidden smDown={true}>
      <Paper className={classes.sidebar} elevation={5} square>
        {getContent()}
      </Paper>
    </Hidden>
  );
};

HelpSectionFixedComponent.prototype = {
  content: PropTypes.object.isRequired,
};

export default HelpSectionFixedComponent;
