import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StepSetupComponent from "../../components/create-booking-wizard/step-1-setup";
import api from "../../api";
import { getSpaceUnavailableDateRangesThunk } from "../../redux/actions/space-actions";
import { getAdSpaceConsultUnavailableDatesThunk } from "../../redux/actions/adspaces-actions";
import { add, dateToDayjs } from "../../utils/dateUtils";
import { sortAlphabetically } from "../../utils";

const INITIAL_DATE_RESTRICTIONS = {
  minDate: dateToDayjs("2000-01-01").toDate(),
};

function StepSetupContainer({ updateBooking, booking }) {
  const dispatch = useDispatch();

  const userManagedAssets = useSelector((state) => state.user.data.managedAssets);
  const unavailableDates = useSelector((state) => state.listing.unavailableDates);
  const adSpacesConsultationUnavailableDates = useSelector(
    (state) => state.adspaces.adSpacesConsultationUnavailableDates,
  );
  // dates related with min_stay and max_stay
  const [spacesStatus, setSpacesStatus] = useState({ status: "initial" });
  const [companyStatus, setCompanyStatus] = useState({ status: "initial" });
  const [stayRestrictions, setStayRestrictions] = useState(INITIAL_DATE_RESTRICTIONS);
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [spaces, setSpaces] = useState([]);
  const [adSpaces, setAdSpaces] = useState([]);
  const [values, setValues] = useState({
    ...booking,
  });

  const getUsers = async (company) => {
    const res = await api.getCustomers({
      companyId: company?.id,
    });
    if (res.status === 200) {
      setUsers(res.data?.payload?.data);
    }
  };

  const getSpaces = async (asset) => {
    if (!asset?.id) return;
    const res = await api.getAdminSpaces(0, 1, { assets: [asset] }, { name: "title" });
    if (res.status === 200) {
      const sortByTitle = sortAlphabetically(res.data?.payload?.data, "title");
      setSpaces(sortByTitle);
    }
  };
  const getAdSpaces = async (asset) => {
    if (!asset?.id) return;
    const res = await api.getAdminAdSpaces({
      numberPerPage: 0,
      page: 1,
      sort: { name: "title" },
      filters: { assets: [asset] },
    });
    if (res.status === 200) {
      setAdSpaces(res.data?.payload?.data?.map((ad) => ({ ...ad, name: ad.title })));
    }
  };

  const getCompanies = async () => {
    setCompanyStatus({ status: "loading" });
    const res = await api.getCompanies({
      page: 1,
      size: 0,
      sort: {
        name: "name",
        direction: "asc",
      },
    });
    if (res.status === 200) {
      const sortName = sortAlphabetically(res.data?.payload?.data, "name");
      setCompanies(sortName);
      setCompanyStatus({ status: "success" });
    }
  };

  const handleSwitchLongTermBooking = (longTerm) => {
    setValues((v) => ({
      ...v,
      startDate: null,
      endDate: null,
      longTerm,
    }));
  };

  const handleOnSelectDates = (dates) => {
    setValues((v) => ({
      ...v,
      startDate: dates[0],
      endDate: dates[1],
    }));
  };

  const handleOnMultipleSelect = (field) => (event, value) => {
    dispatch(getAdSpaceConsultUnavailableDatesThunk(value));
    setValues((v) => ({
      ...v,
      [field]: value,
      startDate: null,
      endDate: null,
    }));
  };

  const handleSelectCompany = (company) => {
    const newValues = { company };
    getUsers(company);
    setValues((v) => ({
      ...v,
      ...newValues,
    }));
  };
  const handleSelectAsset = async (asset) => {
    setSpacesStatus({ status: "loading" });
    const newValues = {
      asset,
      adSpaces: [],
      space: null,
      startDate: null,
      endDate: null,
    };
    await getSpaces(asset);
    await getAdSpaces(asset);
    setValues((v) => ({
      ...v,
      ...newValues,
    }));
    setSpacesStatus({ status: "success" });
  };
  const handelSelectSpace = (space) => {
    const newValues = {
      space,
      startDate: null,
      endDate: null,
    };
    dispatch(getSpaceUnavailableDateRangesThunk(space?.id));
    setValues((v) => ({
      ...v,
      ...newValues,
    }));
  };

  useEffect(() => {
    getCompanies();
    // if (values.company) getUsers(values.company);
    if (booking.asset?.id) {
      getSpaces(booking.asset);
      getAdSpaces(booking.asset);
    }
  }, []);

  const handleOnSubmit = () => updateBooking(values);

  const handleClickDayCalendar = (day) => {
    if (values.space?.id && !stayRestrictions.maxDate) {
      let minDate;
      let maxDate;

      if (values.space.min_stay) {
        minDate = add(day, values.space.min_stay - 1, "day").toDate();
      }

      if (values.space.max_stay) {
        maxDate = add(day, values.space.max_stay - 1, "day").toDate();
      }

      setStayRestrictions({
        minDate,
        maxDate,
      });
    } else {
      setStayRestrictions(INITIAL_DATE_RESTRICTIONS);
    }
  };

  return (
    <StepSetupComponent
      onSelectCompany={handleSelectCompany}
      onSelectAsset={handleSelectAsset}
      onSelectSpace={handelSelectSpace}
      onMultipleSelect={handleOnMultipleSelect}
      onSelectDates={handleOnSelectDates}
      onSwitchLongTermBooking={handleSwitchLongTermBooking}
      onSubmit={handleOnSubmit}
      companies={companies}
      assets={userManagedAssets}
      users={users}
      spaces={spaces}
      adSpaces={adSpaces}
      values={values}
      onClickDayCalendar={handleClickDayCalendar}
      unavailableDates={{
        ...unavailableDates,
        ...adSpacesConsultationUnavailableDates,
      }}
      minDateCalendar={stayRestrictions.minDate}
      maxDateCalendar={stayRestrictions.maxDate}
      spacesStatus={spacesStatus}
      companyStatus={companyStatus}
    />
  );
}

export default StepSetupContainer;
