import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "/src/hooks/navigation";
// import theme
import { useParams } from "@reach/router";
import AddressComponent from "../../components/listing-form/address-step";
import { putSpaceThunk, putSpaceSuccess, postSpaceThunk } from "../../redux/actions/space-actions";
// Actions
import { getCitiesAreasThunk, getSpaceTypesThunk } from "../../redux/actions/misc-actions";

const AddressStepContainer = ({ onSetOpenDelete }) => {
  const dispatch = useDispatch();
  const { navigate } = useNavigate();
  const params = useParams();
  const isNew = params.spaceId === "new";

  useEffect(() => {
    dispatch(getCitiesAreasThunk());
    dispatch(getSpaceTypesThunk());
  }, [dispatch]);

  const cities = useSelector((state) => state.misc.cities);
  const assets = useSelector((state) => state.misc.assets);
  const managedAssets = useSelector((state) => state.user.data.managedAssets);
  const id = useSelector((state) => state.listing.data.id);
  const status = useSelector((state) => state.listing.status);
  const space = useSelector((state) => state.listing.data);
  const maps = useSelector((state) => state.googleMaps.maps);
  const user = useSelector((state) => state.user.data.id);

  const spaceTypes = useSelector((state) => state.misc.spaceTypes);
  const spaceSubTypes = useSelector((state) => state.misc.spaceSubTypes);

  const handleNext = () => navigate(`/administrator/spaces/${id}/space-type`);
  const handleBack = () => navigate(`/administrator/spaces/${params.spaceId}`);

  const handleSubmit = (data) => {
    if (id) {
      dispatch(putSpaceThunk(id, data));
    } else {
      const format = { ...data };
      format.space_sub_type_id = spaceTypes[0].id;
      format.space_type_id = spaceSubTypes[0].id;
      dispatch(postSpaceThunk(format, user));
    }
  };

  const filteredAssets =
    assets?.length && managedAssets?.length ? assets.filter((a) => managedAssets.find((m) => m.id === a.id)) : [];
  const filteredCities =
    cities?.length && filteredAssets?.length
      ? cities.filter((c) => filteredAssets.find((f) => f.city_id === c.id))
      : [];

  return (
    <AddressComponent
      isNew={isNew}
      onSetOpenDelete={onSetOpenDelete}
      handleBack={handleBack}
      handleNext={handleNext}
      cities={filteredCities}
      assets={filteredAssets}
      onSubmit={handleSubmit}
      status={status}
      space={space}
      maps={maps}
    />
  );
};

export default AddressStepContainer;
