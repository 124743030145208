import React, { useEffect } from "react";
import SpaceToolbar from "../../components/spaces-owner/space-toolbar";
import { useDispatch, useSelector } from "react-redux";
import {
  setSpacesFilters,
  setSearchWords,
} from "../../redux/actions/spaces-owner-actions";
import {
  getSpacesOwnerThunk,
  getSpaceTypesThunk,
  getCitiesAreasThunk,
  getCountriesThunk,
} from "../../redux/actions/misc-actions";

const SpacesToolbar = ({ onRefresh }) => {
  const assets = useSelector((state) => state.misc.assets);
  const types = useSelector((state) => state.misc.spaceTypes);
  const cities = useSelector((state) => state.misc.cities);
  const countries = useSelector((state) => state.misc.countries);

  const filters = useSelector((state) => state.spacesOwner.filters);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCitiesAreasThunk());
    dispatch(getSpaceTypesThunk());
    dispatch(getCountriesThunk());
    getUserFilters();
  }, []);

  const getUserFilters = () => {
    const assets = JSON.parse(localStorage.getItem("spaceAssets"));
    const types = JSON.parse(localStorage.getItem("spaceTypes"));
    const cities = JSON.parse(localStorage.getItem("spaceCities"));
    const countries = JSON.parse(localStorage.getItem("spaceCountries"));

    dispatch(setSpacesFilters({ assets, types, cities, countries }));
  };

  const handleSearch = (searchWords) => {
    dispatch(setSearchWords(searchWords));
  };

  const handleFilterSpaces = (filters) => {
    dispatch(setSpacesFilters(filters));
  };

  return (
    <SpaceToolbar
      onRefresh={onRefresh}
      assets={assets}
      types={types}
      cities={cities}
      countries={countries}
      filters={filters}
      handleFilterSpaces={handleFilterSpaces}
      handleSearch={handleSearch}
    />
  );
};

export default SpacesToolbar;
