import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import SecondaryButton from "../../secondary-button";
import React from "react";
import numbro from "numbro";
import { useLingui } from "@lingui/react";
// theme
import theme from "../../../theme";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
    textAlign: "center",
  },
  content: {
    width: "350px",
    textAlign: "center",
    padding: theme.spacing(2),
    paddingTop: 0,
  },
}));

export default ({
  open,
  desc,
  onDescChange,
  bookingsBlocked = [],
  onAccept = () => {},
  onClose = () => {},
}) => {
  const classes = useStyles(theme);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { i18n } = useLingui();

  const getTotalBookingsPrice = (bookings) => {
    let totalPrice = 0;
    bookings.forEach((b) => {
      totalPrice += parseFloat(b.price);
    });

    return numbro(totalPrice).formatCurrency();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      TransitionProps={{
        onEnter: () => {
          onDescChange("");
        },
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.title}>
        {i18n._(t`Razón de bloqueo`)}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <TextField
          rows={3}
          multiline
          variant="outlined"
          value={desc}
          onChange={(e) => {
            onDescChange(e.target.value);
          }}
          style={{ width: "100%" }}
        />
        {bookingsBlocked.length > 0 && (
          <Box mt={1} style={{ color: theme.palette.error.main }}>
            <Typography variant="body1">
              {`${i18n._(t`¡Cuidado hay`)} ${bookingsBlocked.length} ${i18n._(
                t`consultas en progreso por un total de`
              )} ${getTotalBookingsPrice(bookingsBlocked)} ${i18n._(
                t`en este rango de fechas.`
              )}`}
            </Typography>
            <Typography variant="body1">
              <Trans>
                Al confirmar la acción, deberán cambiarse las fechas para poder
                continuar
              </Trans>
            </Typography>
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          mt={2}
        >
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              onAccept();
              onClose();
            }}
            color="primary"
          >
            <Trans>Confirmar</Trans>
          </Button>
          <Box ml={2}>
            <SecondaryButton onClick={onClose}>
              <Trans>Cerrar</Trans>
            </SecondaryButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
