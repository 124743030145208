/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Cell, ResponsiveContainer } from "recharts";
import numbro from "numbro";
import theme from "../../theme";
import Widget from "./large-widget";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.white,
    padding: theme.spacing(1),
    borderRadius: "5px",
    border: theme.borders[1],
  },
}));

const COLORS = [theme.palette.primary.main, theme.palette.grayC4];

const formatValue = (value) => {
  if (typeof value !== "undefined") {
    return `${numbro(value).formatCurrency({ thousandSeparated: true, mantissa: 0 })}`;
  }
  return null;
};

const CustomTooltip = ({ payload }) => {
  const classes = useStyles();

  if (typeof payload[0] !== "undefined") {
    return (
      <Box className={classes.tooltip}>
        <Typography variant="body1">
          {numbro(payload[0].value).formatCurrency({ thousandSeparated: true, mantissa: 0 })}
        </Typography>
      </Box>
    );
  }
  return null;
};

function Info() {
  return (
    <Box width="324px">
      <Typography variant="body1">
        <Trans>Top 5 de clientes con mayor facturación para el período seleccionado</Trans>
      </Typography>
    </Box>
  );
}

function TenantRevenueWidget({ topBillingCompanies = {}, period }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (topBillingCompanies) {
      const newData = [];
      Object.keys(topBillingCompanies).forEach((d) => {
        newData.push({
          name:
            topBillingCompanies[d].company.length > 25
              ? `${topBillingCompanies[d].company.substring(0, 22)}...`
              : topBillingCompanies[d].company,
          score: topBillingCompanies[d].billing_score,
        });
      });
      setData([...newData]);
    }
  }, [topBillingCompanies]);

  return (
    <Widget
      title={<Trans>Ingresos por cliente</Trans>}
      period={period}
      info={<Info />}
      unavailable={data?.length === 0}
    >
      <Box display="flex" justifyContent="center" mt={2} width="100%" height="100%">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={data} height={150} width={150} layout="vertical" barCategoryGap={2} margin={{ right: 100 }}>
            <XAxis type="number" hide />
            <YAxis type="category" width={160} dataKey="name" fontWeight="bold" axisLine={false} tickLine={false} />
            <Tooltip cursor={false} content={CustomTooltip} />
            <Bar
              dataKey="score"
              fill={COLORS[0 % 2]}
              label={{
                width: 200,
                position: "right",
                fill: "#828282",
                fontSize: 12,
                formatter: formatValue,
              }}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[0 % 2]} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Widget>
  );
}

export default TenantRevenueWidget;
