import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import theme from "../../theme-admin";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import FilterPopup from "./filter-popup";

const useStyles = makeStyles((theme) => ({
  popper: {
    border: "1px solid rgba(27,31,35,.15)",
    boxShadow: "0 3px 12px rgba(27,31,35,.15)",
    borderRadius: 3,
    zIndex: 130,
    width: 250,
    "& .MuiAutocomplete-listbox": {
      maxHeight: 200,
    },
  },
  valueSelectedText: {
    fontWeight: 500,
  },
  button: {
    height: "35px",
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
}));

export default function MultiselectSearchFilter({
  title = "",
  localStorageName = "",
  icon = () => {},
  options = [],
  onSelect = () => {},
  placeholder = "",
  canClear,
}) {
  const classes = useStyles(theme);
  const { i18n } = useLingui();

  const ref = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState([]);
  const [allOptions, setAllOptions] = useState([]);
  const [pendingValue, setPendingValue] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);

  useEffect(() => {
    const filter = localStorage.getItem(localStorageName);
    if (filter) {
      setPendingValue([]);
      const localStorageValues = JSON.parse(filter);
      setValue(localStorageValues);
      setPendingValue(localStorageValues);
    }
  }, []);

  useEffect(() => {
    setAllOptions([...options]);
  }, [options]);

  useEffect(() => {
    if (pendingValue.length === options.length) {
      setPendingValue(allOptions);
    }
  }, [pendingValue]);

  const handleOnChange = (opt) => {
    setSelectedAll(opt.id === "all");
    setPendingValue(opt);
  };

  const handleClick = (event) => {
    setPendingValue(value);
    setAnchorEl(event.currentTarget);
    document.addEventListener("mousedown", mouseDown);
  };

  const handleClose = () => {
    setAnchorEl(null);
    document.removeEventListener("mousedown", mouseDown);
  };

  const handleAccept = () => {
    setValue(pendingValue);
    onSelect([pendingValue]);
    handleClose();
    localStorage.removeItem(localStorageName);
    localStorage.setItem(localStorageName, JSON.stringify(pendingValue));
  };

  const open = Boolean(anchorEl);

  function mouseDown(e) {
    if (!ref.current?.contains(e.target)) {
      //handleClose();
    }
  }

  const handleClear = () => {
    setSelectedAll(false);
    setValue([]);
    onSelect([]);
    localStorage.removeItem(localStorageName);
    handleClose();
  };

  const handleTooltip = (value, title) => {
    return (
      <Box display={"flex"} flexDirection={"column"}>
        <Typography variant={"subtitle1"}>{title}</Typography>
        <Typography variant={"body1"}>{i18n._(t`${value.name}`)}</Typography>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Tooltip title={handleTooltip(value, title)}>
        <Button variant={open ? "outlined" : "contained"} className={classes.button} onClick={handleClick}>
          {icon()}
          {value.length ? (
            <Box display={"flex"} ml={1}>
              <Typography variant={"body2"} component={"span"} className={classes.valueSelectedText}>
                {`${title} (${value.length})`}
              </Typography>
            </Box>
          ) : (
            <></>
          )}
        </Button>
      </Tooltip>
      <FilterPopup
        title={title}
        placeholder={placeholder}
        anchorEl={anchorEl}
        allOptions={allOptions}
        pendingValue={pendingValue}
        onClose={handleClose}
        onAccept={handleAccept}
        onChange={handleOnChange}
        onClear={handleClear}
        canClear={canClear}
        selectedAll={selectedAll}
      />
    </React.Fragment>
  );
}
