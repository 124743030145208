/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLingui } from "@lingui/react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Trans, t } from "@lingui/macro";
import { Box, Grid, CircularProgress, Button, Typography } from "@material-ui/core";
import { SeasonBaseModal } from "./season-base-modal";
import { DeleteSeasonModal } from "./delete-season-modal";
import { formatDateUtc, dateToDayjs } from "../../../utils/dateUtils";
import SeasonalCalendar from "./calendar";
import TextField from "../../text-field";
import TrashIcon from "../../../icons/trash-icon";
import CheckIcon from "../../../icons/success-circle-icon";

// theme
import theme from "../../../theme";

const useStyles = makeStyles((th) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    color: th.palette.text.primary,
    paddingLeft: th.spacing(2),
    paddingRight: th.spacing(2),
    overflowY: "hidden",
  },
  fieldsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
}));

const INITIAL_SEASON = { start: null, end: null, rate: null, label: null };

export const SeasonModal = ({
  open,
  seasonSelected,
  seasons,
  status,
  action,
  onCreateSeason,
  onUpdateSeason,
  onDeleteSeason,
  onClose,
}) => {
  const classes = useStyles(theme);
  const { i18n } = useLingui();
  const formRef = useRef();
  const [season, setSeason] = useState(INITIAL_SEASON);
  const [formValid, setFormValid] = useState(false);
  const [resultMessage, setResultMessage] = useState({ successMessage: "", errorMessage: "" });
  const [deleteSeasonModal, setDeleteSeasonModal] = useState(false);

  const canSubmit = season.start && season.end && season.rate && season.label && formValid;

  const getModalTexts = useCallback(() => {
    switch (action) {
      case "create":
        setResultMessage({
          successMessage: <Trans>El período se ha creado con éxito.</Trans>,
          errorMessage: (
            <Trans>Falló la creación del período. Revisa que no haya conflictos con otros períodos existentes.</Trans>
          ),
        });
        break;

      case "update":
        setResultMessage({
          successMessage: <Trans>El período se ha actualizado con éxito.</Trans>,
          errorMessage: (
            <Trans>
              Falló la actualización del período. Revisa que no haya conflictos con otros períodos existentes.
            </Trans>
          ),
        });
        break;

      case "delete":
        setResultMessage({
          successMessage: <Trans>El período se ha eliminado con éxito.</Trans>,
          errorMessage: <Trans>Falló la eliminación del período.</Trans>,
        });
        break;
      default:
      // Handle default case if needed
    }
  }, [action, setResultMessage]);

  useEffect(() => {
    getModalTexts();
  }, [action, getModalTexts]);

  useEffect(() => {
    const validateForm = async () => {
      if (formRef.current) {
        const isValid = await formRef.current.isFormValid();
        setFormValid(isValid);
      }
    };

    validateForm();
  }, [season, formRef]);

  useEffect(() => {
    setSeason(seasonSelected);
  }, [seasonSelected]);

  const handleInputChange =
    (field) =>
    ({ target }) => {
      setSeason((prevSeason) => ({ ...prevSeason, [field]: target.value }));
    };

  const handleOnDateChange = (values) => {
    const formattedDateUtc = formatDateUtc(values[1]);
    setSeason((v) => ({ ...v, start: values[0], end: dateToDayjs(formattedDateUtc).utc().toDate() }));
  };

  const handleResetDates = () => {
    setSeason((v) => ({ ...v, start: null, end: null }));
  };

  const handleCreateSeason = () => {
    const { start, end, rate, label } = season;

    onCreateSeason({
      start: formatDateUtc(start),
      end: formatDateUtc(end),
      rate,
      label,
    });
  };

  const handleUpdateSeason = () => {
    onUpdateSeason({
      seasonId: season.id,
      start: formatDateUtc(season.start),
      end: formatDateUtc(season.end),
      rate: season.rate,
      label: season.label,
    });
  };

  const handleDeleteSeason = () => {
    if (season.serie) {
      setDeleteSeasonModal(true);
    } else {
      onDeleteSeason(season.id);
    }
  };

  const handleDeleteRecurrentSeason = (deleteAction) => {
    setDeleteSeasonModal(false);
    onDeleteSeason(season.id, deleteAction);
  };

  const handleOnClose = () => {
    onClose();
    setSeason(INITIAL_SEASON);
  };

  return (
    <>
      <DeleteSeasonModal open={deleteSeasonModal} onClose={handleDeleteRecurrentSeason} />
      <SeasonBaseModal
        title={seasonSelected.start && seasonSelected.end ? i18n._(t`Editar período`) : i18n._(t`Crear nuevo período`)}
        open={open}
        width={650}
        onClose={handleOnClose}
      >
        {status === "success" && (
          <Box display="flex" flexDirection="column" alignItems="center" alignSelf="center" width={400}>
            <CheckIcon fill={theme.palette.successGreen} width={56} height={56} />
            <Box mb={3}>
              <Typography variant="subtitle1" style={{ color: theme.palette.successGreen }}>
                {resultMessage.successMessage}
              </Typography>
            </Box>
            <Button variant="contained" color="primary" style={{ width: 150 }} onClick={handleOnClose}>
              <Trans>Aceptar</Trans>
            </Button>
          </Box>
        )}

        {status === "error" && (
          <Box display="flex" flexDirection="column">
            <Box mb={3} width="100%" style={{ wordBreak: "break-word" }}>
              <Typography variant="subtitle1" style={{ color: theme.palette.error.main }}>
                <Trans>Oops! Lo sentimos, hubo un inconveniente:</Trans> {resultMessage.errorMessage}
              </Typography>
            </Box>
            <Button variant="text" color="primary" onClick={handleOnClose}>
              <Trans>Salir</Trans>
            </Button>
          </Box>
        )}

        {(status === "initial" || status === "loading") && (
          <>
            <Box className={classes.container} mt={2}>
              <SeasonalCalendar
                seasons={seasons}
                datesSelected={[season.start, season.end]}
                onChange={handleOnDateChange}
                onSeasonSelected={() => {}}
                showSeasonInformation={false}
                selectRange
                blockDates
              />

              <Box
                onClick={handleResetDates}
                style={{ cursor: "pointer", width: "100%", justifyContent: "flex-end" }}
                display="flex"
                flexDirection="row"
                alignItems="center"
                mt={1}
                mb={1}
              >
                <Typography variant="body1" style={{ textDecoration: "underline" }}>
                  <Trans>Limpiar fechas</Trans>
                </Typography>
                <TrashIcon style={{ width: "11px", marginLeft: theme.spacing(1 / 2) }} />
              </Box>
            </Box>

            <Box display="flex" flexDirection="column" mt={2}>
              <ValidatorForm instantValidate ref={formRef} onSubmit={() => {}}>
                <Box className={classes.fieldsContainer}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        description={`${i18n._(t`Nombre`)}`}
                        placeholder={`${i18n._(t`Nombre`)}`}
                        errorMessages={[`${i18n._(t`Por favor indique el nombre del período`)}`]}
                        validators={["minStringLength:1", "maxStringLength:20"]}
                        value={season.label || ""}
                        onChange={handleInputChange("label")}
                        style={{ marginTop: theme.spacing(1), height: 76, width: 200 }}
                        disabled={false}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        description={`${i18n._(t`Multiplicador`)}`}
                        placeholder={`${i18n._(t`Multiplicador`)}`}
                        errorMessages={[`${i18n._(t`Por favor indique un multiplicador válido`)}`]}
                        validators={["isPositive", "isFloat"]}
                        value={season.rate || ""}
                        onChange={handleInputChange("rate")}
                        style={{ marginTop: theme.spacing(1), height: 76, width: 200 }}
                        disabled={false}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" justifyContent="flex-end">
                  {seasonSelected.start && seasonSelected.end && (
                    <Button
                      onClick={handleDeleteSeason}
                      style={{ height: "33px", marginRight: "10px" }}
                      variant="contained"
                      color="secondary"
                      type="submit"
                    >
                      {status === "loading" && action === "delete" ? (
                        <CircularProgress size={20} />
                      ) : (
                        <Trans>Eliminar período</Trans>
                      )}
                    </Button>
                  )}

                  <Button
                    onClick={seasonSelected.start && seasonSelected.end ? handleUpdateSeason : handleCreateSeason}
                    style={{ height: "33px" }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!canSubmit}
                  >
                    {status === "loading" && (action === "update" || action === "create") ? (
                      <CircularProgress size={20} />
                    ) : (
                      <>
                        {seasonSelected.start && seasonSelected.end ? (
                          <Trans>Actualizar período</Trans>
                        ) : (
                          <Trans>Crear período</Trans>
                        )}
                      </>
                    )}
                  </Button>
                </Box>
              </ValidatorForm>
            </Box>
          </>
        )}
      </SeasonBaseModal>
    </>
  );
};
