import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "/src/hooks/navigation";
// Component
import ImagesComponent from "../../components/listing-form/images-step";
// Actions
import { putSpaceSuccess, setSpaceAttachmentsThunk } from "../../redux/actions/space-actions";

const ImagesStepContainer = ({ onSetOpenDelete }) => {
  const dispatch = useDispatch();
  const { navigate } = useNavigate();

  const id = useSelector((state) => state.listing.data.id);
  const status = useSelector((state) => state.listing.status);
  const attachStatus = useSelector((state) => state.listing.attachStatus);
  const space = useSelector((state) => state.listing.data);

  const handleSubmit = (attachments) => {
    dispatch(setSpaceAttachmentsThunk(id, attachments));
  };

  useEffect(() => {
    if (attachStatus === "success") {
      dispatch(putSpaceSuccess({}));
    }
  }, [attachStatus]);

  return (
    <ImagesComponent
      onSetOpenDelete={onSetOpenDelete}
      handleNext={() => navigate(`/administrator/spaces/${id}/price`)}
      handleBack={() => navigate(`/administrator/spaces/${id}/characteristics`)}
      onSubmit={handleSubmit}
      status={status}
      attachStatus={attachStatus}
      space={space}
    />
  );
};

export default ImagesStepContainer;
