import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import MultiselectSearchFilter from "../multiselect-search-filter";
import SearchBoxFilter from "../search-box-filter";
import Assets from "../../icons/sass-icono-assets-info";
import CityIcon from "./../../icons/city-icon";
// theme
import theme from "../../theme-admin";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 32,
    height: 32,
  },
  filters: {
    overflow: "auto",
    paddingBottom: 5,
    "& > .MuiAutocomplete-root": {
      marginRight: theme.spacing(1),
    },
  },
  customInput: {
    "& .MuiOutlinedInput-input": {
      color: theme.palette.text.primary,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      fontSize: 14,
      width: 50,
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "5px 5px 5px 0px",
      border: "none",
    },
    "& .MuiAutocomplete-inputRoot": {
      padding: 0,
      backgroundColor: theme.palette.lighterGray,
      overflowX: "auto",
      flexWrap: "nowrap",
    },
    "& .MuiAutocomplete-input": {
      padding: `4px !important`,
    },
  },
}));

const AdSpacesToolbar = ({
  assets = [],
  cities = [],
  filters = {},
  handleSearch = () => {},
  handleFilterSpaces = () => {},
}) => {
  const classes = useStyles(theme);

  const { i18n } = useLingui();

  const handleOnSelectFilters = (filter) => (newValue) => {
    handleFilterSpaces({ ...filters, [filter]: [...newValue] });
  };

  const handleOnSearch = (newValue) => {
    handleSearch(newValue);
  };

  return (
    <Box
      display={"flex"}
      alignItems={"stretch"}
      justifyContent={"flex-start"}
      width="100%"
      className={classes.filters}
      gridGap={3}
    >
      <MultiselectSearchFilter
        title={i18n._(t`Activos`)}
        localStorageName={"adspaceAssets"}
        icon={() => <Assets className={classes.icon} fill={theme.palette.black} />}
        options={assets}
        onSelect={handleOnSelectFilters("assets")}
      />
      <MultiselectSearchFilter
        title={i18n._(t`Ciudad`)}
        localStorageName={"adspaceCities"}
        icon={() => <CityIcon className={classes.icon} fill={theme.palette.black} />}
        options={cities}
        onSelect={handleOnSelectFilters("cities")}
      />
      <SearchBoxFilter onSearch={handleOnSearch} placeholder={i18n._(t`Buscar`)} />
    </Box>
  );
};

export default AdSpacesToolbar;
