import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "/src/hooks/navigation";
import api from "../../api";
// import theme
import CalendarComponent from "../../components/listing-form/calendar-step";
// Actions
import {
  putSpaceThunk,
  getSpaceUnavailableDateRangesThunk,
  publishSpaceThunk,
  resetSpace,
} from "../../redux/actions/space-actions";
import { formatDateUtc } from "../../utils/dateUtils";

const CalendarStepContainer = ({ onSetOpenDelete }) => {
  const dispatch = useDispatch();
  const { navigate } = useNavigate();

  const [blockDatesError, setBlockDatesError] = useState("");
  const [inProgressBookings, setInProgressBookings] = useState([{}]);
  const [findingBookingsStatus, setFindingBookingStatus] = useState("initial");
  const [findingBookingError, setFindingBookingError] = useState("");

  const id = useSelector((state) => state.listing.data.id);
  const userId = useSelector((state) => state.user.data.id);
  const status = useSelector((state) => state.listing.status);
  const space = useSelector((state) => state.listing.data);
  const searchable = useSelector((state) => state.listing.data.searchable);

  const unavailableDateRangesStatus = useSelector((state) => state.listing.unavailableDateRangesStatus);
  const unavailableDates = useSelector((state) => state.listing.unavailableDates);
  const unavailableDatesRange = useSelector((state) => state.listing.unavailableDatesRange);

  const getInProgressBookings = (start, end) => {
    setFindingBookingStatus("loading");
    api
      .getSpaceBookings({
        spaceId: id,
        userId,
        start: formatDateUtc(start, "YYYY-MM-DD"),
        end: formatDateUtc(end, "YYYY-MM-DD"),
      })
      .then((res) => {
        if (res.response?.message) {
          setFindingBookingError(res.response?.message);
          setFindingBookingStatus("error");
          return;
        }
        setInProgressBookings(res.data?.payload?.data);
        setFindingBookingStatus("success");
      });
  };

  useEffect(() => {
    dispatch(getSpaceUnavailableDateRangesThunk(id));
  }, [id]);

  const handleSubmit = (data) => {
    dispatch(putSpaceThunk(id, data));
  };

  const handleBlockDates = async (start, end, description) => {
    setBlockDatesError();
    const res = await api.blockDates(id, start, end, description);
    if (res.status === 200) {
      dispatch(getSpaceUnavailableDateRangesThunk(id));
    } else {
      setBlockDatesError(res.response?.data?.message);
    }
  };

  const handleUnblockDates = async (dateId) => {
    setBlockDatesError();
    const res = await api.unblockDates(id, dateId);

    if (res.status === 200) {
      dispatch(getSpaceUnavailableDateRangesThunk(id));
    } else {
      setBlockDatesError(res.response?.data?.message);
    }
  };

  const handleNext = () => {
    navigate("/administrator/spaces");
    dispatch(resetSpace());
  };

  const handleClickPublish = () => dispatch(publishSpaceThunk({ listingId: id, hide: searchable }));

  /*
  const handleSyncGoogleCalendar = async () => {
    await api.syncGoogleCalendar();
  };
  */

  return (
    <CalendarComponent
      onSetOpenDelete={onSetOpenDelete}
      handleNext={handleNext}
      handleBack={() => navigate(`/administrator/spaces/${id}/price`)}
      onSubmit={handleSubmit}
      onPublishSpace={handleClickPublish}
      inProgressBookings={inProgressBookings}
      findingBookingsStatus={findingBookingsStatus}
      findingBookingError={findingBookingError}
      getInProgressBookings={getInProgressBookings}
      unavailableDates={unavailableDates}
      unavailableDatesRange={unavailableDatesRange}
      unavailableDateRangesStatus={unavailableDateRangesStatus}
      blockDates={handleBlockDates}
      unblockDates={handleUnblockDates}
      blockDatesError={blockDatesError}
      space={space}
      status={status}
    />
  );
};

export default CalendarStepContainer;
