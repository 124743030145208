import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import adminTheme from "./../theme-admin";
import { CssBaseline } from "@material-ui/core";

export default (props) => {

  return (
    <ThemeProvider theme={adminTheme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  )
};