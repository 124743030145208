import { t } from "@lingui/macro";

const categories = [
  {
    id: 1,
    title: t({ id: "introduction", message: "Introducción" }),
    videos: [
      {
        id: 101,
        title: "Introduction to Book A Corner Platform 📚",
        url: "https://www.youtube.com/watch?v=6z3XyRMheB0&ab_channel=BookACorner",
      },
    ],
  },
  {
    id: 2,
    title: t({ id: "requests", message: "Consultas" }),
    videos: [
      {
        id: 201,
        title: "How to create a request on BAC",
        url: "https://www.youtube.com/watch?v=ZFJ2YockFko&ab_channel=BookACorner",
      },
      {
        id: 202,
        title: "Converting Requests to Bookings in Book A Corner",
        url: "https://www.youtube.com/watch?v=D95tceHvWUE&ab_channel=BookACorner",
      },
    ],
  },
  {
    id: 3,
    title: t({ id: "bookings", message: "Reservas" }),
    videos: [
      {
        id: 301,
        title: "Managing Bookings Module on Book a Corner Platform",
        url: "https://www.youtube.com/watch?v=OWUxBBAB6E4&ab_channel=BookACorner",
      },
      {
        id: 302,
        title: "Creating Third Party Bookings on Book a Corner 📚",
        url: "https://www.youtube.com/watch?v=9Quv5T9Kk5E&ab_channel=BookACorner",
      },
      {
        id: 303,
        title: "Training on Generating Invoices with the Pulse integration on Book A Corner 💼",
        url: "https://www.youtube.com/watch?v=DoDmVD9HcDY&ab_channel=BookACorner",
      },
    ],
  },
  {
    id: 4,
    title: t({ id: "invoicing", message: "Facturación" }),
    videos: [
      {
        id: 401,
        title: "Understanding Invoicing Module 📊",
        url: "https://www.youtube.com/watch?v=XP_xFBodDOI&ab_channel=BookACorner",
      },
    ],
  },

  {
    id: 5,
    title: t({ id: "spaces", message: "Espacios" }),
    videos: [
      {
        id: 501,
        title: "Managing Spaces on Book A Corner Platform",
        url: "https://www.youtube.com/watch?v=FyF2nG1Ejnc&ab_channel=BookACorner",
      },
    ],
  },

  {
    id: 6,
    title: t({ id: "adspaces", message: "Espacios publicitarios" }),
    videos: [
      {
        id: 601,
        title: "Creating and Managing Ad Spaces on Book A Corner 🚀",
        url: "https://www.youtube.com/watch?v=KNOEhLf4Kas&ab_channel=BookACorner",
      },
    ],
  },

  {
    id: 7,
    title: t({ id: "clients", message: "Clientes" }),
    videos: [
      {
        id: 701,
        title: "Managing Tenants Module on Book A Corner",
        url: "https://www.youtube.com/watch?v=JBTebly32V0&ab_channel=BookACorner",
      },
    ],
  },

  {
    id: 8,
    title: t({ id: "centers-and-landlords", message: "Centros y propietarios" }),
    videos: [
      {
        id: 801,
        title: "Managing Assets and Landlords Module 🏢",
        url: "https://www.youtube.com/watch?v=t0XXf-1QwUY&ab_channel=BookACorner",
      },
    ],
  },
];

export default categories;
