import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";

import { ValidatorForm } from "react-material-ui-form-validator";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import { Grid } from "@material-ui/core";
import AdSpaceStepButtons from "../ad-space-step-buttons";
import TextField from "../../text-field";
import theme from "../../../theme";

const useStyles = makeStyles((th) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    color: th.palette.text.primary,
    marginTop: th.spacing(2),
  },
  validatorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    height: "100%",
    width: "100%",
  },
  inputRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
  },
  inputs: {
    width: "217px",
  },
  textField: {
    "& .MuiInputBase-inputMultiline": {
      height: "68px",
    },
  },
  selects: {
    display: "flex",
    flexDirection: "row",
  },
  continueButton: {
    width: 190,
    height: 42,
    [th.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  label: {
    fontSize: "13px",
    fontWeight: "bold",
  },
  backdrop: {
    zIndex: th.zIndex.drawer + 1,
    color: th.palette.white,
  },
  labelDescription: {
    transform: "translate(0, 15px) scale(1)",
    paddingBottom: 0,
  },
  labelInput: {
    transform: "translate(0, -30px) scale(1)!important",
  },
}));

const AdsTitle = ({
  adspace = {},
  status = "initial",
  submit = () => {},
  submitStatus = "initial",
  isNew,
  onSetOpenDelete,
}) => {
  const classes = useStyles(theme);
  const [values, setValues] = useState(adspace);

  const { i18n } = useLingui();

  const handleInputChange =
    (field) =>
    ({ target }) => {
      setValues({ ...values, [field]: target.value });
    };

  const handleFinish = () => {
    submit(values);
  };

  const somethingHasChanged = JSON.stringify(values) !== JSON.stringify(adspace);
  const handleResetChanges = () => setValues(adspace);

  const disableSubmit =
    values?.title?.length < 3 ||
    values?.description?.length < 3 ||
    submitStatus === "loading" ||
    (!isNew && !somethingHasChanged);

  useEffect(() => {
    setValues(adspace);
  }, [adspace]);

  return (
    <Box className={classes.container}>
      <Backdrop className={classes.backdrop} open={status === "loading"}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={submitStatus === "error"} autoHideDuration={3000}>
        <MuiAlert elevation={6} variant="filled" severity="error">
          <Trans>Ha ocurrido un error. Por favor intente de nuevo.</Trans>
        </MuiAlert>
      </Snackbar>
      <ValidatorForm className={classes.validatorContainer} onSubmit={() => {}}>
        <Box px={2}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <TextField
                labelClassNames={classes.labelDescription}
                labelInputClassNames={classes.labelInput}
                label={`${i18n._(t`¿Cuál es el título de tu espacio?*`)}`}
                description={`${i18n._(t`(Este es el título que se va a mostrar a los clientes)`)}`}
                validators={["required"]}
                errorMessages={[""]}
                value={values?.title || ""}
                InputProps={{
                  placeholder: `${i18n._(t`Escalera mecánica P2. B`)}`,
                }}
                onChange={handleInputChange("title")}
                fullWidth
              />
              <Box mt={4}>
                <TextField
                  label={`${i18n._(t`Descripción`)}`}
                  validators={["required"]}
                  errorMessages={[""]}
                  value={values?.description || ""}
                  multiline
                  rows={4}
                  onChange={handleInputChange("description")}
                  textFieldClassNames={classes.textField}
                  InputProps={{
                    placeholder: `${i18n._(t`Gran exposición...`)}`,
                  }}
                  fullWidth
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <AdSpaceStepButtons
          labelSubmit={!isNew ? <Trans>Guardar</Trans> : <Trans>Continuar</Trans>}
          handleSubmit={handleFinish}
          disableSubmit={disableSubmit}
          onSetOpenDelete={onSetOpenDelete}
          showDeleteAdSpace={!isNew}
          hasResetChanges={!isNew}
          onResetChanges={handleResetChanges}
          disableResetChanges={!somethingHasChanged}
        />
      </ValidatorForm>
    </Box>
  );
};

export default AdsTitle;
