import React, { useEffect, useState } from "react";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { Box, Typography, Tooltip } from "@material-ui/core";
// theme
import Table from "../table";
import TableFooter from "../table-footer";
import NewCustomerDialog from "../../containers/customers-owner/new-customer-dialog";
import CustomersToolbar from "./customers-toolbar";
import TableDataDownload from "../table-data-download";
import { limitStringChars } from "../../utils";
import ImportModal from "../import-modal";
import api from "../../api";

function CustomersOwner({
  companies,
  downloadTableData,
  status,
  onTableChange,
  onRowClick,
  pagination,
  onChangePage,
  canCreateNewTenants,
  onSearch,
}) {
  const [openImportCustomersModal, setOpenImportCustomersModal] = useState(false);
  const [importStatus, setImportStatus] = useState("initial");
  const [openNewCustomerModal, setOpenNewCustomerModal] = useState(false);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);

  const handleRowClick = (rowData) => {
    onRowClick(rowData);
  };

  const handleOpenDownloadModal = () => {
    setOpenDownloadModal(true);
    return false;
  };

  useEffect(() => {
    onTableChange("changePage", { page: 0 });
  }, []);

  const { i18n } = useLingui();

  const handleTableData = () => {
    const data = companies.map((c) => {
      return {
        id: c.id,
        name: c.name,
        country: c.country.name,
        taxIdLabel: c.country.tax_id_label,
        taxId: c.tax_id,
        isAgency: c.is_agency === 0 ? <Trans>No</Trans> : <Trans>Si</Trans>,
        billing_address: c.billing_address ? (
          <Tooltip
            placement="bottom-start"
            title={<Typography variant="body1">{i18n._(t`${c.billing_address}`)}</Typography>}
          >
            <Typography variant="subtitle2">{i18n._(t`${limitStringChars(c.billing_address, 30)}`)}</Typography>
          </Tooltip>
        ) : (
          "-"
        ),
      };
    });

    return data;
  };

  const columns = [
    { label: "ID", name: "id" },
    { label: `${i18n._(t`Empresa`)}`, name: "name" },
    {
      label: `${i18n._(t`País`)}`,
      name: "country",
    },
    {
      label: `${i18n._(t`Tax ID`)}`,
      name: "taxId",
    },
    {
      label: `${i18n._(t`Dirección de facturación`)}`,
      name: "billing_address",
    },
    {
      label: `${i18n._(t`Agencia`)}`,
      name: "isAgency",
    },
  ];

  const importCompanies = async (file) => {
    setImportStatus("loading");
    const res = await api.importCompanies({ file });
    if (res.status === 200) {
      setImportStatus("success");
    } else {
      setImportStatus("error");
    }
  };

  const downloadCompaniesImportSample = async () => {
    await api.getCompaniesImportSample();
  };

  return (
    <Box pl={2} pr={2} height="100%">
      <TableDataDownload
        open={openDownloadModal}
        onClose={() => setOpenDownloadModal(false)}
        onDownload={downloadTableData}
      />
      {openNewCustomerModal && (
        <NewCustomerDialog open={openNewCustomerModal} onClose={() => setOpenNewCustomerModal(false)} />
      )}
      {openImportCustomersModal && (
        <ImportModal
          open={openImportCustomersModal}
          importStatus={importStatus}
          title={i18n._(t`Importar clientes`)}
          onSubmit={(file) => importCompanies(file)}
          onDownloadSample={downloadCompaniesImportSample}
          onClose={() => setOpenImportCustomersModal(false)}
        />
      )}
      <Table
        data={handleTableData()}
        columns={columns}
        isLoading={status === "loading"}
        onDownload={handleOpenDownloadModal}
        options={{
          onTableChange,
          onRowClick: handleRowClick,
          customToolbar: () => {
            return <CustomersToolbar onTableChange={onTableChange} onSearch={onSearch} />;
          },
          customFooter: () => {
            return (
              <TableFooter
                pagination={pagination}
                onChangePage={onChangePage}
                canCreate={canCreateNewTenants}
                primaryButton={i18n._(t`Crear nuevo cliente`)}
                secondaryButton={i18n._(t`Importar clientes`)}
                onPrimaryButton={() => setOpenNewCustomerModal(true)}
                onSecondaryButton={() => setOpenImportCustomersModal(true)}
              />
            );
          },
        }}
      />
    </Box>
  );
}

export default CustomersOwner;
