import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Trans } from "@lingui/macro";
import SecondaryButton from "../secondary-button";

const WizardFooter = ({ step, onBack, onNext, canSubmit }) => {
  return (
    <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={2}>
      {step > 1 && (
        <SecondaryButton onClick={onBack}>
          <Trans>Volver</Trans>
        </SecondaryButton>
      )}
      <Button variant="contained" color="primary" onClick={onNext} disabled={!canSubmit}>
        {step < 5 ? <Trans>Continuar</Trans> : <Trans>Crear reserva</Trans>}
      </Button>
    </Box>
  );
};

export default WizardFooter;
