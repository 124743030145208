import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import Tooltip from "@material-ui/core/Tooltip";
import theme from "../../theme-admin";
import { formatDateUtc } from "../../utils/dateUtils";
import CalendarIcon from "../../icons/calendar-icon";
import Calendar from "../calendar";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 32,
    height: 32,
  },
  popper: {
    borderRadius: 3,
    zIndex: 130,
    width: 650,
    "& .MuiAutocomplete-listbox": {
      maxHeight: 200,
    },
  },
  header: {
    borderBottom: theme.borders[0],
    backgroundColor: theme.palette.grayC4,
    padding: "8px 10px",
  },
  searchIcon: {
    position: "absolute",
    top: 10,
    right: 12,
  },
  inputBase: {
    padding: 5,
    paddingLeft: 10,
    width: "100%",
    background: theme.palette.white,
    borderBottom: theme.borders[0],
  },
  paper: {
    boxShadow: "none",
    margin: 0,
    "& ul": {
      paddingTop: 0,
    },
    "& ul li": {
      paddingLeft: 0,
    },
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    paddingLeft: 2,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent",
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: "relative",
  },
  option: {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  datesSelected: {
    marginLeft: 3,
    marginRight: 2,
    fontWeight: 500,
  },
  datesSelectedText: {
    fontWeight: 500,
  },
}));

export default function DateRangeFilter({
  title = "",
  startValues = {},
  localStorageName,
  onSelect = () => {},
  PopoverProps,
  minDate,
}) {
  const classes = useStyles(theme);

  const [anchorEl, setAnchorEl] = useState(null);
  const [dates, setDates] = useState([null, null]);

  const ref = useRef(null);

  const open = Boolean(anchorEl);
  const id = open ? title : undefined;

  const handleChangeDates = () => {
    const newDates = { start: dates[0], end: dates[1] };
    setDates([dates[0], dates[1]]);
    onSelect(newDates);

    if (localStorageName) {
      localStorage.removeItem(localStorageName);
      localStorage.setItem(localStorageName, JSON.stringify({ start: newDates.start, end: newDates.end }));
    }
    setAnchorEl(null);
  };

  const handleCancelDateSelect = () => {
    setAnchorEl(null);
  };

  const handleTooltip = (title) => {
    return (
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle1">{title}</Typography>
      </Box>
    );
  };

  useEffect(() => {
    setDates([startValues.start, startValues.end]);
  }, [startValues]);

  return (
    <>
      <Tooltip title={handleTooltip(title)}>
        <Button
          ref={ref}
          variant={anchorEl ? "outlined" : "contained"}
          style={{ height: "35px" }}
          onClick={(e) => setAnchorEl(ref.current)}
        >
          <Box display="flex" alignItems="center">
            <CalendarIcon className={classes.icon} />
            {startValues.start && startValues.end && (
              <Typography variant="body2" component="span" className={classes.datesSelected}>
                {`${formatDateUtc(startValues.start, "DD/MM/YYYY")} - ${formatDateUtc(startValues.end, "DD/MM/YYYY")}`}
              </Typography>
            )}
          </Box>
        </Button>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        placement="bottom-start"
        className={classes.popper}
        onClose={() => setAnchorEl(null)}
        TransitionProps={{
          onEnter: () => {
            // setDates([null, null]);
          },
        }}
        {...PopoverProps}
      >
        <Box className={classes.header}>
          <Typography variant="subtitle1">
            <Trans>Fechas de reserva</Trans>
          </Typography>
        </Box>
        <Box pt={1} pb={2} pl={2} pr={2}>
          <Calendar
            minDate={minDate}
            startDate={dates[0]}
            endDate={dates[1]}
            onChange={(dates) => setDates(dates)}
            isDouble
          />
          <Box
            display="flex"
            justifyContent="flex-end"
            gridGap={3}
            p={1}
            style={{ backgroundColor: theme.palette.white }}
          >
            <Button
              style={{ textDecoration: "underline" }}
              variant="text"
              color="default"
              onClick={() => setDates([null, null])}
            >
              <Trans>Limpiar fechas</Trans>
            </Button>
            <Button variant="contained" color="default" onClick={handleCancelDateSelect}>
              <Trans>Cancelar</Trans>
            </Button>
            <Button variant="contained" color="primary" onClick={handleChangeDates}>
              <Trans>Aceptar</Trans>
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}
