import React, { useEffect, useState } from "react";
import { Box, Button, Typography, CircularProgress } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { Trans } from "@lingui/macro";
import theme from "../../../theme-admin";
import TrashIcon from "../../../icons/trash-icon";
import EditIcon from "../../../icons/edit-icon";
import DialogInvitationAndEdition from "./dialog-invitation-edition";
import TableRowDelete from "../../table-row-delete";
import OwnerTableModal from "../../owner-table-modal";

const CONTENT_MAX_HEIGHT = "280px";

const useStyles = makeStyles((th) => ({
  clientContent: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
  },
  divider: {
    borderTop: "1px solid black",
  },
  customTableClients: {
    "& .MuiTableHead-root": {
      borderTop: "0px",
    },
  },
  field: {
    marginTop: th.spacing(1 / 2),
    marginBottom: th.spacing(1 / 2),
  },
  fieldInput: {
    paddingTop: th.spacing(1),
    paddingBottom: th.spacing(1),
  },
  inputError: {
    borderColor: th.palette.red,
  },
  tableHead: {
    "& .MuiTableCell-sizeSmall": {
      textTransform: "uppercase",
    },
  },
  iconBtn: {
    minWidth: 15,
    "& span > svg": {
      width: "0.75rem",
    },
    "&:hover": {
      "& span > svg > path": {
        fill: th.palette.primary.main,
      },
    },
  },
}));

const CustomerList = ({
  customers = [],
  customerStatus,
  inviteCustomer,
  userInviteErrors,
  inviteUserStatus,
  onUpdateFieldUser,
  updateFieldUserStatus,
  onDeleteInvitation,
  deleteInvitationStatus,
  openInvitation,
  setOpenInvitation,
  canEdit,
}) => {
  const classes = useStyles({
    ...theme,
  });

  const [openDialog, setOpenDialog] = useState({ isOpen: false, isEdit: false, value: {} });
  const [showRowDelete, setShowRowDelete] = useState(-1);

  useEffect(() => {
    if (openInvitation) {
      setOpenDialog({ isOpen: true, isEdit: false, value: {} });
    }
  }, [openInvitation]);

  return (
    <Box className={classes.clientContent}>
      <ValidatorForm onSubmit={() => {}}>
        <OwnerTableModal>
          {customerStatus === "loading" && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
              height={CONTENT_MAX_HEIGHT}
              mt={1}
            >
              <CircularProgress />
            </Box>
          )}
          {customerStatus !== "loading" && (
            <Box pr={1} className={classes.customTableClients}>
              {customers.length && customerStatus === "success" ? (
                <MuiTable size="small">
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell style={{ width: "250px" }} align="left">
                        <Trans>Nombre</Trans>
                      </TableCell>
                      <TableCell style={{ width: "250px" }} align="left">
                        <Trans>Apellido</Trans>
                      </TableCell>
                      <TableCell align="center">
                        <Trans>Mail</Trans>
                      </TableCell>
                      <TableCell align="center">
                        <Trans>Teléfono</Trans>
                      </TableCell>
                      <TableCell align="center">
                        <Trans>Estado</Trans>
                      </TableCell>
                      {!!canEdit && (
                        <TableCell align="center">
                          <Trans>Acciones</Trans>
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customers.map((c) => (
                      <TableRow key={c.id}>
                        {showRowDelete !== c.id ? (
                          <>
                            <TableCell align="left" variant="body">
                              <Typography variant="h4" style={{ fontSize: "11px" }}>
                                <Trans>{c.name}</Trans>
                              </Typography>
                            </TableCell>
                            <TableCell style={{ width: "150px" }} align="left" variant="body">
                              <Typography variant="h4" style={{ fontSize: "11px" }}>
                                <Trans>{c.lastname}</Trans>
                              </Typography>
                            </TableCell>
                            <TableCell style={{ width: "250px" }} align="center" variant="body">
                              <Typography variant="body2">{c.email}</Typography>
                            </TableCell>
                            <TableCell style={{ width: "160px" }} align="center" variant="body">
                              <Typography variant="h4" style={{ fontSize: "11px" }}>
                                <Trans>{c.phone}</Trans>
                              </Typography>
                            </TableCell>
                            <TableCell style={{ width: "160px" }} align="center" variant="body">
                              {c.status === "pending" ? (
                                <Typography variant="body2" style={{ color: theme.palette.red }}>
                                  <Trans>Esperando registro...</Trans>
                                </Typography>
                              ) : (
                                <Typography variant="body2" style={{ color: theme.palette.successGreen }}>
                                  <Trans>Registrado</Trans>
                                </Typography>
                              )}
                            </TableCell>
                            {!!canEdit && (
                              <TableCell style={{ width: "150px" }} align="center" variant="body">
                                {c.status !== "pending" ? (
                                  <Button
                                    className={classes.iconBtn}
                                    style={{ minWidth: 15 }}
                                    onClick={() => setOpenDialog({ isOpen: true, isEdit: true, value: c })}
                                  >
                                    <EditIcon fill={theme.palette.dark} style={{ width: "13px", height: "13px" }} />
                                  </Button>
                                ) : (
                                  <Button
                                    style={{ minWidth: 15 }}
                                    onClick={() => {
                                      setShowRowDelete(c.id);
                                    }}
                                  >
                                    <TrashIcon style={{ width: "13px", height: "13px" }} />
                                  </Button>
                                )}
                              </TableCell>
                            )}
                          </>
                        ) : (
                          <TableRowDelete
                            colSpan={6}
                            deleteStatus={deleteInvitationStatus}
                            onDelete={() => onDeleteInvitation(c.id)}
                            setShowRowDelete={setShowRowDelete}
                          />
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </MuiTable>
              ) : (
                <Box display="flex" width="100%" justifyContent="center" mt={3} mb={3}>
                  <Typography variant="h4" color="primary">
                    <Trans>No hay usuarios para mostrar</Trans>
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </OwnerTableModal>
        <DialogInvitationAndEdition
          open={openDialog.isOpen}
          isEdit={openDialog.isEdit}
          valueEdit={openDialog.value}
          onClose={() => {
            setOpenDialog({ isOpen: false, isEdit: false, value: {} });
            setOpenInvitation(false);
          }}
          onInviteCustomer={inviteCustomer}
          onUserInviteErrors={userInviteErrors}
          onInviteUserStatus={inviteUserStatus}
          onUpdateFieldUser={onUpdateFieldUser}
          updateFieldUserStatus={updateFieldUserStatus}
        />
      </ValidatorForm>
    </Box>
  );
};

export default CustomerList;
