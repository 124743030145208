import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function CityIcon({ fill = "#E5E5E5", ...rest }) {
  return (
    <SvgIcon
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill={fill}
      {...rest}
    >
      <path
        d="M84 45.5H63.9V25.4C63.9 24.8 63.6 24.2 63.2 23.9L50.9999 13.6C50.1999 13 49.0999 13 48.3999 13.6L36.7999 23.8C36.3999 24.2 36.0999 24.7 36.0999 25.3V33.5H15.9999C14.8999 33.5 13.9999 34.4 13.9999 35.5V84.8C13.9999 85.9 14.8999 86.8 15.9999 86.8H84C85.1 86.8 86 85.9 86 84.8V47.5C86 46.3 85.1 45.5 84 45.5ZM82 82.9H17.9999V37.5H38.0999C39.1999 37.5 40.0999 36.6 40.0999 35.5V26.2L49.7999 17.7L59.9 26.2V47.4C59.9 48.5 60.8 49.4 61.9 49.4H82V82.9Z"
        fill={fill}
      />
      <path
        d="M33 69H24C22.9 69 22 69.9 22 71V78C22 79.1 22.9 80 24 80H33C34.1 80 35 79.1 35 78V71C35 69.9 34.1 69 33 69ZM31 76H26V73H31V76Z"
        fill={fill}
      />
      <path
        d="M33 55H24C22.9 55 22 55.9 22 57V64C22 65.1 22.9 66 24 66H33C34.1 66 35 65.1 35 64V57C35 55.9 34.1 55 33 55ZM31 62H26V59H31V62Z"
        fill={fill}
      />
      <path
        d="M33 41H24C22.9 41 22 41.9 22 43V50C22 51.1 22.9 52 24 52H33C34.1 52 35 51.1 35 50V43C35 41.9 34.1 41 33 41ZM31 48H26V45H31V48Z"
        fill={fill}
      />
      <path
        d="M67 80H76C77.1 80 78 79.1 78 78V71C78 69.9 77.1 69 76 69H67C65.9 69 65 69.9 65 71V78C65 79.1 65.9 80 67 80ZM69 73H74V76H69V73Z"
        fill={fill}
      />
      <path
        d="M67 66H76C77.1 66 78 65.1 78 64V57C78 55.9 77.1 55 76 55H67C65.9 55 65 55.9 65 57V64C65 65.1 65.9 66 67 66ZM69 59H74V62H69V59Z"
        fill={fill}
      />
      <path
        d="M54.9999 69H45.9999C44.8999 69 43.9999 69.9 43.9999 71V78C43.9999 79.1 44.8999 80 45.9999 80H54.9999C56.0999 80 57 79.1 57 78V71C57 69.9 56.0999 69 54.9999 69ZM52.9999 76H47.9999V73H52.9999V76Z"
        fill={fill}
      />
      <path
        d="M54.9999 55H45.9999C44.8999 55 43.9999 55.9 43.9999 57V64C43.9999 65.1 44.8999 66 45.9999 66H54.9999C56.0999 66 57 65.1 57 64V57C57 55.9 56.0999 55 54.9999 55ZM52.9999 62H47.9999V59H52.9999V62Z"
        fill={fill}
      />
      <path
        d="M54.9999 41H45.9999C44.8999 41 43.9999 41.9 43.9999 43V50C43.9999 51.1 44.8999 52 45.9999 52H54.9999C56.0999 52 57 51.1 57 50V43C57 41.9 56.0999 41 54.9999 41ZM52.9999 48H47.9999V45H52.9999V48Z"
        fill={fill}
      />
      <path
        d="M54.9999 26H45.9999C44.8999 26 43.9999 26.9 43.9999 28V35C43.9999 36.1 44.8999 37 45.9999 37H54.9999C56.0999 37 57 36.1 57 35V28C57 26.9 56.0999 26 54.9999 26ZM52.9999 33H47.9999V30H52.9999V33Z"
        fill={fill}
      />
    </SvgIcon>
  );
}
