import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

export default function LampIcon({
  fill = "black",
  width = 120,
  height = 120,
  ...rest
}) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 120 120"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M49.28 79.4971C41.568 75.5191 36.5848 67.2303 36.5848 58.3811C36.5848 45.4699 47.0888 34.9659 60 34.9659C72.9112 34.9659 83.4152 45.4699 83.4152 58.3811C83.4152 67.1563 78.406 75.4436 70.6532 79.4939L69.3564 77.0119C76.196 73.4391 80.6152 66.1259 80.6152 58.3811C80.6152 47.0139 71.3672 37.7659 60 37.7659C48.6328 37.7659 39.3848 47.0139 39.3848 58.3811C39.3848 66.1939 43.7728 73.506 50.5636 77.0088L49.28 79.4971Z"
        fill={fill}
      />
      <path
        d="M65.738 98.7999H54.452L48 94.7587V76.7999H71.2V94.7127L65.738 98.7999ZM55.2776 95.9999H64.8332L68.4 93.2807V79.5999H50.8V93.2347L55.2776 95.9999Z"
        fill={fill}
      />
      <path d="M70 86.3999H49.6V89.1999H70V86.3999Z" fill={fill} />
      <path d="M98.4 58.3999H91.2V61.1999H98.4V58.3999Z" fill={fill} />
      <path d="M28.4 58.3999H21.2V61.1999H28.4V58.3999Z" fill={fill} />
      <path
        d="M88.284 85.9313L83.1892 80.8365L81.2096 82.8161L86.3044 87.9109L88.284 85.9313Z"
        fill={fill}
      />
      <path
        d="M38.7905 36.4418L33.6949 31.347L31.7154 33.3269L36.8109 38.4217L38.7905 36.4418Z"
        fill={fill}
      />
      <path d="M60.8 21.2H58V28.4H60.8V21.2Z" fill={fill} />
      <path
        d="M38.7907 82.8165L36.8111 80.8369L31.716 85.932L33.6956 87.9116L38.7907 82.8165Z"
        fill={fill}
      />
      <path
        d="M88.284 33.3231L86.3044 31.3435L81.2096 36.4383L83.1892 38.4179L88.284 33.3231Z"
        fill={fill}
      />
      <path
        d="M59.9384 70.1252L57.6648 68.4912L62.9056 61.2H51.714L60.6084 47.5164L62.9564 49.042L56.8736 58.4H68.3664L59.9384 70.1252Z"
        fill={fill}
      />
    </SvgIcon>
  );
}
