import React from "react";

export default function PaymentCard({
  fill = "#333333",
  width = 40,
  height = 40,
  ...rest
}) {
  return (
    
    <svg width={width} height={height} viewBox="0 0 190 190" fill="none" {...rest}>
      <g clipPath="url(#clip0_3520_15752)">
        <path d="M92.7 111.7H28.8C26.5 111.7 24.6 113.6 24.6 115.9C24.6 118.2 26.5 120.1 28.8 120.1H92.7C95 120.1 96.9 118.2 96.9 115.9C96.9 113.6 95.1 111.7 92.7 111.7Z" fill={fill} />
        <path d="M28.8 100.6H79.7C82 100.6 83.9 98.7 83.9 96.4C83.9 94.1 82 92.2 79.7 92.2H28.8C26.5 92.2 24.6 94.1 24.6 96.4C24.6 98.7 26.5 100.6 28.8 100.6Z" fill={fill} />
        <path d="M171.3 100.5V49.4C171.3 42 165.3 36 157.9 36H18.4C11 36 5 42 5 49.4V136.8C5 144.2 11 150.2 18.4 150.2H118C124.3 162 136.8 170.1 151.2 170.1C172 170.1 188.9 153.2 188.9 132.4C188.9 118.9 181.9 107.2 171.3 100.5ZM13.5 49.4C13.5 46.7 15.7 44.5 18.4 44.5H158C160.7 44.5 162.9 46.7 162.9 49.4V63.4H13.5V49.4ZM114.7 141.7H18.4C15.7 141.7 13.5 139.5 13.5 136.8V71.9H162.9V96.5C159.2 95.3 155.4 94.7 151.3 94.7C130.5 94.7 113.6 111.6 113.6 132.4C113.6 135.6 114 138.7 114.7 141.7ZM151.2 162.2C134.7 162.2 121.4 148.8 121.4 132.4C121.4 115.9 134.8 102.6 151.2 102.6C167.7 102.6 181 116 181 132.4C181.1 148.8 167.7 162.2 151.2 162.2Z" fill={fill} />
        <path d="M159 130.8C157.1 129.6 154.8 129.1 152.7 128.6C152.4 128.5 152.2 128.5 151.9 128.4C149.4 127.8 148 127.4 147.3 127C146.9 126.8 146.8 126.6 146.7 126.4C146.6 126.2 146.5 125.9 146.5 125.5C146.5 124.5 146.7 123.8 147.1 123.2C147.5 122.6 148.1 122.2 148.9 121.9C150 121.5 151.3 121.4 152.5 121.6C153.7 121.8 154.8 122.3 155.5 123C157 124.5 159.5 124.5 161.1 123.2C162.8 121.7 162.7 119.4 161.3 117.9C159.6 116.2 157.4 115.1 155 114.5V113.6C155 111.5 153.1 109.9 151 109.9C148.9 109.9 147 111.5 147 113.6V114.7L146.1 115C141.4 116.7 138.6 120.6 138.5 125.5C138.5 128.9 140.1 131.6 143 133.3C145.2 134.5 147.7 135.1 150 135.6C152.5 136.2 153.9 136.6 154.7 137C155.1 137.2 155.2 137.4 155.3 137.6C155.4 137.8 155.5 138.1 155.5 138.5C155.5 140.2 155 141.4 154.2 142.1C153.5 142.7 152.4 143.1 151.1 143.1C149.9 143.1 148.7 142.8 147.8 142.3C146.9 141.8 146.4 141.2 146.4 140.6C146.4 138.5 144.5 136.9 142.4 136.9C140.3 136.9 138.4 138.5 138.4 140.6C138.4 145.4 142.4 148.7 146.9 149.9V151.2C146.9 153.3 148.8 154.9 150.9 154.9C153 154.9 154.9 153.3 154.9 151.2V150C156.6 149.5 158.2 148.7 159.5 147.6C161.3 146.1 163.4 143.3 163.4 138.6C163.6 135.2 162 132.5 159 130.8Z" fill={fill} />
      </g>
      <defs>
      <clipPath id="clip0_3520_15752">
      <rect width="183.9" height="134" fill="white" transform="translate(5 36)"/>
      </clipPath>
      </defs>
    </svg>

    

  );
}
