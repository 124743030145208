import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import {
  getAdSpaceThunk,
  updateAdSpaceData,
} from "../../redux/actions/adspaces-actions";
import VisibilitySwitchComponent from "../../components/listing-form/visibility-switch";
import { useParams } from "@reach/router";
import { Trans } from "@lingui/macro";
import api from "../../api";

const AdSpaceVisibilitySwitch = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const adspace = useSelector((state) => state.adspaces.adSpace);
  const searchable = useSelector((state) => state.adspaces.adSpace.searchable);

  const handlePublishAdSpace = async () => {
    if (params.adSpaceId !== "new") {
      adspace.searchable
        ? await api.hideAdSpace(adspace.id)
        : await api.publishAdSpace(adspace.id);
      dispatch(getAdSpaceThunk({ adSpaceId: params.adSpaceId }));
    } else {
      dispatch(updateAdSpaceData({ searchable: !adspace.searchable }));
    }
  };

  return (
    <>
      {params.adSpaceId !== "new" ? (
        <VisibilitySwitchComponent
          label={<Trans>Mostrar espacio publicitario</Trans>}
          searchable={searchable}
          onClickPublish={handlePublishAdSpace}
        />
      ) : (
        <Box></Box>
      )}
    </>
  );
};

export default AdSpaceVisibilitySwitch;
