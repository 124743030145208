import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function VisibilityOn({ fill = "#FFF", ...rest }) {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...rest}>
      <path
        d="M10.0001 14.0012C6.91029 14.0012 3.96789 12.6537 1.71497 10.207L1.5249 10.0006L1.71497 9.79417C3.96789 7.34743 6.91029 6 10.0001 6C13.0899 6 16.0323 7.34752 18.2852 9.79417L18.4753 10.0006L18.2852 10.207C16.0323 12.6538 13.0899 14.0012 10.0001 14.0012ZM2.35778 10.0006C4.46695 12.1897 7.17035 13.3917 10.0001 13.3917C12.8298 13.3917 15.5333 12.1896 17.6424 10.0006C15.5332 7.8115 12.8298 6.60946 10.0001 6.60946C7.17035 6.60946 4.46686 7.8115 2.35778 10.0006Z"
        fill={fill}
      />
      <path
        d="M10 13.9948C7.79769 13.9948 6.00604 12.2031 6.00604 10.0008C6.00604 7.79848 7.79769 6.00684 10 6.00684C12.2024 6.00684 13.994 7.79848 13.994 10.0008C13.994 12.2031 12.2024 13.9948 10 13.9948ZM10 6.6163C8.13376 6.6163 6.6155 8.13455 6.6155 10.0008C6.6155 11.8671 8.13376 13.3853 10 13.3853C11.8663 13.3853 13.3845 11.8671 13.3845 10.0008C13.3845 8.13455 11.8663 6.6163 10 6.6163Z"
        fill={fill}
      />
    </SvgIcon>
  );
}
