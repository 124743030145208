import { Box, Grid, InputAdornment, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { currencySymbol } from "../../../utils/currencyUtils";
// theme
import theme from "../../../theme";
// My Components
import TextField from "../../text-field";

const useStyles = makeStyles((theme) => ({
  inputBox: {
    marginTop: 0,
  },
  inputLabel: {
    fontSize: theme.typography.body1.fontSize,
    transform: "translate(0, -5px) scale(1)",
    fontWeight: "bold",
    color: theme.palette.black,
  },
  inputDescriptionLabel: {
    color: theme.palette.gray,
  },
  inputsContainer: {
    marginTop: theme.spacing(2),
  },
  label: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: "bold",
    color: theme.palette.gray,
    textTransform: "uppercase",
  },
  field: {
    width: 200,
  },
}));

const PriceInput = ({ onChange = () => {}, value = "", label = "" }) => {
  const classes = useStyles(theme);

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Box className={classes.labelBox}>
        <InputLabel className={classes.label}>{label}</InputLabel>
      </Box>
      <Box mt={1}>
        <TextField
          type="number"
          value={value}
          onChange={onChange}
          InputProps={{
            startAdornment: <InputAdornment position="start">{currencySymbol()}</InputAdornment>,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.field}
        />
      </Box>
    </Box>
  );
};

const PriceInputs = ({
  onChangePricePerDay = () => {},
  onChangePricePerWeekEnd = () => {},
  onChangePricePerWeek = () => {},
  onChangePricePerMonth = () => {},
  submitedWithErrors = false,
  value,
}) => {
  const classes = useStyles(theme);

  const { i18n } = useLingui();

  return (
    <Box className={classes.inputBox}>
      <InputLabel className={classes.inputLabel} error={submitedWithErrors}>
        <Trans>Define el precio</Trans>*
      </InputLabel>
      <Grid container className={classes.inputsContainer}>
        <Grid item xs={3}>
          <PriceInput
            label={`${i18n._(t`Precio por día laborable`)}`}
            onChange={onChangePricePerDay}
            value={value.week_day_price}
          />
        </Grid>
        <Grid item xs={3}>
          <PriceInput
            label={`${i18n._(t`Precio por fin de semana`)}`}
            onChange={onChangePricePerWeekEnd}
            value={value.full_weekend_price}
          />
        </Grid>
        <Grid item xs={3}>
          <PriceInput
            label={`${i18n._(t`Precio por semana completa`)}`}
            onChange={onChangePricePerWeek}
            value={value.full_week_price}
          />
        </Grid>
        <Grid item xs={3}>
          <PriceInput
            label={`${i18n._(t`Precio por mes completo`)}`}
            onChange={onChangePricePerMonth}
            value={value.full_month_price}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

PriceInputs.prototype = {
  onChangePricePerDay: PropTypes.func,
  onChangePricePerWeekEnd: PropTypes.func,
  onChangePricePerWeek: PropTypes.func,
  onChangePricePerMonth: PropTypes.func,
  submitedWithErrors: PropTypes.bool,
  value: PropTypes.object.isRequired,
};

export default PriceInputs;
