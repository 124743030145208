import React from "react";
import { Redirect } from "@reach/router";
import { useLocalization } from "gatsby-theme-i18n";

function LocalizedRedirect({ path = "", to = "", ...rest }) {
  const localization = useLocalization();

  return (
    <Redirect
      from={path}
      to={localization.localizedPath({ ...localization, path: to })}
      {...rest}
    />
  );
}

export default LocalizedRedirect;
