import React, { useEffect, useState } from "react";
import { useParams } from "@reach/router";
import { useNavigate } from "/src/hooks/navigation";
import { useSelector, useDispatch } from "react-redux";
import CalendarComponent from "../../../components/ad-spaces-form/calendar";
import api from "../../../api";
import {
  getAdSpaceTypesThunk,
  getAdSpaceThunk,
  getAdSpaceUnavailableDatesThunk,
} from "../../../redux/actions/adspaces-actions";
import { formatDateUtc } from "../../../utils/dateUtils";

const Calendar = () => {
  const dispatch = useDispatch();
  const { navigate } = useNavigate();
  const params = useParams();

  const [submitStatus, setSubmitStatus] = useState("initial");
  const [blockedDates, setBlockedDates] = useState([]);
  const [blockedDatesStatus, setBlockedDatesStatus] = useState("initial");
  const [blockDateStatus, setBlockDateStatus] = useState("initial");
  const [bookingsBlocked, setBookingsBlocked] = useState([]);
  const [isNewAdSpace, setIsNewAdSpace] = useState(false);

  const adspace = useSelector((state) => state.adspaces.adSpace);
  const status = useSelector((state) => state.adspaces.adSpaceStatus);
  const adSpaceUnavailableDates = useSelector(
    (state) => state.adspaces.adSpaceUnavailableDates
  );
  const adSpaceUnavailableDatesRange = useSelector(
    (state) => state.adspaces.adSpaceUnavailableDatesRange
  );

  const getBlockedDates = async (id) => {
    setBlockedDatesStatus("loading");
    const res = await api.getAdSpaceUnavailableDateRanges(id);
    if (res.status === 200) {
      setBlockedDatesStatus("success");
      setBlockedDates(res.data?.payload?.data);
    } else {
      setBlockedDatesStatus("error");
    }
  };

  const blockDates = async (start, end, description) => {
    setBlockDateStatus("loading");
    const res = await api.blockAdSpaceDate(adspace.id, start, end, description);
    if (res.status === 200) {
      setBlockDateStatus("success");
      getBlockedDates(adspace?.id);
      dispatch(getAdSpaceUnavailableDatesThunk(adspace?.id));
    } else {
      setBlockDateStatus("error");
    }
  };

  const unblockDate = async (id) => {
    setBlockDateStatus("loading");
    const res = await api.unblockAdSpaceDate(adspace?.id, id);
    if (res.status === 200) {
      setBlockDateStatus("success");
      getBlockedDates(adspace?.id);
      dispatch(getAdSpaceUnavailableDatesThunk(adspace?.id));
    } else {
      setBlockDateStatus("error");
    }
  };

  const getInProgressBookings = (start, end) => {
    api
      .getAdSpaceInprogressBookings({
        adSpaceId: adspace?.id,
        start: formatDateUtc(start, "YYYY-MM-DD"),
        end: formatDateUtc(end, "YYYY-MM-DD"),
      })
      .then((res) => {
        if (res.status === 200) {
          setBookingsBlocked(res.data.payload.data);
        }
      });
  };

  useEffect(() => {
    dispatch(getAdSpaceTypesThunk());
    if (adspace?.id < 1) {
      setIsNewAdSpace(true);
    } else {
      dispatch(getAdSpaceUnavailableDatesThunk(adspace?.id));
    }
  }, [adspace]);

  const handlePublishAdSpace = async () => {
    adspace.searchable
      ? await api.hideAdSpace(adspace.id)
      : await api.publishAdSpace(adspace.id);
    dispatch(getAdSpaceThunk({ adSpaceId: params.adSpaceId }));
  };

  const handleFinish = async () => {
    navigate(`/administrator/adspaces/`);
  };

  const goBack = () => {
    navigate(
      `/administrator/adspaces/${params.adSpaceId}/information`
    );
  };

  return (
    <CalendarComponent
      bookingsBlocked={bookingsBlocked}
      getInProgressBookings={getInProgressBookings}
      blockedDates={blockedDates}
      blockDates={blockDates}
      unblockDate={unblockDate}
      finish={handleFinish}
      adspace={adspace}
      isNewAdSpace={isNewAdSpace}
      goBack={goBack}
      handlePublishAdSpace={handlePublishAdSpace}
      adSpaceUnavailableDates={adSpaceUnavailableDates}
      adSpaceUnavailableDatesRange={adSpaceUnavailableDatesRange}
      blockedDatesStatus={blockedDatesStatus}
      blockDateStatus={blockDateStatus}
      submitStatus={submitStatus}
    />
  );
};

export default Calendar;
