import React, { useState, useEffect } from "react";
import { Container, List, ListItem, ListItemText, ListItemIcon, Typography, Box, Collapse } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ReactPlayer from "react-player/youtube";
import OwnerModal from "../owner-modal";
import categories from "./videos-list";
import theme from "../../theme-admin";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  listContainer: {
    flex: 1,
    overflowY: "auto",
    marginBottom: theme.spacing(2),
  },
  playerContainer: {
    position: "sticky",
    bottom: 0,
    backgroundColor: "white",
    padding: theme.spacing(2),
    boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
  },
  categoryTitle: {
    fontSize: 16,
    fontWeight: "bold",
  },
  listItem: {
    paddingLeft: 20,
  },
  selected: {
    backgroundColor: `${theme.palette.primary.light} !important`,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  },
  icon: {
    fontSize: "2rem",
  },
  selectedIcon: {
    color: theme.palette.primary.contrastText,
  },
});

const HelpVideos = ({ open = false, onClose }) => {
  const classes = useStyles(theme);
  const { i18n } = useLingui();
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [openCategories, setOpenCategories] = useState({});

  useEffect(() => {
    if (categories.length > 0) {
      setOpenCategories((prevOpenCategories) => ({
        ...prevOpenCategories,
        [categories[0].id]: true,
      }));
      setSelectedVideo(categories[0].videos[0]);
    }
  }, []);

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
  };

  const handleCategoryClick = (categoryId) => {
    setOpenCategories((prevOpenCategories) => ({
      ...prevOpenCategories,
      [categoryId]: !prevOpenCategories[categoryId],
    }));
  };

  return (
    <OwnerModal open={open} title={i18n._(t`Videos de ayuda`)} width={750} onClose={onClose}>
      <Container className={classes.container}>
        <Box className={classes.listContainer}>
          <List>
            {categories.map((category) => (
              <React.Fragment key={category.id}>
                <ListItem button onClick={() => handleCategoryClick(category.id)}>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant="body2" style={{ fontSize: "16px", fontWeight: "bold" }}>
                        {category.title}
                      </Typography>
                    }
                  />
                  {openCategories[category.id] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openCategories[category.id]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {category.videos.map((video) => (
                      <ListItem
                        button
                        key={video.id}
                        onClick={() => handleVideoClick(video)}
                        selected={selectedVideo && selectedVideo.id === video.id}
                        className={`${classes.listItem} ${
                          selectedVideo && selectedVideo.id === video.id ? classes.selected : ""
                        }`}
                      >
                        <ListItemIcon>
                          <PlayCircleOutlineIcon
                            className={`${classes.icon} ${
                              selectedVideo && selectedVideo.id === video.id ? classes.selectedIcon : ""
                            }`}
                          />
                        </ListItemIcon>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography variant="body2" style={{ fontSize: "14px", fontWeight: 500 }}>
                              {video.title}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
          </List>
        </Box>

        {selectedVideo && (
          <Box className={classes.playerContainer}>
            <Typography variant="h6" className={classes.categoryTitle}>
              <Trans>Reproduciendo</Trans>: {selectedVideo.title}
            </Typography>
            <ReactPlayer url={selectedVideo.url} controls />
          </Box>
        )}
      </Container>
    </OwnerModal>
  );
};

export default HelpVideos;
