import React, { useEffect, useState } from "react";
import { useParams } from "@reach/router";
import { useNavigate } from "/src/hooks/navigation";
import { useSelector, useDispatch } from "react-redux";
import PricesComponent from "../../../components/asset-form/prices";
import api from "../../../api";
import { getAssetThunk } from "../../../redux/actions/asset-actions";

const Information = () => {
  const dispatch = useDispatch();
  const { navigate } = useNavigate();
  const params = useParams();

  const [priceStatus, setPriceStatus] = useState("initial");
  const [submitStatus, setSubmitStatus] = useState();
  const [spaceActivities, setSpaceActivities] = useState([]);

  const { assetId } = params;

  const asset = useSelector((state) => state.asset.data);
  const assetConfigs = useSelector((state) => state.asset.data.config);

  useEffect(() => {
    api.getAssetSpaceActivities(assetId).then((res) => {
      if (res.status === 200) {
        setSpaceActivities(res.data?.payload);
      }
    });
  }, []);

  const updateAssetConfig = async (config) => {
    setPriceStatus("loading");
    const res = await api.updateAsset(assetId, { ...asset, config });
    if (res.status === 200) {
      dispatch(getAssetThunk(assetId));
      setPriceStatus("success");
    } else {
      setPriceStatus("error");
    }
  };

  const updatePrice = async (spcActivities) => {
    const res = await api.updateSpaceActivityCoefficients(spcActivities);
    if (res.status === 200) {
      dispatch(getAssetThunk(assetId));
      setSpaceActivities([...spcActivities]);
      setSubmitStatus("success");
    } else {
      setSubmitStatus("error");
    }
  };

  const handleSubmit = async (spcActivities, assetCfgs) => {
    setSubmitStatus("loading");
    updatePrice(spcActivities);
    updateAssetConfig(assetCfgs);
  };

  const resetSubmitStatus = () => {
    setSubmitStatus("initial");
    setPriceStatus("initial");
  };

  const goNext = () => {
    navigate(`/administrator/assets-landlords/assets/${assetId}/proforma`);
  };

  const goBack = () => {
    navigate(`/administrator/assets-landlords/assets/${assetId}/information`);
  };

  return (
    <PricesComponent
      assetConfigs={assetConfigs}
      priceStatus={priceStatus}
      spaceActivities={spaceActivities}
      submit={handleSubmit}
      submitStatus={submitStatus}
      resetSubmitStatus={resetSubmitStatus}
      goNext={goNext}
      goBack={goBack}
    />
  );
};

export default Information;
