import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "@lingui/macro";
import { useNavigate } from "../../hooks/navigation";
import CustomerOwnerComponent from "../../components/customers-owner";
import { getCompaniesThunk } from "../../redux/actions/companies-actions";
import { getRatingCategoriesThunk, setDashboardTitle } from "../../redux/actions/misc-actions";
import { getBookingRatingThunk } from "../../redux/actions/consultation-actions";
import { downloadBlobFiles, filterColumnsDisplayTableToString, userCanWrite, useQuery } from "../../utils";
import api from "../../api";
import { resetAllAdSpaces } from "../../redux/actions/adspaces-actions";
import { resetAllSpaces } from "../../redux/actions/spaces-owner-actions";

const ClientsOwnerContainer = ({ children }) => {
  const dispatch = useDispatch();
  const { navigate } = useNavigate();
  const query = useQuery();
  const searchParam = query.get("search");

  const permissions = useSelector((state) => state.user.data.permissions);
  const companies = useSelector((state) => state.companies.companies);
  const pagination = useSelector((state) => state.companies.pagination);
  const status = useSelector((state) => state.companies.status);
  const user = useSelector((state) => state.user.data);

  const [searchWords, setSearchWords] = useState(searchParam || "");
  const [sort, setSort] = useState({});
  const [columns, setColumns] = useState("");

  useEffect(() => {
    dispatch(setDashboardTitle(t`Clientes`));
    dispatch(getRatingCategoriesThunk());
    dispatch(
      getCompaniesThunk({
        page: 1,
        search: searchWords,
      }),
    );
    dispatch(resetAllAdSpaces());
    dispatch(resetAllSpaces());
  }, []);

  useEffect(() => {
    if (Object.keys(sort).length) {
      dispatch(
        getCompaniesThunk({
          page: pagination.page,
          sort,
          search: searchWords,
        }),
      );
    }
  }, [sort]);

  const handleTableChange = (action, tableState) => {
    const columnsDisplay = filterColumnsDisplayTableToString(tableState);
    setColumns(columnsDisplay);
    switch (action) {
      case "sort":
        setSort(tableState.sortOrder);
        break;
      default:
        console.log("action not handled.");
    }
  };

  const handleRowClick = async (rowData) => {
    navigate(`/administrator/clients/${rowData[0]}/users/all`);
  };

  const handleChangePage = (evt, page) => {
    dispatch(
      getCompaniesThunk({
        page,
        sort,
        search: searchWords,
      }),
    );
  };

  const handleSearch = (search) => {
    setSearchWords(search);
    dispatch(
      getCompaniesThunk({
        page: 1,
        sort,
        search,
      }),
    );
  };

  const handleDownloadCompaniesData = async (format) => {
    const res = await api.downloadCompaniesData({
      exportFormat: format,
      search: searchWords,
      sort,
      columns,
    });
    downloadBlobFiles(res, `Clientes.${format}`);
  };

  const canShowCreateButton = () => {
    let canCreateNewCustomer = false;
    user.scopes?.forEach((e) => {
      if (e === "see-all-companies") {
        canCreateNewCustomer = true;
      }
    });
    return canCreateNewCustomer && userCanWrite(permissions);
  };

  return (
    <>
      <CustomerOwnerComponent
        downloadTableData={handleDownloadCompaniesData}
        onTableChange={handleTableChange}
        onSearch={handleSearch}
        onRowClick={handleRowClick}
        companies={companies}
        pagination={pagination}
        onChangePage={handleChangePage}
        canCreateNewTenants={canShowCreateButton()}
        status={status}
        user={user}
      />
      {children}
    </>
  );
};

export default ClientsOwnerContainer;
