import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CreateBookingWizardComponent from "../../components/create-booking-wizard";
import { updateContractDataReset } from "../../redux/actions/consultation-actions";
import { INITIAL_STATE } from "../../redux/reducers/consultation-reducer";
import { getConsultsOwnerThunk } from "../../redux/actions/consults-owner-actions";

function CreateBookingWizardContainer({ open = false, onClose }) {
  // step-setup, step-project-data, step-price, step-uses, step-contract-payment
  const searchBookingState = useSelector((state) => state.consultsOwner.bookingState);

  const [step, setStep] = useState(1);
  const [booking, setBooking] = useState({
    ...INITIAL_STATE.data,
    periodPrice: 0,
    startDate: "",
    endDate: "",
  });
  const dispatch = useDispatch();

  const handleNext = (data) => {
    setBooking({
      ...booking,
      ...data,
    });
    if (step >= 6) {
      dispatch(getConsultsOwnerThunk({ state: searchBookingState, page: 1 }));
      onClose();
    } else {
      setStep(step + 1);
    }
  };

  useEffect(() => {
    if (!open) {
      setTimeout(() => setStep(1), 500);
      setBooking({
        ...INITIAL_STATE.data,
        startDate: "",
        endDate: "",
      });
    }
    return () => {
      dispatch(updateContractDataReset());
    };
  }, [open]);

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  return (
    <CreateBookingWizardComponent
      open={open}
      booking={booking}
      step={step}
      onClose={onClose}
      onNext={handleNext}
      onBack={handleBack}
    />
  );
}

export default CreateBookingWizardContainer;
