import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
// theme
import { Trans } from "@lingui/macro";
import { Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";
import OwnerModal from "../owner-modal";
import TableInformation from "./table-information";
import TableContact from "./table-contact";
import SecondaryButton from "../secondary-button";

const useStyles = makeStyles((th) => ({
  tab: {
    "& .MuiTabs-root": {
      borderBottom: "0px solid white",
    },
    "& .MuiButtonBase-root": {
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },
    "& .MuiTab-wrapper": {
      alignItems: "flex-start",
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTab-root": {
      backgroundColor: th.palette.lightGray,
    },
    "& .Mui-selected": {
      backgroundColor: th.palette.black,
    },
    "& .Mui-selected .MuiTab-wrapper": {
      color: th.palette.lightGray,
    },
  },
  content: {
    border: th.borders[6],
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    minHeight: "350px",
    overflowY: "auto",
    padding: th.spacing(2),
  },
}));
export default ({
  open = false,
  onClose = () => {},
  title = "",
  status = "initial",
  selectedLandlord = { asset: [] },
}) => {
  const classes = useStyles({ ...theme });
  const [tab, setTab] = useState("TAB_INFORMATION");

  const TAB_INFORMATION = "TAB_INFORMATION";
  const TAB_CONTACT = "TAB_CONTACT";

  const handleChange = (event, changeTab) => {
    setTab(changeTab);
  };

  useEffect(() => {
    setTab(TAB_INFORMATION);
  }, []);

  return (
    <OwnerModal title={title} open={open} onClose={onClose}>
      <Box className={classes.tab}>
        <Tabs onChange={handleChange} variant="fullWidth" value={tab}>
          <Tab
            value={TAB_INFORMATION}
            label={
              <Typography variant="subtitle1">
                <Trans>Información del centro comercial</Trans>
              </Typography>
            }
          />
          <Tab
            value={TAB_CONTACT}
            label={
              <Typography variant="subtitle1">
                <Trans>Contacto</Trans>
              </Typography>
            }
          />
        </Tabs>
        <Box className={classes.content}>
          {tab === TAB_INFORMATION ? (
            <TableInformation status={status} selectedLandlord={selectedLandlord} />
          ) : (
            <TableContact status={status} selectedLandlord={selectedLandlord} />
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={1}>
        <SecondaryButton onClick={onClose}>
          <Trans>Volver</Trans>
        </SecondaryButton>
      </Box>
    </OwnerModal>
  );
};
