import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles({
  root: {
    width: "34px",
    height: "17px",
    borderRadius: "12px",
    padding: 0,
  },
  thumb: {
    width: "13px",
    height: "13px",
  },
  switchBase: {
    left: "-6px",
    top: "-2px",
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: theme.palette.primary.main,
      opacity: 1,
    },
  },
  checked: {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: theme.palette.white,
      transform: "unset",
      right: "-14px",
      left: "0px",
      top: "-2px",
    },
  },
  track: {
    backgroundColor: theme.palette.grayC4,
    opacity: 1,
  },
});

export default function CustomSwitch({ checked, onChange, name, isDisabled = false }) {
  const classes = useStyles(theme);

  return (
    <Switch
      disabled={isDisabled}
      className={classes.checked}
      classes={{
        root: classes.root,
        thumb: classes.thumb,
        switchBase: classes.switchBase,
        track: classes.track,
      }}
      checked={checked}
      onChange={onChange}
      name={name}
    />
  );
}
