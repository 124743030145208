import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { Trans } from "@lingui/macro";
import OwnerModal from "../../owner-modal";
import theme from "../../../theme-admin";
import CheckIcon from "../../../icons/success-circle-icon";

const ConfirmationModal = ({ open, onGoToNewSpace, title }) => (
  <OwnerModal width={450} open={open} onClose={onGoToNewSpace} title="">
    <Box display="flex" flexDirection="column" alignItems="center" width={400}>
      <CheckIcon fill={theme.palette.successGreen} width={56} height={56} />
      <Box mb={3} textAlign="center">
        <Typography variant="subtitle1" style={{ color: theme.palette.successGreen }}>
          <Trans>El espacio</Trans>
          <span style={{ marginLeft: 3, marginRight: 3 }}> "{title}" </span>
          <Trans>ha sido creado exitosamente.</Trans>
        </Typography>
      </Box>
      <Button variant="contained" color="primary" style={{ width: 150 }} onClick={onGoToNewSpace}>
        <Trans>Aceptar</Trans>
      </Button>
    </Box>
  </OwnerModal>
);
export default ConfirmationModal;
