import React from "react";
import numbro from "numbro";
import { Box, Typography } from "@material-ui/core";
import { formatDateUtc } from "../../utils/dateUtils";
import { makeStyles } from "@material-ui/core/styles";
import theme from "./../../theme";
import { Trans } from "@lingui/macro";
import BackLongIcon from "../../icons/back-long-icon";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.white,
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    borderRadius: 10,
    margin: -10,
    boxShadow: "0px 2px 11px rgba(0, 0, 0, 0.25)",
  },
}));

const getBlockDates = (block) => (
  <Box display="flex" flexDirection="row" alignItems="center">
    {formatDateUtc(block.start, "DD/MM/YYYY")}
    <BackLongIcon
      style={{
        transform: "rotate(180deg)",
        width: 12,
        height: 12,
        marginLeft: theme.spacing(1 / 2),
        marginRight: theme.spacing(1 / 2),
      }}
    />
    {formatDateUtc(block.end, "DD/MM/YYYY")}
  </Box>
);

const BlockTooltip = ({ block }) => {
  const classes = useStyles(theme);

  return block.blocked_date_ranges_id ? (
    <Box className={classes.tooltip}>
      <Typography variant={"subtitle1"}>
        <Trans>Bloqueo</Trans>
      </Typography>
      <Typography variant={"body1"}>
        <Trans>Motivo:</Trans>
        <b style={{ marginLeft: 3 }}>{block.description}</b>
      </Typography>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography variant="body1">
          <Trans>Fecha:</Trans>
        </Typography>
        <Typography variant="body1" style={{ marginLeft: theme.spacing(1 / 2) }}>
          <b>{getBlockDates(block)}</b>
        </Typography>
      </Box>
    </Box>
  ) : (
    <Box className={classes.tooltip}>
      <Typography variant="subtitle1">{`#${block.booking_id}`}</Typography>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography variant="body1">
          <Trans>Cliente:</Trans>
        </Typography>
        <Typography variant="body1" style={{ marginLeft: theme.spacing(1 / 2) }}>
          <b>{`${block.brand_name}`}</b>
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography variant="body1">
          <Trans>Fecha:</Trans>
        </Typography>
        <Typography variant="body1" style={{ marginLeft: theme.spacing(1 / 2) }}>
          <b>{getBlockDates(block)}</b>
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography variant="body1">
          <Trans>Monto:</Trans>
        </Typography>
        <Typography variant="body1" style={{ marginLeft: theme.spacing(1 / 2) }}>
          <b>{numbro(parseFloat(block.price)).formatCurrency()}</b>
        </Typography>
      </Box>
    </Box>
  );
};

export default BlockTooltip;
