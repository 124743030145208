import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CalendarStarIcon = ({ fill = "#333333", width = "11px", height = "11px", ...rest }) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 11 11" fill="none" {...rest}>
      <path
        d="M2.53328 6.70902L3.75086 7.57505L3.25833 9.07493C3.20716 9.22878 3.26111 9.39829 3.39234 9.49471C3.52287 9.59044 3.70109 9.59218 3.83336 9.49819L5.14214 8.56569L6.45162 9.49819C6.51671 9.54414 6.59294 9.56816 6.66917 9.56816C6.74784 9.56816 6.82546 9.54379 6.89194 9.49471C7.02282 9.39864 7.07677 9.22878 7.0263 9.07493L6.53307 7.57505L7.75031 6.70902C7.88397 6.61435 7.94036 6.44379 7.89058 6.28785C7.84081 6.13226 7.69566 6.02609 7.53206 6.02609H6.02452L5.49857 4.42562C5.4481 4.27072 5.304 4.16699 5.14179 4.16699C4.97924 4.16699 4.83513 4.27142 4.78466 4.42562L4.25871 6.02609H2.75117C2.58723 6.02609 2.44278 6.13226 2.39265 6.28785C2.34322 6.44414 2.39996 6.61435 2.53328 6.70902ZM4.53161 6.77829C4.69486 6.77829 4.83827 6.67387 4.88839 6.51967L5.14214 5.74728L5.39554 6.52002C5.44706 6.67456 5.59116 6.77864 5.75372 6.77864H6.35694L5.87276 7.12324C5.7384 7.21896 5.68271 7.39057 5.73423 7.54755L5.9556 8.22178L5.36073 7.79852C5.2953 7.75222 5.21941 7.72855 5.14249 7.72855C5.06626 7.72855 4.99003 7.75187 4.92459 7.79852L4.33007 8.22178L4.5518 7.54755C4.60296 7.39057 4.54623 7.21931 4.41222 7.12324L3.92838 6.77864H4.53161V6.77829Z"
        fill={fill}
      />
      <path
        d="M1.91862 0.000696152V0.762296H0V3.64301V10.9315H8.94985C9.68534 10.9315 10.284 10.3321 10.284 9.59554V2.89185V0.761948H8.36577V0H7.61426V0.761948H5.51847V0H4.76592V0.761948H2.66978V0H1.91862V0.000696152ZM9.53254 9.59588C9.53254 9.91855 9.27113 10.1803 8.94985 10.1803H0.752202V3.64336H9.53288V9.59588H9.53254ZM4.76592 1.5138V2.05542H5.51847V1.5138H7.61426V2.05542H8.36577V1.5138H9.53219V2.89081H0.752202V1.5138H1.91897V2.05542H2.67013V1.5138H4.76592Z"
        fill={fill}
      />
    </SvgIcon>
  );
};
export default CalendarStarIcon;
