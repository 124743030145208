import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setDashboardTitle } from "../../redux/actions/misc-actions";
import HomeOwnerComponent from "../../components/home-owner";
import { resetAllAdSpaces } from "../../redux/actions/adspaces-actions";
import { resetAllSpaces } from "../../redux/actions/spaces-owner-actions";

const HomeOwner = () => {
  const dispatch = useDispatch();

  const bookingConfirmedNews = useSelector((state) => state.user.bookingConfirmedNews);
  const bookingProgressNews = useSelector((state) => state.user.bookingProgressNews);

  useEffect(() => {
    dispatch(setDashboardTitle("Dashboard"));
    dispatch(resetAllAdSpaces());
    dispatch(resetAllSpaces());
  }, []);

  return <HomeOwnerComponent bookingProgressNews={bookingProgressNews} bookingConfirmedNews={bookingConfirmedNews} />;
};

export default HomeOwner;
