import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPendingTransactionsThunk } from "../../redux/actions/pending-transactions-actions";
import { formatDateUtc } from "../../utils/dateUtils";
import PendingTransactionsComponent from "../../components/pending-transactions";
import ConfirmTransaction from "../../components/pending-transactions/ConfirmTransaction";
import api from "../../api";

const PendingTransactionsContainer = ({ open = false, onClose }) => {
  const dispatch = useDispatch();
  const currentDate = new Date();
  const nextMonthDate = new Date(currentDate);
  nextMonthDate.setMonth(currentDate.getMonth() + 1);

  const [dates, setDates] = useState({ start: currentDate, end: nextMonthDate });
  const [confirmTransaction, setConfirmTransaction] = useState({
    status: "initial",
  });
  const [updateTransaction, setUpdateTransaction] = useState({
    status: "initial",
  });
  const [deleteTransaction, setDeleteTransaction] = useState({
    status: "initial",
  });
  const [transactionToConfirm, setTransactionToConfirm] = useState({ booking: { asset: {} } });
  const [sort, setSort] = useState();

  const permissions = useSelector((state) => state.user.data.permissions);
  const transactions = useSelector((state) => state.pendingTransactions.transactions);
  const pagination = useSelector((state) => state.pendingTransactions.pagination);
  const status = useSelector((state) => state.pendingTransactions.status);

  const handleDatesChange = ({ start, end }) => {
    setDates({ start, end });
  };

  const handleTableChange = (action, tableState) => {
    switch (action) {
      case "sort":
        setSort(tableState.sortOrder);
        break;
      default:
        console.log("Action not handled.");
    }
  };

  const handleChangePage = (evt, page) => {
    dispatch(getPendingTransactionsThunk({ start: dates.start, end: dates.end, sort, page }));
  };

  const handleUpdateTransaction = async (transaction) => {
    setUpdateTransaction({ status: "loading" });
    const res = await api.editBookingTransaction(transaction.id, transaction);
    if (res.status === 200) {
      setUpdateTransaction({ data: transaction, status: "success" });
      dispatch(getPendingTransactionsThunk({ start: dates.start, end: dates.end, sort, page: 1 }));
    } else {
      setUpdateTransaction({ data: {}, error: res.data, status: "error" });
    }
  };

  const handleDeleteTransaction = async (id) => {
    setDeleteTransaction({ status: "loading" });
    const res = await api.deleteBookingTransaction(id);
    if (res.status === 200) {
      setDeleteTransaction({ status: "success" });
      dispatch(getPendingTransactionsThunk({ start: dates.start, end: dates.end, sort, page: 1 }));
    } else {
      setDeleteTransaction({ data: {}, error: res.data, status: "error" });
    }
  };

  const handleConfirmTransaction = async (dueDate) => {
    setConfirmTransaction({ status: "loading" });
    const res = await api.createInvoiceFromTransaction(transactionToConfirm.id, {
      module: transactionToConfirm.booking.asset.module,
      due_date: formatDateUtc(dueDate),
    });
    if (res.status === 200) {
      await new Promise((resolve) => {
        setTimeout(resolve, 2200);
      });
      setConfirmTransaction({ status: "success" });
      dispatch(getPendingTransactionsThunk({ start: dates.start, end: dates.end, sort, page: 1 }));
    } else {
      setConfirmTransaction({ status: "error" });
    }
  };

  const resetStates = () => {
    setConfirmTransaction({ status: "initial" });
    setUpdateTransaction({ status: "initial" });
    setDeleteTransaction({ status: "initial" });
  };

  useEffect(() => {
    if (open) {
      dispatch(getPendingTransactionsThunk({ start: dates.start, end: dates.end, sort, page: 1 }));
    }
  }, [dispatch, open, dates, sort]);

  return (
    <>
      <ConfirmTransaction
        module={transactionToConfirm.booking.asset.module}
        transaction={transactionToConfirm}
        confirmTransaction={confirmTransaction}
        onConfirmTransaction={handleConfirmTransaction}
        onResetStates={resetStates}
      />

      <PendingTransactionsComponent
        open={open}
        permissions={permissions}
        transactions={transactions}
        status={status}
        filters={dates}
        pagination={pagination}
        confirmTransaction={confirmTransaction}
        updateTransaction={updateTransaction}
        deleteTransaction={deleteTransaction}
        onFilterDatesChange={handleDatesChange}
        onTableChange={handleTableChange}
        onChangePage={handleChangePage}
        onConfirmTransaction={(c) => setTransactionToConfirm({ ...c })}
        onUpdateTransaction={handleUpdateTransaction}
        onDeleteTransaction={handleDeleteTransaction}
        onResetStates={resetStates}
        onClose={onClose}
      />
    </>
  );
};

export default PendingTransactionsContainer;
