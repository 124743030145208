import React from "react";
import { Box, Button } from "@material-ui/core";
import SecondaryButton from "../secondary-button";
import { Trans } from "@lingui/macro";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "sticky",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    bottom: "0px",
    boxShadow: "0px -4px 11px rgba(0, 0, 0, 0.25)",
    backgroundColor: theme.palette.white,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(4),
  },
  backButton: {
    marginRight: theme.spacing(1),
    width: 150,
    height: 42,
  },
  continueButton: {
    width: 114,
    height: 34,
  },
}));

function StepButtons({
  continueButtonLabel,
  backButtonLabel,
  handleContinue,
  handleBack,
  disableBack = false,
  disableContinue = false,
  stepButtonComponent,
}) {
  const classes = useStyles(theme);

  const { i18n } = useLingui();

  return (
    <Box
      className={classes.container}
      style={stepButtonComponent ? { justifyContent: "space-between", paddingLeft: theme.spacing(1) } : {}}
    >
      <Box>{stepButtonComponent && <>{stepButtonComponent}</>}</Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box>
          {handleBack && (
            <SecondaryButton disabled={disableBack} onClick={handleBack}>
              {backButtonLabel ? <>{backButtonLabel}</> : <Trans>Atrás</Trans>}
            </SecondaryButton>
          )}
        </Box>
        <Box justifySelf="flex-end">
          {handleContinue && (
            <Button
              onClick={handleContinue}
              disabled={disableContinue}
              type="submit"
              variant="contained"
              color="primary"
              className={classes.continueButton}
            >
              {continueButtonLabel ? <>{continueButtonLabel}</> : <Trans>Continuar</Trans>}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default StepButtons;
