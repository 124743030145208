import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "@reach/router";
import { useNavigate } from "/src/hooks/navigation";
import { t } from "@lingui/macro";
import { setDashboardTitle, setDashboardTitleComponent } from "../../redux/actions/misc-actions";
import api from "../../api";
import theme from "../../theme";

import CustomersList from "./customer-list";
import AtachmentList from "./attachment-list";
import RatingList from "./rating-list";
import NotesList from "./note-list";
import BigStartComponent from "../../components/customers-owner/rating-list/big-start";
import BookingByBrandList from "./booking-by-brand-list";
import MoreInfomation from "./more-information";
import DrawerConsultationComponent from "../../components/drawer-consultation";
import CustomTabs from "../../components/custom-tabs";
import LayoutDashboard from "../../components/layout-dashboard";

const TabComponent = ({ stepName, onGoNext, onGoBack }) => {
  const params = useParams();

  switch (stepName) {
    case "users":
      return <CustomersList custumerId={params.custumerId} onGoNext={onGoNext} onGoBack={onGoBack} />;
    case "attachments":
      return <AtachmentList custumerId={params.custumerId} onGoNext={onGoNext} onGoBack={onGoBack} />;
    case "ratings":
      return <RatingList custumerId={params.custumerId} onGoNext={onGoNext} onGoBack={onGoBack} />;
    case "notes":
      return <NotesList custumerId={params.custumerId} onGoNext={onGoNext} onGoBack={onGoBack} />;
    case "bookings-by-brand":
      return <BookingByBrandList custumerId={params.custumerId} onGoNext={onGoNext} onGoBack={onGoBack} />;
    case "other-information":
      return <MoreInfomation custumerId={params.custumerId} onGoNext={onGoNext} onGoBack={onGoBack} />;
    default:
      return <></>;
  }
};

const CustemersOwnerDetail = () => {
  const { navigate } = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const bookingIdByParam = params.booking;
  const { custumerId } = params;
  const { stepName } = params;

  const STEPS = ["users", "attachments", "ratings", "notes", "bookings-by-brand", "other-information"];

  // translations
  t({ id: "users", message: "Usuarios" });
  t({ id: "attachments", message: "Adjuntos" });
  t({ id: "ratings", message: "Valoraciones" });
  t({ id: "notes", message: "Notas" });
  t({ id: "bookings-by-brand", message: "Reservas por marca" });
  t({ id: "other-information", message: "Otra información" });

  const [activeStep, setActiveStep] = useState(0);

  const handleGoBackToCustemerList = () => {
    navigate("/administrator/clients");
  };

  const handleStepClick = (step) => navigate(`/administrator/clients/${custumerId}/${step.replaceAll(" ", "-")}/all`);

  const getAvgTotalRating = (companyAverageRatings) => {
    let count = 0;
    let avg = 0;

    Object.keys(companyAverageRatings).forEach((rc) => {
      count++;
      avg += companyAverageRatings[rc];
    });

    return Number(avg / count).toFixed(1);
  };

  const getCompany = async () => {
    const company = await api.getCompany({ id: custumerId });
    const companyRatings = await api.getCompanyRatings({
      companyId: custumerId,
    });
    if (!company.message && !companyRatings.message) {
      const ratingNumber = getAvgTotalRating(companyRatings.data?.payload?.average || []);
      dispatch(setDashboardTitle(`${t`Cliente`}: ${company.data?.payload.name}`));
      const renderStar =
        getAvgTotalRating(companyRatings.data?.payload?.average || []) >= 0 ? (
          <BigStartComponent ratingNumber={ratingNumber} />
        ) : (
          <></>
        );
      dispatch(setDashboardTitleComponent(renderStar));
    }
  };

  useEffect(() => {
    dispatch(setDashboardTitle(t`Cargando...`));
    if (custumerId && STEPS.indexOf(stepName) >= 0) {
      getCompany();
    }
  }, [custumerId]);

  useEffect(() => {
    const activeStepByParam = STEPS.indexOf(stepName);
    if (activeStepByParam < 0) {
      handleStepClick(STEPS[0]);
      return;
    }
    setActiveStep(activeStepByParam);
  }, [stepName]);

  const goNext = () => handleStepClick(STEPS[STEPS.indexOf(stepName) + 1]);

  const goBack = () => handleStepClick(STEPS[STEPS.indexOf(stepName) - 1]);

  const handleClose = () => handleStepClick(STEPS[activeStep]);

  useEffect(() => {
    return () => dispatch(setDashboardTitleComponent(null));
  }, []);

  return (
    <>
      <CustomTabs steps={STEPS} activeStep={STEPS[activeStep]} onStepClick={handleStepClick}>
        <TabComponent stepName={STEPS[activeStep]} onGoBack={goBack} onGoNext={goNext} />
      </CustomTabs>
      <DrawerConsultationComponent isOpen={!!parseInt(bookingIdByParam, 10)} onClose={handleClose} />
    </>
  );
};

export default CustemersOwnerDetail;
