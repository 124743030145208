import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Paper, Popover } from "@material-ui/core";
import InfoIcon from "../../icons/info-icon";
import { Trans } from "@lingui/macro";
import theme from "../../theme";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(3 / 2),
    margin: theme.spacing(1),
    width: "auto",
    overflow: "auto",
  },
  unavailable: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    textAlign: "center",
  },
  info: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginLeft: theme.spacing(1),
  },
}));

function Widget({
  title,
  period,
  info,
  children,
  width = "40%",
  height = 300,
  unavailable,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Paper
      className={classes.container}
      style={{ height, width }}
      elevation={3}
    >
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box p={3}>{info}</Box>
      </Popover>
      <Box display="flex" alignItems="center" flexDirection="row">
        <Typography variant="subtitle1">{title}</Typography>
        <Box onClick={handleClick} className={classes.info}>
          <InfoIcon
            fill={open ? theme.palette.primary.main : theme.palette.black}
          />
        </Box>
      </Box>
      <Typography variant="body1">{period}</Typography>
      {unavailable ? (
        <Box className={classes.unavailable}>
          <Typography variant="body1">
            <Trans>información no disponible</Trans>
          </Typography>
        </Box>
      ) : (
        children
      )}
    </Paper>
  );
}

export default Widget;
