import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "/src/hooks/navigation";
import { Trans } from "@lingui/macro";
import Box from "@material-ui/core/Box";
import MoreInformationComponent from "../../../components/customers-owner/more-information";
import StepButtons from "../../../components/step-buttons";
import LayoutDashboard from "../../../components/layout-dashboard";
import BackBtn from "../../../components/customers-owner/back-btn";
import api from "../../../api";

const MoreInfomation = ({ custumerId }) => {
  const [companyStatus, setCompanyStatus] = useState({ status: "initial" });
  const [company, setCompany] = useState("");
  const [updatedCompany, setUpdatedCompany] = useState("");
  const flagAdditionalCompanyFields = useSelector(
    (state) => state.misc.featureFlags["flag.ops.allow_company_meta_data"],
  );
  const { navigate } = useNavigate();

  const customerSearch = useSelector((state) => state.companies.search);

  const valueHasChanged = JSON.stringify(company) !== JSON.stringify(updatedCompany);

  const getInfoDirection = async () => {
    setCompanyStatus({ status: "loading", type: "render-page" });
    const companyById = await api.getCompany({ id: custumerId });

    if (companyById.message) {
      setCompanyStatus({ status: "error", type: "render-page" });
    } else {
      setCompanyStatus({ status: "success", type: "render-page" });
      setCompany(companyById.data?.payload);
      setUpdatedCompany(companyById.data?.payload);
    }
  };

  const handleContinue = async () => {
    setCompanyStatus({ status: "loading", type: "save-form" });
    const updateDateAttachment = await api.patchCompany(custumerId, updatedCompany);

    if (updateDateAttachment.message) {
      setCompanyStatus({ status: "error", type: "save-form" });
    } else {
      setCompanyStatus({ status: "success", type: "save-form" });
      setCompany(updateDateAttachment.data.payload);
      setUpdatedCompany(updateDateAttachment.data.payload);
    }
    setTimeout(() => {
      setCompanyStatus({ status: "initial", type: "save-form" });
    }, 2000);
  };

  const handleBack = () => {
    navigate(customerSearch ? `/administrator/clients?search=${customerSearch}` : "/administrator/clients");
  };

  useEffect(() => {
    getInfoDirection();
  }, []);

  return (
    <>
      <LayoutDashboard>
        <MoreInformationComponent
          data={company}
          status={companyStatus}
          additionalCompanyFields={flagAdditionalCompanyFields}
          onSave={(data) => setUpdatedCompany(data)}
        />
      </LayoutDashboard>
      <StepButtons
        disableContinue={(companyStatus.status === "loading" && companyStatus.type === "save-form") || !valueHasChanged}
        continueButtonLabel={<Trans>Guardar</Trans>}
        handleContinue={handleContinue}
        stepButtonComponent={
          <Box display="flex" flexDirection="row">
            <BackBtn onBack={handleBack} />
          </Box>
        }
      />
    </>
  );
};

export default MoreInfomation;
