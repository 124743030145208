import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "/src/hooks/navigation";
import Box from "@material-ui/core/Box";
import BookingByBrandListComponent from "../../../components/customers-owner/booking-by-brand-list";
import StepButtons from "../../../components/step-buttons";
import LayoutDashboard from "../../../components/layout-dashboard";
import BackBtn from "../../../components/customers-owner/back-btn";
import api from "../../../api";

const BookingByBrandList = ({ custumerId }) => {
  const [bookingByBrand, setBookingByBrand] = useState({});
  const [bookingByBrandStatus, setBookingByBrandStatus] = useState("initial");
  const { navigate } = useNavigate();

  const customerSearch = useSelector((state) => state.companies.search);

  const getBookingByBrand = async () => {
    setBookingByBrandStatus("loading");
    const res = await api.getCompanyByBrand({
      companyId: custumerId,
    });
    if (res.status === 200) {
      setBookingByBrand(res.data?.payload || {});
      setBookingByBrandStatus("success");
    } else {
      setBookingByBrandStatus("error");
    }
  };

  const handleBack = () => {
    navigate(customerSearch ? `/administrator/clients?search=${customerSearch}` : "/administrator/clients");
  };

  useEffect(() => {
    getBookingByBrand();
  }, []);

  return (
    <>
      <LayoutDashboard>
        <BookingByBrandListComponent
          custumerId={custumerId}
          bookingByBrand={bookingByBrand}
          bookingByBrandStatus={bookingByBrandStatus}
        />
      </LayoutDashboard>
      <StepButtons
        stepButtonComponent={
          <Box display="flex" flexDirection="row">
            <BackBtn onBack={handleBack} />
          </Box>
        }
      />
    </>
  );
};

export default BookingByBrandList;
