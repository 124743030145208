import React, { useEffect } from "react";
import AdSpaceToolbar from "../../components/ad-spaces-owner/adspace-toolbar";
import { useDispatch, useSelector } from "react-redux";
import {
  setAdSpacesFilters,
  setSearchWords,
} from "../../redux/actions/adspaces-actions";
import {
  getSpaceTypesThunk,
  getCitiesAreasThunk,
  getCountriesThunk,
} from "../../redux/actions/misc-actions";

const AdSpacesToolbar = ({ onRefresh }) => {
  const assets = useSelector((state) => state.misc.assets);
  const cities = useSelector((state) => state.misc.cities);
  const countries = useSelector((state) => state.misc.countries);

  const filters = useSelector((state) => state.adspaces.filters);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCitiesAreasThunk());
    dispatch(getSpaceTypesThunk());
    dispatch(getCountriesThunk());
    getAdspaceFilters();
  }, []);

  const getAdspaceFilters = () => {
    const assets = JSON.parse(localStorage.getItem("adspaceAssets")) || [];
    const cities = JSON.parse(localStorage.getItem("adspaceCities")) || [];

    dispatch(setAdSpacesFilters({ assets, cities }));
  };

  const handleFilterSpaces = (filters) => {
    dispatch(setAdSpacesFilters(filters));
  };

  const handleSearch = (words) => {
    dispatch(setSearchWords(words));
  };

  return (
    <AdSpaceToolbar
      onRefresh={onRefresh}
      assets={assets}
      cities={cities}
      countries={countries}
      filters={filters}
      handleSearch={handleSearch}
      handleFilterSpaces={handleFilterSpaces}
    />
  );
};

export default AdSpacesToolbar;
