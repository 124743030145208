import React from "react";
import { Trans } from "@lingui/macro";
import { Box } from "@material-ui/core";
import { AddSharp } from "@material-ui/icons";
import SecondaryButton from "../../secondary-button";

const InviteCustomerBtn = ({ onOpenInvitation }) => {
  return (
    <Box display="flex" alignItems="center" width="100%" whiteSpace="nowrap">
      <SecondaryButton onClick={onOpenInvitation}>
        <Trans>Invitar usuario</Trans>
        <AddSharp />
      </SecondaryButton>
    </Box>
  );
};

export default InviteCustomerBtn;
