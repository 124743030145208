import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { Trans } from "@lingui/macro";
import { useNavigate } from "../../../hooks/navigation";
import OwnerModal from "../../owner-modal";
import theme from "../../../theme-admin";
import CheckIcon from "../../../icons/success-circle-icon";

const ConfirmationModal = ({ modalData }) => {
  const { navigate } = useNavigate();
  const handleGonToAdsPace = () => {
    navigate(`/administrator/adspaces/${modalData.adSpaceId}/information`);
  };
  return (
    <OwnerModal width={450} open={modalData.status} onClose={handleGonToAdsPace} title="">
      <Box display="flex" flexDirection="column" alignItems="center" width={400}>
        <CheckIcon fill={theme.palette.successGreen} width={56} height={56} />
        <Box mb={3} textAlign="center">
          <Typography variant="subtitle1" style={{ color: theme.palette.successGreen }}>
            <Trans>El espacio publicitario</Trans>
            <span style={{ marginLeft: 3, marginRight: 3 }}> "{modalData.title}" </span>
            <Trans>ha sido creado exitosamente.</Trans>
          </Typography>
        </Box>
        <Button variant="contained" color="primary" style={{ width: 150 }} onClick={handleGonToAdsPace}>
          <Trans>Aceptar</Trans>
        </Button>
      </Box>
    </OwnerModal>
  );
};
export default ConfirmationModal;
