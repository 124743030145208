import { Button, Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ChevronRightSharp } from "@material-ui/icons";
import React from "react";
import theme from "../../theme";
import ChatConsultationContainer from "../../containers/chat-consultation";

const useStyles = makeStyles((th) => ({
  modal: {
    position: "inherit !important",
  },
  paper: {
    width: "790px",
    display: "flex",
    flexDirection: "row",
    backgroundColor: th.palette.lightGray,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  button: {
    maxWidth: 15,
    minWidth: 15,
    flex: 1,
    padding: 10,
    height: "100%",
    fontSize: "1.5rem",
    alignItems: "flex-start",
    backgroundColor: th.palette.gray,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

const DrawerConsultationComponent = ({ isOpen, onClose }) => {
  const classes = useStyles(theme);

  return (
    <Drawer anchor="right" open={isOpen} hideBackdrop classes={{ paper: classes.paper, modal: classes.modal }}>
      <Button color="secondary" variant="contained" onClick={onClose} className={classes.button}>
        <ChevronRightSharp fill="white" />
      </Button>
      <ChatConsultationContainer />
    </Drawer>
  );
};

export default DrawerConsultationComponent;
