import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "/src/hooks/navigation";
import { Box } from "@material-ui/core";
import AtachmentListComponent from "../../../components/customers-owner/attachment-list";
import StepButtons from "../../../components/step-buttons";
import AddAttachmentBtn from "../../../components/customers-owner/attachment-list/add-file-btn";
import { formatDateUtc } from "../../../utils/dateUtils";
import { userCanWrite } from "../../../utils";
import LayoutDashboard from "../../../components/layout-dashboard";
import BackBtn from "../../../components/customers-owner/back-btn";
import api from "../../../api";

const AtachmentList = ({ custumerId }) => {
  const [filesByClientStatus, setFilesByClientStatus] = useState("initial");
  const [fileUploadStatus, setFileUploadStatus] = useState({ status: "initial" });
  const [deleteFileStatus, setDeleteFileStatus] = useState("initial");
  const [filesByClient, setfilesByClient] = useState([]);
  const [company, setCompany] = useState("");
  const [updateExpirationDateStatus, setUpdateExpirationDateStatus] = useState("initial");

  const { navigate } = useNavigate();
  const customerSearch = useSelector((state) => state.companies.search);
  const permissions = useSelector((state) => state.user.data.permissions);

  const getFilesByClient = async () => {
    setFilesByClientStatus("loading");
    const res = await api.getAttachmentByClient("company", custumerId);
    if (res.message) {
      setFilesByClientStatus("error");
    } else {
      setFilesByClientStatus("success");
      setfilesByClient(res.data?.payload || []);
    }
  };

  const handleOnUpdateFile = async (statusUpdated) => {
    if (statusUpdated.status === 200) {
      setFileUploadStatus({ status: "initial" });
      getFilesByClient();
    } else {
      setFileUploadStatus({ status: "error", error: statusUpdated.data?.data?.message });
    }
  };

  const handleOnStartUpdateFile = () => setFileUploadStatus({ status: "loading" });

  const handleOnErrorUpdateFile = () => setFileUploadStatus({ status: "error" });

  const deleteFileByClient = async (attachment) => {
    setDeleteFileStatus("loading");
    const resDelete = await api.deleteAttachment(attachment.id);
    if (resDelete.status === 200) {
      getFilesByClient();
      setDeleteFileStatus("success");
      return;
    }
    setDeleteFileStatus("error");
  };

  const getCompany = async () => {
    // setCustomerStatus("loading");
    getFilesByClient();
    const cpmny = await api.getCompany({ id: custumerId });

    if (cpmny.message) {
      // setCustomerStatus("error");
    } else {
      // setCustomerStatus("success");
      setCompany(cpmny.data?.payload);
    }
  };
  const updateExpirationDate = async (id, date) => {
    setUpdateExpirationDateStatus("loading");
    const updateDateAttachment = await api.updateExpirationDateAttachment(id, formatDateUtc(date, "YYYY-MM-DD"));

    if (updateDateAttachment.message) {
      setUpdateExpirationDateStatus("error");
    } else {
      setUpdateExpirationDateStatus("success");
      const updateDate = filesByClient.map((f) => ({ ...f, expire_at: id === f.id ? date : f.expire_at }));
      setfilesByClient(updateDate);
    }
    setTimeout(() => {
      setUpdateExpirationDateStatus("initial");
    }, 2000);
  };

  const handleBack = () => {
    navigate(customerSearch ? `/administrator/clients?search=${customerSearch}` : "/administrator/clients");
  };

  useEffect(() => {
    getCompany();
  }, []);

  return (
    <>
      <LayoutDashboard>
        <AtachmentListComponent
          fileUploadStatus={fileUploadStatus}
          filesByClient={filesByClient}
          filesByClientStatus={filesByClientStatus}
          deleteFileByClient={deleteFileByClient}
          deleteFileStatus={deleteFileStatus}
          onUpdateExpirationDate={updateExpirationDate}
          updateExpirationDateStatus={updateExpirationDateStatus}
          canEdit={userCanWrite(permissions)}
        />
      </LayoutDashboard>
      <StepButtons
        stepButtonComponent={
          <Box display="flex" flexDirection="row">
            <BackBtn onBack={handleBack} />
            {userCanWrite(permissions) ? (
              <AddAttachmentBtn
                onStartUpdateFile={handleOnStartUpdateFile}
                onUpdateFile={handleOnUpdateFile}
                onErrorUpdateFile={handleOnErrorUpdateFile}
                company={company}
              />
            ) : (
              <></>
            )}
          </Box>
        }
      />
    </>
  );
};

export default AtachmentList;
