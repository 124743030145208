import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MoneyIcon({ fill = "black", ...rest }) {
  return (
    <SvgIcon width="34" height="34" viewBox="0 0 100 100" fill="none" {...rest}><g>
	<g>
	<path fill={fill} d="M70.121,41.26c-2.339-3.111-5.112-5.817-8.236-7.847c0.593-1.073,1.668-3.062,2.754-5.293
		c0.807-1.661,1.619-3.449,2.247-5.122c0.313-0.838,0.582-1.646,0.78-2.416c0.195-0.772,0.331-1.496,0.334-2.249
		c-0.001-0.377-0.034-0.768-0.156-1.193c-0.121-0.419-0.347-0.892-0.749-1.296c-0.504-0.499-0.935-1.018-1.515-1.547
		c-0.294-0.262-0.635-0.533-1.082-0.758c-0.441-0.225-1.005-0.384-1.582-0.381c-0.445,0-0.881,0.087-1.279,0.229
		c-0.6,0.214-1.128,0.536-1.667,0.939c-0.538,0.406-1.089,0.902-1.704,1.518c-0.262,0.26-0.477,0.391-0.662,0.47
		c-0.188,0.077-0.354,0.108-0.556,0.109c-0.312,0.002-0.718-0.096-1.163-0.306c-0.667-0.308-1.378-0.856-1.882-1.326
		c-0.253-0.234-0.458-0.447-0.594-0.595l-0.149-0.167l-0.031-0.037l-0.001-0.13L52.627,13h-4.575l-0.597,0.858l0,0
		c-0.018,0.024-0.611,0.788-1.42,1.414c-0.401,0.313-0.854,0.639-1.282,0.833c-0.43,0.197-0.819,0.304-1.121,0.302
		c-0.201-0.001-0.369-0.024-0.556-0.102c-0.186-0.079-0.4-0.206-0.662-0.465c-0.822-0.82-1.524-1.429-2.243-1.891
		c-0.36-0.23-0.729-0.421-1.129-0.563c-0.398-0.142-0.834-0.229-1.278-0.228c-0.578-0.003-1.142,0.156-1.583,0.381
		c-0.67,0.342-1.103,0.766-1.492,1.153c-0.387,0.393-0.729,0.778-1.103,1.152c-0.403,0.403-0.629,0.876-0.75,1.296
		c-0.122,0.425-0.156,0.816-0.157,1.193c0.004,0.752,0.139,1.476,0.335,2.249c0.347,1.343,0.907,2.821,1.554,4.333
		c1.364,3.177,3.12,6.479,4.05,8.175c-5.021,3.108-9.164,7.962-12.103,13.465c-2.96,5.563-4.703,11.798-4.708,17.702
		c-0.002,3.936,0.791,7.387,2.273,10.314c2.223,4.4,5.971,7.518,10.479,9.463c4.517,1.953,9.813,2.803,15.44,2.807
		c3.753-0.002,7.355-0.383,10.681-1.211c4.979-1.244,9.372-3.514,12.536-7.082c1.579-1.781,2.835-3.879,3.684-6.27
		c0.85-2.393,1.293-5.07,1.293-8.021C78.179,56.389,75.107,47.919,70.121,41.26z M36.86,19.479c-0.12-0.478-0.172-0.866-0.179-1.082
		c0.488-0.506,0.861-0.923,1.092-1.125l0.065-0.054c0.089,0.046,0.216,0.121,0.377,0.239c0.331,0.238,0.794,0.638,1.37,1.215
		c0.583,0.585,1.242,1.037,1.94,1.329c0.696,0.294,1.423,0.423,2.105,0.422c1.078-0.002,2.037-0.3,2.859-0.683
		c1.233-0.582,2.201-1.498,2.9-2.141c0.182-0.169,0.343-0.6,0.485-0.6h0.927c0.323,1,0.75,0.86,1.272,1.277
		c0.587,0.465,1.289,1.011,2.112,1.398c0.821,0.383,1.78,0.713,2.859,0.715c0.683,0.001,1.408-0.112,2.105-0.406
		c0.697-0.292,1.356-0.736,1.94-1.321c0.701-0.704,1.238-1.138,1.567-1.345c0.073-0.047,0.131-0.077,0.181-0.103
		c0.087,0.073,0.207,0.184,0.355,0.339c0.211,0.215,0.486,0.514,0.8,0.838c-0.006,0.232-0.066,0.663-0.205,1.192
		c-0.258,1.009-0.756,2.355-1.357,3.754c-0.901,2.104-2.03,4.352-2.93,6.059c-0.45,0.854-0.843,1.575-1.121,2.08
		c-0.005,0.008-0.008,0.014-0.013,0.022c-2.622-1.157-5.437-1.827-8.371-1.826c-2.73,0-5.354,0.584-7.819,1.595
		c-0.648-1.179-1.767-3.267-2.832-5.501c-0.721-1.509-1.414-3.085-1.911-4.452C37.188,20.635,36.99,20.004,36.86,19.479z
		 M72.351,72.764c-1.755,3.453-4.647,5.922-8.501,7.602c-3.844,1.668-8.627,2.479-13.85,2.477c-3.48,0-6.769-0.357-9.711-1.092
		c-4.422-1.105-8.028-3.047-10.512-5.854c-1.246-1.408-2.227-3.039-2.908-4.957s-1.063-4.127-1.063-6.682
		c-0.015-6.811,2.777-14.634,7.271-20.596c2.24-2.985,4.893-5.504,7.767-7.252c2.879-1.75,5.957-2.734,9.156-2.735
		c3.199,0.001,6.277,0.985,9.156,2.735c4.313,2.618,8.118,6.99,10.799,12.028c2.688,5.031,4.243,10.713,4.238,15.82
		C74.191,67.664,73.519,70.455,72.351,72.764z"/>
	<path fill={fill} d="M57.56,57.295c-0.672-0.883-1.618-1.58-2.512-1.846C54.148,55.176,53.296,55,52.501,55h-2.5h-1h-1.5
		c-0.608,0-1.106,0.115-1.429,0.059c-0.245-0.041-0.378-0.031-0.444-0.068l-0.125-0.075c-0.067-0.082-0.194-0.265-0.308-0.718
		c-0.111-0.449-0.195-1.129-0.194-2.072c0.005-0.92,0.212-1.475,0.492-1.918c0.214-0.33,0.491-0.602,0.841-0.842
		c0.52-0.36,1.208-0.624,1.892-0.778c0.68-0.157,1.352-0.206,1.775-0.205c0.366,0,0.867,0.04,1.368,0.146
		c0.759,0.156,1.491,0.474,1.922,0.872c0.22,0.2,0.38,0.411,0.501,0.681c0.12,0.271,0.208,0.613,0.209,1.114c0,1.104,0.896,2,2,2
		s2-0.896,2-2c0.005-1.331-0.35-2.549-0.969-3.513c-0.46-0.725-1.05-1.299-1.67-1.738c-0.935-0.659-1.935-1.033-2.86-1.26
		c-0.175-0.042-0.332-0.056-0.502-0.088v-1.338c0-1.104-0.896-2-2-2s-2,0.896-2,2v1.303c-0.108,0.019-0.213,0.033-0.325,0.055
		c-1.343,0.271-2.932,0.801-4.306,1.998c-0.682,0.597-1.294,1.371-1.717,2.309c-0.424,0.938-0.652,2.025-0.651,3.21
		c0.002,1.383,0.131,2.516,0.445,3.516c0.234,0.744,0.59,1.422,1.083,1.975c0.368,0.416,0.807,0.748,1.258,0.982
		c0.68,0.355,1.359,0.375,1.977,0.449C46.384,59.127,46.959,59,47.501,59h1.5h1h2.5c0.542,0,0.979,0.168,1.267,0.244
		c0.217,0.055,0.346,0.184,0.441,0.252c0.135,0.105,0.281,0.263,0.466,0.759c0.177,0.49,0.328,1.354,0.326,2.612
		c-0.005,0.918-0.213,1.521-0.51,2.008c-0.227,0.361-0.521,0.672-0.882,0.939c-0.537,0.4-1.231,0.701-1.908,0.883
		c-0.672,0.184-1.331,0.249-1.7,0.247c-0.341,0.002-0.831-0.045-1.326-0.162c-0.751-0.17-1.489-0.509-1.937-0.937
		c-0.229-0.215-0.397-0.443-0.523-0.729c-0.125-0.287-0.213-0.639-0.214-1.141c0-1.104-0.896-2-2-2s-2,0.896-2,2
		c-0.005,1.332,0.349,2.551,0.958,3.52c0.453,0.73,1.032,1.316,1.646,1.77c0.925,0.682,1.921,1.086,2.852,1.336
		c0.542,0.144,1.058,0.231,1.544,0.283v2.373c0,1.105,0.896,2,2,2s2-0.895,2-2v-2.771c1.207-0.357,2.527-0.959,3.679-2.025
		c0.668-0.623,1.264-1.412,1.679-2.357c0.416-0.943,0.645-2.033,0.644-3.221C59,61.5,58.867,60.342,58.566,59.324
		C58.341,58.564,58.011,57.877,57.56,57.295z"/>
	</g>
</g>
</SvgIcon>
  );
}
