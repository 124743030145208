import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Select, Grid, Typography } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import MenuItem from "@material-ui/core/MenuItem";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import OwnerModal from "../owner-modal";
import CheckIcon from "../../icons/success-circle-icon";
import CheckBox from "../custom-checkbox/custom-checkbox-alternative-2";
import SecondaryButton from "../secondary-button";
import TextField from "../text-field";
import theme from "../../theme";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    heigth: "100%",
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  select: {
    height: "38px",
    borderRadius: 0,
    border: theme.borders[1],
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(2),
  },
  inputs: {
    width: "100%",
    height: "38px",
    borderRadius: 0,
    border: theme.borders[1],
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  inputLabel: {
    fontSize: theme.typography.body1.fontSize,
    transform: "translate(0, -5px) scale(1)",
    fontWeight: "bold",
  },
  divider: {
    width: "100%",
    borderBottom: `1px solid ${theme.palette.black}`,
  },
  inputError: {
    color: theme.palette.red,
    borderColor: theme.palette.red,
  },
  successIcon: {
    "& > svg": {
      width: "64px",
      height: "64px",
    },
  },
  fieldTaxId: {
    marginBottom: 0,
  },
}));

function NewCustomerModal({
  open = false,
  onClose = () => {},
  countries = [],
  handleRegisterCustomer = () => {},
  registerCompanyStatus = "initial",
  company = {},
  showAddresesFields,
}) {
  const classes = useStyles(theme);

  const [selectRef, setSelectRef] = useState(null);
  const [countryTaxId, setCountryTaxId] = useState(null);
  const [values, setValues] = useState({
    country: null,
    taxId: "",
    company: "",
    billingAddress: "",
    serviceAddress: "",
    isAgency: false,
  });
  const [inputStatus, setInputStatus] = useState({
    country: "initial",
    taxId: "initial",
    company: "initial",
    billingAddress: "initial",
    serviceAddress: "initial",
  });

  const { i18n } = useLingui();

  const handleOnChangeInput =
    (field) =>
    ({ target }) => {
      setValues((v) => ({ ...v, [field]: target.value }));
    };

  const handleUpperCaseInputChange =
    (field) =>
    ({ target }) => {
      const inputFormatted = target.value.toUpperCase();
      setValues((state) => ({ ...state, [field]: inputFormatted }));
    };

  const handleOnChangeCheckBox = (e, checked) => {
    setValues((v) => ({ ...v, isAgency: checked }));
  };

  const handleSubmit = () => {
    let canSubmit = true;
    setInputStatus({
      country: "initial",
      taxId: "initial",
      company: "initial",
      billingAddress: "initial",
      serviceAddress: "initial",
    });
    if (values.country < 0) {
      setInputStatus((is) => ({ ...is, country: "error" }));
      canSubmit = false;
    }
    if (values.company === "") {
      setInputStatus((is) => ({ ...is, company: "error" }));
      canSubmit = false;
    }
    if (values.taxId === "") {
      setInputStatus((is) => ({ ...is, taxId: "error" }));
      canSubmit = false;
    }
    if (showAddresesFields && values.billingAddress === "") {
      setInputStatus((is) => ({ ...is, billingAddress: "error" }));
      canSubmit = false;
    }
    if (canSubmit) {
      handleRegisterCustomer({
        name: values.company,
        taxId: values.taxId,
        countryId: values.country,
        billingAddress: values.billingAddress,
        serviceAddress: values.serviceAddress,
        isAgency: values.isAgency,
      });
    }
  };

  const checkInputError = (input) => {
    return inputStatus[input] === "error" ? classes.inputError : "";
  };

  useEffect(() => {
    if (countries.length > 0) {
      setCountryTaxId(countries.find((c) => c.id === values?.country)?.tax_id_label);
    }
  }, [values.country, countries]);

  return (
    <OwnerModal
      open={open}
      onClose={onClose}
      width={registerCompanyStatus === "success" ? "400px" : undefined}
      title={registerCompanyStatus !== "success" ? <Trans>Clientes - Crear nuevo</Trans> : ""}
    >
      <Box className={classes.container}>
        {registerCompanyStatus !== "success" && (
          <ValidatorForm onSubmit={() => {}}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="flex-end"
                  height="100%"
                  width="100%"
                  flexDirection="column"
                  ref={(el) => setSelectRef(el)}
                >
                  <Box width="100%" mb={1 / 3}>
                    <Typography className={`${checkInputError("country")}`} variant="subtitle1">
                      <Trans>País</Trans>
                    </Typography>
                  </Box>
                  <Select
                    label={i18n._(t`País`)}
                    validators={["required"]}
                    style={{ width: "275px" }}
                    placeholder={i18n._(t`País`)}
                    className={`${classes.select} ${checkInputError("country")}`}
                    value={values.country}
                    onChange={handleOnChangeInput("country")}
                    MenuProps={{
                      anchorEl: selectRef || null,
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                    }}
                  >
                    {countries.map((c) => (
                      <MenuItem key={c.name} value={c.id}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Grid>
              <Grid item xs={6} style={{ display: "flex", alignItems: "flex-end" }}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-end"
                  justifyContent="flex-start"
                  heigth="100%"
                  mt={1}
                >
                  <Box width="100%" mb={1 / 3}>
                    <Typography className={`${checkInputError("taxId")}`} variant="subtitle1">
                      <Trans>Tax ID</Trans>
                    </Typography>
                  </Box>
                  <TextField
                    value={values.taxId}
                    onChange={handleUpperCaseInputChange("taxId")}
                    style={{ width: "285px", heigth: "38px", marginBottom: "0px" }}
                    validators={["required"]}
                    errorMessages={[""]}
                    inputClassNames={`${checkInputError("taxId")}`}
                    inputContainerClassNames={`${classes.fieldTaxId}`}
                    className={`${classes.fieldTaxId}`}
                    disabled={values.country === null || registerCompanyStatus === "loading"}
                    InputProps={{
                      placeholder: countryTaxId != null ? countryTaxId : `${i18n._(t`Tax ID`)}`,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box width="100%" mb={1 / 3}>
                  <Typography className={`${checkInputError("company")}`} variant="subtitle1">
                    <Trans>Empresa</Trans>
                  </Typography>
                </Box>
                <TextField
                  value={values.company}
                  onChange={handleUpperCaseInputChange("company")}
                  style={{ width: "100%", heigth: "38px" }}
                  validators={["required"]}
                  errorMessages={[""]}
                  className={`${classes.field}`}
                  inputClassNames={`${checkInputError("company")}`}
                  textFieldClassNames={classes.textFieldLabel}
                  InputProps={{
                    placeholder: `${i18n._(t`Razón social`)}`,
                  }}
                />
              </Grid>
              {showAddresesFields && (
                <>
                  <Grid item xs={12}>
                    <Box width="100%" mb={1 / 3}>
                      <Typography className={`${checkInputError("billingAddress")}`} variant="subtitle1">
                        <Trans>Dirección de facturación</Trans>
                      </Typography>
                    </Box>
                    <TextField
                      value={values.billingAddress}
                      onChange={handleOnChangeInput("billingAddress")}
                      style={{ width: "100%", heigth: "38px" }}
                      validators={["required"]}
                      errorMessages={[""]}
                      className={`${classes.field}`}
                      inputClassNames={`${checkInputError("billingAddress")}`}
                      textFieldClassNames={classes.textFieldLabel}
                      InputProps={{
                        placeholder: `${i18n._(t`Dirección de facturación`)}`,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box width="100%" mb={1 / 3}>
                      <Typography className={`${checkInputError("serviceAddress")}`} variant="subtitle1">
                        <Trans>Dirección de servicio</Trans>
                      </Typography>
                    </Box>
                    <TextField
                      value={values.serviceAddress}
                      onChange={handleOnChangeInput("serviceAddress")}
                      style={{ width: "100%", heigth: "38px" }}
                      validators={[""]}
                      errorMessages={[""]}
                      className={`${classes.field}`}
                      textFieldClassNames={classes.textFieldLabel}
                      InputProps={{
                        placeholder: `${i18n._(t`Dirección de servicio`)}`,
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" mt={2}>
              <CheckBox style={{ padding: 0 }} checked={values.isAgency} onChange={handleOnChangeCheckBox} />
              <Box ml={1}>
                <Typography variant="body1">
                  <Trans>La empresa es una agencia</Trans>
                </Typography>
              </Box>
            </Box>
            <Box mt={2} className={classes.divider} />
            <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={2}>
              <SecondaryButton onClick={onClose}>
                <Trans>Cancelar</Trans>
              </SecondaryButton>
              <Button
                onClick={handleSubmit}
                disabled={registerCompanyStatus === "loading"}
                variant="contained"
                color="primary"
              >
                <Trans>Dar de alta</Trans>
              </Button>
            </Box>
          </ValidatorForm>
        )}
        {registerCompanyStatus === "success" && (
          <Box display="flex" flexDirection="column" alignItems="center" width={400} p={2}>
            <CheckIcon fill={theme.palette.successGreen} width={56} height={56} />
            <Box mb={3}>
              <Typography variant="subtitle1" style={{ color: theme.palette.successGreen }}>
                <Trans>El cliente</Trans>
                <span style={{ marginLeft: 3, marginRight: 3 }}>#{company.id}</span>
                <Trans>ha sido creado exitosamente.</Trans>
              </Typography>
            </Box>
            <Button onClick={onClose} variant="contained" color="primary" style={{ width: 150 }}>
              <Trans>Aceptar</Trans>
            </Button>
          </Box>
        )}
      </Box>
    </OwnerModal>
  );
}

export default NewCustomerModal;
