import React from "react";
import { Box, Button } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { makeStyles } from "@material-ui/core/styles";
import SecondaryButton from "../secondary-button";
import VisibilitySwitch from "../../containers/ad-spaces-form/adspace-visibility-switch";
import theme from "../../theme";
import TrashIcon from "../../icons/trash-icon";

const useStyles = makeStyles((th) => ({
  container: {
    position: "sticky",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    bottom: "0px",
    boxShadow: "0px -4px 11px rgba(0, 0, 0, 0.25)",
    backgroundColor: th.palette.white,
    paddingLeft: th.spacing(4),
    paddingRight: th.spacing(4),
    paddingTop: th.spacing(1),
    paddingBottom: th.spacing(1),
    zIndex: 999,
  },
  backButton: {
    marginRight: th.spacing(1),
    width: 150,
    height: 42,
  },
  continueButton: {
    height: 34,
  },
}));

function AdSpaceStepButtons({
  labelSubmit,
  handleSubmit = () => {},
  handleBack = () => {},
  disableBack = false,
  disableSubmit = false,
  hasBack = false,
  showDeleteAdSpace = false,
  onSetOpenDelete = () => {},
  hasResetChanges = false,
  disableResetChanges = false,
  onResetChanges = () => {},
  widthSubmit = 114,
}) {
  const classes = useStyles(theme);

  return (
    <Box className={classes.container}>
      <Box display="flex" flexDirection="row" alignItems="left">
        <VisibilitySwitch />
        {showDeleteAdSpace && (
          <Box ml={1} sx={{ borderLeft: theme.borders[6] }}>
            <Button
              variant="text"
              onClick={() => onSetOpenDelete(true)}
              className={classes.headerButton}
              style={{ color: theme.palette.grayLabel }}
            >
              <TrashIcon fill={theme.palette.grayLabel} style={{ width: "13px", marginRight: theme.spacing(1 / 2) }} />
              <Trans>Eliminar espacio</Trans>
            </Button>
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box>
          {hasBack && (
            <SecondaryButton disabled={disableBack} onClick={handleBack}>
              <Trans>Atrás</Trans>
            </SecondaryButton>
          )}
          {hasResetChanges && (
            <SecondaryButton disabled={disableResetChanges} onClick={onResetChanges}>
              <Trans>Olvidar cambios</Trans>
            </SecondaryButton>
          )}
        </Box>
        <Box justifySelf="flex-end">
          <Button
            onClick={handleSubmit}
            disabled={disableSubmit}
            type="submit"
            variant="contained"
            color="primary"
            className={classes.continueButton}
            sx={{ width: widthSubmit }}
          >
            {labelSubmit}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default AdSpaceStepButtons;
