import React, { useEffect, useState } from "react";
import { useParams } from "@reach/router";
import { useNavigate } from "/src/hooks/navigation";
import { useSelector, useDispatch } from "react-redux";
import AdsTitleComponent from "../../../components/ad-spaces-form/title";
import api from "../../../api";
import { getAdSpaceTypesThunk, updateAdSpaceData } from "../../../redux/actions/adspaces-actions";

const AdsTitle = ({ onSetOpenDelete }) => {
  const dispatch = useDispatch();
  const { navigate } = useNavigate();
  const params = useParams();
  const isNew = params.adSpaceId === "new";

  const [submitStatus, setSubmitStatus] = useState("initial");

  const adspace = useSelector((state) => state.adspaces.adSpace);
  const status = useSelector((state) => state.adspaces.adSpaceStatus);

  useEffect(() => {
    dispatch(getAdSpaceTypesThunk());
  }, [params.adSpaceId]);

  useEffect(() => {
    return () => {
      setSubmitStatus("initial");
    };
  }, []);

  const goNext = () => {
    navigate(`/administrator/adspaces/${params.adSpaceId}/location`);
  };

  const handleSubmit = async (data) => {
    setSubmitStatus("loading");
    if (isNew) {
      dispatch(updateAdSpaceData({ ...data }));
      goNext();
    } else {
      await api.patchAdSpace(params.adSpaceId, data);
      setSubmitStatus("success");
      dispatch(updateAdSpaceData({ ...data }));
    }
  };

  return (
    <AdsTitleComponent
      isNew={isNew}
      onSetOpenDelete={onSetOpenDelete}
      adspace={adspace}
      status={status}
      submit={handleSubmit}
      goNext={goNext}
      submitStatus={submitStatus}
    />
  );
};

export default AdsTitle;
