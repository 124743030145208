import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { makeStyles } from "@material-ui/core/styles";
import { dateToDayjs } from "../../../utils/dateUtils";
import Calendar from "../../calendar/index";
import theme from "../../../theme";

const useStyles = makeStyles((th) => ({
  label: {
    color: "#001F3F",
    fontWeight: "bold",
    fontSize: "10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginTop: 0,
    marginBottom: 0,
  },
  rateLabel: { color: "#00441B", fontSize: "11px", fontWeight: "bold", marginTop: 0, marginBottom: 0 },
  rateNumber: { color: "#000000" },
}));

const SeasonalCalendar = ({
  seasons,
  blockDates = false,
  selectRange = false,
  showSeasonInformation = true,
  maxAvailableHeight = false,
  datesSelected,
  onChange,
  onSeasonSelected,
}) => {
  const classes = useStyles(theme);
  const [priceRanges, setPriceRanges] = useState([]);

  useEffect(() => {
    if (seasons) {
      let ranges = seasons.map((item) => ({
        id: item.id,
        rate: parseFloat(item.rate),
        start: dateToDayjs(item.start).utc().toDate(),
        end: dateToDayjs(item.end).utc().toDate(),
        label: item.label,
        serie: item.serie,
      }));

      if (datesSelected.length && datesSelected[0] && datesSelected[1]) {
        ranges = ranges.filter(
          (item) =>
            item.start.getTime() !== datesSelected[0].getTime() && item.end.getTime() !== datesSelected[1].getTime(),
        );
      }

      setPriceRanges(ranges);
    }
  }, [seasons]);

  const handleDayClick = (value) => {
    const season = priceRanges.find((range) => value >= range.start && value <= range.end);

    if (season) {
      onSeasonSelected(season);
    }
  };

  const tileContent = ({ date, view }) => {
    const isInDateRange = priceRanges.some((range) => date >= range.start && date <= range.end);

    if (isInDateRange) {
      const season = priceRanges.find((range) => date >= range.start && date <= range.end);

      return (
        <>
          <Typography>
            <p className={classes.label}>{season.label}</p>
            <p className={classes.rateLabel}>
              <Trans>Rate</Trans>: <span className={classes.rateNumber}>{season.rate}</span>
            </p>
          </Typography>
        </>
      );
    }

    return null;
  };

  const tileDisabled = ({ date }) => {
    const isWithinPriceRanges = priceRanges.some((range) => date >= range.start && date <= range.end);
    return isWithinPriceRanges;
  };

  return (
    <Calendar
      startDate={datesSelected[0] || null}
      endDate={datesSelected[1] || null}
      tileContent={showSeasonInformation ? tileContent : <></>}
      onChange={onChange}
      onClickDay={handleDayClick}
      selectRange={selectRange}
      tileDisabled={blockDates ? tileDisabled : () => {}}
      maxAvailableHeight={maxAvailableHeight}
      isDouble
    />
  );
};

export default SeasonalCalendar;
