import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import BookingInvoicingReportToolbar from "../../containers/invoicing-report/booking-invoicing-report-toolbar";
// theme
import theme from "../../theme-admin";
import Table from "./../table";
import TableFooter from "./../table-footer";
import TableDataDownload from "../table-data-download";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import { dateToDayjs, withLocale } from "../../utils/dateUtils";
import numbro from "numbro";
import { useLocalization } from "gatsby-theme-i18n";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    height: "100%",
    "& .MuiTableCell-head > span": {
      justifyContent: "center",
    },
  },
  insightsContainer: {
    width: 300,
    padding: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "&:nth-child(odd)": {
      marginTop: theme.spacing(6),
    },
    "&:nth-child(even)": {
      marginTop: theme.spacing(2),
    },
  },
}));

const INITIAL_DISPLAY = {
  booking_id: true,
  date: true,
  payment: true,
  asset: true,
  brand: true,
  company: true,
  start: true,
  space: true,
  end: true,
  duration: true,
  price: true,
  taxId: true,
  category: true,
  ...Array(12)
    .fill(1, 0, 12)
    .reduce((prev, curr, i) => {
      return (prev = { ...prev, [`month-${i}`]: true });
    }, {}),
  total: true,
};

const BookingInvoicingReport = ({
  bookings = [],
  pagination,
  onChangePage,
  status,
  downloadTableData,
  onTableChange,
  onRowClick = () => {},
}) => {
  const { i18n } = useLingui();
  const classes = useStyles(theme);
  const { locale } = useLocalization();

  const [displayColumn, setDisplayColumn] = useState(INITIAL_DISPLAY);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);

  const totals = [];

  bookings.forEach((b) => {
    const calculatedTotal = Object.keys(b.account).reduce((prev, curr, index) => {
      return (prev += displayColumn[`month-${index}`] ? b.account[curr] : 0);
    }, 0);
    totals.push(calculatedTotal);
  });

  const handleShowHideColumn = (col, action) => {
    if (action === "add") {
      setDisplayColumn({ ...displayColumn, [col]: true });
      localStorage.removeItem("invoicesColumns");
      localStorage.setItem("invoicesColumns", JSON.stringify({ ...displayColumn, [col]: true }));
    } else if (action === "remove") {
      setDisplayColumn({ ...displayColumn, [col]: false });
      localStorage.removeItem("invoicesColumns");
      localStorage.setItem("invoicesColumns", JSON.stringify({ ...displayColumn, [col]: false }));
    }
  };

  const handleOpenDownloadTable = () => {
    setOpenDownloadModal(true);
    return false;
  };

  const handleTableData = () => {
    const data = bookings?.map((b, index) => {
      return {
        id: b.id,
        asset: b.asset?.name,
        company: b.author?.profile?.company?.name,
        taxId: b.author?.profile?.company?.tax_id,
        brand: b.brand_name,
        space: b.spaces[0]?.title || "",
        category: i18n._(t`${b.category?.slug}`),
        start: b.start,
        end: b.end,
        duration: b.days_count,
        ...Object.keys(b.account)?.reduce(
          (prev, curr, i) => ({ ...prev, [`month-${i}`]: numbro(parseFloat(b.account[curr]) || 0).formatCurrency() }),
          {},
        ),
        total: numbro(parseFloat(totals[index])).formatCurrency(),
      };
    });

    return data;
  };

  const columnOptions = {
    setCellProps: (value) => {
      return {
        style: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          whiteSpace: "nowrap",
          textAlign: "center",
        },
      };
    },
    setCellHeaderProps: (value) => {
      return {
        style: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          whiteSpace: "nowrap",
          textAlign: "center",
        },
      };
    },
  };

  const monthColumnOptions = {
    setCellProps: (value) => {
      return {
        style: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          whiteSpace: "nowrap",
          textAlign: "center",
          width: "100px",
        },
      };
    },
    setCellHeaderProps: (value) => {
      return {
        style: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          whiteSpace: "nowrap",
          textAlign: "center",
          width: "100px",
        },
      };
    },
  };

  const columns = [
    {
      label: `${i18n._(t`Booking ID`)}`,
      name: "id",
      options: { display: displayColumn.booking_id, sort: true, ...columnOptions },
    },
    {
      label: `${i18n._(t`Centro Comercial`)}`,
      name: "asset",
      options: { display: displayColumn.asset, sort: true, ...columnOptions },
    },
    {
      label: `${i18n._(t`Empresa`)}`,
      name: "company",
      options: { display: displayColumn.company, sort: true, ...columnOptions },
    },
    {
      label: i18n._(t`Tax ID`),
      name: "taxId",
      options: { display: displayColumn.taxId, sort: false, ...columnOptions },
    },
    {
      label: `${i18n._(t`Marca`)}`,
      name: "brand",
      options: { display: displayColumn.brand, sort: true, ...columnOptions },
    },
    {
      label: `${i18n._(t`Espacio`)}`,
      name: "space",
      options: { display: displayColumn.space, sort: false, ...columnOptions },
    },
    {
      label: `${i18n._(t`Categoría`)}`,
      name: "category",
      options: { display: displayColumn.category, sort: true, ...columnOptions },
    },
    {
      label: i18n._(t`Comienzo`),
      name: "start",
      options: { display: displayColumn.start, sort: true, ...columnOptions },
    },
    {
      label: i18n._(t`Final`),
      name: "end",
      options: { display: displayColumn.end, sort: true, ...columnOptions },
    },
    {
      label: i18n._(t`Duración`),
      name: "duration",
      options: { display: displayColumn.duration, sort: false, ...columnOptions },
    },
    ...Array(12)
      .fill(1, 0, 12)
      .map((v, i) => {
        let monthCapitalized = i18n._(t`${withLocale(new Date(), locale?.substring(0, 2)).month(i).format("MMMM")}`);
        monthCapitalized = monthCapitalized.charAt(0).toUpperCase() + monthCapitalized.slice(1).toLowerCase();

        return {
          label: monthCapitalized,
          name: `month-${i}`,
          options: { display: displayColumn[`month-${i}`], sort: false, ...monthColumnOptions },
        };
      }),
    {
      label: i18n._(t`Total`),
      name: "total",
      options: { display: displayColumn.total, sort: false, ...columnOptions },
    },
  ];

  return (
    <Box className={classes.container} pl={2} pr={2}>
      <TableDataDownload
        open={openDownloadModal}
        onClose={() => setOpenDownloadModal(false)}
        onDownload={downloadTableData}
      />
      <Box position="relative" width={"100%"}>
        <Table
          data={handleTableData()}
          columns={columns}
          isLoading={status === "loading"}
          onDownload={handleOpenDownloadTable}
          options={{
            fixedSelectColumn: true,
            responsive: "simple",
            onTableChange,
            onRowClick,
            customToolbar: () => {
              return <BookingInvoicingReportToolbar />;
            },
            onViewColumnsChange: handleShowHideColumn,
            customFooter: () => {
              return <TableFooter pagination={pagination} onChangePage={onChangePage} canCreate={false} />;
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default BookingInvoicingReport;
