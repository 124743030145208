import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "@lingui/macro";
import { getAssetsThunk, setDashboardTitle } from "../../redux/actions/misc-actions";
import OwnerAssets from "../../components/owner-assets";
import { downloadBlobFiles, filterColumnsDisplayTableToString, getRowsFromWindowSize } from "../../utils";
import api from "../../api";

const OwnerAssetsContainer = () => {
  const dispatch = useDispatch();

  const assets = useSelector((state) => state.misc.assets);
  /** this is different from other paginations. because of the misc.
   *  if needed, refactor this to another reducer "assets-owner"
   * * */
  const pagination = useSelector((state) => state.misc.assetsPagination);
  const status = useSelector((state) => state.misc.assetsStatus);
  const userRole = useSelector((state) => state.user.data.role);
  const [columns, setColumns] = useState("");
  const [sortOrder, setSortOrder] = useState({});

  const handleDownloadTableData = async (format) => {
    const res = await api.downloadAssetsData({
      exportFormat: format,
      columns,
    });
    downloadBlobFiles(res, `Assets.${format}`);
  };

  useEffect(() => {
    dispatch(setDashboardTitle(t`Centros & Propietarios`));
    dispatch(
      getAssetsThunk({
        /** this is a fix becuse of the extra height provided by the tabs. * */
        /** When refactoring this view, remove this height fix * */
        numberPerPage: getRowsFromWindowSize(window.innerHeight - 330),
        page: 1,
      }),
    );
  }, []);

  const handleTableChange = (action, tableState) => {
    const columnsDisplay = filterColumnsDisplayTableToString(tableState);
    setColumns(columnsDisplay);
    switch (action) {
      case "sort":
        setSortOrder(tableState.sortOrder);
        dispatch(
          getAssetsThunk({
            page: 1,
            sort: tableState.sortOrder,
          }),
        );
        break;
      default:
      // console.log("action not handled.");
    }
  };

  const handleChangePage = (evt, page) => {
    dispatch(
      getAssetsThunk({
        numberPerPage: getRowsFromWindowSize(window.innerHeight - 330),
        page,
        sort: sortOrder,
      }),
    );
  };

  return (
    <OwnerAssets
      assets={assets}
      status={status}
      pagination={pagination}
      onChangePage={handleChangePage}
      onTableChange={handleTableChange}
      onDownloadTableData={handleDownloadTableData}
      userRole={userRole}
    />
  );
};

export default OwnerAssetsContainer;
