import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import TimelineComponent from "./timeline-component";
import Image from "material-ui-image";
// theme
import theme from "./../../theme";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";

import { Box, MenuItem, Select } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { SPACE_TYPE } from "../../utils";
import { dateToDayjs } from "../../utils/dateUtils";

const useStyles = makeStyles((theme) => ({
  container: {
    color: "#000000",
    position: "relative",
    flex: 1,
    marginTop: theme.spacing(2),
    overflow: "auto",
  },
  select: {
    height: 35,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    minWidth: 150,
    "& .MuiInputBase-input": {
      display: "flex",
      justifyContent: "flex-start",
    },
  },
}));

export default function TimelineVacancy({
  filters = {},
  spaceType,
  loading,
  filteredDate,
  error,
  onChangeFilters,
  assets = [],
  onClickBlock = () => {},
  onChangeSpaceType = () => {},
  data = {},
}) {
  const classes = useStyles(theme);
  const { i18n } = useLingui();
  const routesContainerWith = document.getElementById("routes-container").clientWidth - 50;

  const { spaces = {}, occupation } = data;

  const handleFilterDatesChange = ({ target }) => {
    onChangeFilters({
      ...filters,
      start: new Date(target.value, 0, 1).toDateString(),
      end: new Date(target.value, 11, 31).toDateString(),
    });
  };

  const handleChangeAsset = ({ target }) =>
    onChangeFilters({
      ...filters,
      asset: assets.find((a) => a.id === target.value),
    });

  const handleClickBlock = (block) => onClickBlock(block);

  const handleOnChangeSpaceType = ({ target }) => {
    onChangeSpaceType(target.value);
  };

  const availableYears = () => {
    const firstYear = dateToDayjs(new Date()).year(2021).get("year");
    const nextYear = dateToDayjs(new Date()).add(1, "year").get("year");

    const years = [];
    for (let i = firstYear; i <= nextYear; i++) {
      years.push(i);
    }

    return years;
  };

  return (
    <Box className={classes.container} pl={2} pr={2}>
      <Box>
        <Box display={"flex"} alignItems={"center"}>
          <Box display={"flex"} alignItems={"center"}>
            {filters.asset.id && (
              <Image
                src={filters.asset.logo_dark_url}
                disableSpinner
                style={{
                  height: "50px",
                  padding: "0",
                  width: "50px",
                  objectFit: "contain",
                }}
              />
            )}
            <Select
              value={filters.asset.id || 0}
              onChange={handleChangeAsset}
              variant="outlined"
              className={classes.select}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {assets.map((a) => (
                <MenuItem key={a.id} value={a.id}>
                  {a.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Select
            value={spaceType}
            onChange={handleOnChangeSpaceType}
            variant="outlined"
            style={{ marginLeft: theme.spacing(0) }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            className={classes.select}
          >
            <MenuItem value={SPACE_TYPE.SPACE}>{i18n._(t`${SPACE_TYPE.SPACE}`)}</MenuItem>
            <MenuItem value={SPACE_TYPE.ADSPACE}>{i18n._(t`${SPACE_TYPE.ADSPACE}`)}</MenuItem>
          </Select>
          <Select
            value={new Date(filters?.start).getFullYear()}
            onChange={handleFilterDatesChange}
            variant="outlined"
            style={{ marginLeft: theme.spacing(0) }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            className={classes.select}
          >
            {availableYears().map((y) => (
              <MenuItem key={y} value={y}>
                {y}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box mt={2} mb={2}>
          {loading && <Typography variant={"body1"}>Cargando...</Typography>}
          {error && (
            <Typography variant={"body1"} color={"error"}>
              Ooops!: {error}
            </Typography>
          )}
        </Box>
        <TimelineComponent
          spaces={Object.values(spaces).sort((a, b) => (a.title < b.title ? -1 : 1))}
          occupation={occupation}
          start={filters.start}
          end={filters.end}
          onClickBlock={handleClickBlock}
          todayLabel={i18n._(t`Hoy`)}
          width={routesContainerWith}
        />
      </Box>
    </Box>
  );
}
