import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import StepPriceComponent from "../../components/create-booking-wizard/step-3-price";
import { formatDateUtc, getDays } from "../../utils/dateUtils";

function StepPriceContainer({ updateBooking, booking, onBack }) {
  const daysCount = () => {
    return getDays(formatDateUtc(booking.startDate, "YYYY-MM-DD"), formatDateUtc(booking.endDate, "YYYY-MM-DD"));
  };

  const [values, setValues] = useState({
    ...booking,
  });

  useEffect(() => {
    if (values.longTerm) {
      setValues((prevValues) => ({
        ...prevValues,
        paymentPlan: "Monthly",
      }));
    }
  }, [values.longTerm]);

  const handleMakeSpecialOffer = (price, paymentPlan) => {
    let periodPrice = 0;

    if (paymentPlan === "Total") {
      periodPrice = Math.round(price / daysCount());
    } else if (paymentPlan === "Monthly" || paymentPlan === "Weekly") {
      periodPrice = price;
    }
    setValues({
      ...values,
      paymentPlan,
      price,
      periodPrice,
    });
  };

  const handleSubmit = () => updateBooking(values);

  return (
    <StepPriceComponent
      onMakeSpecialOffer={handleMakeSpecialOffer}
      daysCount={daysCount()}
      onBack={onBack}
      onSubmit={handleSubmit}
      values={values}
    />
  );
}

export default StepPriceContainer;
