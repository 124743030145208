import React, { useEffect, useState } from "react";
import { Trans } from "@lingui/macro";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip } from "recharts";
import numbro from "numbro";
import { getMonthShortName, dateWithFormat, formatDateUtc } from "../../utils/dateUtils";
import theme from "../../theme";
import LargeWidget from "./large-widget";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.white,
    padding: theme.spacing(1),
    borderRadius: "5px",
    border: theme.borders[1],
  },
}));

const CustomTooltip = ({ payload }) => {
  const classes = useStyles();

  if (payload && typeof payload[0] !== "undefined") {
    return (
      <Box className={classes.tooltip}>
        <Typography variant="body1">
          {numbro(payload[0].value).formatCurrency({ mantissa: 0, thousandSeparated: true })}
        </Typography>
      </Box>
    );
  }
  return null;
};

function MonthlyIncomeWidget({ period, monthlyIncome = {} }) {
  const [data, setData] = useState();

  useEffect(() => {
    const data = Object.keys(monthlyIncome)
      .map((mi) => ({
        monthNumber: Number(formatDateUtc(dateWithFormat(mi, "MM-YYY"), "M")),
        month: getMonthShortName(dateWithFormat(mi, "MM-YYY")),
        income: monthlyIncome[mi],
      }))
      .sort((a, b) => a.monthNumber - b.monthNumber);
    setData(data);
  }, [monthlyIncome]);

  function Info() {
    return (
      <Box width="324px">
        <Typography variant="body1">
          <Trans>Detalle de la facturación generada mensualmente para el período seleccionado</Trans>
        </Typography>
      </Box>
    );
  }

  return (
    <LargeWidget title={<Trans>Ingreso mensual</Trans>} period={period} info={<Info />}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart width={150} height={40} data={data}>
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip cursor={false} content={CustomTooltip} />
          <Bar maxBarSize={120} dataKey="income" fill={theme.palette.primary.main} />
        </BarChart>
      </ResponsiveContainer>
    </LargeWidget>
  );
}

export default MonthlyIncomeWidget;
