/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from "react";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import numbro from "numbro";
import { useNavigate } from "/src/hooks/navigation";
import { formatDateUtc } from "../../utils/dateUtils";
import { userCanWrite } from "../../utils";
import { isBookingConfirmedOrDone } from "../../redux/reducers";
import OwnerModal from "../owner-modal";
import PendingTransactionsToolbar from "./PendingTransactionsToolbar";
import Table from "../table";
import TableFooter from "../table-footer";
import UpdateTransactionModal from "./UpdateTransactionModal";
import DeleteTransactionModal from "./DeleteTransactionModal";
import TransactionActions from "./TransactionActions";
import theme from "../../theme-admin";

const useStyles = makeStyles((th) => ({
  amountContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  link: {
    color: th.palette.black,
    fontWeight: "bold",
    textDecoration: "underline",
    "& span": {
      fontSize: 11,
    },
  },
}));

const PendingTransactionsComponent = ({
  open = false,
  permissions,
  transactions,
  status,
  filters,
  pagination,
  confirmTransaction,
  updateTransaction,
  deleteTransaction,
  onFilterDatesChange,
  onTableChange,
  onChangePage,
  onConfirmTransaction,
  onUpdateTransaction,
  onDeleteTransaction,
  onResetStates,
  onClose,
}) => {
  const { navigate } = useNavigate();
  const { i18n } = useLingui();
  const classes = useStyles(theme);

  const [transactionToUpdate, setTransactionToUpdate] = useState({ booking: { asset: {} } });
  const [isUpdatingTransaction, setIsUpdatingTransaction] = useState(false);
  const [isDeletingTransaction, setIsDeletingTransaction] = useState(false);

  const handleStartUpdatingTransaction = (transaction) => {
    setTransactionToUpdate(transaction);
    setIsUpdatingTransaction(true);
  };

  const handleStartDeletingTransaction = (transaction) => {
    setTransactionToUpdate(transaction);
    setIsDeletingTransaction(true);
  };

  const handleOnClickBookingId = (bookingId) => {
    navigate(`/administrator/bookings/${bookingId}`);
    onClose();
  };

  const handleClose = () => {
    onResetStates();
    setIsUpdatingTransaction(false);
    setIsDeletingTransaction(false);
  };

  const columns = [
    { label: `${i18n._(t`Tipo`)}`, name: "type" },
    { label: "ID", name: "id" },
    {
      label: `${i18n._(t`Booking ID`)}`,
      name: "bookingId",
    },
    { label: `${i18n._(t`Concepto`)}`, name: "description" },
    { label: `${i18n._(t`Fecha`)}`, name: "date" },
    {
      label: `${i18n._(t`Centro Comercial`)}`,
      name: "asset",
    },
    {
      label: `${i18n._(t`Empresa`)}`,
      name: "company",
    },
    {
      label: `${i18n._(t`Importe`)}`,
      name: "amount",
    },
    {
      label: `${i18n._(t`Confirmar`)}`,
      name: "confirm",
      options: {
        sort: false,
      },
    },
    {
      label: `${i18n._(t`Acciones`)}`,
      name: "actions",
      options: {
        sort: false,
      },
    },
  ];

  const getColor = (trs) => {
    if (trs.type === "invoice") {
      return theme.palette.darkGreen;
    }
    return theme.palette.red;
  };

  const handleTableData = () => {
    const data = transactions.map((c) => {
      const userHasPermission = userCanWrite(permissions) && isBookingConfirmedOrDone(c.booking.confirmed_at);

      return {
        type: (
          <>
            {c.type === "invoice" && <Trans>Factura</Trans>}
            {c.type === "credit" && <Trans>Abono</Trans>}
          </>
        ),
        id: c.id,
        bookingId: (
          <Button variant="text" className={classes.link} onClick={() => handleOnClickBookingId(c.booking_id)}>
            {c.booking_id}
          </Button>
        ),
        description: c.description || "-",
        date: formatDateUtc(c.date, "DD/MM/YYYY"),
        asset: c.booking.asset.name,
        company: c.booking.brand_name,
        amount: (
          <Box className={classes.amountContainer} style={{ color: getColor(c) }}>
            {numbro(Math.abs(parseFloat(c.amount))).formatCurrency()}
          </Box>
        ),
        confirm: (
          <Box p={1}>
            <Button
              onClick={() => onConfirmTransaction(c)}
              variant="contained"
              color="primary"
              disabled={confirmTransaction.status === "loading"}
            >
              <Trans>Confirmar</Trans>
            </Button>
          </Box>
        ),
        actions: (
          <TransactionActions
            userHasPermission={userHasPermission}
            onStartUpdatingTransaction={() => handleStartUpdatingTransaction(c)}
            onStartDeletingTransaction={() => handleStartDeletingTransaction(c)}
          />
        ),
      };
    });

    return data;
  };

  return (
    <OwnerModal title={i18n._(t`Confirmar transacciones`)} open={open} onClose={() => onClose()} width={1050}>
      <Box position="relative" width="100%" height="100%">
        <UpdateTransactionModal
          open={isUpdatingTransaction}
          transaction={transactionToUpdate}
          booking={transactionToUpdate.booking}
          updateTransaction={updateTransaction}
          onUpdateTransaction={onUpdateTransaction}
          onClose={handleClose}
        />
        <DeleteTransactionModal
          open={isDeletingTransaction}
          transaction={transactionToUpdate}
          deleteTransaction={deleteTransaction}
          onDeleteTransaction={onDeleteTransaction}
          onClose={handleClose}
        />

        <Table
          data={handleTableData()}
          columns={columns}
          isLoading={status === "loading"}
          options={{
            responsive: "simple",
            download: false,
            onTableChange,
            customToolbar: () => {
              return <PendingTransactionsToolbar filters={filters} onFilterDatesChange={onFilterDatesChange} />;
            },
            customFooter: () => {
              return (
                <Box pt={6}>
                  <TableFooter pagination={pagination} onChangePage={onChangePage} />
                </Box>
              );
            },
          }}
        />
      </Box>
    </OwnerModal>
  );
};

export default PendingTransactionsComponent;
