import { Box, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// Local components
import { ValidatorForm } from "react-material-ui-form-validator";
// theme
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";

import theme from "../../../theme";
// My Components
import BaseStep from "../step-base";
import StepButtons from "../step-buttons";
import DurationInputs from "./duration-inputs";
import PriceInputs from "./price-inputs";
import CheckBox from "../../custom-checkbox/custom-checkbox-alternative-2";
import CustomCheckBox2 from "../../custom-checkbox/custom-checkbox-alternative-3";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    height: "100%",
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
    width: 150,
    height: 42,
  },
  continueButton: {
    width: 190,
    height: 42,
  },
  errorMessageContainer: {
    marginTop: theme.spacing(2),
    minHeight: 36,
  },
  helpTitle: {
    fontWeight: "bold",
  },
  icons: {
    float: "left",
    padding: 2,
  },
}));

const HelpSection = styled(Box)({
  marginBottom: theme.spacing(1),
});

const HelpTitle = ({ text }) => {
  const classes = useStyles(theme);
  return (
    <HelpSection>
      <Typography variant="body2" className={classes.helpTitle}>
        {text}
      </Typography>
    </HelpSection>
  );
};

const HelpItem = ({ text }) => {
  const classes = useStyles(theme);
  return (
    <HelpSection>
      <Box display="flex">
        <CheckIcon fontSize="small" className={classes.icons} />
        <Typography variant="body2" component="label">
          {" "}
          {text}{" "}
        </Typography>
      </Box>
    </HelpSection>
  );
};

const StepContent = ({ space, spots, handleBack, handleNext, onSubmit, status, onSetOpenDelete }) => {
  const classes = useStyles(theme);
  const [values, setValues] = useState({});
  const [submited, setSubmited] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [durationError, setDurationError] = useState(false);

  useEffect(() => {
    setValues(space);
  }, [space]);

  const validate = () => validatePrice() & validateDuration();

  const validateDuration = () => {
    const valid = values.min_stay > 0 && values.max_stay > 0 && values.max_stay >= values.min_stay;
    setDurationError(!valid);
    return valid;
  };

  const validatePrice = () => {
    const valid =
      values.week_day_price > 0 &&
      values.full_month_price > 0 &&
      values.full_week_price > 0 &&
      values.full_weekend_price > 0;
    setPriceError(!valid);
    return valid;
  };

  const handleSubmit = () => {
    if (validate()) {
      onSubmit({
        ...space,
        ...values,
      });
      setSubmited(true);
    }
  };

  const handleInputChange =
    (field) =>
    ({ target }) => {
      setValues((state) => ({ ...state, [field]: target.value }));
      setPriceError(false);
      setDurationError(false);
    };

  const handleChangeSpot =
    (slug) =>
    ({ target }) => {
      const { checked } = target;
      const tags = checked
        ? [...values.tags, spots.find((s) => s.slug === slug)]
        : values.tags.filter((s) => s.slug !== slug);
      setValues((state) => ({
        ...state,
        tags,
      }));
    };

  const handleBlurInputMinDuration = () => {
    setValues((state) => ({
      ...state,
      min_stay: state.min_stay > 0 ? state.min_stay : 1,
    }));
  };

  const handleBlurInputMaxDuration = () => {
    if (values.max_stay < 0 || values.max_stay < values.min_stay) {
      setValues((state) => ({ ...state, max_stay: state.min_stay }));
    }
  };

  const { i18n } = useLingui();

  const somethingHasChanged = JSON.stringify(values) !== JSON.stringify(space);
  const handleResetChanges = () => setValues(space);

  const disableContinue =
    (values.min_stay < 0 &&
      values.max_stay < 0 &&
      values.full_month_price < 0 &&
      values.full_week_price < 0 &&
      values.full_weekend_price < 0 &&
      values.week_day_price < 0) ||
    status === "loading" ||
    !somethingHasChanged;

  const assetHasPromotion = spots.find((s) => s.slug === "promotion");
  const assetHasRetail = spots.find((s) => s.slug === "retail");

  return (
    <Box width="100%">
      <ValidatorForm className={classes.container} onSubmit={handleSubmit}>
        <Box px={2} pt={2} style={{ overflowY: "auto" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DurationInputs
                onChangeMinimum={handleInputChange("min_stay")}
                onChangeMaximum={handleInputChange("max_stay")}
                onBlurMinimum={handleBlurInputMinDuration}
                onBlurMaximum={handleBlurInputMaxDuration}
                value={{
                  min_stay: values.min_stay,
                  max_stay: values.max_stay,
                }}
                submitedWithErrors={durationError}
              />
            </Grid>
            <Grid item xs={12}>
              <PriceInputs
                onChangePricePerDay={handleInputChange("week_day_price")}
                onChangePricePerWeekEnd={handleInputChange("full_weekend_price")}
                onChangePricePerWeek={handleInputChange("full_week_price")}
                onChangePricePerMonth={handleInputChange("full_month_price")}
                value={{
                  week_day_price: values.week_day_price,
                  full_weekend_price: values.full_weekend_price,
                  full_week_price: values.full_week_price,
                  full_month_price: values.full_month_price,
                }}
                submitedWithErrors={priceError}
              />
            </Grid>
            {spots.length > 0 && (
              <Grid item xs={12}>
                <Box display="flex" flexDirection="column" justifyContent="center">
                  <Box display="flex" alignItems="center">
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                      {`${i18n._(t`Mostrar para:*`)}`}
                    </Typography>
                    <Box ml={1 / 2}>
                      <Typography variant="body1">{`(${i18n._(
                        t` se pueden seleccionar varias opciones`,
                      )})`}</Typography>
                    </Box>
                  </Box>
                  <Box mt={1}>
                    {assetHasPromotion && (
                      <Box display="flex">
                        <CustomCheckBox2
                          onChange={handleChangeSpot("promotion")}
                          style={{ padding: 0, marginRight: theme.spacing(1) }}
                          checked={values.tags?.some((t) => t.slug === "promotion")}
                        />
                        <Typography variant="body1">
                          {`${i18n._(t`Promotion`)} (${i18n._(t`precio total`)})`}
                        </Typography>
                      </Box>
                    )}
                    {assetHasRetail && (
                      <Box display="flex" mt={1}>
                        <CustomCheckBox2
                          onChange={handleChangeSpot("retail")}
                          style={{ padding: 0, marginRight: theme.spacing(1) }}
                          checked={values.tags?.some((t) => t.slug === "retail")}
                        />
                        <Typography variant="body1">
                          {`${i18n._(t`Retail & Others`)} (${i18n._(t`precio mensual`)})`}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
            )}
            <Grid item xs={12} className={classes.errorMessageContainer}>
              <Box display="flex" style={{ float: "right" }}>
                {(priceError || durationError) && (
                  <Typography variant="body1" color="error">
                    {`${i18n._(t`Por favor complete todos los campos.`)}`}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <StepButtons
          hasNext
          labelNext={<Trans>Guardar</Trans>}
          onResetChanges={handleResetChanges}
          disableResetChanges={!somethingHasChanged}
          disableBack={status === "loading"}
          disableContinue={disableContinue}
          onSetOpenDelete={onSetOpenDelete}
        />
      </ValidatorForm>
    </Box>
  );
};

StepContent.propTypes = {
  space: PropTypes.object.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

const PriceStep = (props) => {
  const { i18n } = useLingui();

  const Helper = () => (
    <>
      <HelpTitle text={`${i18n._(t`Duración máxima / mínima`)}`} />
      <HelpItem
        text={`${i18n._(
          t`La duración mínima es la menos cantidad de días que deseas alquilar tu espacio. Si eres flexible, te recomendamos dejarlo en un solo día.`,
        )}`}
      />
      <HelpItem
        text={`${i18n._(
          t`La duración máxima define el límite de tiempo que estás dispuesto a alquilar tu espacio. Si eres flexible, te recomendamos dejarlo en 12 meses.`,
        )}`}
      />
      <HelpTitle text={`${i18n._(t`Precio`)}`} />
      <HelpItem text={`${i18n._(t`El precio por día será el precio visible en los resultados de la búsqueda.`)}`} />
      <HelpItem text={`${i18n._(t`El precio por semana corresponde a 7 días, y el precio de mes a 30.`)}`} />
      <HelpItem
        text={`${i18n._(
          t`Muchos de nuestros clientes filtran sus búsquedas basándose en el precio. Te recomendamos tener precios competitivos.`,
        )}`}
      />
      <HelpItem
        text={`${i18n._(t`Si no sabes qué precio establecer, consulta a nuestros expertos en info@bookcorner.com`)}`}
      />
      <HelpItem
        text={`${i18n._(t`Podrás negociar el precio con los clientes una vez que inicies una conversación.`)}`}
      />
    </>
  );

  return <BaseStep mainContent={<StepContent {...props} />} helpContent={<Helper />} title={`${i18n._(t`Precio`)}`} />;
};

export default PriceStep;
