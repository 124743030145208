import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import { CircularProgress, Paper, TextField, Typography } from "@material-ui/core";
import { Trans, t } from "@lingui/macro";

import { useLingui } from "@lingui/react";
import theme from "../../theme-admin";
import CustomCheckbox from "../custom-checkbox";
import { limitStringChars, sanitizeMultiSelectOptions } from "../../utils";

const useStyles = makeStyles((th) => ({
  option: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    paddingLeft: 2,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent",
    },
    '&[data-focus="true"]': {
      backgroundColor: th.palette.action.hover,
    },
  },
  input: {
    borderRadius: 0,
    border: th.borders[1],
    paddingTop: th.spacing(0),
    paddingBottom: th.spacing(0),
    paddingLeft: th.spacing(2),
  },
  paper: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: th.spacing(1),
    paddingRight: th.spacing(1),
  },
}));

const PaperBody = ({ children, lengthOptions, onClose, onClear, onAccept }) => (
  <Paper>
    <Box sx={{ height: 170, overflow: "hidden", overflowY: "scroll" }}>{children}</Box>
    {lengthOptions > 0 ? (
      <Box display="flex" justifyContent="flex-end" gridGap={3} p={1} style={{ backgroundColor: theme.palette.white }}>
        <Button
          onMouseDown={(event) => {
            event.preventDefault();
          }}
          color="default"
          onClick={onClear}
        >
          <Trans>Limpiar</Trans>
        </Button>
        <Button
          onMouseDown={(event) => {
            event.preventDefault();
          }}
          variant="contained"
          color="default"
          onClick={onClose}
        >
          <Trans>Cancelar</Trans>
        </Button>
        <Button
          onMouseDown={(event) => {
            event.preventDefault();
          }}
          variant="contained"
          color="primary"
          onClick={onAccept}
        >
          <Trans>Aceptar</Trans>
        </Button>
      </Box>
    ) : (
      <Box display="flex" justifyContent="flex-end" gridGap={3} p={1} style={{ backgroundColor: theme.palette.white }}>
        <Button
          onMouseDown={(event) => {
            event.preventDefault();
          }}
          color="default"
          onClick={onClose}
        >
          <Trans>Cerrar</Trans>
        </Button>
      </Box>
    )}
  </Paper>
);

const MultiselectAutocomplete = ({
  disabled = false,
  open = false,
  allOptions = [],
  pendingValue,
  onClose,
  onClear,
  onAccept,
  onChange,
  onOpen,
}) => {
  const classes = useStyles(theme);
  const { i18n } = useLingui();

  return (
    <Autocomplete
      classes={{
        option: classes.paper,
      }}
      open={open}
      onOpen={onOpen}
      disabled={disabled}
      multiple
      limitTags={2}
      onChange={onChange}
      freeSolo
      getOptionSelected={(option, value) => {
        return option.id === value.id;
      }}
      value={pendingValue}
      getOptionLabel={(option) => option.name}
      disableCloseOnSelect
      renderTags={() => null}
      noOptionsText="..."
      ListboxComponent={PaperBody}
      ListboxProps={{
        lengthOptions: allOptions.length,
        onClose,
        onClear,
        onAccept,
      }}
      renderOption={(option) => {
        const isSelected = pendingValue.some((opt) => opt.id === option.id);
        return allOptions.length > 0 ? (
          <Box display="flex" alignItems="center">
            <CustomCheckbox checked={isSelected} />
            <Typography variant="body2" className={classes.option}>
              {i18n._(t`${option.name}`)}
            </Typography>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" width="100%">
            <CircularProgress size={30} />
          </Box>
        );
      }}
      options={sanitizeMultiSelectOptions(allOptions)}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          placeholder={limitStringChars(pendingValue.map((ad) => ad.title).join(","), 25)}
          InputProps={{
            ...params.InputProps,
            className: classes.input,
            style: {
              color: theme.palette.black,
              width: "100%",
              paddingTop: 0,
            },
          }}
          fullWidth
        />
      )}
    />
  );
};

export default MultiselectAutocomplete;
