import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "@reach/router";
import { useNavigate } from "/src/hooks/navigation";
// Actions
import { t } from "@lingui/macro";
import { getSpaceThunk, resetSpace } from "../../redux/actions/space-actions";
import ListingFormComponent from "../../components/listing-form";
import { setDashboardTitle } from "../../redux/actions/misc-actions";
import api from "../../api";
import { resetAllAdSpaces } from "../../redux/actions/adspaces-actions";

const ListingFormContainer = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { navigate } = useNavigate();

  const user = useSelector((state) => state.user.data.id);
  const spaceId = useSelector((state) => state.listing.data.id);
  const spaceTitle = useSelector((state) => state.listing.data.title);
  const getStatus = useSelector((state) => state.listing.getStatus);

  const [deleteStatus, setDeleteStatus] = useState("initial");

  const handleDeleteAdSpace = async () => {
    setDeleteStatus("loading");
    const res = await api.deleteSpace(spaceId);
    if (res.status === 200) {
      setDeleteStatus("success");
    } else {
      setDeleteStatus("error");
    }
  };

  const hasAValidId = () => parseInt(params.spaceId) > 0;
  const hasStepName = () => params.stepName !== undefined;

  useEffect(() => {
    const spaceTitleCheck = params.spaceId === "new" && !spaceTitle ? `${t`Nuevo espacio`}` : spaceTitle;
    const title = params.spaceId === "new" ? `${t`Crear`}` : `${t`Editar`}`;
    dispatch(setDashboardTitle(`${t`Espacios`} - ${title}: ${spaceTitleCheck}`));
    dispatch(resetAllAdSpaces());
  }, [spaceTitle]);

  useEffect(() => {
    if (
      !hasAValidId() &&
      hasStepName() &&
      params.stepName !== "type" &&
      params.spaceId === "new" &&
      params.stepName !== "location"
    ) {
      navigate("/administrator/spaces/new");
    }
  }, []);

  useEffect(() => {
    if (hasAValidId()) {
      dispatch(getSpaceThunk(params.spaceId, user, true));
    }
  }, [params.spaceId]);

  useEffect(() => {
    if (hasAValidId() && getStatus === "error") {
      navigate("/administrator/spaces");
    }
  }, [getStatus]);

  const resolveStep = () => {
    switch (params.stepName) {
      case "title":
        return 0;
      case "location":
        return 1;
      case "space-type":
        return 2;
      case "characteristics":
        return 3;
      case "photos":
        return 4;
      case "price":
        return 5;
      case "calendar":
        return 6;
      default:
        return 0;
    }
  };

  return (
    <ListingFormComponent
      isNew={params.spaceId === "new"}
      spaceId={spaceId}
      spaceTitle={spaceTitle}
      step={resolveStep()}
      deleteSpace={handleDeleteAdSpace}
      deleteStatus={deleteStatus}
    />
  );
};

export default ListingFormContainer;
