import React, { useEffect, useState } from "react";
import { useParams } from "@reach/router";
import { useSelector, useDispatch } from "react-redux";
import { getAssetThunk } from "../../../redux/actions/asset-actions";
import RepresentativeComponent from "../../../components/asset-form/representative";
import api from "../../../api";

const Representative = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [patchRequest, setPatchRequest] = useState({ status: "initial", error: "" });

  const { assetId } = params;
  const asset = useSelector((state) => state.asset.data);

  useEffect(() => {
    return () => {
      setPatchRequest({ status: "initial" });
    };
  }, []);

  const handleSubmit = async (values) => {
    setPatchRequest({ status: "loading" });
    const res = await api.updateAsset(assetId, { ...asset, ...values });
    if (res.status === 200) {
      dispatch(getAssetThunk(assetId));
      setPatchRequest({ status: "success" });
    } else {
      setPatchRequest({ status: "error", error: res.data });
    }
  };

  return <RepresentativeComponent asset={asset} submitStatus={patchRequest.status} submit={handleSubmit} />;
};

export default Representative;
