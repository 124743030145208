/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useLingui } from "@lingui/react";
import Typography from "@material-ui/core/Typography";
import { Trans, t } from "@lingui/macro";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AdSpaceStepButtons from "../ad-space-step-buttons";
import TextField from "../../text-field";
import theme from "../../../theme";
import { currencySymbol } from "../../../utils/currencyUtils";
import SelectCategory from "../../listing-form/type-step/select-category";
import GalleryComponent from "../../listing-form/images-step/gallery";

const useStyles = makeStyles((th) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    color: th.palette.text.primary,
    marginTop: th.spacing(2),
  },
  validatorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    height: "100%",
    width: "100%",
  },
  inputRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
  },
  inputs: {
    width: "217px",
  },
  textField: {
    "& .MuiInputBase-inputMultiline": {
      height: "68px",
    },
  },
  selects: {
    display: "flex",
    flexDirection: "row",
  },
  attachmentIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: th.spacing(1 / 2),
    marginLeft: theme.spacing(1),
    borderRadius: "5px",
    width: 80,
    height: 80,
    backgroundColor: th.palette.grayC4,
    cursor: "pointer",
    position: "relative",
  },
  attachmentImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: th.spacing(1 / 2),
    marginLeft: th.spacing(1),
    borderRadius: "5px",
    width: 80,
    height: 80,
    cursor: "pointer",
    position: "relative",
  },
  closeIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "22px",
    height: "22px",
    position: "absolute",
    borderRadius: "50%",
    backgroundColor: th.palette.grayC4,
    zIndex: 999,
    right: "-8px",
    top: "-8px",
    cursor: "pointer",
  },
  continueButton: {
    width: 190,
    height: 42,
    [th.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  typeRadioButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    marginRight: th.spacing(4),
  },
  typeRadioButtonSuccess: {
    color: th.palette.primary.main,
  },
  label: {
    fontSize: "13px",
    fontWeight: "bold",
  },
  backdrop: {
    zIndex: th.zIndex.drawer + 1,
    color: th.palette.white,
  },
  customInput: {
    width: 230,
    height: "auto",
    padding: 0,
    display: "flex",
    justifyContent: "center",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingTop: 0,
    },
  },
  filterItemRow: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    border: `1px solid ${theme.palette.disabledRow}`,
    borderRadius: "5px",
  },
  customAutocomplete: {
    width: "auto",
    minWidth: "475px",
    height: "auto",
    padding: 0,
    display: "flex",
    fontSize: "14px",
    marginRight: "24px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingTop: 0,
    },
  },
}));

const Information = ({
  showAdspaceCategories,
  adspace = {},
  adSpacesCategories = [],
  spotAdspaceCategories = [],
  status = "initial",
  types = [],
  onDataChange = () => {},
  submit = () => {},
  submitStatus = "initial",
  onSetOpenDelete,
  isNew,
  showOverlapField,
}) => {
  const classes = useStyles(theme);
  const [values, setValues] = useState(adspace);
  const [categoryTags, setCategoryTags] = useState([]);
  const [spotTags, setSpotTags] = useState([]);

  const { i18n } = useLingui();

  const handleUploadImage = (images) => {
    setValues((v) => ({ ...v, attachments: images }));
    onDataChange({ ...values, attachments: images });
  };

  const handleInputChange =
    (field) =>
    ({ target }) => {
      setValues((v) => ({ ...v, [field]: target.value }));
    };

  const handleSelectInput = (field) => (value) => {
    setValues((v) => ({ ...v, [field]: value.name }));
  };

  const handleAdSpaceCategory = (adSpaceCategory) => {
    setCategoryTags([adSpaceCategory]);
    setValues((v) => ({ ...v, tags: [...spotTags, adSpaceCategory] }));
  };

  const handleSubmit = () => {
    submit(values);
  };

  const parseFloatPrices = (price) => {
    return !isNaN(price) && price ? parseFloat(price) : "";
  };

  const hashCode = function (s) {
    return typeof s === "string"
      ? s.split("").reduce(function (a, b) {
          a = (a << 5) - a + b.charCodeAt(0);
          return a & a;
        }, 0)
      : "";
  };

  const handleSpotAdspaceCategory = (selectedCategories) => {
    setSpotTags(selectedCategories);
    setValues((v) => ({ ...v, tags: [...categoryTags, ...selectedCategories] }));
  };

  useEffect(() => {
    if (spotAdspaceCategories.length) {
      const { tags } = adspace;
      setCategoryTags(tags?.filter((tag) => !spotAdspaceCategories?.some((spotTag) => spotTag.id === tag.id)) || []);
      setSpotTags(tags?.filter((tag) => spotAdspaceCategories?.some((spotTag) => spotTag.id === tag.id)) || []);
    }
    setValues(adspace);
  }, [adspace, spotAdspaceCategories]);

  const somethingHasChanged = JSON.stringify(values) !== JSON.stringify(adspace);
  const handleResetChanges = () => setValues(adspace);

  const disableSubmit =
    Number(adspace.month_price) > 0 &&
    Number(adspace.fortnight_price) > 0 &&
    adspace.adspace_type &&
    adspace.latitude &&
    adspace.longitude &&
    adspace.asset_id > 0 &&
    (adspace.level || typeof adspace.level === "number") &&
    submitStatus !== "loading" &&
    !isNew &&
    !somethingHasChanged;

  return (
    <Box className={classes.container}>
      <Backdrop className={classes.backdrop} open={status === "loading"}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={submitStatus === "error"} autoHideDuration={3000}>
        <MuiAlert elevation={6} variant="filled" severity="error">
          <Trans>Ha ocurrido un error. Por favor intente de nuevo.</Trans>
        </MuiAlert>
      </Snackbar>
      <ValidatorForm className={classes.validatorContainer} onSubmit={() => {}}>
        <Box px={2} pt={2} sx={{ overflowY: "auto", height: "calc(100% - 96px)" }}>
          <Box className={classes.inputRow}>
            <Box display="flex" flexDirection="row">
              <TextField
                type="number"
                label={`${i18n._(t`Precio Quincenal`)}`}
                style={{ width: "180px" }}
                validators={["required", "isPositive"]}
                errorMessages={[""]}
                value={parseFloatPrices(values.fortnight_price)}
                onChange={handleInputChange("fortnight_price")}
                InputProps={{
                  placeholder: "250",
                  startAdornment: <InputAdornment position="start">{currencySymbol()}</InputAdornment>,
                }}
              />
              <TextField
                type="number"
                label={`${i18n._(t`Precio Mensual`)}`}
                style={{ width: "180px", marginLeft: theme.spacing(2) }}
                validators={["required", "isPositive"]}
                errorMessages={[""]}
                value={parseFloatPrices(values.month_price)}
                onChange={handleInputChange("month_price")}
                InputProps={{
                  placeholder: "500",
                  startAdornment: <InputAdornment position="start">{currencySymbol()}</InputAdornment>,
                }}
              />
            </Box>
          </Box>
          <Box mt={2} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-end">
            <Box>
              <Box>
                <Typography variant="body1" style={{ fontWeight: 700 }}>
                  <Trans>Subir imágenes del espacio (hasta 6 imágenes)</Trans>
                </Typography>
              </Box>
              <GalleryComponent
                type="image"
                companyId={values.id}
                values={values.attachments}
                onChangeValues={handleUploadImage}
                modelToUpdateFile="adspace"
                widthPhoto={63}
                heightPhoto={63}
              />
            </Box>
          </Box>

          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" mt={2} mb={3}>
            <Box mr={20}>
              <Box className={classes.selects}>
                <SelectCategory
                  onSelect={handleSelectInput("adspace_type")}
                  value={hashCode(values?.adspace_type)}
                  values={types?.map((t) => ({ id: hashCode(t), name: t }))}
                  label={`${i18n._(t`Ubicación`)}`}
                  fullWidth
                />
              </Box>
            </Box>

            {showAdspaceCategories && (
              <Box pt={2} className={classes.filterItemRow}>
                <Autocomplete
                  value={categoryTags?.length ? categoryTags[0].name : ""}
                  options={adSpacesCategories || []}
                  getOptionLabel={(option) => option.name}
                  className={classes.customInput}
                  onChange={(event, adSpaceCategory) => {
                    handleAdSpaceCategory(adSpaceCategory);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.customInput}
                      placeholder={categoryTags?.length ? categoryTags[0].name : `${i18n._(t`Seleccionar categoría`)}`}
                    />
                  )}
                />
              </Box>
            )}
          </Box>

          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" mt={3} mb={3}>
            <Autocomplete
              multiple
              value={spotTags?.length ? spotTags : []}
              options={
                spotTags?.length
                  ? spotAdspaceCategories?.filter((cat) => !spotTags.some((tag) => tag.name === cat.name)) || []
                  : spotAdspaceCategories || []
              }
              getOptionLabel={(option) => option.data?.title || option.name}
              className={classes.customAutocomplete}
              onChange={(event, spotCategory) => {
                handleSpotAdspaceCategory(spotCategory);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.customAutocomplete}
                  placeholder={`${i18n._(t`Seleccionar categoría de espacio`)}`}
                />
              )}
            />

            {showOverlapField && (
              <TextField
                type="number"
                label={`${i18n._(t`Superposición`)}`}
                value={values.overlap}
                onChange={handleInputChange("overlap")}
                inputProps={{ maxLength: 2 }}
              />
            )}
          </Box>
        </Box>
        <AdSpaceStepButtons
          labelSubmit={<Trans>Guardar</Trans>}
          handleSubmit={handleSubmit}
          disableSubmit={disableSubmit}
          disableContinue={status === "loading"}
          onSetOpenDelete={onSetOpenDelete}
          showDeleteAdSpace={!isNew}
          hasResetChanges={!isNew}
          onResetChanges={handleResetChanges}
          disableResetChanges={!somethingHasChanged}
        />
      </ValidatorForm>
    </Box>
  );
};

export default Information;
