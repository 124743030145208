import { Box, Grid, Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import Calendar from "../../calendar";
import { formatDateUtc } from "../../../utils/dateUtils";
// theme
import theme from "../../../theme";
// My Components
import BlockDatesDialog from "./block-dates-dialog";
import BaseStep from "../step-base";

import UnavailableDateRangesList from "./unavailable-date-ranges-list";
import FinishDialog from "../../../containers/listing-form/finish-dialog";
import StepButtons from "../step-buttons";

const useStyles = makeStyles((th) => ({
  backButton: {
    marginRight: th.spacing(1),
    width: 150,
    height: 42,
  },
  continueButton: {
    width: 190,
    height: 42,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    height: "100%",
    width: "100%",
  },
}));

const CalendarContent = ({
  onSubmit,
  onPublishStep,
  inProgressBookings,
  findingBookingsStatus,
  getInProgressBookings,
  status,
  blockDates,
  unblockDates,
  unavailableDateRangesStatus,
  blockDatesError,
  unavailableDates,
  unavailableDatesRange,
  space,
  onSetOpenDelete,
}) => {
  const classes = useStyles(theme);
  const [values, setValues] = useState({});
  const [open, setOpen] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [datesSelected, setDatesSelected] = useState(["", ""]);
  const [openBlockDatesDialog, setOpenBlockDatesDialog] = useState(false);
  const [blockDescription, setBlockDescription] = useState("");

  const { i18n } = useLingui();

  const getErrorText = (error) => {
    switch (error) {
      case "space_not_available":
        return i18n._(t`El espacio no se encuentra disponible. Revisa las fechas elegidas.`);
      default:
        return i18n._(t`No fue posible completar la acción.`);
    }
  };

  useEffect(() => {
    if (space) {
      setValues(space);
    }
  }, [space]);

  useEffect(() => {
    getInProgressBookings(datesSelected[0], datesSelected[1]);
  }, [datesSelected]);

  const handleSubmit = () => {
    onSubmit(values);
    setSubmited(true);
  };

  const handleSelectDates = (dates) => {
    setDatesSelected(dates);
  };

  const handleBlockDates = () => {
    blockDates(
      formatDateUtc(datesSelected[0], "YYYY-MM-DD"),
      formatDateUtc(datesSelected[1], "YYYY-MM-DD"),
      blockDescription,
    );
    setDatesSelected(["", ""]);
  };

  const handleIsTileDisabled = ({ date }) => {
    const formattedDate = formatDateUtc(date, "YYYY-MM-DD");

    return unavailableDates[formattedDate] !== undefined;
  };
  const disableSubmit =
    status === "loading" ||
    findingBookingsStatus === "loading" ||
    findingBookingsStatus === "error" ||
    datesSelected[0] === "" ||
    datesSelected[1] === "" ||
    submited;

  return (
    <Box className={classes.container} mt={2}>
      <BlockDatesDialog
        open={openBlockDatesDialog}
        desc={blockDescription}
        bookingsBlocked={inProgressBookings}
        onDescChange={setBlockDescription}
        onAccept={handleBlockDates}
        onClose={() => {
          setOpenBlockDatesDialog(false);
        }}
      />
      <FinishDialog
        open={open}
        status={status}
        publishSpace={onPublishStep}
        onSubmit={handleSubmit}
        onClose={() => {
          setOpen(false);
        }}
      />
      <Box px={2} style={{ overflowY: "auto" }}>
        <Grid container spacing={2}>
          <Grid item sm={6} md={8}>
            <Box display="flex" flexDirection="column" mb={1}>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                <Trans>
                  Muestra la disponibilidad de tus espacios y bloquea el calendario si tienes reservas anteriores
                </Trans>
              </Typography>
            </Box>
            <Calendar
              startDate={datesSelected[0] || null}
              endDate={datesSelected[1] || null}
              tileDisabled={handleIsTileDisabled}
              onChange={handleSelectDates}
              isDouble
            />
            {blockDatesError && (
              <Typography variant="body1" color="error">
                {getErrorText(blockDatesError)}
              </Typography>
            )}
            {findingBookingsStatus === "error" && (
              <Typography variant="body1" style={{ color: theme.palette.error.main }}>
                <Trans>Ha ocurrido un error. Por favor intente de nuevo.</Trans>
              </Typography>
            )}
          </Grid>
          <Grid item sm={6} md={4}>
            <UnavailableDateRangesList
              unavailableDatesRange={unavailableDatesRange}
              unavailableDateRangesStatus={unavailableDateRangesStatus}
              unblockDates={unblockDates}
            />
          </Grid>
        </Grid>
      </Box>
      <StepButtons
        hasNext
        handleContinue={() => setOpenBlockDatesDialog(true)}
        onSetOpenDelete={onSetOpenDelete}
        labelNext={<Trans>Bloquear fechas</Trans>}
        disableContinue={disableSubmit}
        hasResetChanges={false}
        widthNext={150}
      />
    </Box>
  );
};

const CalendarStep = (props) => {
  const { i18n } = useLingui();

  return <BaseStep mainContent={<CalendarContent {...props} />} title={`${i18n._(t`CALENDARIO`)}`} />;
};

export default CalendarStep;
