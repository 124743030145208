import { Box, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { RefreshSharp } from "@material-ui/icons";
import React from "react";
// theme
import theme from "../../theme-admin";

const useStyles = makeStyles((theme) => ({
  icon: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

// bookingState represents inprogress, confirmed.
const AssetsToolbar = ({ onTableChange }) => {
  const classes = useStyles(theme);

  const handleRefresh = () => onTableChange("changePage", { page: 0 });

  return (
    <Box
      flex={5}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"flex-start"}
    >
      <IconButton onClick={handleRefresh} className={classes.icon}>
        <RefreshSharp />
      </IconButton>
    </Box>
  );
};

export default AssetsToolbar;
