import React from "react";
import { DashboardSharp } from "@material-ui/icons";
import { Trans } from "@lingui/macro";
import AssetsIcon from "../../icons/sass-icono-assets-info";
import TenantsIcon from "../../icons/sass-icono-assigne";
import RequestsIcon from "../../icons/sass-icono-consultas";
import SpacesIcon from "../../icons/sass-icono-espacios";
import PerformanceIcon from "../../icons/sass-icono-estadisticas";
import InvoicesIcon from "../../icons/sass-icono-facturacion";
import BookingsIcon from "../../icons/sass-icono-reservas";
import AdIcon from "../../icons/ad-icon";
import FileUploadIcon from "../../icons/upload-icon";

const OPTIONS = {
  home: {
    text: "Dashboard",
    icon: (props) => <DashboardSharp fill="white" {...props} />,
    description: "",
    link: "",
    pathUrl: "home",
  },
  consultas: {
    text: "Consultas",
    icon: (props) => <RequestsIcon fill="white" width={20} height={20} {...props} />,
    description: (
      <Trans>
        Administre fácilmente todas las solicitudes que reciba y abra una línea de contacto rápida y fluida con sus
        inquilinos.
      </Trans>
    ),
    pathUrl: "requests",
    linkText: <Trans>ir a consultas {">>"}</Trans>,
  },
  reservas: {
    text: "Reservas",
    icon: (props) => <BookingsIcon fill="white" width={20} height={20} {...props} />,
    description: (
      <Trans>
        Gestiona las reservas y controla la llegada de tus clientes para una organización sencilla y efectiva.
      </Trans>
    ),
    pathUrl: "bookings",
    linkText: <Trans>ir a reservas {">>"}</Trans>,
  },
  facturacion: {
    text: "Facturación",
    icon: (props) => <InvoicesIcon fill="white" width={20} height={20} {...props} />,
    description: (
      <Trans>
        Mantén un registro de los cobros y la facturación de tus activos accediendo al historial de todas las
        operaciones.
      </Trans>
    ),
    pathUrl: "invoicing",
    linkText: <Trans>ir a facturación {">>"}</Trans>,
  },
  rendimiento: {
    text: "Rendimiento",
    icon: (props) => <PerformanceIcon fill="white" {...props} />,
    description: (
      <Trans>Analiza la performance de cada activo y haz una comparativa entre la información que elijas.</Trans>
    ),
    pathUrl: "performance",
    linkText: <Trans>ir a rendimiento {">>"}</Trans>,
  },
  espacios: {
    text: "Espacios",
    icon: (props) => <SpacesIcon fill="white" {...props} />,
    description: (
      <Trans>Maneja los activos cargados en la base de datos pudiendo editarlos o eliminarlos de la plataforma.</Trans>
    ),
    pathUrl: "spaces",
    linkText: <Trans>ir a espacios {">>"}</Trans>,
  },
  publicitariosespacios: {
    text: "Espacios Publicitarios",
    icon: (props) => <AdIcon fill="white" width={20} height={20} {...props} />,
    description: (
      <Trans>Maneja los activos cargados en la base de datos pudiendo editarlos o eliminarlos de la plataforma.</Trans>
    ),
    pathUrl: "adspaces",
    linkText: <Trans>ir a espacios publicitarios{" >>"}</Trans>,
  },
  clientes: {
    text: "Clientes",
    icon: (props) => <TenantsIcon style={{ width: "50px", marginLeft: "4px" }} fill="white" {...props} />,
    description: (
      <Trans>
        Maneja los clientes existentes en la base de datos pudiendo editarlos o eliminarlos de la plataforma.
      </Trans>
    ),
    pathUrl: "clients",
    linkText: <Trans>ir a clientes {">>"}</Trans>,
  },
  assets: {
    text: "Centros & Propietarios",
    icon: (props) => <AssetsIcon fill="white" {...props} />,
    description: (
      <Trans>Administra tu cartera de Centros Comerciales y propietarios de manera eficaz en un solo lugar.</Trans>
    ),
    pathUrl: "assets-landlords",
    linkText: <Trans>ir a centros & propietarios {">>"}</Trans>,
  },
  imports: {
    text: "Importaciones",
    icon: (props) => (
      <FileUploadIcon style={{ fill: "white", width: 45, height: 45, marginBottom: "4px" }} {...props} />
    ),
    description: (
      <Trans>
        Agiliza la incorporación de espacios, espacios publicitarios y compañías a tu sistema mediante la carga masiva.
        Una solución rápida y directa para expandir tus datos.
      </Trans>
    ),
    pathUrl: "imports",
    linkText: <Trans>ir a importaciones {">>"}</Trans>,
  },
};

export default OPTIONS;
