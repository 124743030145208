import { Box, Chip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import MultiselectSearchFilter from "../multiselect-search-filter";
import CountryIcon from "../../icons/country-icon";
// theme
import theme from "../../theme-admin";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 32,
    height: 32,
  },
  filters: {
    "& > .MuiAutocomplete-root": {
      marginRight: theme.spacing(1),
    },
  },
  customInput: {
    "& .MuiOutlinedInput-input": {
      color: theme.palette.text.primary,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      fontSize: 16,
      width: "75px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "5px 5px 5px 0px",
      border: "none",
    },
    "& .MuiAutocomplete-inputRoot": {
      padding: 0,
      backgroundColor: theme.palette.lighterGray,
      overflowX: "auto",
    },
  },
}));

const LandlordsToolbar = ({
  onRefresh,
  countries = [],
  handleFilterSpaces = () => {},
}) => {
  const classes = useStyles(theme);

  const [filters, setFilters] = useState({});

  const { i18n } = useLingui();

  useEffect(() => {
    handleFilterSpaces(filters);
  }, [filters]);

  const handleOnSelectFilters = (filter) => (newValue) => {
    setFilters({
      ...filters,
      [filter]: [...newValue.map((v) => v.id)],
    });
  };

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      width="100%"
      className={classes.filters}
    >
      <MultiselectSearchFilter
        title={i18n._(t`País`)}
        localStorageName={"landlordCountries"}
        icon={() => (
          <CountryIcon className={classes.icon} fill={theme.palette.black} />
        )}
        options={countries}
        onSelect={handleOnSelectFilters("countries")}
      />
    </Box>
  );
};

export default LandlordsToolbar;
