import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function SizeIcon({ fill = "#333333", ...rest }) {
  return (
    <SvgIcon
      width="156"
      height="127"
      viewBox="0 0 156 127"
      fill="none"
      {...rest}
    >
      <path
        d="M130.968 0H53.448C41.208 0 31.008 9.18 29.172 20.808H8.16V8.568H0V20.808V37.128V47.328H28.968V102C28.968 115.464 39.984 126.48 53.448 126.48H130.968C144.432 126.48 155.448 115.464 155.448 102V24.48C155.448 11.016 144.432 0 130.968 0ZM28.56 39.168H8.16V37.128V28.968H28.56V39.168ZM147.288 102C147.288 110.976 139.944 118.32 130.968 118.32H53.448C44.472 118.32 37.128 110.976 37.128 102V24.48C37.128 15.504 44.472 8.16 53.448 8.16H130.968C139.944 8.16 147.288 15.504 147.288 24.48V102Z"
        fill={fill}
      />
      <path
        d="M92.208 18.36C67.524 18.36 47.328 38.556 47.328 63.24C47.328 87.924 67.524 108.12 92.208 108.12C116.892 108.12 137.088 87.924 137.088 63.24C137.088 38.556 117.096 18.36 92.208 18.36ZM92.208 99.96C72.012 99.96 55.488 83.436 55.488 63.24C55.488 43.044 72.012 26.52 92.208 26.52C105.06 26.52 116.484 33.252 123.012 43.248H94.86C84.252 43.248 75.48 52.02 75.48 62.628C75.48 73.236 84.252 82.008 94.86 82.008H123.828C117.3 92.82 105.672 99.96 92.208 99.96ZM127.296 73.848H94.656C88.536 73.848 83.436 68.748 83.436 62.628C83.436 56.508 88.536 51.408 94.656 51.408H126.888C128.112 55.08 128.928 58.956 128.928 63.24C128.928 66.912 128.316 70.584 127.296 73.848Z"
        fill={fill}
      />
    </SvgIcon>
  );
}
