import React from "react";
import { Box, Typography } from "@material-ui/core";
import Switch from "../custom-switch";

const VisibilitySwitch = ({ label = "", searchable = false, onClickPublish = () => {}, isDisabled = false }) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box mr={1}>
        <Typography variant="body1">{label}</Typography>
      </Box>
      <Switch isDisabled={isDisabled} checked={searchable} onChange={onClickPublish} />
    </Box>
  );
};

export default VisibilitySwitch;
