import { Box, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// Local components
import { ValidatorForm } from "react-material-ui-form-validator";
// theme
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import theme from "../../../theme";
import TextField from "../../text-field";
// My Components
import BaseStep from "../step-base";
import StepButtons from "../step-buttons";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    height: "100%",
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
    width: 150,
    height: 42,
  },
  continueButton: {
    width: 190,
    height: 42,
  },
  errorMessageContainer: {
    marginTop: theme.spacing(2),
    minHeight: 36,
  },
  icons: {
    float: "left",
    padding: 2,
  },
  helpTitle: {
    fontWeight: "bold",
  },
  labelDescription: {
    transform: "translate(0, 15px) scale(1)",
    paddingBottom: 0,
  },
  labelInput: {
    transform: "translate(0, -30px) scale(1)!important",
  },
  label: {
    fontSize: "13px",
    fontWeight: "bold",
  },
}));

const HelpSection = styled(Box)({
  marginBottom: theme.spacing(1),
});

const StepContent = ({ space, handleBack, handleNext, onSubmit, status, isNew, onSetOpenDelete }) => {
  const classes = useStyles(theme);
  const [values, setValues] = useState({});
  const [submited, setSubmited] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const labelNext = isNew ? <Trans>Continuar</Trans> : <Trans>Guardar</Trans>;

  useEffect(() => {
    setValues(space);
  }, [space]);

  useEffect(() => {
    if (submited && isNew) {
      handleNext();
    }
  }, [submited, status]);

  const handleSubmit = () => {
    onSubmit({
      ...space,
      ...values,
    });
    setSubmited(true);
  };

  const handleInputChange =
    (field) =>
    ({ target }) => {
      setValues((state) => ({ ...state, [field]: target.value }));
    };

  const validatorListener = (result) => {
    setValidationError(!result);
  };

  const { i18n } = useLingui();

  const somethingHasChanged = JSON.stringify(values) !== JSON.stringify(space);
  const handleResetChanges = () => setValues(space);
  const canContinue =
    (values.title?.length <= 3 && values.description?.length <= 3) ||
    status === "loading" ||
    (!isNew && !somethingHasChanged);

  return (
    <Box width="100%">
      <ValidatorForm className={classes.container} onSubmit={handleSubmit}>
        <Box px={2} pt={2}>
          <Box mb={1}>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <TextField
                  labelClassNames={classes.labelDescription}
                  labelInputClassNames={classes.labelInput}
                  label={`${i18n._(t`¿Cuál es el título de tu espacio?*`)}`}
                  description={`${i18n._(t`No está permitido utilizar el nombre real.`)}`}
                  placeholder={`${i18n._(t`Ej.: Tienda boutique ubicada en Malasaña`)}`}
                  validators={["required"]}
                  errorMessages={[""]}
                  value={values.title}
                  onChange={handleInputChange("title")}
                  validatorListener={validatorListener}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
          <Box mb={2}>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <TextField
                  multiline
                  rows={6}
                  description={`${i18n._(t`Describe brevemente qué es lo que hace único a tu espacio*`)}`}
                  placeholder={`${i18n._(
                    t`Ej.: Tienda recién reformada perfecta para hacer cualquier tipo de acción comercial, situada en una zona de alta afluencia de público`,
                  )}`}
                  validators={["required"]}
                  errorMessages={[""]}
                  value={values.description}
                  onChange={handleInputChange("description")}
                  validatorListener={validatorListener}
                  labelClassNames={classes.label}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
          <Grid item xs={6} className={classes.errorMessageContainer}>
            <Box display="flex" style={{ float: "right" }}>
              {validationError && (
                <Typography variant="body1" color="error">
                  {`${i18n._(t`Por favor complete todos los campos.`)}`}
                </Typography>
              )}
            </Box>
          </Grid>
        </Box>
        <StepButtons
          disableShowSpace={isNew}
          onSetOpenDelete={onSetOpenDelete}
          hasNext
          handleContinue={handleSubmit}
          labelNext={labelNext}
          disableBack={status === "loading"}
          disableContinue={canContinue}
          hasResetChanges={!isNew}
          onResetChanges={handleResetChanges}
          disableResetChanges={!somethingHasChanged}
        />
      </ValidatorForm>
    </Box>
  );
};

StepContent.propTypes = {
  space: PropTypes.object.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSetOpenDelete: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

const DescriptionStep = (props) => {
  const classes = useStyles(theme);
  const { i18n } = useLingui();

  const Helper = () => (
    <>
      <HelpSection>
        <Typography variant="body2" className={classes.helpTitle}>
          <Trans>Título</Trans>
        </Typography>
        <Typography variant="body2">
          <Trans>Será lo primero que verán tus clientes</Trans>.
        </Typography>
        <Box display="flex">
          <CheckIcon fontSize="small" className={classes.icons} />
          <Typography variant="body2" component="label">
            {" "}
            <Trans>Genera una buena primera impresión</Trans>{" "}
          </Typography>
        </Box>
        <Box display="flex">
          <CheckIcon fontSize="small" className={classes.icons} />
          <Typography variant="body2" component="label">
            {" "}
            <Trans>Intenta ser breve y conciso</Trans>{" "}
          </Typography>
        </Box>
      </HelpSection>
      <HelpSection style={{ marginBottom: 0 }}>
        <Typography variant="body2" className={classes.helpTitle}>
          <Trans>Descripción</Trans>
        </Typography>
        <Typography variant="body2">
          <Trans>Describe tu espacio y la zona en la que se encuentra</Trans>.
        </Typography>
      </HelpSection>
    </>
  );

  return <BaseStep mainContent={<StepContent {...props} />} helpContent={<Helper />} title={`${i18n._(t`Título`)}`} />;
};

export default DescriptionStep;
