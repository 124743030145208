import React from "react";
import { Box } from "@material-ui/core";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import DateRangeFilter from "../date-range-filter";

const ConfirmTransactionsToolbar = ({ filters, onFilterDatesChange }) => {
  const { i18n } = useLingui();

  return (
    <Box flex={5} display="flex" alignItems="center" justifyContent="flex-start">
      <DateRangeFilter
        startValues={{
          start: filters.start,
          end: filters.end,
        }}
        title={i18n._(t`Fechas`)}
        onSelect={onFilterDatesChange}
        minDate={null}
      />
    </Box>
  );
};

export default ConfirmTransactionsToolbar;
