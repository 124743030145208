import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useLingui } from "@lingui/react";
import Typography from "@material-ui/core/Typography";
import { Trans, t } from "@lingui/macro";
import { ValidatorForm } from "react-material-ui-form-validator";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import { Grid } from "@material-ui/core";
import TextField from "../../text-field";
import StepButtons from "../../step-buttons";
import theme from "../../../theme";
import DecideDialog from "../../decide-dialog-owner";
import { adapterInvoiceDataByModule } from "../../../utils/adapter-invoice";
import { userRoleAreaManager } from "../../../utils";
import { useSelector } from "react-redux";

const useStyles = makeStyles((th) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    color: th.palette.text.primary,
    paddingLeft: th.spacing(2),
    overflowY: "hidden",
  },
  validatorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    width: "100%",
    paddingBottom: th.spacing(4),
    overflowY: "auto",
  },
  selects: {
    display: "flex",
    flexDirection: "row",
  },
  continueButton: {
    width: 190,
    height: 42,
    [th.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  typeRadioButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    marginRight: th.spacing(4),
  },
  label: {
    fontSize: "13px",
    fontWeight: "bold",
  },
  backdrop: {
    zIndex: th.zIndex.drawer + 1,
    color: th.palette.white,
  },
  fields: {
    marginTop: th.spacing(1),
  },
}));

const Invoicing = ({ asset, submitStatus = "initial", submit = () => {} }) => {
  const classes = useStyles(theme);
  const [openDialog, setOpenDialog] = useState(false);
  const [invoiceData, setInvoiceData] = useState({});
  const userRole = useSelector((state) => state.user.data.role);

  const { i18n } = useLingui();

  const valuesHasChanged = JSON.stringify(invoiceData) !== JSON.stringify(adapterInvoiceDataByModule(asset));

  const handleInputChange =
    (field) =>
    ({ target }) => {
      setInvoiceData((v) => ({ ...v, [field]: target.value }));
    };

  const handleFinish = () => {
    submit(invoiceData);
  };

  useEffect(() => {
    if (asset.invoices_data) {
      const inovicesData = adapterInvoiceDataByModule(asset);
      setInvoiceData(inovicesData);
    }
  }, [asset]);

  const handleCancel = () => {
    const inovicesData = adapterInvoiceDataByModule(asset);
    setInvoiceData(inovicesData);
  };

  return (
    <>
      <Box className={classes.container}>
        <DecideDialog
          open={openDialog}
          status={submitStatus}
          onAccept={handleFinish}
          title={<Trans>Existen cambios sin guardar</Trans>}
          desc={
            <Trans>
              Al editar un campo debes guardar los cambios. ¿Desea guardar los cambios realizados antes de continuar?
            </Trans>
          }
          acceptText={<Trans>Guardar y continuar</Trans>}
          rejectText={<Trans>Continuar sin guardar</Trans>}
          onClose={() => setOpenDialog(false)}
        />
        <Backdrop className={classes.backdrop} open={submitStatus === "loading"}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar open={submitStatus === "error"} autoHideDuration={3000}>
          <MuiAlert elevation={6} variant="filled" severity="error">
            <Trans>Ha ocurrido un error. Por favor intente de nuevo.</Trans>
          </MuiAlert>
        </Snackbar>
        <ValidatorForm className={classes.validatorContainer} onSubmit={() => {}}>
          <Box>
            <Typography variant="subtitle1">
              <Trans>
                Complete aquellos campos que desea sean precargados al momento de generar facturas. Podrán ser editados
                luego.
              </Trans>
            </Typography>
            <Box>
              <Box mt={1}>
                <Typography variant="subtitle1" style={{ textTransform: "uppercase" }}>
                  <Trans>Código de asset/Property Code</Trans>
                  {`: ${asset.property_code || "-"}`}
                </Typography>
              </Box>
              <Box mt={2}>
                <Grid container>
                  <Grid item xs={8}>
                    <TextField
                      multiline
                      rows={6}
                      description={`${i18n._(t`Datos del emisor (remitente)`)}`}
                      placeholder={`${i18n._(t`Facturación del centro comercial`)}`}
                      validators={["required"]}
                      errorMessages={[`${i18n._(t`Campo requerido`)}`]}
                      value={invoiceData.billing_data}
                      onChange={handleInputChange("billing_data")}
                      fullWidth
                      disabled={!userRoleAreaManager(userRole)}
                    />
                    <TextField
                      multiline
                      rows={6}
                      description={`${i18n._(t`Nota adicional`)}`}
                      placeholder={`${i18n._(t`Nota adicional en la factura generada`)}`}
                      validators={["required"]}
                      errorMessages={[`${i18n._(t`Campo requerido`)}`]}
                      value={invoiceData.additional_note}
                      onChange={handleInputChange("additional_note")}
                      fullWidth
                      disabled={!userRoleAreaManager(userRole)}
                    />
                  </Grid>
                </Grid>
              </Box>
              {asset.module === "mri" && (
                <Box display="flex">
                  <Grid container spacing={2} style={{ maxWidth: "100%" }}>
                    <Grid item xs={4}>
                      <TextField
                        description={`${i18n._(t`Email`)}`}
                        validators={["required", "isEmail"]}
                        errorMessages={[`${i18n._(t`Campo requerido`)}`]}
                        placeholder={`${i18n._(t`email`)}`}
                        value={invoiceData?.email}
                        onChange={handleInputChange("email")}
                        fullWidth
                        disabled={!userRoleAreaManager(userRole)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        description={`${i18n._(t`Biller Code`)}`}
                        validators={["required"]}
                        errorMessages={[`${i18n._(t`Campo requerido`)}`]}
                        placeholder={`${i18n._(t`1234`)}`}
                        value={invoiceData?.biller_code}
                        onChange={handleInputChange("biller_code")}
                        fullWidth
                        disabled={!userRoleAreaManager(userRole)}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
              <Box mt={3}>
                <Typography variant="subtitle1" style={{ textTransform: "uppercase" }}>
                  <Trans>Opciones de pago</Trans>
                </Typography>
              </Box>
              <Box display="flex">
                <Grid container spacing={2} style={{ maxWidth: "100%" }}>
                  <Grid item xs={4}>
                    <TextField
                      description={`${i18n._(t`Cuenta bancaria`)}`}
                      validators={["required"]}
                      errorMessages={[""]}
                      placeholder={`${i18n._(t`Número de cuenta`)}`}
                      value={invoiceData.bank_account_number}
                      onChange={handleInputChange("bank_account_number")}
                      fullWidth
                      disabled={!userRoleAreaManager(userRole)}
                    />
                    <TextField
                      multiline
                      rows={3}
                      errorMessages={[""]}
                      placeholder={`${i18n._(t`Nombre de la cuenta`)}`}
                      value={invoiceData.bank_account_name}
                      onChange={handleInputChange("bank_account_name")}
                      style={{ marginTop: theme.spacing(1), height: 76 }}
                      fullWidth
                      disabled={!userRoleAreaManager(userRole)}
                    />
                  </Grid>

                  {(asset.module === "mri" || asset.module === "bac_au") && (
                    <Grid item xs={4}>
                      <TextField
                        multiline
                        disabled={!userRoleAreaManager(userRole)}
                        rows={7}
                        description={`${i18n._(t`Cheque bancario`)}`}
                        placeholder={`${i18n._(t`Datos cheque bancario`)}`}
                        errorMessages={[""]}
                        value={invoiceData.bank_check}
                        onChange={handleInputChange("bank_check")}
                        fullWidth
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Box display="flex">
                <Grid container spacing={2} style={{ maxWidth: "100%" }}>
                  <Grid item xs={3}>
                    <TextField
                      description={`${i18n._(t`IBAN`)}`}
                      validators={["required"]}
                      errorMessages={[""]}
                      placeholder={`${i18n._(t`IBAN`)}`}
                      value={invoiceData.bank_iban}
                      onChange={handleInputChange("bank_iban")}
                      disabled={!userRoleAreaManager(userRole)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      description={`${i18n._(t`Sort Code`)}`}
                      validators={["required"]}
                      errorMessages={[""]}
                      placeholder={`${i18n._(t`Sort code`)}`}
                      value={invoiceData.bank_sort_code}
                      onChange={handleInputChange("bank_sort_code")}
                      disabled={!userRoleAreaManager(userRole)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      description={`${i18n._(t`Swift`)}`}
                      validators={["required"]}
                      errorMessages={[""]}
                      placeholder={`${i18n._(t`Swift`)}`}
                      value={invoiceData.bank_swift}
                      onChange={handleInputChange("bank_swift")}
                      disabled={!userRoleAreaManager(userRole)}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={3}>
                <Typography variant="subtitle1" style={{ textTransform: "uppercase" }}>
                  <Trans>Vencimiento de la factura</Trans>
                </Typography>
              </Box>
              <Box display="flex">
                <Box flex={1}>
                  <TextField
                    description={`${i18n._(t`Plazo de vencimiento (Días)`)}`}
                    validators={["required"]}
                    errorMessages={[""]}
                    placeholder="60"
                    type="number"
                    value={invoiceData.due_in_days}
                    onChange={handleInputChange("due_in_days")}
                    disabled={!userRoleAreaManager(userRole)}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </ValidatorForm>
      </Box>
      <StepButtons
        continueButtonLabel={<Trans>Guardar</Trans>}
        backButtonLabel={<Trans>Olvidar cambios</Trans>}
        handleContinue={handleFinish}
        handleBack={handleCancel}
        disableContinue={!valuesHasChanged || submitStatus === "loading"}
        disableBack={!valuesHasChanged}
      />
    </>
  );
};

export default Invoicing;
