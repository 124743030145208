import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "@lingui/macro";
import LandlordsComponent from "../../components/landlords";
import { getLandlordsThunk } from "../../redux/actions/landlords-actions";
import { downloadBlobFiles, getRowsFromWindowSize, filterColumnsDisplayTableToString } from "../../utils";
import { setDashboardTitle } from "../../redux/actions/misc-actions";
import api from "../../api";

// config
import { resetAllAdSpaces } from "../../redux/actions/adspaces-actions";
import { resetAllSpaces } from "../../redux/actions/spaces-owner-actions";

const LandLordsContainer = () => {
  const dispatch = useDispatch();

  const landlords = useSelector((state) => state.landlords.landlords);
  const status = useSelector((state) => state.landlords.status);
  const pagination = useSelector((state) => state.landlords.pagination);
  const filters = useSelector((state) => state.landlords.filters);
  const userRole = useSelector((state) => state.user.data.role);
  const [selectedLandlord, setSelectedLandlord] = useState();
  const [sort, setSort] = useState();
  const [landlordStatus, setLandlordStatus] = useState("initial");
  const [columns, setColumns] = useState("");

  useEffect(() => {
    dispatch(setDashboardTitle(t`Centros & Propietarios`));
    dispatch(resetAllAdSpaces());
    dispatch(resetAllSpaces());
  }, []);

  useEffect(() => {
    dispatch(
      getLandlordsThunk({
        /** this is a fix becuse of the extra height provided by the tabs. * */
        /** When refactoring this view, remove this height fix * */
        numberPerPage: getRowsFromWindowSize(window.innerHeight - 330),
        page: 1,
        sort,
        filters,
      }),
    );
  }, [sort, filters]);

  const handleChangePage = (evt, page) => {
    dispatch(
      getLandlordsThunk({
        numberPerPage: getRowsFromWindowSize(window.innerHeight - 330),
        page,
        sort,
        filters,
      }),
    );
  };

  const handleTableChange = (action, tableState) => {
    const columnsDisplay = filterColumnsDisplayTableToString(tableState);
    setColumns(columnsDisplay);
    switch (action) {
      case "sort":
        setSort(tableState.sortOrder);
        break;
      default:
      // console.log("action not handled.");
    }
  };

  const onRowClick = async (id) => {
    setLandlordStatus("loading");
    setSelectedLandlord([]);
    const landlord = await api.getLandLord(id);
    if (landlord.status === 200) {
      setSelectedLandlord(landlord.data?.payload);
      setLandlordStatus("success");
    } else {
      setLandlordStatus("error");
    }
  };

  const downloadLandlordsData = async (format) => {
    const res = await api.downloadLandlordsData({
      exportFormat: format,
      countries: filters.countries,
      sort,
      columns,
    });
    downloadBlobFiles(res, `Landlords.${format}`);
  };

  return (
    <LandlordsComponent
      landlords={landlords}
      status={status}
      pagination={pagination}
      onChangePage={handleChangePage}
      landlordStatus={landlordStatus}
      selectedLandlord={selectedLandlord}
      onTableChange={handleTableChange}
      downloadTableData={downloadLandlordsData}
      onRowClick={onRowClick}
      userRole={userRole}
    />
  );
};

export default LandLordsContainer;
