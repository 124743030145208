import React, { useEffect, useState } from "react";
import { useParams } from "@reach/router";
import SeasonalCalendarComponent from "../../../components/asset-form/seasonal-calendar";
import api from "../../../api";

const SeasonalCalendar = () => {
  const params = useParams();
  const { assetId } = params;
  const [assetSeasons, setAssetSeasons] = useState();
  const [requestStatus, setRequestStatus] = useState({ status: "initial", error: "" });

  const getAssetSeasons = async () => {
    const res = await api.getAssetSeasons(assetId);
    if (res.status === 200) {
      setAssetSeasons(Object.values(res.data.payload));
    }
  };

  const createAssetSeason = async ({ start, end, rate, label }) => {
    setRequestStatus({ status: "loading", action: "create" });
    const res = await api.postAssetSeasons(assetId, { start, end, rate, label });

    if (res.status === 200) {
      setRequestStatus({ status: "success", action: "create" });
      setAssetSeasons((seasons) => [...seasons, res.data.payload]);
    } else {
      setRequestStatus({ status: "error", action: "create", error: res.data });
    }
  };

  const createRecurrentAssetSeason = async ({ start, end, rate, label, interval }) => {
    setRequestStatus({ status: "loading", action: "create" });
    const res = await api.postAssetSeasonInterval(assetId, { start, end, rate, label, interval });

    if (res.status === 200) {
      setRequestStatus({ status: "success", action: "create" });
      getAssetSeasons();
    } else {
      setRequestStatus({ status: "error", action: "create", error: res.data });
    }
  };

  const updateAssetSeason = async ({ seasonId, start, end, rate, label }) => {
    setRequestStatus({ status: "loading", action: "update" });
    const res = await api.patchAssetSeason(seasonId, { start, end, rate, label });

    if (res.status === 200) {
      setRequestStatus({ status: "success", action: "update" });
      const indexToUpdate = assetSeasons.findIndex((season) => season.id === seasonId);
      setAssetSeasons((seasons) => [
        ...seasons.slice(0, indexToUpdate),
        res.data.payload,
        ...seasons.slice(indexToUpdate + 1),
      ]);
    } else {
      setRequestStatus({ status: "error", action: "update", error: res.data });
    }
  };

  const deleteAssetSeason = async (seasonId, deleteAction = null) => {
    setRequestStatus({ status: "loading", action: "delete" });
    const res = await api.deleteAssetSeason(seasonId, deleteAction);

    if (res.status === 200) {
      setRequestStatus({ status: "success", action: "delete" });
      getAssetSeasons();
    } else {
      setRequestStatus({ status: "error", action: "delete", error: res.data });
    }
  };

  const resetRequestStatus = async () => {
    setRequestStatus({ status: "initial", error: "" });
  };

  useEffect(() => {
    getAssetSeasons();
  }, [assetId]);

  return (
    <SeasonalCalendarComponent
      seasons={assetSeasons}
      onCreateSeason={createAssetSeason}
      onCreateRecurrentSeason={createRecurrentAssetSeason}
      onUpdateSeason={updateAssetSeason}
      onDeleteSeason={deleteAssetSeason}
      requestStatus={requestStatus}
      resetRequestStatus={resetRequestStatus}
    />
  );
};

export default SeasonalCalendar;
