import React from "react";
import { Box, Tabs, Tab, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";

import theme from "../../theme";

const useStyles = makeStyles((th) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: th.spacing(2),
    "& .MuiTab-root": {
      minWidth: 150,
    },
    "& .MuiTab-root:last-child": {
      borderRight: theme.borders[5],
    },
    "& .MuiTab-root:first-child": {
      borderLeft: theme.borders[5],
    },
  },
}));

const CustomTabsContainer = withStyles({
  root: {
    borderBottom: "none",
  },
})(Tabs);

const CustomTab = withStyles({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.lightGray,
    marginLeft: "1px",
    marginRight: "1px",
  },
  selected: {
    backgroundColor: theme.palette.white,
    fontWeight: "bold",
  },
})(Tab);

const TabLabel = ({ label, selected }) => (
  <Typography variant="body1" style={{ fontWeight: selected ? "bold" : "normal", textTransform: "capitalize" }}>
    {label}
  </Typography>
);

const CustomTabs = ({ steps = [], activeStep = 0, onStepClick = () => {}, tabStyles, children }) => {
  const classes = useStyles();

  const { i18n } = useLingui();

  const handleChange = (event, newValue) => {
    onStepClick(newValue);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.header} style={{ ...tabStyles }}>
        <CustomTabsContainer
          value={activeStep}
          onChange={handleChange}
          TabIndicatorProps={{ style: { top: "0px", backgroundColor: theme.palette.primary.main } }}
        >
          {steps.map((s) => (
            <CustomTab
              key={`tab-${s}`}
              value={s}
              label={<TabLabel label={i18n._(t`${s}`)} selected={activeStep === s} />}
            />
          ))}
        </CustomTabsContainer>
      </Box>
      {children}
    </Box>
  );
};

export default CustomTabs;
