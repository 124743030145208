import React, { useEffect, useState } from "react";
import StepProjectData from "../../components/create-booking-wizard/step-2-project-data";
import api from "../../api";

function StepProjectDataContainer({ updateBooking, booking, onBack }) {
  const [spaceActivities, setSpaceActivities] = useState([]);
  const [spaceActivitiesCategories, setSpaceActivitiesCategories] = useState([]);
  const [values, setValues] = useState({
    ...booking,
  });

  const handleSelect =
    (field) =>
    ({ target }) => {
      const value = spaceActivities.find((s) => s.id === target.value);

      setValues((values) => ({
        ...values,
        [field]: value,
      }));
    };

  const handleChangeField =
    (field) =>
    ({ target }) => {
      setValues((values) => ({
        ...values,
        [field]: target.value,
      }));
    };

  const handleOnSubmit = () => updateBooking(values);

  useEffect(() => {
    api.getAssetSpaceActivities(booking.asset?.id).then((res) => {
      if (res.status === 200) {
        let categories = new Set(res.data?.payload.map((c) => c.category));
        categories = Array.from(categories);
        setSpaceActivitiesCategories(categories);
        setSpaceActivities(res.data?.payload);
      }
    });
  }, []);

  return (
    <StepProjectData
      canShowSpaceActivity={booking.space?.id}
      onSelect={handleSelect}
      onInputChange={handleChangeField}
      onSubmit={handleOnSubmit}
      onBack={onBack}
      spaceActivities={spaceActivities}
      spaceActivitiesCategories={spaceActivitiesCategories}
      values={values}
    />
  );
}

export default StepProjectDataContainer;
