import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getContractModelsThunk } from "../../redux/actions/misc-actions";
import { userCanWrite } from "../../utils";
import StepAttachmentsComponent from "../../components/create-booking-wizard/step-5-attachments";

function StepAttachmentsContainer({ updateBooking, booking, onBack }) {
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    ...booking,
  });
  const [uploadStatus, setUploadStatus] = useState({
    contract: "initial",
    payment: "initial",
  });
  const permissions = useSelector((state) => state.user.data.permissions);

  useEffect(() => {
    if (booking && booking.asset && booking.asset.id) {
      dispatch(getContractModelsThunk(booking.asset.id));
    }
  }, [dispatch]);

  const handleStartAttaching = (field) => () => {
    setUploadStatus((state) => ({
      ...state,
      [field]: "loading",
    }));
  };

  const handleOnErrorAttaching = (field) => {
    setUploadStatus((state) => ({
      ...state,
      [field]: "error",
    }));
  };

  const handleUploadAttachment = (field) => (file) => {
    setValues((state) => ({
      ...state,
      [field]: file,
    }));
    setUploadStatus((state) => ({
      ...state,
      [field]: "success",
    }));
  };

  const handleResetFile = (field) => (e) => {
    e.preventDefault();
    setValues({
      ...values,
      [field]: undefined,
    });
  };

  const handleSubmit = async (contractMode, contractModel, contractContent) => {
    return updateBooking({ ...values, contractMode, selectedContractModel: contractModel, contractContent });
  };

  return (
    <StepAttachmentsComponent
      values={values}
      uploadStatus={uploadStatus}
      onStartAttaching={handleStartAttaching}
      onErrorAttaching={handleOnErrorAttaching}
      onUploadAttachment={handleUploadAttachment}
      onResetFile={handleResetFile}
      canUpdateContract={userCanWrite(permissions)}
      onBack={onBack}
      onSubmit={handleSubmit}
    />
  );
}

export default StepAttachmentsContainer;
