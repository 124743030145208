import { t } from "@lingui/macro";

// characteristics

t({ id: "a_pie_de_calle", message: "A pie de calle" });
t({ id: "escaparate", message: "Escaparate" });
t({ id: "apto_movilidad_reducida", message: "Apto movilidad reducida" });
t({ id: "parking", message: "Parking" });
t({ id: "cocina", message: "Cocina" });
t({ id: "wc", message: "WC" });
t({ id: "acceso_al_ascensor", message: "Acceso al ascensor" });
t({ id: "office", message: "Oficina" });
t({ id: "office", message: "Oficina" });

// services
t({ id: "wifi", message: "Wifi" });
t({ id: "aire_acondicionado", message: "Aire acondicionado" });
t({ id: "calefaccion", message: "Calefacción" });
t({ id: "mobiliario", message: "Mobiliario" });
t({ id: "probadores", message: "Probadores" });
t({ id: "sistema_de_seguridad", message: "Sistema de seguridad" });
t({ id: "proyector_con_pantalla", message: "Proyector con pantalla" });
t({ id: "sistema_de_audio", message: "Sistema de audio" });

// Space features - Cygnet West AU
t({ id: "connection_to_power", message: "Connection to Power" });
t({ id: "connection_to_waste", message: "Connection to Waste" });
t({ id: "grease_trap", message: "Grease Trap" });
t({ id: "connection_to_gas", message: "Connection to Gas" });
t({ id: "communication", message: "Communication" });
t({ id: "existing_fit_out", message: "Existing Fit Out" });
t({ id: "warm_shell", message: "Warm Shell" });
t({ id: "cold_shell", message: "Cold Shell" });
t({ id: "bare_shell", message: "Bare Shell" });
t({ id: "close_to_supermarket", message: "Close to Supermarket" });
t({ id: "close_to_department_store", message: "Close to Department Store" });
t({ id: "close_to_entrance", message: "Close to Entrance" });
t({ id: "high_foot_traffic", message: "High Foot Traffic" });
t({ id: "no_shopfront", message: "No Shopfront" });
t({ id: "high_street_exposure", message: "High Street Exposure" });
t({ id: "main_road_exposure", message: "Main Road Exposure" });
t({ id: "in_bathroom", message: "In Bathroom" });
t({ id: "high_foot_traffic_location", message: "High Foot Traffic Location" });
t({ id: "close_to_car_park", message: "Close to Car Park" });
t({ id: "close_to_playground", message: "Close to Playground" });
t({ id: "close_to_food_court", message: "Close to Food Court" });
t({ id: "walls", message: "Walls" });
t({ id: "ceilings", message: "Ceilings" });
t({ id: "floors", message: "Floors" });
t({ id: "shopfront_lighting", message: "Shopfront Lighting" });
t({ id: "on_site_security", message: "On-Site Security" });
t({ id: "high_ceilings", message: "High Ceilings" });
t({ id: "in_good_condition", message: "In Good Condition" });
t({ id: "close_to_fashion_outlets", message: "Close to Fashion Outlets" });
t({ id: "ready_to_lease", message: "Ready To Lease" });
t({ id: "longer_leases_considered", message: "Longer Leases Considered" });
t({ id: "air_conditioned_mall", message: "Air-Conditioned Mall" });
t({ id: "air_conditioning", message: "Air Conditioning" });

// owner dashboard
t({ id: "Reservas", message: "Reservas" });
t({ id: "Facturación", message: "Facturación" });
t({ id: "Rendimiento", message: "Rendimiento" });
t({ id: "Espacios Publicitarios", message: "Espacios Publicitarios" });
t({ id: "Centros & Propietarios", message: "Centros & Propietarios" });
t({ id: "Centros", message: "Centros" });
t({ id: "Espacios", message: "Espacios" });
t({ id: "Clientes", message: "Clientes" });
t({ id: "Propietarios", message: "Propietarios" });

t({ id: "Cargar espacio", message: "Cargar espacio" });
t({ id: "Editar espacio", message: "Editar espacio" });

// bookings
t({ id: "Borrador", message: "Borrador" });
t({ id: "Solicitada", message: "Solicitada" });
t({ id: "Aceptada", message: "Aceptada" });
t({ id: "Firmada", message: "Firmada" });
t({ id: "Reservada", message: "Reservada" });
t({ id: "Realizada", message: "Realizada" });
t({ id: "Cancelada", message: "Cancelada" });

// bookings states
t({ id: "all-bookings", message: "Todos" });
t({ id: "inprogress", message: "En proceso" });
t({ id: "confirmed", message: "Confirmadas" });
t({ id: "cancelled", message: "Cancelada" });

// Uses
t({ id: "static_advertising", message: "Publicidad Estática" });
t({ id: "digital_screen", message: "Pantalla Digital" });
t({ id: "semitransparent _screen", message: "Pantalla Semitransparente" });
t({ id: "billboard", message: "Cartelera" });
t({ id: "large_format_panel", message: "Panel de Gran Formato" });
t({ id: "hologram", message: "holograma" });

t({ id: "kiosk", message: "Quiosco" });
t({ id: "rmu", message: "Rmu" });
t({ id: "pop-up_store", message: "Pop-up store" });
t({ id: "seasonal_fair", message: "Seasonal fair" });
t({ id: "others", message: "Others" });
t({ id: "kiddies", message: "Kiddies" });
t({ id: "scales", message: "Scales" });
t({ id: "relax_chairs", message: "Relax chairs" });
t({ id: "food_vending_machine", message: "Food vending machine" });
t({ id: "others", message: "Others" });
t({ id: "terrace_rental", message: "Terrace rental" });
t({ id: "storage_room", message: "Storage room" });
t({ id: "antenna", message: "Antenna" });
t({ id: "parking_service", message: "Parking service" });
t({ id: "atm", message: "Atm" });
t({ id: "others", message: "Others" });
t({
  id: "cerca_de_tiendas_de_perfumeria",
  message: "Cerca de tiendas de perfumería",
});
t({ id: "cerca_del_hipermercado", message: "Cerca del hipermercado" });
t({ id: "cerramiento_nocturno", message: "Cerramiento nocturno" });
t({ id: "cuadro_electrico", message: "cuadro_eléctrico" });
t({
  id: "dentro_de_la_galeria_comercial",
  message: "Dentro de la galería comercial",
});
t({ id: "desague", message: "Desagüe" });
t({ id: "dispone_de_almacen", message: "Dispone de almacen" });
t({ id: "en_buen_estado", message: "En buen estado" });
t({ id: "en_la_zona_exterior", message: "En la zona exterior" });
t({ id: "extintor", message: "Extintor" });
t({ id: "facil_acceso", message: "Fácil acceso" });
t({ id: "iluminacion", message: "Iluminación" });
t({ id: "listo_para_venderentrar", message: "Listo para vender/entrar" });
t({ id: "local_amueblado", message: "Local amueblado" });
t({ id: "local_diafano", message: "Local diáfano" });
t({ id: "necesita_puesta_a_punto", message: "Necesita puesta a punto" });
t({ id: "opcion_de_terraza", message: "Opción de terraza" });
t({ id: "persiana", message: "Persiana" });
t({ id: "planta_alta", message: "Planta alta" });
t({ id: "planta_baja", message: "Planta baja" });
t({
  id: "preinstalacion_de_voz_y_datos",
  message: "Preinstalación de voz y datos",
});
t({ id: "puerta_manual", message: "Puerta manual" });
t({ id: "puertas_automaticas", message: "Puertas automáticas" });
t({ id: "se_necesita_elevadora", message: "Se necesita elevadora" });
t({ id: "sistema_contraincendios", message: "Sistema contraincendios" });
t({ id: "superficie_de_cristal", message: "Superficie de cristal" });
t({ id: "techos_altos", message: "Techos altos" });
t({ id: "tiene_probadores", message: "Tiene probadores" });
t({ id: "toma_de_agua", message: "Toma de agua" });
t({ id: "toma_electrica", message: "Toma eléctrica" });
t({ id: "vinilo_microperforado", message: "Vinilo microperforado" });
t({ id: "vinilo_opaco", message: "Vinilo opaco" });
t({ id: "visibilidad_360", message: "Visibilidad 360°" });

// Types
t({ id: "parking", message: "Parking" });
t({ id: "shopping_center", message: "Shopping Center" });
t({ id: "commercial-gallery", message: "Commercial Gallery" });
t({ id: "exterior-zone", message: "Exterior Zone" });

// Subtypes
t({ id: "local-popup_store", message: "Popup Store" });
t({ id: "kiosk-stand", message: "Kiosko/Promoción" });
t({ id: "markets-food-vans", message: "Markets/Food vans" });
t({ id: "community-sites", message: "Community sites" });
t({ id: "others", message: "Others" });

t({ id: "promotion", message: "Promoción" });
t({ id: "roadshows", message: "Roadshows" });
t({ id: "sponsorship", message: "Sponsorship" });
t({ id: "promotional_stand", message: "Stand Promocional" });
t({ id: "promotional_event", message: "Evento Promocional" });
t({ id: "digital_advertising", message: "Publicidad Digital" });
t({ id: "vinyl_exhibition", message: "Exposición de Vinilo" });
t({ id: "sampling", message: "muestreo" });
t({ id: "brand_experience", message: "Brand Experience" });

t({ id: "retail", message: "Retail" });
t({ id: "vending", message: "Vending" });

// klepierre types
t({ id: "events", message: "Eventos" });
t({ id: "venta", message: "Venta" });
t({ id: "bd_brand_venture", message: "BD Brand Venture" });
t({ id: "bd_specialty_leasing", message: "BD Specialty Leasing" });

t({ id: "local_events", message: "Local Events" });
t({ id: "national_events", message: "National Events" });
t({ id: "rmu_stands", message: "RMU Stands" });
t({ id: "xmas", message: "Xmas" });
t({ id: "popup_store", message: "Popup Store" });
t({ id: "servicios", message: "Servicios" });
t({ id: "vending", message: "Vending" });
t({ id: "otros", message: "Otros" });
t({ id: "cajeros", message: "Cajeros" });
t({ id: "terrazas", message: "Terrazas" });
t({ id: "almacenes", message: "Almacenes" });
t({ id: "others", message: "Others" });

t({ id: "totem_billboards", message: "Totem Billboards" });
t({ id: "covering", message: "Covering" });
t({ id: "sky_banner", message: "Sky Banner" });

t({ id: "rotulos_damero", message: "Rótulos Damero" });
t({ id: "mupis_digitales_papel", message: "Mupis digitales papel" });
t({ id: "vallas_totem", message: "Vallas Totem" });
t({ id: "vinilos", message: "Vinilos" });
t({ id: "lonas", message: "Lonas" });
t({ id: "web_redes_sociales", message: "Web redes sociales" });

// payment methods
t({ id: "Total", message: "Total" });
t({ id: "Monthly", message: "Mensual" });

t({ id: "paid", message: "Facturada" });
t({ id: "unpaid", message: "Pendiente" });

t({ id: "image", message: "Imagen" });
t({ id: "management", message: "Administración" });
t({ id: "payments", message: "Pagos" });
t({ id: "product", message: "Producto" });
t({ id: "client_care", message: "Atención al Cliente" });
t({ id: "cleanliness", message: "Limpieza" });

// orders invoices, credit notes
t({ id: "orders_confirmed", message: "Facturas/Abonos confirmado" });
t({ id: "orders_unconfirmed", message: "Facturas/Abonos sin confirmar" });
t({ id: "orders_paid", message: "Facturas pagas" });
t({ id: "orders_unpaid", message: "Facturas sin pagar" });

// ammenities
t({ id: "amenity_00", message: "amenity_00" });
t({ id: "amenity_01", message: "amenity_01" });
t({ id: "amenity_02", message: "amenity_20" });
t({ id: "amenity_03", message: "amenity_03" });
t({ id: "amenity_04", message: "amenity_04" });
t({ id: "amenity_05", message: "amenity_05" });
t({ id: "amenity_06", message: "amenity_06" });
t({ id: "amenity_07", message: "amenity_07" });
t({ id: "amenity_08", message: "amenity_08" });
t({ id: "amenity_09", message: "amenity_09" });

t({ id: "amenity_10", message: "amenity_10" });
t({ id: "amenity_11", message: "amenity_11" });
t({ id: "amenity_12", message: "amenity_12" });
t({ id: "amenity_13", message: "amenity_13" });
t({ id: "amenity_14", message: "amenity_14" });
t({ id: "amenity_15", message: "amenity_15" });
t({ id: "amenity_16", message: "amenity_16" });
t({ id: "amenity_17", message: "amenity_17" });
t({ id: "amenity_18", message: "amenity_18" });
t({ id: "amenity_19", message: "amenity_19" });

t({ id: "amenity_20", message: "amenity_20" });
t({ id: "amenity_21", message: "amenity_21" });
t({ id: "amenity_22", message: "amenity_22" });
t({ id: "amenity_23", message: "amenity_23" });
t({ id: "amenity_24", message: "amenity_24" });
t({ id: "amenity_25", message: "amenity_25" });
t({ id: "amenity_26", message: "amenity_26" });
t({ id: "amenity_27", message: "amenity_27" });
t({ id: "amenity_28", message: "amenity_28" });
t({ id: "amenity_29", message: "amenity_29" });

t({ id: "amenity_30", message: "amenity_30" });
t({ id: "amenity_31", message: "amenity_31" });
t({ id: "amenity_32", message: "amenity_32" });
t({ id: "amenity_33", message: "amenity_33" });
t({ id: "amenity_34", message: "amenity_34" });
t({ id: "amenity_35", message: "amenity_35" });
t({ id: "amenity_36", message: "amenity_36" });
t({ id: "amenity_37", message: "amenity_37" });
t({ id: "amenity_38", message: "amenity_38" });
t({ id: "amenity_39", message: "amenity_39" });

t({ id: "amenity_40", message: "amenity_40" });
t({ id: "amenity_41", message: "amenity_41" });
t({ id: "amenity_42", message: "amenity_42" });
t({ id: "amenity_43", message: "amenity_43" });
t({ id: "amenity_44", message: "amenity_44" });
t({ id: "amenity_45", message: "amenity_45" });
t({ id: "amenity_46", message: "amenity_46" });
t({ id: "amenity_47", message: "amenity_47" });
t({ id: "amenity_48", message: "amenity_48" });
t({ id: "amenity_49", message: "amenity_49" });

t({ id: "amenity_50", message: "amenity_50" });
t({ id: "amenity_51", message: "amenity_51" });
t({ id: "amenity_52", message: "amenity_52" });
t({ id: "amenity_53", message: "amenity_53" });
t({ id: "amenity_54", message: "amenity_54" });
t({ id: "amenity_55", message: "amenity_55" });
t({ id: "amenity_56", message: "amenity_56" });
t({ id: "amenity_57", message: "amenity_57" });
t({ id: "amenity_58", message: "amenity_58" });
t({ id: "amenity_59", message: "amenity_59" });

t({ id: "amenity_60", message: "amenity_60" });
t({ id: "amenity_61", message: "amenity_61" });
t({ id: "amenity_62", message: "amenity_62" });
t({ id: "amenity_63", message: "amenity_63" });
t({ id: "amenity_64", message: "amenity_64" });
t({ id: "amenity_65", message: "amenity_65" });
t({ id: "amenity_66", message: "amenity_66" });
t({ id: "amenity_67", message: "amenity_67" });
t({ id: "amenity_68", message: "amenity_68" });
t({ id: "amenity_69", message: "amenity_69" });

t({ id: "amenity_70", message: "amenity_70" });
t({ id: "amenity_71", message: "amenity_71" });
t({ id: "amenity_72", message: "amenity_72" });
t({ id: "amenity_73", message: "amenity_73" });
t({ id: "amenity_74", message: "amenity_74" });
t({ id: "amenity_75", message: "amenity_75" });
t({ id: "amenity_76", message: "amenity_76" });
t({ id: "amenity_77", message: "amenity_77" });
t({ id: "amenity_78", message: "amenity_78" });
t({ id: "amenity_79", message: "amenity_79" });

t({ id: "amenity_80", message: "amenity_80" });
t({ id: "amenity_81", message: "amenity_81" });
t({ id: "amenity_82", message: "amenity_82" });
t({ id: "amenity_83", message: "amenity_83" });
t({ id: "amenity_84", message: "amenity_84" });
t({ id: "amenity_85", message: "amenity_85" });
t({ id: "amenity_86", message: "amenity_86" });
t({ id: "amenity_87", message: "amenity_87" });
t({ id: "amenity_88", message: "amenity_88" });
t({ id: "amenity_89", message: "amenity_89" });

t({ id: "amenity_90", message: "amenity_90" });
t({ id: "amenity_91", message: "amenity_91" });
t({ id: "amenity_92", message: "amenity_92" });
t({ id: "amenity_93", message: "amenity_93" });
t({ id: "amenity_94", message: "amenity_94" });
t({ id: "amenity_95", message: "amenity_95" });
t({ id: "amenity_96", message: "amenity_96" });
t({ id: "amenity_97", message: "amenity_97" });
t({ id: "amenity_98", message: "amenity_98" });
t({ id: "amenity_99", message: "amenity_99" });

t({ id: "amenity_100", message: "amenity_100" });
t({ id: "amenity_101", message: "amenity_101" });
t({ id: "amenity_102", message: "amenity_102" });
t({ id: "amenity_103", message: "amenity_103" });
t({ id: "amenity_104", message: "amenity_104" });
t({ id: "amenity_105", message: "amenity_105" });
t({ id: "amenity_106", message: "amenity_106" });
t({ id: "amenity_107", message: "amenity_107" });
t({ id: "amenity_108", message: "amenity_108" });
t({ id: "amenity_109", message: "amenity_109" });

// booking categories
// TODO: REMOVE WHEN BAC-2842 is deployed to all environments
t({ id: "booking-category-01", message: "booking-category-01" });
t({ id: "booking-category-02", message: "booking-category-02" });
t({ id: "booking-category-03", message: "booking-category-03" });
t({ id: "booking-category-04", message: "booking-category-04" });
t({ id: "booking-category-05", message: "booking-category-05" });
t({ id: "booking-category-06", message: "booking-category-06" });
t({ id: "booking-category-07", message: "booking-category-07" });
t({ id: "booking-category-08", message: "booking-category-08" });
t({ id: "booking-category-09", message: "booking-category-09" });

t({ id: "booking-category-10", message: "booking-category-10" });
t({ id: "booking-category-11", message: "booking-category-11" });
t({ id: "booking-category-12", message: "booking-category-12" });
t({ id: "booking-category-13", message: "booking-category-13" });
t({ id: "booking-category-14", message: "booking-category-14" });
t({ id: "booking-category-15", message: "booking-category-15" });
t({ id: "booking-category-16", message: "booking-category-16" });
t({ id: "booking-category-17", message: "booking-category-17" });
t({ id: "booking-category-18", message: "booking-category-18" });
t({ id: "booking-category-19", message: "booking-category-19" });

// booking categories
t({ id: "kids_and_babies", message: "Kids & Babies" });
t({ id: "cosmetics_hair_beauty", message: "Cosmetics, Hair & Beauty" });
t({ id: "charity_and_fundraising", message: "Charity & Fundraising" });
t({ id: "fashion_and_accessories", message: "Fashion & Accessories" });
t({ id: "food_and_beverages", message: "Food & Beverages" });
t({ id: "gifts_books_and_toys", message: "Gifts Books & Toys" });
t({ id: "health_and_wellbeing", message: "Health & Wellbeing" });
t({ id: "home_and_garden", message: "Home & Garden" });
t({ id: "services", message: "Services" });
t({ id: "sports_and_recreation", message: "Sorts & Recreation" });
t({ id: "travel_and_tourism", message: "Travel & Tourism" });
t({ id: "other", message: "Other" });
t({ id: "website_and_social_media", message: "Website & Social media" });
t({ id: "digital_media", message: "Digital media" });
t({ id: "banners_and_decal", message: "Banners & Decal" });
t({ id: "visual_merchandising", message: "Visual Merchandising" });

t({ id: "education", message: "Education" });
t({ id: "real_estate", message: "Real Estate" });
t({ id: "taxation", message: "Taxation" });
t({ id: "technology", message: "Technology" });
t({ id: "mall_retail", message: "Mall Retail" });
t({ id: "customer_acquisition", message: "Customer Acquisition" });
t({ id: "brand", message: "Brand" });
t({ id: "ambient_media", message: "Ambient Media" });
t({ id: "pop_up_shops", message: "Pop up shops" });
t({ id: "static_display", message: "Static Display" });

// Booking categories - CBRE AU
t({ id: "kids-and-babies", message: "Kids & Babies" });
t({ id: "cosmetics-hair-and-beauty", message: "Cosmetics, Hair & Beauty" });
t({ id: "community-and-fundraising", message: "Community & Fundraising" });
t({ id: "fashion-and-accessories", message: "Fashion & Accessories" });
t({ id: "food-and-beverages", message: "Food & Beverages" });
t({ id: "gifts-books-toys-and-education", message: "Gifts, Books, Toys & Education" });
t({ id: "health-and-wellbeing", message: "Health & Wellbeing" });
t({ id: "home-and-garden", message: "Home & Garden" });
t({ id: "professional-services", message: "Professional Services" });
t({ id: "sports-and-recreation", message: "Sports & Recreation" });
t({ id: "travel-and-tourism", message: "Travel & Tourism" });
t({ id: "other", message: "Other" });
t({ id: "website-and-social-media", message: "Website & Social Media" });
t({ id: "digital-media", message: "Digital Media" });
t({ id: "banners-and-decal", message: "Banners & Decal" });
t({ id: "visual-merchandising", message: "Visual Merchandising" });
t({ id: "education", message: "Education" });
t({ id: "government", message: "Government" });
t({ id: "accounting-and-taxation", message: "Accounting & Taxation" });
t({ id: "entertainment-and-technology", message: "Entertainment & Technology" });
t({ id: "cosmetics-hair-and-beauty-sales", message: "Cosmetics, Hair & Beauty - Sales" });
t({ id: "cosmetics-hair-and-beauty-service", message: "Cosmetics, Hair & Beauty - Service" });
t({ id: "unisex-fashion", message: "Unisex fashion" });
t({ id: "mens-fashion", message: "Men’s Fashion" });
t({ id: "womens-fashion", message: "Women’s Fashion" });
t({ id: "footwear", message: "Footwear" });
t({ id: "food-retail", message: "Food Retail" });
t({ id: "food-catering", message: "Food Catering" });
t({ id: "pet-care", message: "Pet Care" });
t({ id: "discount-variety", message: "Discount Variety" });
t({ id: "marketing-and-events", message: "Marketing & Events" });
