import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box, Hidden, Typography } from "@material-ui/core";

// theme
import theme from "../../theme";

import HelpSectionPopover from "./help-section/help-section-popover";
import HelpSectionFixed from "./help-section/help-section-fixed";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    height: "100%",
    overflowY: "hidden",
  },
  leftContent: {
    display: "none", // TODO: to enable when moving stepper
    width: "calc(100% - 373px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  rightContent: {
    width: 373,
  },
}));

const BaseStep = ({ mainContent, helpContent, title }) => {
  const classes = useStyles(theme);

  return (
    <Box className={classes.container}>
      <Box display="flex" height="100%">
        <Box p={1} flexGrow={1} className={classes.leftContent}>
          <Box p={1} position="relative" justifyContent="center">
            <Box p={0}>
              <Typography variant="h4" color="primary">
                {title}
              </Typography>
            </Box>
            <Box p={0} position="absolute" right="5px" top="0">
              <HelpSectionPopover content={helpContent} />
            </Box>
          </Box>
        </Box>
        {mainContent}
      </Box>
    </Box>
  );
};

BaseStep.propTypes = {
  mainContent: PropTypes.object.isRequired,
  helpContent: PropTypes.object,
  title: PropTypes.string.isRequired,
};

export default BaseStep;
