import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import theme from "../../theme-admin";
import MultiselectSearchPopup from "./multiselect-search-popup";

const useStyles = makeStyles((theme) => ({
  popper: {
    border: "1px solid rgba(27,31,35,.15)",
    boxShadow: "0 3px 12px rgba(27,31,35,.15)",
    borderRadius: 3,
    zIndex: 130,
    width: 250,
    "& .MuiAutocomplete-listbox": {
      maxHeight: 200,
    },
  },
  valueSelectedText: {
    fontWeight: 500,
  },
  button: {
    height: "35px",
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
}));

export default function MultiselectSearchFilter({
  title = "",
  localStorageName = "",
  icon = () => {},
  options = [],
  onSelect = () => {},
  placeholder = "",
}) {
  const classes = useStyles(theme);
  const { i18n } = useLingui();

  const ref = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState([]);
  const [allOptions, setAllOptions] = useState([]);
  const [pendingValue, setPendingValue] = useState([]);

  useEffect(() => {
    const filter = localStorage.getItem(localStorageName);
    if (filter) {
      setPendingValue([]);
      const localStorageValues = JSON.parse(filter);
      setValue(localStorageValues);
      setPendingValue(localStorageValues);
    }
  }, []);

  useEffect(() => {
    if (options.length > 0) {
      const sortedOptions = options.sort((a, b) => {
        const nameA = a?.name?.toLowerCase ? a.name.toLowerCase() : "";
        const nameB = b?.name?.toLowerCase ? b.name.toLowerCase() : "";

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setAllOptions([{ id: "all", name: i18n._(t`Seleccionar todo`) }, ...sortedOptions]);
    }
  }, [options]);

  useEffect(() => {
    if (pendingValue.length === options.length) {
      setPendingValue(allOptions);
    }
  }, [pendingValue]);

  const handleOnChange = (event, value, reason) => {
    const isAllElementExists = value.find((e) => e.id === "all");
    if (reason === "select-option" && isAllElementExists) {
      setPendingValue(allOptions);
      return;
    }
    if (reason === "remove-option" && pendingValue.length === allOptions.length && !isAllElementExists) {
      setPendingValue([]);
      return;
    }
    if (reason === "remove-option") {
      const newPendingValue = value.filter((e) => e.id !== "all");
      setPendingValue(newPendingValue);
      return;
    }
    setPendingValue(value);
  };

  const handleClick = (event) => {
    setPendingValue(value);
    setAnchorEl(event.currentTarget);
    document.addEventListener("mousedown", mouseDown);
  };

  const handleClose = () => {
    setAnchorEl(null);
    document.removeEventListener("mousedown", mouseDown);
  };
  const handleClear = () => {
    setValue([]);
    onSelect([]);
    localStorage.removeItem(localStorageName);
    handleClose();
  };

  const handleAccept = () => {
    setValue(pendingValue);
    onSelect(pendingValue.filter((e) => e.id !== "all" && e.id !== null && e.name !== null));
    handleClose();
    localStorage.removeItem(localStorageName);
    localStorage.setItem(localStorageName, JSON.stringify(pendingValue));
  };

  const open = Boolean(anchorEl);

  function mouseDown(e) {
    if (!ref.current?.contains(e.target)) {
      // handleClose();
    }
  }

  const handleTooltip = (values, title) => {
    return (
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle1">{title}</Typography>
        {values.map((v, i) =>
          i < 12 ? (
            <Typography key={v.id} variant="body1">
              {i18n._(t`${v.name}`)}
            </Typography>
          ) : null,
        )}
        {values.length > 12 && <Typography variant="body1">{`${values.length - 12} ${i18n._(t` más...`)}`}</Typography>}
      </Box>
    );
  };

  return (
    <>
      <Tooltip title={handleTooltip(value, title)}>
        <Button variant={open ? "outlined" : "contained"} className={classes.button} onClick={handleClick}>
          {icon()}
          {value.length ? (
            <Box display="flex" ml={1}>
              <Typography variant="body2" component="span" className={classes.valueSelectedText}>
                {`${title} (${value.length})`}
              </Typography>
            </Box>
          ) : (
            <></>
          )}
        </Button>
      </Tooltip>
      <MultiselectSearchPopup
        title={title}
        placeholder={placeholder}
        anchorEl={anchorEl}
        allOptions={allOptions}
        pendingValue={pendingValue}
        onClose={handleClose}
        onClear={handleClear}
        onAccept={handleAccept}
        onChange={handleOnChange}
      />
    </>
  );
}
