import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "/src/hooks/navigation";
// import theme
import PriceComponent from "../../components/listing-form/price-step";
// Actions
import { getSpaceThunk, putSpaceThunk } from "../../redux/actions/space-actions";
import { getSpotsByAssetThunk } from "../../redux/actions/misc-actions";
import api from "../../api";

const PriceStepContainer = ({ onSetOpenDelete }) => {
  const dispatch = useDispatch();
  const { navigate } = useNavigate();

  const [updateTagResult, setUpdateTagResult] = useState({});

  const id = useSelector((state) => state.listing.data.id);
  const status = useSelector((state) => state.listing.status);
  const space = useSelector((state) => state.listing.data);
  const assetSpots = useSelector((state) => state.misc.spots[space.asset?.id]);

  // in the future we might need to change for data.identifier
  const spots = assetSpots ? assetSpots.filter((spot) => ["promotion", "retail"].includes(spot.slug)) : [];

  const handleSubmit = async (data) => {
    const response = await api.setSpaceSpots(
      id,
      data.tags.map((spot) => spot.id),
    );
    if (response.status === 200) {
      dispatch(
        putSpaceThunk(id, {
          ...data,
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(getSpotsByAssetThunk(space.asset?.id));
  }, [space]);

  return (
    <PriceComponent
      onSetOpenDelete={onSetOpenDelete}
      handleNext={() => navigate(`/administrator/spaces/${id}/calendar`)}
      handleBack={() => navigate(`/administrator/spaces/${id}/images`)}
      onSubmit={handleSubmit}
      spots={spots}
      status={status}
      space={space}
    />
  );
};

export default PriceStepContainer;
