import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Box, Grid } from "@material-ui/core";
import TextField from "../../text-field";
import StepButtons from "../../step-buttons";
import theme from "../../../theme";

const useStyles = makeStyles((th) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    color: th.palette.text.primary,
    paddingLeft: th.spacing(2),
    paddingRight: th.spacing(2),
    overflowY: "hidden",
  },
  validatorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    width: "100%",
    paddingBottom: th.spacing(4),
    overflowY: "auto",
  },
}));

const Representative = ({ asset, submitStatus = "initial", submit = () => {} }) => {
  const { i18n } = useLingui();
  const classes = useStyles(theme);
  const [representativeName, setRepresentativeName] = useState(asset.meta_data?.representative);

  const valuesHasChanged = representativeName !== asset.meta_data?.representative;

  const handleInputChange = (e) => {
    const { value } = e.target;
    setRepresentativeName(value);
  };

  const handleSubmit = () => {
    submit({
      meta_data: {
        representative: representativeName,
      },
    });
  };

  useEffect(() => {
    setRepresentativeName(asset.meta_data?.representative);
  }, [asset]);

  const handleCancel = () => {
    setRepresentativeName(asset.meta_data?.representative);
  };

  return (
    <>
      <Box className={classes.container}>
        <ValidatorForm className={classes.validatorContainer} onSubmit={() => {}}>
          <Grid container>
            <Grid item xs={6}>
              <TextField
                mt={4}
                rows={4}
                multiline
                name="representative"
                description={`${i18n._(t`Nombre del representante`)}`}
                errorMessages={[""]}
                value={representativeName}
                onChange={handleInputChange}
                labelClassNames={classes.label}
                fullWidth
              />
            </Grid>
          </Grid>
        </ValidatorForm>
      </Box>
      <StepButtons
        continueButtonLabel={<Trans>Guardar</Trans>}
        backButtonLabel={<Trans>Olvidar cambios</Trans>}
        handleContinue={handleSubmit}
        handleBack={handleCancel}
        disableContinue={!valuesHasChanged || submitStatus === "loading"}
        disableBack={!valuesHasChanged}
      />
    </>
  );
};

export default Representative;
