import { Box, Typography } from "@material-ui/core";
import React from "react";
import BigStarIcon from "../../../icons/big-star-icon";
import theme from "../../../theme-admin";

const BigStartComponent = ({ ratingNumber }) => {
  return (
    <Box display="flex" justifyContent="center" width="100%">
      <BigStarIcon style={{ position: "absolute", width: 29, height: 27 }} />
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: theme.palette.black,
          width: "32px",
          height: "32px",
          zIndex: 0,
        }}
      >
        <Typography variant="body2" style={{ fontWeight: 700, fontSize: "9px", transform: "translateY(-3px)" }}>
          {ratingNumber}
        </Typography>
      </Box>
    </Box>
  );
};

export default BigStartComponent;
