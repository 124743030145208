import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "/src/hooks/navigation";
// Component
import CharacteristicsComponent from "../../components/listing-form/characteristics-step";
// Actions
import { putSpaceThunk } from "../../redux/actions/space-actions";
import { getSpaceFeaturesThunk, getSpaceTypesThunk } from "../../redux/actions/misc-actions";

import "./features-translations.js";

const CharacteristicsStepContainer = ({ onSetOpenDelete }) => {
  const dispatch = useDispatch();
  const { navigate } = useNavigate();

  const features = useSelector((state) => state.misc.spaceFeatures);

  const id = useSelector((state) => state.listing.data.id);
  const status = useSelector((state) => state.listing.status);
  const space = useSelector((state) => state.listing.data);

  const handleSubmit = (data) => {
    dispatch(putSpaceThunk(id, data));
  };

  useEffect(() => {
    dispatch(getSpaceFeaturesThunk());
    dispatch(getSpaceTypesThunk());
  }, []);

  return (
    <CharacteristicsComponent
      onSetOpenDelete={onSetOpenDelete}
      handleNext={() => navigate(`/administrator/spaces/${id}/images`)}
      handleBack={() => navigate(`/administrator/spaces/${id}/type`)}
      onSubmit={handleSubmit}
      status={status}
      features={features}
      space={space}
    />
  );
};

export default CharacteristicsStepContainer;
