import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Tooltip, Fade } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import numbro from "numbro";
import theme from "../../theme";
import Widget from "./small-widget";
import { formatDateUtc } from "../../utils/dateUtils";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    color: theme.palette.white,
    padding: theme.spacing(1),
    borderRadius: "5px",
  },
  label: {
    color: theme.palette.grayLabel,
  },
}));

const CustomTooltip = ({ payload }) => {
  const classes = useStyles();

  return (
    <Box className={classes.tooltip}>
      <Typography variant="body1">
        {numbro(payload).formatCurrency({ mantissa: 0, thousandSeparated: true })}
      </Typography>
    </Box>
  );
};

function Info() {
  return (
    <Box width="324px">
      <Typography variant="body1">
        <Trans>Objetivos anuales de facturación por categoría</Trans>
      </Typography>
    </Box>
  );
}

function AnnualGoalsWidget({ period, annualGoals = [] }) {
  const classes = useStyles();

  return (
    <Widget
      title={<Trans>Objetivos de facturación</Trans>}
      period={period}
      info={<Info />}
      width="100%"
      height={616}
      unavailable={annualGoals.length === 0}
    >
      <Box mt={2} width="100%" height="100%" pl={3} pb={3}>
        <Box width="100%" p={2}>
          {annualGoals.map((goal, index) => (
            <Box mb={1} key={`goal-${index}`} display="flex">
              <Box flex={9}>
                <Typography variant="subtitle1">{goal.title}</Typography>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Tooltip
                    disableFocusListener
                    title={<CustomTooltip payload={goal.scored_amount} />}
                    TransitionComponent={Fade}
                    style={{
                      padding: 0,
                    }}
                  >
                    <Box width="85%" bgcolor={theme.palette.grayC4} height={20} position="relative">
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        width={`${Math.min(100, goal.success_rate)}%`}
                        height={20}
                        position="absolute"
                        left={0}
                        zIndex={2}
                        bgcolor={theme.palette.primary.main}
                        style={{
                          transition: "0.3s all ease-in",
                        }}
                      >
                        <Typography style={{ transform: "translate(1px, 8px)" }} variant="body1" color="primary">
                          |
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent={goal.success_rate > 30 ? "flex-end" : "flex-start"}
                        width={`${Math.min(100, goal.success_rate)}%`}
                        height={20}
                        position="absolute"
                        left={0}
                        zIndex={2}
                        style={{
                          transition: "0.3s all ease-in",
                        }}
                      >
                        <Typography
                          variant="body1"
                          style={{
                            color: theme.palette.white,
                            fontSize: "11px",
                            paddingRight: theme.spacing(1 / 2),
                            paddingLeft: theme.spacing(1 / 2),
                          }}
                        >
                          {`${numbro(goal.success_rate).format({
                            average: true,
                            mantissa: 0,
                          })}%`}
                        </Typography>
                      </Box>
                    </Box>
                  </Tooltip>
                  <Box ml={1}>
                    <Typography variant="caption" className={classes.label}>
                      {numbro(goal.goal_amount).formatCurrency({ mantissa: 0, thousandSeparated: true })}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row" width="85%">
                  <Box
                    width={`${Math.min(100, goal.success_rate)}%`}
                    style={{
                      transition: "0.3s all ease-in",
                    }}
                  />
                  <Box mt={1 / 2}>
                    <Typography
                      variant="body1"
                      color="primary"
                      style={{
                        fontWeight: "bold",
                        transform: "translateX(-50%)",
                      }}
                    >
                      {numbro(goal.scored_amount).formatCurrency({ mantissa: 0, thousandSeparated: true })}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Widget>
  );
}

export default AnnualGoalsWidget;
