import React from "react";
import { Box, Button, Typography, CircularProgress } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
// theme
import theme from "../../theme-admin";
import { Trans } from "@lingui/macro";

const TableRowDelete = ({ colSpan, deleteStatus, onDelete, setShowRowDelete }) => {
  return (
    <TableCell colSpan={colSpan}>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Box>
          <Typography variant="body2">
            <Trans>¿Deseas eliminar este documento?</Trans>
          </Typography>
        </Box>
        <Box>
          <>
            <Button
              style={{ marginRight: theme.spacing(1) }}
              color="primary"
              onClick={() => setShowRowDelete(-1)}
              disabled={deleteStatus === "loading"}
            >
              <Typography variant={"body2"}>
                <Trans>Cancel</Trans>
              </Typography>
            </Button>
            <Button
              style={{
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
              }}
              variant={"contained"}
              color={"primary"}
              onClick={onDelete}
              disabled={deleteStatus === "loading"}
            >
              {deleteStatus === "loading" ? (
                <CircularProgress size="15px" fill={theme.palette.disabledRow} />
              ) : (
                <Typography variant={"body2"}>
                  <Trans>Sí, estoy seguro</Trans>
                </Typography>
              )}
            </Button>
          </>
        </Box>
      </Box>
    </TableCell>
  );
};
export default TableRowDelete;
