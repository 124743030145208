import React from "react";
import { useSelector } from "react-redux";
import DashboardTitleComponent from "../../components/owner-dashboard/dashboard-title";

const DashboardTitle = () => {
  const dashboardTitle = useSelector((state) => state.misc.dashboardTitle);
  const customComponentNextToTitle = useSelector((state) => state.misc.customComponentNextToTitle);

  return (
    <DashboardTitleComponent dashboardTitle={dashboardTitle} customComponentNextToTitle={customComponentNextToTitle} />
  );
};

export default DashboardTitle;
