import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { publishSpaceThunk } from "../../redux/actions/space-actions";
import FinishDialogComponent from "../../components/listing-form/calendar-step/finish-dialog";

const FinishDialog = ({
  open = false,
  status = "initial",
  hidden = false,
  onClose = () => {},
  onSubmit = () => {},
}) => {
  const dispatch = useDispatch();

  const spaceId = useSelector((state) => state.listing.data.id);
  const searchable = useSelector((state) => state.listing.data.searchable);

  const handleClickPublish = () =>
    dispatch(publishSpaceThunk({ listingId: spaceId, hide: searchable }));

  return (
    <FinishDialogComponent
      open={open}
      status={status}
      hidden={!searchable}
      publishSpace={handleClickPublish}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export default FinishDialog;
