import React from "react";
import Box from "@material-ui/core/Box";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import OwnerModal from "../owner-modal";
import PDFViewer from "../pdf-viewer";
import config from "../../config";

const PrivacyPolicyComponent = ({ open = false, onClose }) => {
  const { i18n } = useLingui();

  return (
    <OwnerModal title={i18n._(t`Política de privacidad`)} open={open} onClose={onClose} width={700}>
      <Box
        display="flex"
        flexDirection="column"
        position="relative"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <PDFViewer fileUrl={config.PRIVACY_POLICY} />
      </Box>
    </OwnerModal>
  );
};

export default PrivacyPolicyComponent;
