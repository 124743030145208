import React from "react";
import { useNavigate } from "/src/hooks/navigation";
import { useParams } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import DrawerConsultationComponent from "../../components/drawer-consultation";
import { resetConsultation } from "../../redux/actions/consultation-actions";
import { setTimelineDateRangeBlock } from "../../redux/actions/spaces-owner-actions";
import DaterangeBlockModal from "../../components/timeline-vacancy/daterange-block-modal";

const TimelineVacancyModalsContainer = () => {
  const { navigate } = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const urlSpaceType = params.spaceType;

  const selectedBlock = useSelector((state) => state.spacesOwner.timelineDateRangeBlock);

  const bookingId = useSelector((state) => state.consultation.data.bookingId);

  const handleClose = () => {
    navigate(`/administrator/${urlSpaceType}/occupation/booking/${urlSpaceType}/all`);
    dispatch(resetConsultation());
  };

  const handleCloseBlockModal = () => dispatch(setTimelineDateRangeBlock());

  return (
    <>
      <DrawerConsultationComponent isOpen={!!bookingId} onClose={handleClose} />
      <DaterangeBlockModal open={!!selectedBlock} onClose={handleCloseBlockModal} block={selectedBlock} />
    </>
  );
};

export default TimelineVacancyModalsContainer;
