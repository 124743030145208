import React from "react";
import { Box, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TimelineBlock from "./block";
import { add, dateDiff, DIFFERENCE_FORMAT, formatDateUtc, getNow } from "../../utils/dateUtils";
import getRandomColor from "./random-color";
import theme from "../../theme";
import BlockTooltip from "./block-tooltip";

const ROW_HEIGHT = 30;

const useStyles = makeStyles((theme) => ({
  row: {
    height: ROW_HEIGHT,
    marginTop: theme.spacing(1),
    zIndex: 9,
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.1)",
      cursor: "pointer",
      transition: "0.1s all ease-in",
    },
  },
  tableHeadRow: {
    borderBottom: "1px solid #C4C4C4",
    height: 20,
  },
  blocksContainer: {
    position: "relative",
  },
  monthsContainer: {
    position: "absolute",
    borderLeft: "1px solid #828282",
  },
  monthColumnHead: {
    position: "absolute",
    textAlign: "center",
    borderRight: "1px solid #828282",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    "&:first-child": {
      borderLeft: "1px solid #828282",
    },
  },
  backgroundColumn: {
    backgroundColor: "red",
    flex: 10,
    zIndex: 0,
    backgroundColor: "#ffffff",
    opacity: 0.8,
    display: "inline-block",
    overflow: "hidden",
    textAlign: "left",
    "& > div": {
      width: ({ colSize }) => `${colSize}px`,
      height: "100%",
      borderRight: "1px solid #C4C4C4",
      display: "inline-block",
    },
    "& > div:last-child": {
      borderRight: "none",
    },
  },
  columnName: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    zIndex: 9,
    backgroundColor: theme.palette.white,
  },
  title: {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  todayLine: {
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    width: 1,
    zIndex: 9,
  },
  todayLabel: {
    position: "absolute",
    top: -20,
    color: theme.palette.primary.main,
  },
  tooltip: {
    backgroundColor: theme.palette.white,
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    borderRadius: 10,
    margin: -10,
    boxShadow: "0px 2px 11px rgba(0, 0, 0, 0.25)",
  },
}));

/**
 *
 * spaces: list of objects { id, title },
 * occupation: object with [space.id]: [{ id, ... }]
 */

export default function ({
  spaces = [],
  occupation = {},
  onClickBlock = () => {},
  start,
  end,
  todayLabel = "-",
  width = 500,
}) {
  const columnNameWidth = 130;
  const leftPositionTable = 145;
  const blocksWidth = width - leftPositionTable;
  const diffDays = dateDiff(start, end) > 0 ? dateDiff(start, end) : 1;

  /** to clean up * */
  const months = dateDiff(start, end, DIFFERENCE_FORMAT.MONTH);
  const totalHeight = (theme.spacing(1) + ROW_HEIGHT) * (spaces.length + 1);

  const monthsColumns = [];
  const today = {};
  let current = start;

  // set today line
  today.x = (dateDiff(start, getNow()) * blocksWidth) / diffDays;
  today.label = todayLabel;

  monthsColumns.push({
    label: formatDateUtc(current, "MMM"),
    x: 0,
    width: (dateDiff(current, add(start, 0).endOf("month")) * blocksWidth) / diffDays,
  });

  current = add(current, 1, "month").startOf("month");

  for (let i = 0; i < months; i++) {
    const next = add(current, 1, "month");

    monthsColumns.push({
      label: formatDateUtc(current, "MMM"),
      x: (dateDiff(start, current) * blocksWidth) / diffDays,
      width: (dateDiff(current, next) * blocksWidth) / diffDays,
    });

    current = next;
  }

  /** to clean up * */

  const classes = useStyles({
    ...theme,
    // We select the last column width. assuming its a month complete
    colSize: months > 0 ? monthsColumns[months - 1].width / 4 : blocksWidth,
  });

  return (
    <Box
      mt={2}
      width="100%"
      height={totalHeight}
      display="flex"
      flexDirection="column"
      borderBottom="1px solid #C4C4C4"
      style={{ overflow: "hidden" }}
    >
      <Box display="flex" className={classes.tableHeadRow}>
        <Box className={classes.columnName} width={columnNameWidth} />
        <Box width={blocksWidth} className={classes.monthsContainer} style={{ left: leftPositionTable }}>
          {monthsColumns.map((month) => (
            <Box
              style={{
                left: month.x,
                width: month.width,
                height: totalHeight,
              }}
              className={classes.monthColumnHead}
              key={`${month.x}-${month.label}`}
              data-testid="timeline-column"
            >
              <Typography variant="subtitle1" style={{ textTransform: "uppercase", lineHeight: 1.5 }}>
                {month.label}
              </Typography>
              <Box className={classes.backgroundColumn}>
                <Box />
                <Box />
                <Box />
                <Box />
              </Box>
            </Box>
          ))}
          {today.x < blocksWidth && (
            <Box
              style={{
                left: today.x,
                height: totalHeight,
              }}
              className={classes.todayLine}
            >
              <Typography className={classes.todayLabel} variant="subtitle1">
                {today.label}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {/** rows of spaces * */}
      {spaces.map((space) => (
        <Box className={classes.row} key={space.id} display="flex">
          <Box className={classes.columnName} width={columnNameWidth} title={space.title}>
            <Typography variant="subtitle1" className={classes.title}>
              {space.title}
            </Typography>
          </Box>
          <Box width={blocksWidth} className={classes.blocksContainer}>
            {occupation[space.id] &&
              occupation[space.id].map((block) => (
                <TimelineBlock
                  key={`${space.id}-${block.id}`}
                  bg={block.booking_id ? theme.palette.primary.main : theme.palette.grayC4}
                  data={block}
                  height={ROW_HEIGHT}
                  text=""
                  tooltip={<BlockTooltip block={block} />}
                  onClick={onClickBlock}
                  x={(dateDiff(start, block.start) * blocksWidth) / diffDays}
                  width={((dateDiff(block.start, block.end) + 1) * blocksWidth) / diffDays}
                />
              ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
}
