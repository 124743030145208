import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useLingui } from "@lingui/react";
import Typography from "@material-ui/core/Typography";
import { Trans, t } from "@lingui/macro";
import { ValidatorForm } from "react-material-ui-form-validator";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import TextField from "../../text-field";
import StepButtons from "../../step-buttons";
import theme from "../../../theme";
import DecideDialog from "../../decide-dialog-owner";
import { limitStringChars, sortAlphabetically } from "../../../utils";
import CheckBox from "../../custom-checkbox/custom-checkbox-alternative-3";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    color: theme.palette.text.primary,
    paddingLeft: theme.spacing(3),
    overflowY: "hidden",
  },
  validatorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    height: "100%",
    width: "100%",
    overflowY: "auto",
  },
  inputRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
  },
  inputs: {
    width: "217px",
  },
  selects: {
    display: "flex",
    flexDirection: "row",
  },
  attachmentIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(1 / 2),
    borderRadius: "5px",
    width: 80,
    height: 80,
    backgroundColor: theme.palette.grayC4,
    cursor: "pointer",
    position: "relative",
  },
  attachmentImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(1 / 2),
    borderRadius: "5px",
    width: 80,
    height: 80,
    cursor: "pointer",
    position: "relative",
  },
  closeIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "22px",
    height: "22px",
    position: "absolute",
    borderRadius: "50%",
    backgroundColor: theme.palette.grayC4,
    zIndex: 999,
    left: "66px",
    top: "-8px",
    cursor: "pointer",
  },
  continueButton: {
    width: 190,
    height: 42,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  typeRadioButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    marginRight: theme.spacing(4),
  },
  typeRadioButtonSuccess: {
    color: theme.palette.primary.main,
  },
  label: {
    fontSize: "13px",
    fontWeight: "bold",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.white,
  },
  fields: {
    marginTop: theme.spacing(1),
  },
}));

const Prices = ({
  assetConfigs,
  priceStatus,
  spaceActivities,
  status = "initial",
  submitStatus = "initial",
  submit = () => {},
}) => {
  const classes = useStyles(theme);
  const [openDialog, setOpenDialog] = useState(false);
  const [assetCfgs, setAssetCfgs] = useState();
  const [spaceActivitiesValues, setSpaceActivitiesValues] = useState([]);
  const [valuesHasChanged, setValuesHasChanged] = useState(false);

  const { i18n } = useLingui();

  const handleInputChange =
    (activityIndex) =>
    ({ target }) => {
      const newValues = [...spaceActivitiesValues];
      newValues[activityIndex] = { ...newValues[activityIndex], rate: target.value };
      setSpaceActivitiesValues(newValues);
    };

  const handleFinish = () => {
    submit(spaceActivitiesValues, assetCfgs);
  };

  useEffect(() => {
    setAssetCfgs({ ...assetConfigs });
  }, [assetConfigs]);

  useEffect(() => {
    if (spaceActivities.length > 0) {
      const sortActivities = sortAlphabetically(spaceActivities, "space_activity");
      setSpaceActivitiesValues(sortActivities);
    }
  }, [spaceActivities]);

  useEffect(() => {
    const hasChanged = !spaceActivities.every((e, i) => String(e.rate) === String(spaceActivitiesValues[i].rate));
    const hasAnyChange =
      hasChanged ||
      assetCfgs?.show_adspace_price !== assetConfigs?.show_adspace_price ||
      assetCfgs?.show_space_price !== assetConfigs?.show_space_price;

    setValuesHasChanged(hasAnyChange);
  }, [spaceActivitiesValues, assetCfgs]);

  const handleCancel = () => {
    setSpaceActivitiesValues([...spaceActivities]);
  };

  return (
    <>
      <Box className={classes.container}>
        <DecideDialog
          open={openDialog}
          status={submitStatus}
          onAccept={handleFinish}
          title={<Trans>Existen cambios sin guardar</Trans>}
          desc={
            <Trans>
              Al editar un campo debes guardar los cambios. ¿Desea guardar los cambios realizados antes de continuar?
            </Trans>
          }
          acceptText={<Trans>Guardar y continuar</Trans>}
          rejectText={<Trans>Continuar sin guardar</Trans>}
          onClose={() => setOpenDialog(false)}
        />
        <Backdrop className={classes.backdrop} open={status === "loading" || submitStatus === "loading"}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar open={submitStatus === "error"} autoHideDuration={3000}>
          <MuiAlert elevation={6} variant="filled" severity="error">
            <Trans>Ha ocurrido un error. Por favor intente de nuevo.</Trans>
          </MuiAlert>
        </Snackbar>
        <ValidatorForm className={classes.validatorContainer} onSubmit={() => {}}>
          <Box mt={1}>
            <Typography variant="subtitle1">
              <Trans>Mostrar precios para:*</Trans>
            </Typography>
            <Box mt={1}>
              <Typography variant="body1">
                <Trans>(se pueden seleccionar todas las opciones)</Trans>
              </Typography>
            </Box>
            <Box className={classes.inputRow} mt={1}>
              {priceStatus !== "loading" ? (
                <CheckBox
                  style={{ paddingLeft: theme.spacing(0) }}
                  checked={assetCfgs?.show_space_price}
                  onChange={(e, checked) => {
                    setAssetCfgs({ ...assetCfgs, show_space_price: checked });
                  }}
                />
              ) : (
                <Box display="flex" alignItems="center" width={31} height={30}>
                  <CircularProgress size={20} />
                </Box>
              )}
              <Typography variant="body1">
                <Trans>Espacios tradicionales</Trans>
              </Typography>
            </Box>
            <Box className={classes.inputRow} mt={1}>
              {priceStatus !== "loading" ? (
                <CheckBox
                  style={{ paddingLeft: theme.spacing(0) }}
                  checked={assetCfgs?.show_adspace_price}
                  onChange={(e, checked) => {
                    setAssetCfgs({ ...assetCfgs, show_adspace_price: checked });
                  }}
                />
              ) : (
                <Box display="flex" alignItems="center" width={31} height={30}>
                  <CircularProgress size={20} />
                </Box>
              )}
              <Typography variant="body1">
                <Trans>Advertising</Trans>
              </Typography>
            </Box>
            <Box mt={3}>
              <Typography variant="subtitle1">
                <Trans>Actividades comerciales*</Trans>
              </Typography>
              <Box mt={1}>
                <Grid container>
                  {spaceActivitiesValues.map((e, i) => (
                    <Grid item xs={3} key={`prices-${i}`}>
                      <Tooltip
                        placement="bottom-start"
                        title={<Typography variant="body1">{i18n._(t`${e.space_activity}`)}</Typography>}
                      >
                        <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
                          {i18n._(t`${limitStringChars(e.space_activity, 25)}`)}
                        </Typography>
                      </Tooltip>
                      <TextField
                        value={e.rate}
                        style={{ width: "200px", marginBottom: theme.spacing(2) }}
                        textFieldClassNames={classes.fields}
                        type="number"
                        validators={["required"]}
                        onChange={handleInputChange(i)}
                        errorMessages={[""]}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Box>
        </ValidatorForm>
      </Box>
      <StepButtons
        continueButtonLabel={<Trans>Guardar</Trans>}
        backButtonLabel={<Trans>Olvidar cambios</Trans>}
        handleContinue={handleFinish}
        handleBack={handleCancel}
        disableContinue={!valuesHasChanged || submitStatus === "loading"}
        disableBack={!valuesHasChanged}
      />
    </>
  );
};

export default Prices;
