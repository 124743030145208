import React, { useEffect, useState } from "react";
import { useParams } from "@reach/router";
import { useNavigate } from "/src/hooks/navigation";
import { useSelector, useDispatch } from "react-redux";
import InformationComponent from "../../../components/asset-form/information";
import { getAssetThunk } from "../../../redux/actions/asset-actions";
import api from "../../../api";

const Information = () => {
  const dispatch = useDispatch();
  const { navigate } = useNavigate();
  const params = useParams();

  const [submitStatus, setSubmitStatus] = useState("initial");

  const { assetId } = params;
  const asset = useSelector((state) => state.asset.data);
  const status = useSelector((state) => state.asset.status);

  useEffect(() => {
    return () => {
      setSubmitStatus("initial");
    };
  }, []);

  const handleSubmit = async (values) => {
    setSubmitStatus("loading");
    const res = await api.updateAsset(assetId, { ...asset, ...values });
    if (res.status === 200) {
      dispatch(getAssetThunk(assetId));
      setSubmitStatus("success");
    } else {
      setSubmitStatus("error");
      setTimeout(() => setSubmitStatus("initial"), 3000);
    }
  };

  const goNext = () => {
    navigate(`/administrator/assets-landlords/assets/${assetId}/prices`);
  };

  const resetSubmitStatus = () => {
    setSubmitStatus("initial");
  };

  return (
    <InformationComponent
      asset={asset}
      status={status}
      submitStatus={submitStatus}
      resetSubmitStatus={resetSubmitStatus}
      submit={handleSubmit}
      goNext={goNext}
    />
  );
};

export default Information;
