import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "/src/hooks/navigation";
import Box from "@material-ui/core/Box";
import RatingListComponent from "../../../components/customers-owner/rating-list";
import StepButtons from "../../../components/step-buttons";
import LayoutDashboard from "../../../components/layout-dashboard";
import BackBtn from "../../../components/customers-owner/back-btn";
import api from "../../../api";

const RatingList = ({ custumerId }) => {
  const [companyRatings, setCompanyRatings] = useState([]);
  const [companyAverageRatings, setCompanyAverageRatings] = useState({});
  const [ratingStatus, setRatingStatus] = useState("initial");
  const { navigate } = useNavigate();

  const customerSearch = useSelector((state) => state.companies.search);

  const getCompanyRatings = async () => {
    setRatingStatus("loading");
    const companyRatings = await api.getCompanyRatings({
      companyId: custumerId,
    });
    if (companyRatings.message) {
      setRatingStatus("error");
    } else {
      setRatingStatus("success");
      setCompanyRatings(companyRatings.data?.payload?.ratings || []);
      setCompanyAverageRatings(companyRatings.data?.payload?.average || []);
    }
  };

  const handleOnNotesClick = (id) => navigate(`/administrator/clients/${custumerId}/notes/all/?selected-booking=${id}`);

  const handleBack = () => {
    navigate(customerSearch ? `/administrator/clients?search=${customerSearch}` : "/administrator/clients");
  };

  useEffect(() => {
    getCompanyRatings();
  }, []);

  return (
    <>
      <LayoutDashboard>
        <RatingListComponent
          custumerId={custumerId}
          companyAverageRatings={companyAverageRatings}
          companyRatings={companyRatings}
          onNotesClick={handleOnNotesClick}
          ratingStatus={ratingStatus}
        />
      </LayoutDashboard>
      <StepButtons
        stepButtonComponent={
          <Box display="flex" flexDirection="row">
            <BackBtn onBack={handleBack} />
          </Box>
        }
      />
    </>
  );
};

export default RatingList;
