import { Box, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import { AddSharp, NavigateBefore, NavigateNext } from "@material-ui/icons";
import React from "react";
import theme from "./../../theme";
import { Trans } from "@lingui/macro";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  footerStyle: {
    display: "flex",
    justifyContent: "space-around",
    padding: "0px 0px 5px 0px",
    alignItems: "flex-end",
    "&:hover": {
      color: "black",
    },
  },
  actions: {
    flex: 4,
    display: "flex",
    justifyContent: "flex-start",
  },
  pageNumber: {
    padding: 2,
  },
  chevron: {
    height: "20px",
    width: "20px",
    fill: "black",
  },
}));

const CustomTableFooter = ({
  pagination = {},
  onChangePage,
  canCreate = true,
  primaryButton,
  secondaryButton,
  onPrimaryButton,
  onSecondaryButton,
  actionButtons = () => {},
  showActionButtons = true,
}) => {
  const classes = useStyles(theme);

  return (
    <TableFooter className={classes.container}>
      <Box>
        <Box className={classes.footerStyle} colSpan={1000}>
          <Box className={classes.actions}>
            {showActionButtons ? actionButtons() : null}
            {canCreate && (
              <>
                {primaryButton && (
                  <Button size="small" variant="text" color="primary" onClick={onPrimaryButton}>
                    {primaryButton}
                    <AddSharp />
                  </Button>
                )}
                {secondaryButton && (
                  <Button size="small" variant="text" color="primary" onClick={onSecondaryButton}>
                    {secondaryButton}
                    <AddSharp />
                  </Button>
                )}
              </>
            )}
          </Box>
          <Box className={classes.paginate}>
            <Pagination
              count={pagination.totalPages}
              color={"primary"}
              page={pagination.page}
              onChange={onChangePage}
            />
          </Box>
        </Box>
      </Box>
    </TableFooter>
  );
};

export default CustomTableFooter;
