import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "@reach/router";
import { useNavigate } from "/src/hooks/navigation";
import { useSelector, useDispatch } from "react-redux";
import InformationComponent from "../../../components/ad-spaces-form/information";
import api from "../../../api";
import {
  getAdSpaceTypesThunk,
  updateAdSpaceData,
  getAdSpaceThunk,
  getAdSpacesCategoriesThunk,
} from "../../../redux/actions/adspaces-actions";
import { getSpotsCategoriesThunk } from "../../../redux/actions/space-actions";

const Information = ({ onSetOpenDelete }) => {
  const dispatch = useDispatch();
  const { navigate } = useNavigate();
  const params = useParams();
  const isNew = params.adSpaceId === "new";

  const [submitStatus, setSubmitStatus] = useState("initial");

  const flagAdspaceCategories = useSelector((state) => state.misc.featureFlags["flag.ops.adspace_categories"]);
  const adspace = useSelector((state) => state.adspaces.adSpace);
  const status = useSelector((state) => state.adspaces.adSpaceStatus);
  const types = useSelector((state) => state.adspaces.adSpaceTypes);
  const adSpacesCategories = useSelector((state) => state.adspaces.adSpacesCategories);
  const spotsCategories = useSelector((state) => state.listing.spacesCategories);
  const spotAdspaceCategories = useMemo(
    () => spotsCategories?.filter((spaceCategory) => spaceCategory.data.type === "adspace"),
    [spotsCategories],
  );
  const showOverlapField = useSelector((state) => state.misc.featureFlags["flag.exp.show_overlap_field"]);

  useEffect(() => {
    dispatch(getAdSpaceTypesThunk());
  }, [params.adSpaceId]);

  useEffect(() => {
    return () => {
      setSubmitStatus("initial");
    };
  }, []);

  useEffect(() => {
    if (flagAdspaceCategories) {
      dispatch(getAdSpacesCategoriesThunk());
    }
  }, [flagAdspaceCategories]);

  const deleteAttachment = async (data) => {
    const attachmentsFilter = adspace.attachments.filter((f) => !data.attachments.some((m) => m.role == f.role));
    const resDelete = await api.deleteAttachment(attachmentsFilter[0].id);
    if (resDelete.status === 200) {
      dispatch(updateAdSpaceData({ ...data }));
    }
  };

  const handleDataChange = (data) => {
    if (data?.attachments && data.attachments.length < adspace.attachments.length) {
      deleteAttachment(data);
    }
  };

  const handlePublishAdSpace = async () => {
    adspace.searchable ? await api.hideAdSpace(adspace.id) : await api.publishAdSpace(adspace.id);
    dispatch(getAdSpaceThunk({ adSpaceId: params.adSpaceId }));
  };

  const handleSubmit = async (submittedValue) => {
    const { tags, ...adsValue } = submittedValue;
    setSubmitStatus("loading");

    if (JSON.stringify(adspace.attachments) !== JSON.stringify(submittedValue.attachments)) {
      const attachments = submittedValue.attachments?.map((attachment) => attachment.id) || [];
      await api.setAdSpaceAttachments(params.adSpaceId, attachments);
    }

    if (JSON.stringify(adspace.tags) !== JSON.stringify(submittedValue.tags)) {
      const ids = tags?.map((tag) => tag.id) || [];
      const tagIds = ids.join(",");
      await api.postAdSpaceTags(params.adSpaceId, { tags: tagIds });
    }

    await api.patchAdSpace(params.adSpaceId, adsValue);
    dispatch(updateAdSpaceData(submittedValue));

    setSubmitStatus("success");
  };

  const goBack = () => {
    navigate(`/administrator/adspaces/${params.adSpaceId}/location`);
  };

  const goNext = () => {
    navigate(`/administrator/adspaces/${params.adSpaceId}/calendar`);
  };

  useEffect(() => {
    if (adspace.asset_id > 0) {
      dispatch(getSpotsCategoriesThunk(adspace.asset_id));
    }
  }, [adspace.asset_id]);

  return (
    <InformationComponent
      showAdspaceCategories={flagAdspaceCategories}
      adspace={adspace}
      adSpacesCategories={adSpacesCategories}
      spotAdspaceCategories={spotAdspaceCategories}
      status={status}
      types={types}
      onDataChange={handleDataChange}
      submit={handleSubmit}
      goBack={goBack}
      goNext={goNext}
      handlePublishAdSpace={handlePublishAdSpace}
      submitStatus={submitStatus}
      onSetOpenDelete={onSetOpenDelete}
      isNew={isNew}
      showOverlapField={showOverlapField}
    />
  );
};

export default Information;
