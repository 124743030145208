import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { Trans } from "@lingui/macro";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import theme from "../../theme";
import OwnerModal from "../owner-modal";
import DecideDialog from "../decide-dialog-owner";
import StepSetupContainer from "../../containers/create-booking-wizard/step-1-setup";
import StepProjectDataContainer from "../../containers/create-booking-wizard/step-2-project-data";
import StepPriceContainer from "../../containers/create-booking-wizard/step-3-price";
import StepUsesContainer from "../../containers/create-booking-wizard/step-4-uses";
import StepAttachmentsContainer from "../../containers/create-booking-wizard/step-5-attachments";
import StepCreateContainer from "../../containers/create-booking-wizard/step-6-create";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
  },
  divider: {
    borderBottom: "1px solid black",
  },
}));

function CreateBookingWizardComponent({
  open = false,
  onClose = () => {},
  onBack,
  handleOnEnter = () => {},
  step,
  booking = {},
  onNext,
}) {
  const classes = useStyles(theme);
  const [confirmCloseModal, setConfirmCloseModal] = useState(false);

  const handleClose = () => {
    setConfirmCloseModal(false);
    onClose();
  };

  return (
    <>
      <OwnerModal
        open={open}
        onClose={() => setConfirmCloseModal(true)}
        onEnter={handleOnEnter}
        width={step < 6 ? 650 : 400}
      >
        <Box>
          <Box mb={1} display="flex" flexDirection="column">
            {step < 6 && (
              <>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h1">
                    <Trans>Reserva - Crear nueva</Trans>
                  </Typography>
                  <Typography variant="subtitle1">{step}/5</Typography>
                </Box>
                <Box mt={1} className={classes.divider} />
              </>
            )}
          </Box>
          {step === 1 && <StepSetupContainer updateBooking={onNext} booking={booking} />}
          {step === 2 && <StepProjectDataContainer updateBooking={onNext} booking={booking} onBack={onBack} />}
          {step === 3 && <StepPriceContainer updateBooking={onNext} booking={booking} onBack={onBack} />}
          {step === 4 && <StepUsesContainer updateBooking={onNext} booking={booking} onBack={onBack} />}
          {step === 5 && <StepAttachmentsContainer updateBooking={onNext} booking={booking} onBack={onBack} />}
          {step === 6 && <StepCreateContainer booking={booking} onBack={onBack} onNext={onNext} />}
        </Box>
      </OwnerModal>

      <DecideDialog
        open={confirmCloseModal}
        onAccept={handleClose}
        title={<Trans>Existen cambios sin guardar</Trans>}
        desc={<Trans>¿Estás seguro de que quieres salir sin guardar?</Trans>}
        onClose={() => setConfirmCloseModal(false)}
      />
    </>
  );
}

export default CreateBookingWizardComponent;
