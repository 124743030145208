import { Box, Fade, Tooltip, Typography } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
// theme
import theme from "./../../theme";

const useStyles = makeStyles((theme) => ({
  block: {
    width: ({ width }) => width,
    height: ({ height }) => height,
    position: "absolute",
    left: ({ x }) => x,
    backgroundColor: ({ bg }) => alpha(bg, 0.5),
    borderRight: ({ bg }) => `1px solid ${bg}`,
    borderLeft: ({ bg }) => `1px solid ${bg}`,
    color: "#000000",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: ({ bg }) => alpha(bg, 1),
    },
  },
  label: {
    fontWeight: "bold",
  },
}));

const TimelineBlock = ({ bg, width, height, x, onClick = () => {}, text = "", tooltip, data }) => {
  const classes = useStyles({
    x,
    width,
    height,
    bg,
  });

  const handleClick = () => onClick(data);

  return (
    <Tooltip
      disableFocusListener
      title={tooltip}
      TransitionComponent={Fade}
      style={{
        padding: 0,
      }}
    >
      <Box className={classes.block} onClick={handleClick} data-testid={"timeline-block-box"}>
        <Typography variant={"body1"} className={classes.label}>
          {text}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default TimelineBlock;
