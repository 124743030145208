import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { ArrowBackIos as BackIcon } from "@material-ui/icons";
import theme from "../../theme";

import { useNavigate } from "/src/hooks/navigation";
import DecideDialog from "../decide-dialog";
import CustomTabs from "../custom-tabs";
import AdsTitle from "../../containers/ad-spaces-form/title";
import Location from "../../containers/ad-spaces-form/location";
import Information from "../../containers/ad-spaces-form/information";
import Calendar from "../../containers/ad-spaces-form/calendar";

const useStyles = makeStyles((th) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    alignItems: "center",
    overflowY: "hidden",
  },
  wrapper: {
    width: "100%",
    height: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
  },
  continueButton: {
    width: 190,
    height: 42,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  stepText: {
    textTransform: "uppercase",
    "& .MuiStepLabel-root.Mui-disabled": {
      cursor: "pointer",
    },
  },
  headerButton: {
    top: th.spacing(3),
    left: th.spacing(2),
    whiteSpace: "nowrap",
    transform: "translateY(-3px)",
  },
  stepper: {
    width: "100%",
    overflow: "hidden",
  },
  stepperRoot: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const MyConnector = withStyles({
  active: {
    "& $line": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  completed: {
    "& $line": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: theme.palette.darkGray,
    borderRadius: 1,
  },
})(StepConnector);

const MyStepLabel = withStyles({
  label: {
    color: `${theme.palette.darkGray} !important`,
    textTransform: "uppercase",
  },
  active: {
    color: `${theme.palette.primary.main} !important`,
  },
  completed: {
    color: `${theme.palette.darkGray} !important`,
  },
})(StepLabel);

const TabComponent = ({ stepName, setOpen }) => {
  switch (stepName) {
    case "title":
      return <AdsTitle onSetOpenDelete={setOpen} />;
    case "location":
      return <Location onSetOpenDelete={setOpen} />;
    case "information":
      return <Information onSetOpenDelete={setOpen} />;
    case "calendar":
      return <Calendar onSetOpenDelete={setOpen} />;
    default:
      return "Unknown step";
  }
};

const AdSpacesDashboard = ({
  steps = [],
  activeStep = 0,
  goBackToAdSpaceList = () => {},
  onStepClick = () => {},
  deleteAdSpace,
  deleteStatus,
  adSpaceTitle,
  isNew,
  adSpaceId,
}) => {
  const classes = useStyles();
  const { navigate } = useNavigate();
  const { i18n } = useLingui();

  const [open, setOpen] = useState(false);

  const handleStepClick = (stepTab) => navigate(`/administrator/adspaces/${adSpaceId}/${stepTab}`);

  return (
    <Box className={classes.container}>
      <DecideDialog
        open={open}
        onClose={() => setOpen(false)}
        onAccept={deleteAdSpace}
        onSuccessAccept={goBackToAdSpaceList}
        title={<Trans>Eliminar espacio publicitario</Trans>}
        status={deleteStatus}
        questionText={i18n._(t`¿Estás seguro que deseas eliminar el espacio publicitario ${adSpaceTitle}?`)}
        successText={<Trans>El espacio ha sido eliminado con éxito.</Trans>}
        errorText={
          <Trans>No ha sido posible eliminar el espacio publicitario ya que cuenta con reservas en curso.</Trans>
        }
        isNewStyle
      />
      {!isNew ? (
        <CustomTabs steps={steps} activeStep={steps[activeStep]} onStepClick={handleStepClick}>
          <TabComponent stepName={steps[activeStep]} setOpen={setOpen} />
        </CustomTabs>
      ) : (
        <>
          <Box width="100%">
            <Box className={classes.header}>
              <Button variant="text" onClick={goBackToAdSpaceList} className={classes.headerButton}>
                <BackIcon />
                <Trans>Volver</Trans>
              </Button>
              <Stepper
                activeStep={activeStep}
                connector={<MyConnector />}
                style={{ width: "50%", maxWidth: "800px", margin: "auto" }}
                alternativeLabel
                className={classes.stepper}
                classes={{
                  root: classes.stepperRoot,
                }}
              >
                {steps.map((s, index) => (
                  <Step
                    onClick={() => {
                      onStepClick(s);
                    }}
                    className={classes.stepText}
                    key={index}
                  >
                    <MyStepLabel>{i18n._(t`${s}`)}</MyStepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>
          <TabComponent stepName={steps[activeStep]} setOpen={setOpen} />
        </>
      )}
    </Box>
  );
};

export default AdSpacesDashboard;
