import { Box, Typography, Tooltip, Hidden, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import numbro from "numbro";
import TenantsIcon from "../../icons/sass-icono-assigne";
import MoneyIcon from "../../icons/money-icon";
import UseIcon from "../../icons/use-icon";
import PaymentCardIcon from "../../icons/payment-card-icon";
// theme
import theme from "../../theme-admin";
import MultiselectSearchFilter from "../multiselect-search-filter";
import SingleSelectFilter from "../single-select-filter";
import Assets from "../../icons/sass-icono-assets-info";
import { dateToDayjs } from "../../utils/dateUtils";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 32,
    height: 32,
  },
  filters: {
    height: 30,
    "& > .MuiAutocomplete-root": {
      marginRight: theme.spacing(1),
    },
  },
  customInput: {
    "& .MuiOutlinedInput-input": {
      color: theme.palette.text.primary,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      fontSize: 16,
      width: "75px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "5px 5px 5px 0px",
      border: "none",
    },
    "& .MuiAutocomplete-inputRoot": {
      padding: 0,
      backgroundColor: theme.palette.lighterGray,
      overflowX: "auto",
    },
  },
}));

const BookingInvoicingReportToolbar = ({
  filters,
  onRefresh,
  invoicesTotal,
  filtersOptions,
  onFilterChange = () => {},
  onChangePage,
  isInvoiceTransactions,
}) => {
  const classes = useStyles(theme);
  const { i18n } = useLingui();
  const [selectedView, setSelectedView] = useState("booking");

  const availableYears = () => {
    const firstYear = dateToDayjs(new Date()).year(2021).get("year");
    const nextYear = dateToDayjs(new Date()).add(1, "year").get("year");

    const years = [];
    for (let i = firstYear; i <= nextYear; i++) {
      years.push(i);
    }

    return years;
  };

  const handleOnViewChange = ({ target }) => {
    setSelectedView(target.value);
    onChangePage(target.value);
  };

  return (
    <Box display="flex" alignItems="stretch" justifyContent="space-between" width="100%" className={classes.filters}>
      <Box display="flex" alignItems="center" gridGap={3}>
        <Select
          value={filters?.year || ""}
          onChange={({ target }) => onFilterChange("year")(target.value)}
          variant="outlined"
          style={{ minWidth: "100px", maxWidth: "100px", height: "36px", marginLeft: 0, textAlign: "start" }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          {availableYears().map((y) => (
            <MenuItem key={y} value={y}>
              {y}
            </MenuItem>
          ))}
        </Select>
        <MultiselectSearchFilter
          title={i18n._(t`Activos`)}
          localStorageName="bookingInvoiceAssets"
          icon={() => <Assets className={classes.icon} fill={theme.palette.black} />}
          options={filtersOptions.assets}
          onSelect={onFilterChange("assets")}
        />
        <MultiselectSearchFilter
          title={i18n._(t`Clientes`)}
          localStorageName="bookingInvoiceClients"
          icon={() => <TenantsIcon className={classes.icon} fill={theme.palette.black} />}
          options={filtersOptions.companies}
          onSelect={onFilterChange("companies")}
        />
        {isInvoiceTransactions ? (
          <></>
        ) : (
          <SingleSelectFilter
            title={i18n._(t`Estado`)}
            localStorageName="bookingInvoiceStatus"
            icon={() => (
              <PaymentCardIcon
                style={{ width: "22px", height: "22px", transform: "scale(1.5)" }}
                fill={theme.palette.black}
              />
            )}
            options={filtersOptions.status}
            onSelect={onFilterChange("status")}
            canClear
          />
        )}
        <MultiselectSearchFilter
          title={i18n._(t`Categoría`)}
          localStorageName="bookingInvoiceCategories"
          icon={() => (
            <UseIcon style={{ width: "16px", height: "16px", transform: "scale(1.5)" }} fill={theme.palette.black} />
          )}
          options={filtersOptions.bookingCategories}
          onSelect={onFilterChange("bookingCategories")}
        />
      </Box>

      <Box display="flex" alignItems="center" justifyContent="center">
        {(invoicesTotal || invoicesTotal === 0) && selectedView === "invoice" && (
          <Tooltip title={i18n._(t`Total facturado`)}>
            <Box display="flex" alignItems="center">
              <MoneyIcon fill={theme.palette.primary.main} style={{ width: 36, height: 36 }} />
              <Hidden lgDown>
                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                  <Trans>Total facturado:</Trans>
                </Typography>
              </Hidden>
              <Box ml={1 / 2} display="flex" alignItems="center">
                <Typography variant="body1" style={{ fontWeight: "bold" }} color="primary">
                  {numbro(parseFloat(invoicesTotal)).formatCurrency()}
                </Typography>
              </Box>
              <Box ml={3 / 2} mr={3 / 2} style={{ height: "36px", width: "1px", borderRight: theme.borders[6] }} />
            </Box>
          </Tooltip>
        )}

        <Select
          value={selectedView}
          onChange={handleOnViewChange}
          variant="outlined"
          style={{ minWidth: "150px", maxWidth: "150px", height: "36px" }}
        >
          <MenuItem value="invoice">{i18n._(t`Vista por facturación`)}</MenuItem>
          <MenuItem value="booking">{i18n._(t`Vista por reserva`)}</MenuItem>
        </Select>
      </Box>
    </Box>
  );
};

export default BookingInvoicingReportToolbar;
