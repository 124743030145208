import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import theme from "../../theme";
import CheckIcon from "../../icons/success-circle-icon";
import LoadingBar from "../loading-bar";

const StepCreateComponent = ({ onNext, resultCreate }) => (
  <Box display="flex" justifyContent="center" pb={3}>
    {resultCreate.status === "loading" && (
      <Box mb={3} width="100%">
        <LoadingBar height="10px" mt={2}>
          <Trans>Creando reserva....</Trans>
        </LoadingBar>
      </Box>
    )}
    {resultCreate.status === "success" && (
      <Box display="flex" flexDirection="column" alignItems="center" width={400}>
        <CheckIcon fill={theme.palette.successGreen} width={56} height={56} />
        <Box mb={3}>
          <Typography variant="subtitle1" style={{ color: theme.palette.successGreen }}>
            <Trans>La reserva</Trans>
            <span style={{ marginLeft: 3, marginRight: 3 }}>#{resultCreate.bookingId}</span>
            <Trans>se ha generado con éxito.</Trans>
          </Typography>
        </Box>
        <Button variant="contained" color="primary" style={{ width: 150 }} onClick={onNext}>
          <Trans>Aceptar</Trans>
        </Button>
      </Box>
    )}
    {resultCreate.status === "error" && (
      <Box display="flex" flexDirection="column">
        <Box mb={3} width="100%" style={{ wordBreak: "break-word" }}>
          <Typography variant="subtitle1" style={{ color: theme.palette.error.main }}>
            <Trans>Oops! Lo sentimos, hubo un inconveniente:</Trans>{" "}
            {resultCreate.error === "FAILED_CREATE_BOOKING" && <Trans>Falló la creación de la reserva.</Trans>}
            {(resultCreate.error === "FAILED_TEMPLATE_CONTRACT" || resultCreate.error === "FAILED_UPDATE_CONTRACT") && (
              <Trans>Reserva creada, falló la creación del contrato.</Trans>
            )}
            {resultCreate.error === "FAILED_GENERATE_PREVIEW_CONTRACT" && (
              <Trans>Reserva y contrato creado, falló la generación de la vista previa.</Trans>
            )}
          </Typography>
        </Box>
        <Button variant="text" color="primary" onClick={onNext}>
          <Trans>Salir</Trans>
        </Button>
      </Box>
    )}
  </Box>
);

export default StepCreateComponent;
