import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Trans } from "@lingui/macro";
import numbro from "numbro";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";
import EuroIcon from "../../icons/negotiation-icon";
import { getDays, getReservationMonths, getReservationWeeks } from "../../utils/dateUtils";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: "50px",
    padding: theme.spacing(1),
    borderRadius: "5px",
    backgroundColor: theme.palette.lightGray,
  },
}));

function HeaderWizardInfo({ periodPrice = 0, startDate, endDate, longTerm, bookingPrice, paymentPlan }) {
  const classes = useStyles({ ...theme });

  let diff;
  if (paymentPlan === "Total") {
    diff = getDays(startDate, endDate);
  } else if (paymentPlan === "Monthly") {
    diff = getReservationMonths(startDate, endDate);
  } else if (paymentPlan === "Weekly") {
    diff = getReservationWeeks(startDate, endDate);
  }

  return (
    <Box className={classes.header}>
      <Box mr={1}>
        <EuroIcon fill={theme.palette.black} style={{ width: "32px", height: "32px" }} />
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" width="100%">
        <Typography variant="subtitle1">
          {paymentPlan === "Total" && <Trans>Precio total:</Trans>}
          {paymentPlan === "Monthly" && <Trans>Cuota mensual:</Trans>}
          {paymentPlan === "Weekly" && <Trans>Cuota semanal:</Trans>}
          {` `}
          {paymentPlan === "Total" && numbro(parseFloat(bookingPrice)).formatCurrency()}
          {paymentPlan === "Monthly" && numbro(parseFloat(periodPrice)).formatCurrency()}
          {paymentPlan === "Weekly" && numbro(parseFloat(periodPrice)).formatCurrency()}
        </Typography>

        {!longTerm && (
          <Box display="flex">
            <Typography variant="subtitle1">
              {bookingPrice ? <Trans>Duración:</Trans> : ""}
              {` `}
              {paymentPlan === "Total" && (
                <>
                  <span>{diff} </span>
                  <Trans>días</Trans>
                </>
              )}
              {paymentPlan === "Monthly" && (
                <>
                  <span>{diff} </span>
                  <Trans>meses</Trans>
                </>
              )}
              {paymentPlan === "Weekly" && (
                <>
                  <span>{diff} </span>
                  <Trans>semanas</Trans>
                </>
              )}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default HeaderWizardInfo;
