import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "@reach/router";
import { t } from "@lingui/macro";
import { useNavigate } from "../../hooks/navigation";
import ConsultsOwnerComponent from "../../components/consults-owner";
import DrawerConsultationComponent from "../../components/drawer-consultation";
import { resetConsultation } from "../../redux/actions/consultation-actions";
import { getConsultsOwnerThunk, setConsultsOwnerState } from "../../redux/actions/consults-owner-actions";
import { CONSULT_STATUS } from "../../redux/reducers";
import CreateBookingWizardContainer from "../create-booking-wizard";
import { downloadBlobFiles, filterColumnsDisplayTableToString } from "../../utils";
import { setDashboardTitle } from "../../redux/actions/misc-actions";
import api from "../../api";
import { resetAllAdSpaces } from "../../redux/actions/adspaces-actions";
import { resetAllSpaces } from "../../redux/actions/spaces-owner-actions";
import PendingTransactionsContainer from "../pending-transactions";

const ConsultsOwnerContainer = ({ mainState }) => {
  const { navigate } = useNavigate();
  const params = useParams();
  const bookingIdByParam = params.booking.split("-")[0];
  // Note: the "all" is needed to avoid having another route
  // const bookingId =
  //  params?.booking && params.booking !== "all" ? params.booking : undefined;

  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState(-1);
  const [openCreateBooking, setOpenCreateBooking] = useState(false);
  const [openPendingTransactions, setOpenPendingTransactions] = useState(false);

  const consults = useSelector((state) => state.consultsOwner.items);
  const pagination = useSelector((state) => state.consultsOwner.pagination);
  const filters = useSelector((state) => state.consultsOwner.filters);
  const status = useSelector((state) => state.consultsOwner.status);
  const bookingState = useSelector((state) => state.consultsOwner.bookingState);
  const searchWords = useSelector((state) => state.consultsOwner.searchWords);

  const [sort, setSort] = useState({});
  const [hasLoadedState, setHasLoadedState] = useState(false);
  const [columns, setColumns] = useState("");

  const REQUEST_VIRTUAL_STATES = ["all-requests", "unpaid-n-cancelled"];
  const BOOKING_VIRTUAL_STATES = ["active", "to-be-started", "done", "confirmed-n-cancelled", "all-bookings"];

  const getUrlByState = (state) => {
    switch (state) {
      case CONSULT_STATUS.ACTIVE:
        return `/administrator/bookings`;
      case CONSULT_STATUS.IN_PROGRESS:
      default:
        return `/administrator/requests`;
    }
  };

  const getVirtualStates = (state) => {
    switch (state) {
      case CONSULT_STATUS.ACTIVE:
        return BOOKING_VIRTUAL_STATES;
      case CONSULT_STATUS.IN_PROGRESS:
      default:
        return REQUEST_VIRTUAL_STATES;
    }
  };

  const shouldShowCreateWizard = mainState === CONSULT_STATUS.ACTIVE;

  useEffect(() => {
    if (mainState === CONSULT_STATUS.ALL_REQUESTS) {
      dispatch(setDashboardTitle(t`Consultas`));
      localStorage.setItem(`all-requestsState`, "all-requests");
      dispatch(setConsultsOwnerState("all-requests"));
    } else if (mainState === CONSULT_STATUS.ACTIVE) {
      dispatch(setDashboardTitle(t`Reservas`));
      localStorage.setItem(`activeState`, "active");
      dispatch(setConsultsOwnerState("active"));
    }
    dispatch(resetAllAdSpaces());
    dispatch(resetAllSpaces());
  }, []);

  // TODO: check this when consults grow or next page, etc.
  useEffect(() => {
    if (parseInt(bookingIdByParam, 10)) {
      const row = consults.reduce(
        (prev, curr, index) => (curr.id === parseInt(bookingIdByParam, 10) ? index : prev),
        -1,
      );
      setSelectedRow(row);
    } else {
      setSelectedRow();
    }
  }, [params.booking, consults]);

  const handleFetchTableData = () => {
    dispatch(
      getConsultsOwnerThunk({
        state: bookingState,
        page: pagination.page,
        search: searchWords,
        filters,
        sort,
      }),
    );
  };

  useEffect(() => {
    if (Object.keys(sort).length > 0) {
      handleFetchTableData();
    }
  }, [sort]);

  useEffect(() => {
    dispatch(resetConsultation());

    if (!hasLoadedState) {
      setHasLoadedState(true);
      const bookingFilterState = localStorage.getItem("BookingVirtualState");
      const state =
        bookingFilterState && getVirtualStates(mainState).includes(bookingFilterState) ? bookingFilterState : mainState;

      dispatch(
        getConsultsOwnerThunk({
          state,
          page: 1,
          filters,
          sort,
        }),
      );
    }
  }, []);

  const handleChangePage = (evt, page) => {
    dispatch(
      getConsultsOwnerThunk({
        state: bookingState,
        page,
        search: searchWords,
        filters,
        sort,
      }),
    );
  };

  const handleTableChange = (action, tableState) => {
    const columnsDisplay = filterColumnsDisplayTableToString(tableState);
    setColumns(columnsDisplay);
    switch (action) {
      case "refresh":
        handleFetchTableData();
        break;
      case "sort":
        setSort(tableState.sortOrder);
        break;
      default:
      // console.log("action not handled.");
    }
  };

  const handleRowClick = (rowData) => {
    const url = getUrlByState(mainState);
    navigate(`${url}/${rowData[2]}`);
  };
  const handleClose = () => {
    const url = getUrlByState(mainState);
    // Note: the "all" is needed to avoid having another route
    navigate(`${url}/all`, { replace: true });
  };

  const handleCreateBooking = shouldShowCreateWizard ? () => setOpenCreateBooking(true) : undefined;
  const handleConfirmPendingTransactions = () => setOpenPendingTransactions(true);

  const handleCloseCreateBooking = () => setOpenCreateBooking(false);
  const handleClosePendingTransactions = () => setOpenPendingTransactions(false);

  const downloadConsultData = async (format) => {
    const res = await api.downloadConsultData({
      exportFormat: format,
      state: bookingState,
      search: searchWords,
      filters,
      sort,
      columns,
    });
    downloadBlobFiles(res, `Reservas.${format}`);
  };

  return (
    <>
      <ConsultsOwnerComponent
        consults={consults}
        // canCreateBooking={userCanWrite(permissions) && shouldShowCreateWizard}
        canCreateBooking={shouldShowCreateWizard}
        pagination={pagination}
        status={status}
        isFirstLoadCompleted={hasLoadedState}
        mainState={mainState}
        selectedRow={selectedRow}
        downloadTableData={downloadConsultData}
        onTableChange={handleTableChange}
        onRowClick={handleRowClick}
        onClickAdd={handleCreateBooking}
        onConfirmPendingTransactions={handleConfirmPendingTransactions}
        onChangePage={handleChangePage}
      />
      <DrawerConsultationComponent
        isOpen={!!parseInt(bookingIdByParam, 10)}
        onClose={handleClose}
      />
      <CreateBookingWizardContainer open={openCreateBooking} onClose={handleCloseCreateBooking} />
      <PendingTransactionsContainer open={openPendingTransactions} onClose={handleClosePendingTransactions} />
    </>
  );
};

export default ConsultsOwnerContainer;
