import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from "recharts";
import theme from "../../theme";
import Widget from "./small-widget";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  legendBox: {
    width: "24px",
    height: "24px",
    marginRight: theme.spacing(1),
  },
}));

const COLORS = [theme.palette.primary.main, theme.palette.grayC4];

const RADIAN = Math.PI / 180;
const RenderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <>
      <circle cx={x} cy={y} r="17" stroke={COLORS[index]} strokeWidth="3" fill="white"></circle>
      <text x={x - 10} y={y} fill="black" dominantBaseline="central" textAnchor={"center"}>
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </>
  );
};

function Info() {
  return (
    <Box width="324px">
      <Typography variant="body1">
        <Trans>Porcentaje de ocupación de los espacios para el período seleccionado</Trans>
      </Typography>
    </Box>
  );
}

function GlobalOccupationWidget({ globalOcupation = "", period }) {
  const data = [
    {
      name: `${i18n._(t`Ocupado`)}`,
      value: globalOcupation ? parseFloat(globalOcupation.toFixed(2)) : 1,
    },
    {
      name: `${i18n._(t`Vacío`)}`,
      value: globalOcupation ? parseFloat((100 - globalOcupation).toFixed(2)) : 0,
    },
  ];

  return (
    <Widget
      title={<Trans>Ocupación global</Trans>}
      period={period}
      unavailable={!globalOcupation}
      info={<Info />}
      width="30%"
    >
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            dataKey="value"
            isAnimationActive={true}
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={40}
            outerRadius={60}
            paddingAngle={0}
            label={RenderCustomizedLabel}
            labelLine={false}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Legend />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </Widget>
  );
}

export default GlobalOccupationWidget;
