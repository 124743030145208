/* eslint-disable import/prefer-default-export */
import React, { useState } from "react";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Box, Button, FormControlLabel, RadioGroup, Radio, Typography } from "@material-ui/core";
import { SeasonBaseModal } from "./season-base-modal";

// theme
import theme from "../../../theme";

export const DeleteSeasonModal = ({ open, onClose }) => {
  const { i18n } = useLingui();
  const [deleteAction, setDeleteAction] = useState(null);

  const handleOnClose = () => {
    onClose(deleteAction);
  };

  return (
    <SeasonBaseModal title={i18n._(t`Eliminar período recurrente`)} width={400} open={open}>
      <Box display="flex" flexDirection="column" alignItems="start">
        <Typography variant="subtitle1" style={{ color: theme.palette.grey }}>
          <Trans>
            El período seleccionado corresponde a una serie de intervalos. Seleccione la opción para eliminar:
          </Trans>
        </Typography>

        <Box mt={2}>
          <RadioGroup
            value={deleteAction}
            onChange={(e) => {
              setDeleteAction(e.target.value);
            }}
          >
            <Box display="flex" flexDirection="column" mb={2}>
              <FormControlLabel
                value="thisandbackward"
                label={i18n._(t`Entrada actual y anteriores`)}
                control={<Radio color="primary" style={{ transform: "translateX(1px)" }} />}
                style={{ marginRight: theme.spacing(2) }}
              />
              <FormControlLabel
                value="backward"
                label={i18n._(t`Anteriores`)}
                control={<Radio color="primary" style={{ transform: "translateX(1px)" }} />}
                style={{ marginRight: theme.spacing(2) }}
              />
              <FormControlLabel
                value="thisandforward"
                label={i18n._(t`Entrada actual y posteriores`)}
                control={<Radio color="primary" style={{ transform: "translateX(1px)" }} />}
                style={{ marginRight: theme.spacing(2) }}
              />
              <FormControlLabel
                value="forward"
                label={i18n._(t`Posteriores`)}
                control={<Radio color="primary" style={{ transform: "translateX(1px)" }} />}
                style={{ marginRight: theme.spacing(2) }}
              />
              <FormControlLabel
                value="allinterval"
                label={i18n._(t`Toda la serie`)}
                control={<Radio color="primary" style={{ transform: "translateX(1px)" }} />}
                style={{ marginRight: theme.spacing(2) }}
              />
              <FormControlLabel
                value="currentEntry"
                label={i18n._(t`Entrada actual`)}
                control={<Radio color="primary" style={{ transform: "translateX(1px)" }} />}
                style={{ marginRight: theme.spacing(2) }}
              />
            </Box>
          </RadioGroup>
        </Box>

        <Button
          variant="contained"
          color="primary"
          style={{ width: 150, margin: "auto" }}
          disabled={!deleteAction}
          onClick={handleOnClose}
        >
          <Trans>Aceptar</Trans>
        </Button>
      </Box>
    </SeasonBaseModal>
  );
};
