import React from "react";
import { Trans } from "@lingui/macro";
import { formatDateUtc } from "../../utils/dateUtils";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

export default ({ open, onClose, block={} }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity="info">
        <Trans>Bloqueado por <strong>{block.description}</strong>.</Trans>
        <p>{formatDateUtc(block.start, "DD/MM/YYYY")}-{formatDateUtc(block.start, "DD/MM/YYYY")}</p>
      </Alert>
    </Snackbar>
  );
};
