import React, { useState, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Rating from "@material-ui/lab/Rating";
import WarningIcon from "@material-ui/icons/Warning";
import AddSharp from "@material-ui/icons/AddSharp";
import RemoveSharp from "@material-ui/icons/RemoveSharp";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { Trans, t } from "@lingui/macro";

import { useLingui } from "@lingui/react";
import Tooltip from "@material-ui/core/Tooltip";
import theme from "../../../theme-admin";
import OwnerTableModal from "../../owner-table-modal";
import Link from "../../link";
import NotesIcon from "../../../icons/sass-icono-notas";
import RatingIcon from "../../../icons/sass-icono-rating";
import CalendarStarIcon from "../../../icons/calendar-star";
import { limitStringChars } from "../../../utils";
import BigStartComponent from "./big-start";

const CONTENT_MAX_HEIGHT = "280px";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
  },
  customTable: {
    "& .MuiTableBody-root .MuiTableCell-root": {
      borderLeft: theme.borders[6],
      borderRight: theme.borders[6],
      "&:first-child": {
        borderLeft: 0,
      },
      "&:last-child": {
        borderRight: 0,
      },
    },
    "& .MuiTableCell-sizeSmall": {
      paddingLeft: theme.spacing(2),
      "&:first-child": {
        paddingLeft: 5,
      },
    },
    borderCollapse: "separate !important",
  },
  customTableHead: {
    "& .MuiTableCell-sizeSmall > p": {
      height: "15px",
      fontWeight: "bold",
    },
    "& .MuiTableCell-root": {
      cursor: "default",
    },
  },
  customAccordion: {
    boxShadow: "none",
    width: "100%",
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "32px",
      height: "32px",
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
    "& .MuiAccordionSummary-root": {
      height: "100%",
      padding: "0px",
      minHeight: theme.spacing(3),
    },
  },
  accordionTextDetails: {
    textAlign: "left",
    fontSize: "11px",
  },
  notes: {
    textDecoration: "underline",
    fontSize: "11px",
    "&:hover": {
      color: theme.palette.primary.main,
      "& svg path": {
        fill: theme.palette.primary.main,
      },
    },
  },
  linkStyle: {
    display: "flex",
    fontWeight: 400,
    textDecoration: "underline",
    fontSize: "11px",
    "&:hover": {
      color: theme.palette.primary.main,
      "& svg path": {
        fill: theme.palette.primary.main,
      },
    },
  },
  ratingCells: {
    height: "42px",
    cursor: "pointer !important",
  },
  ratingIcon: {
    opacity: 1,
  },
  ratingIcons: {
    color: theme.palette.yellow,
    width: "14px",
    height: "14px",
    ".MuiRating-root.Mui-disabled": {
      opacity: 1,
    },
  },
  averagePerClient: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.black,
    width: "32px",
    height: "32px",
    zIndex: 0,
  },
  ratingHeadRow: {
    "& .MuiTableCell-sizeSmall": {
      fontWeight: "bold",
      backgroundColor: theme.palette.lightGray,
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
  },
}));

const RatingList = ({
  custumerId,
  onNotesClick = () => {},
  companyRatings = [],
  companyAverageRatings = {},
  ratingStatus = "initial",
}) => {
  const classes = useStyles({
    ...theme,
  });

  const { i18n } = useLingui();

  const [isBrandExpanded, setIsBrandExpanded] = useState({});

  useEffect(() => {
    const isExpanded = {};
    companyRatings.map((cr) => {
      isExpanded[cr.booking_id] = false;
    });
    setIsBrandExpanded(isExpanded);
  }, [companyRatings]);

  const handleOnClickRow = (bookindId) => {
    isBrandExpanded[bookindId] = !isBrandExpanded[bookindId];
    setIsBrandExpanded({ ...isBrandExpanded });
  };

  const handleOnClickNotes = (id) => {
    onNotesClick(id);
  };

  const getClientAverage = (clientRatings) => {
    let count = 0;
    let avg = 0;
    Object.keys(clientRatings).forEach((rc) => {
      count++;
      avg += clientRatings[rc];
    });

    return Number(avg / count).toFixed(1);
  };

  const checkIfThereIsLowRate = (clientRatings) => Object.keys(clientRatings).some((rc) => clientRatings[rc] < 3);

  return (
    <Box className={classes.container}>
      {ratingStatus === "loading" && (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" height={CONTENT_MAX_HEIGHT} mt={1}>
          <CircularProgress />
        </Box>
      )}
      {companyRatings.length > 0 && ratingStatus !== "loading" && (
        <OwnerTableModal
          style={{
            overflowX: "hidden",
          }}
        >
          <MuiTable size="small" className={classes.customTable}>
            <TableHead className={classes.customTableHead}>
              <TableRow>
                <TableCell style={{ width: "260px" }} align="left">
                  <Trans>Reserva</Trans>
                </TableCell>
                <TableCell align="center">
                  <Trans>Rating</Trans>
                </TableCell>
                <TableCell align="center">
                  <Trans>Imagen</Trans>
                </TableCell>
                <TableCell align="center">
                  <Trans>Producto</Trans>
                </TableCell>
                <TableCell align="center">
                  <Trans>Administración</Trans>
                </TableCell>
                <TableCell align="center" style={{ width: "130px" }}>
                  <Trans>At. al cliente</Trans>
                </TableCell>
                <TableCell align="center">
                  <Trans>Pagos</Trans>
                </TableCell>
                <TableCell align="center">
                  <Trans>limpieza</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={classes.ratingHeadRow}>
                <TableCell />
                <TableCell align="center">%</TableCell>
                <TableCell align="center">{companyAverageRatings.image?.toFixed(2)}</TableCell>
                <TableCell align="center">{companyAverageRatings.product?.toFixed(2)}</TableCell>
                <TableCell align="center">{companyAverageRatings.management?.toFixed(2)}</TableCell>
                <TableCell align="center">{companyAverageRatings.client_care?.toFixed(2)}</TableCell>
                <TableCell align="center">{companyAverageRatings.payments?.toFixed(2)}</TableCell>
                <TableCell align="center">{companyAverageRatings.cleanliness?.toFixed(2)}</TableCell>
              </TableRow>
              {companyRatings.map((cr) => (
                <TableRow key={cr.booking_id}>
                  <TableCell align="left">
                    <Accordion
                      expanded={isBrandExpanded[cr.booking_id] ? isBrandExpanded[cr.booking_id] : false}
                      className={classes.customAccordion}
                    >
                      <AccordionSummary onClick={() => handleOnClickRow(cr.booking_id)}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="s">
                            <Box display="flex" alignItems="center" mr={1}>
                              {isBrandExpanded[cr.booking_id] ? (
                                <RemoveSharp fontSize="small" />
                              ) : (
                                <AddSharp fontSize="small" />
                              )}
                            </Box>
                            <Box>
                              <Tooltip
                                placement="bottom-start"
                                title={
                                  <Typography variant="body1">
                                    #{cr.booking_id} {cr.project_name} | {cr.brand_name}
                                  </Typography>
                                }
                              >
                                <Typography
                                  style={{
                                    textAlign: "start",
                                    transform: "translateX(-2px)",
                                    transform: "translateY(2px)",
                                    fontWeight: "bold",
                                  }}
                                  variant="body2"
                                >
                                  {limitStringChars(`#${cr.booking_id} ${cr.project_name} | ${cr.brand_name}`, 30)}
                                </Typography>
                              </Tooltip>
                            </Box>
                          </Box>
                          <Box display="flex" alignItems="center" justifyContent="center" width="auto" height="100%">
                            {checkIfThereIsLowRate(cr.ratings) && (
                              <Tooltip title={i18n._(t`Algunas valoraciones están bajas`)}>
                                <WarningIcon
                                  style={{
                                    color: theme.palette.error.main,
                                  }}
                                />
                              </Tooltip>
                            )}
                          </Box>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box display="flex" flexDirection="column" alignItems="flex-start" pl={2}>
                          <Typography variant="body1" className={classes.accordionTextDetails}>
                            <Trans>Centro</Trans>: {cr.asset_name}
                          </Typography>
                          <Typography variant="body1" className={classes.accordionTextDetails}>
                            <Trans>Gestor</Trans>: {cr.agent}
                          </Typography>
                          <Typography variant="body1" className={classes.accordionTextDetails}>
                            <Link
                              className={classes.linkStyle}
                              to={`/administrator/clients/${custumerId}/ratings/${cr.booking_id}`}
                            >
                              <CalendarStarIcon style={{ fontSize: ".75rem", marginRight: theme.spacing(0.5) }} />
                              <Trans>Ver reserva</Trans>
                            </Link>
                          </Typography>
                          {cr.booking_notes > 0 && (
                            <Box
                              display="flex"
                              flexDirection="row"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleOnClickNotes(cr.booking_id)}
                              className={classes.linkStyle}
                            >
                              <Box display="flex" alignItems="center" mr={1 / 2}>
                                <NotesIcon
                                  style={{
                                    width: "14px",
                                    height: "14px",
                                  }}
                                />
                              </Box>
                              <Typography variant="body1" className={classes.notes}>
                                <Trans>Ver notas</Trans>
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </TableCell>
                  <TableCell onClick={() => handleOnClickRow(cr.booking_id)} className={classes.ratingCells}>
                    <BigStartComponent ratingNumber={getClientAverage(cr.ratings)} />
                  </TableCell>
                  <TableCell className={classes.ratingCells} onClick={() => handleOnClickRow(cr.booking_id)}>
                    <Rating
                      name={`$rating-${cr.booking_id}-A`}
                      disabled
                      icon={<RatingIcon className={classes.ratingIcons} fill={theme.palette.yellow} />}
                      emptyIcon={<RatingIcon className={classes.ratingIcons} fill={theme.palette.white} />}
                      value={cr.ratings?.image}
                      max={cr.ratings?.image}
                    />
                  </TableCell>
                  <TableCell className={classes.ratingCells} onClick={() => handleOnClickRow(cr.booking_id)}>
                    <Rating
                      name={`$rating-${cr.booking_id}-B`}
                      disabled
                      icon={<RatingIcon className={classes.ratingIcons} fill={theme.palette.yellow} />}
                      emptyIcon={<RatingIcon className={classes.ratingIcons} fill={theme.palette.white} />}
                      value={cr.ratings?.product}
                      max={cr.ratings?.product}
                    />
                  </TableCell>
                  <TableCell className={classes.ratingCells} onClick={() => handleOnClickRow(cr.booking_id)}>
                    <Rating
                      name={`$rating-${cr.booking_id}-C`}
                      disabled
                      icon={<RatingIcon className={classes.ratingIcons} fill={theme.palette.yellow} />}
                      emptyIcon={<RatingIcon className={classes.ratingIcons} fill={theme.palette.white} />}
                      value={cr.ratings?.management}
                      max={cr.ratings?.management}
                    />
                  </TableCell>
                  <TableCell className={classes.ratingCells} onClick={() => handleOnClickRow(cr.booking_id)}>
                    <Rating
                      name={`$rating-${cr.booking_id}-D`}
                      disabled
                      icon={<RatingIcon className={classes.ratingIcons} fill={theme.palette.yellow} />}
                      emptyIcon={<RatingIcon className={classes.ratingIcons} fill={theme.palette.white} />}
                      value={cr.ratings?.client_care}
                      max={cr.ratings?.client_care}
                    />
                  </TableCell>
                  <TableCell className={classes.ratingCells} onClick={() => handleOnClickRow(cr.booking_id)}>
                    <Rating
                      name={`$rating-${cr.booking_id}-E`}
                      disabled
                      icon={<RatingIcon className={classes.ratingIcons} fill={theme.palette.yellow} />}
                      emptyIcon={<RatingIcon className={classes.ratingIcons} fill={theme.palette.white} />}
                      value={cr.ratings?.payments}
                      max={cr.ratings?.payments}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.ratingCells}
                    onClick={() => handleOnClickRow(cr.booking_id)}
                  >
                    <Rating
                      name={`$rating-${cr.booking_id}-G`}
                      disabled
                      icon={<RatingIcon className={classes.ratingIcons} fill={theme.palette.yellow} />}
                      emptyIcon={<RatingIcon className={classes.ratingIcons} fill={theme.palette.white} />}
                      value={cr.ratings?.cleanliness}
                      max={cr.ratings?.cleanliness}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>
        </OwnerTableModal>
      )}
      {companyRatings.length === 0 && ratingStatus !== "error" && ratingStatus !== "loading" && (
        <Box display="flex" width="100%" justifyContent="center" mt={3} mb={3}>
          <Typography variant="h4" color="primary">
            <Trans>Aún no hay reseñas para mostrar</Trans>
          </Typography>
        </Box>
      )}
      {ratingStatus === "error" && (
        <Box display="flex" width="100%" justifyContent="center" mt={2}>
          <Typography variant="subtitle1" color="error">
            <Trans>Ha ocurrido un error. Por favor intente de nuevo!</Trans>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default RatingList;
