import { Box, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table, { TableViewCol } from "mui-datatables";
import React from "react";

// theme
import theme from "../../theme";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
  },
  loadingMask: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.whiteMask,
  },
  cell: {
    "& .MuiTableCell-head": {
      width: "20px",
    },
    "& .MuiTableRow-hover:hover": {
      "& svg path": {
        fill: theme.palette.white,
      },
    },
  },
  filters: {
    "& .MuiIconButton-label": {
      color: theme.palette.primary.main,
    },
    "& .MuiCheckbox-colorPrimary": {
      height: 25,
      width: 25,
    },
    "& .MuiFormControlLabel-label": {
      fontWeight: 400,
      fontSize: 12,
    },
  },
}));

const CustomTableView = (props) => {
  const classes = useStyles(theme);

  return (
    <Box className={classes.filters}>
      <TableViewCol {...props} />
    </Box>
  );
};

export default ({ selectedRow, options, isLoading, onDownload = () => {}, ...rest }) => {
  const classes = useStyles(theme);
  return (
    <Box position={"relative"} className={classes.container}>
      <Table
        components={{
          TableViewCol: CustomTableView,
        }}
        className={classes.cell}
        options={{
          serverSide: true,
          print: false,
          rowsPerPageOptions: [],
          elevation: 0,
          selectableRows: "none",
          textLabels,
          filter: false,
          search: false,
          onDownload: onDownload,
          setRowProps: (row, dataIndex) => {
            return dataIndex === selectedRow
              ? {
                  className: "row-selected",
                }
              : {};
          },
          ...options,
        }}
        {...rest}
      />
      {isLoading && (
        <Box className={classes.loadingMask}>
          <CircularProgress size={24} style={{ marginTop: 90 }} />
        </Box>
      )}
    </Box>
  );
};

const textLabels = {
  body: {
    noMatch: <Trans>No existen más items</Trans>,
    toolTip: <Trans>Ordenar</Trans>,
    columnHeaderTooltip: (column) => (
      <>
        <Trans>Orden para</Trans> {column.label}
      </>
    ),
  },
  pagination: {
    next: <Trans>Siguiente</Trans>,
    previous: <Trans>Anterior</Trans>,
    rowsPerPage: <Trans>Filas por página:</Trans>,
    displayRows: <Trans>de</Trans>,
  },
  toolbar: {
    search: <Trans>Buscar</Trans>,
    downloadCsv: <Trans>Descargar</Trans>,
    print: <Trans>Imprimir</Trans>,
    viewColumns: <Trans>Ver Columnas</Trans>,
    filterTable: <Trans>Filtrar</Trans>,
  },
  filter: {
    all: <Trans>Todos</Trans>,
    title: <Trans>FILTROS</Trans>,
    reset: <Trans>REINICIAR</Trans>,
  },
  viewColumns: {
    title: <Trans>Mostrar columnas</Trans>,
    titleAria: <Trans>Mostrar/Ocultar columnas</Trans>,
  },
  selectedRows: {
    text: <Trans>fila(s) seleccionadas</Trans>,
    delete: <Trans>Eliminar</Trans>,
    deleteAria: <Trans>Eliminar selección</Trans>,
  },
};
