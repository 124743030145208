import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import { useNavigate } from "/src/hooks/navigation";
// theme
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import { Typography } from "@material-ui/core";
import Table from "../table";
import TableFooter from "../table-footer";
import AssetToolbar from "./asset-toolbar";
import TableDataDownload from "../table-data-download";

const useStyles = makeStyles((theme) => ({
  addButton: {
    position: "absolute",
    right: 50,
    bottom: 50,
  },
  notification: {
    color: theme.palette.primary.main,
  },
  filters: {
    height: 25,
    textTransform: "none",
    display: "flex",
    justifyContent: "flex-start",
    marginRight: theme.spacing(1),
    borderRadius: 4,
  },
  tooltip: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

function OwnerAssets({
  assets = [],
  status,
  pagination,
  onChangePage,
  onTableChange = () => {},
  onRowClick = () => {},
  onDownloadTableData,
  userRole,
}) {
  const { navigate } = useNavigate();
  const classes = useStyles();
  const { i18n } = useLingui();

  const [openDownloadModal, setOpenDownloadModal] = useState(false);

  const handleTableData = () => {
    const data = assets.map((l) => {
      return {
        id: l.id,
        name: l.name,
        city: l.city?.name,
        address: l.address,
        country: l.city?.country?.name,
        taxId: l.tax_id,
      };
    });

    return data;
  };

  const columns = [
    { label: "ID", name: "id", options: { sort: true } },
    { label: `${i18n._(t`Centro comercial`)}`, name: "name", options: { sort: true } },
    {
      label: `${i18n._(t`Ciudad`)}`,
      name: "city",
      options: { sort: true },
    },
    {
      label: `${i18n._(t`País`)}`,
      name: "country",
      options: { sort: true },
    },
    {
      label: `${i18n._(t`Dirección`)}`,
      name: "address",
      options: {
        customBodyRenderLite: (dataIndex) => (
          <Box>
            <Tooltip arrow title={assets[dataIndex].address}>
              <span className={classes.tooltip}>
                {assets[dataIndex].address.length > 40
                  ? `${assets[dataIndex].address.substring(0, 39)}...`
                  : assets[dataIndex].address}
              </span>
            </Tooltip>
          </Box>
        ),
        sort: true,
      },
    },
    {
      label: `${i18n._(t`Tax ID`)}`,
      name: "taxId",
      options: { sort: true },
    },
  ];

  const handleGoToLandlords = () => {
    navigate("/administrator/assets-landlords/landlords");
  };

  const handleGoToUsersReport = () => {
    navigate("/administrator/assets-landlords/users-report");
  };

  const handleGoToAssetForm = (dataIndex) => {
    navigate(`/administrator/assets-landlords/assets/${dataIndex[0]}`);
  };

  const handleOpenDownloadModal = () => {
    setOpenDownloadModal(true);
    return false;
  };

  return (
    <Box pl={2} pr={2}>
      <TableDataDownload
        open={openDownloadModal}
        onClose={() => setOpenDownloadModal(false)}
        onDownload={onDownloadTableData}
      />
      <Tabs value="Assets" indicatorColor="primary" textColor="primary" variant="fullWidth" centered>
        <Tab
          value="Assets"
          label={
            <Typography variant="subtitle1">
              <Trans>Centros Comerciales</Trans>
            </Typography>
          }
        />
        <Tab
          value="Landlords"
          label={
            <Typography variant="subtitle1">
              <Trans>Propietarios</Trans>
            </Typography>
          }
          onClick={handleGoToLandlords}
        />
        {userRole === "Area Manager" && (
          <Tab
            value="UsersReport"
            label={
              <Typography variant="subtitle1">
                <Trans>Reporte de usuarios</Trans>
              </Typography>
            }
            onClick={handleGoToUsersReport}
          />
        )}
      </Tabs>
      {/** this is a fix becuse of the extra height provided by the tabs. * */}
      {/** When refactoring this view, remove this height fix * */}
      <Box height={window.innerHeight - 190}>
        <Table
          data={handleTableData()}
          columns={columns}
          isLoading={status === "loading"}
          onDownload={handleOpenDownloadModal}
          options={{
            onRowClick: handleGoToAssetForm,
            onTableChange,
            customToolbar: () => {
              return <AssetToolbar onTableChange={onTableChange} />;
            },
            customFooter: () => {
              return <TableFooter pagination={pagination} onChangePage={onChangePage} canCreate={false} />;
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default OwnerAssets;
