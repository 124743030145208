import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Trans } from "@lingui/macro";
import { Box } from "@material-ui/core";
import StepButtons from "../../step-buttons";
import theme from "../../../theme";
import SeasonalCalendar from "./calendar";
import { SeasonModal } from "./season-modal";
import { RecurrentSeasonModal } from "./recurrent-season-modal";

const useStyles = makeStyles((th) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    color: th.palette.text.primary,
    paddingLeft: th.spacing(2),
    paddingRight: th.spacing(2),
    overflowY: "hidden",
  },
  validatorContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "relative",
    width: "100%",
    paddingBottom: th.spacing(4),
    overflowY: "auto",
  },
}));

const INITIAL_SEASON = { start: null, end: null, rate: null, label: null };

const SeasonalCalendarStep = ({
  seasons,
  onCreateSeason,
  onCreateRecurrentSeason,
  onUpdateSeason,
  onDeleteSeason,
  requestStatus,
  resetRequestStatus,
}) => {
  const classes = useStyles(theme);

  const [selectedSeason, setSelectedSeason] = useState(INITIAL_SEASON);
  const [isSeasonModalOpen, setIsSeasonModalOpen] = useState(false);
  const [isRecurrentSeasonModalOpen, setIsRecurrentSeasonModalOpen] = useState(false);

  const handleSeasonSelected = (seasonSelected) => {
    setIsSeasonModalOpen(true);
    setSelectedSeason(seasonSelected);
  };

  const handleCloseModal = () => {
    setIsSeasonModalOpen(false);
    setIsRecurrentSeasonModalOpen(false);
    setSelectedSeason(INITIAL_SEASON);
    resetRequestStatus();
  };

  return (
    <>
      <SeasonModal
        open={isSeasonModalOpen}
        seasons={seasons}
        status={requestStatus.status}
        action={requestStatus.action}
        onCreateSeason={onCreateSeason}
        onUpdateSeason={onUpdateSeason}
        onDeleteSeason={onDeleteSeason}
        seasonSelected={selectedSeason}
        onClose={handleCloseModal}
      />

      <RecurrentSeasonModal
        open={isRecurrentSeasonModalOpen}
        seasons={seasons}
        status={requestStatus.status}
        onCreateRecurrentSeason={onCreateRecurrentSeason}
        onClose={handleCloseModal}
      />

      <Box className={classes.container}>
        <SeasonalCalendar
          seasons={seasons}
          datesSelected={[null, null]}
          onSeasonSelected={handleSeasonSelected}
          maxAvailableHeight
        />
      </Box>
      <StepButtons
        continueButtonLabel={<Trans>Crear período</Trans>}
        backButtonLabel={<Trans>Crear período recurrente</Trans>}
        handleContinue={() => setIsSeasonModalOpen(true)}
        handleBack={() => setIsRecurrentSeasonModalOpen(true)}
      />
    </>
  );
};

export default SeasonalCalendarStep;
