import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Image from "material-ui-image";
import React, { useState } from "react";
// theme
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import theme from "../../../theme";
import FileUpload from "../../file-upload/multiple";
import InputFileUpload from "../../file-upload/input-file-upload";

import CloseIcon from "../../../icons/close-icon";
import { MAX_MB_IMAGES } from "../../../redux/reducers";

const useStyles = makeStyles((theme) => ({
  item: {
    height: 100,
    width: 100,
    paddingRight: 10,
    paddingTop: 10,
    cursor: "pointer",
  },
  imagePlaceholder: {
    padding: 0,
    height: "100%",
    backgroundColor: theme.palette.darkGray,
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "2rem",
  },
  errorText: {
    color: theme.palette.error.main,
    position: "absolute",
    backgroundColor: "white",
    zIndex: 9,
    top: 0,
    lineHeight: "initial",
  },
  droppable: {
    borderRadius: "5px",
  },
  droppableBox: {
    background: theme.palette.grayC4,
    borderRadius: 5,
    display: "flex",
    padding: theme.spacing(1),
  },
  attachmentIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: ({ heightPhoto }) => (heightPhoto === 65 ? theme.spacing(1 / 2) : 0),
    borderRadius: "5px",
    width: ({ widthPhoto }) => (widthPhoto === 65 ? 65 : widthPhoto + 15),
    height: ({ heightPhoto }) => (heightPhoto === 65 ? 65 : heightPhoto + 15),
    backgroundColor: theme.palette.grayC4,
    cursor: "pointer",
    position: "relative",
  },
  closeIcon: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    position: "absolute",
    zIndex: "999",
    width: "22px",
    height: "22px",
    backgroundColor: theme.palette.darkGray,
    right: "-5px",
    top: "-5px",
  },
}));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle, widthPhoto, heightPhoto) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: "0 5px 0px 5px",
  position: "relative",

  width: widthPhoto,
  height: heightPhoto,

  // change background colour if dragging
  opacity: isDragging ? 0.7 : 1,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const GalleryComponent = ({
  values = [],
  max = 6,
  onChangeValues = () => {},
  type = "",
  companyId = -1,
  modelToUpdateFile = "",
  isDragDisabled = false,
  widthPhoto = 80,
  heightPhoto = 80,
}) => {
  const classes = useStyles({ ...theme, widthPhoto, heightPhoto });
  const [status, setStatus] = useState({ state: "initial", message: "" });

  const { i18n } = useLingui();

  const handleStartUpload = () => setStatus({ state: "loading" });
  const handleErrorUpload = (type) => setStatus({ state: "error", message: type });

  const handlePartialComplete = (results) => {
    const newImages = results.map((r, index) => ({
      url: r.url,
      type, // this is not type png or something
      role: `${type}-${index + values.length}`,
      description: `${type}-${index}`,
    }));
    const newValues = [...values, ...newImages];
    if (newValues.length <= max) {
      onChangeValues(newValues);
    }
  };
  const handleUploadImage = (results) => {
    handlePartialComplete(results);
    setStatus({ state: "initial", message: "" });
  };

  const handleUploadImageByModel = (fileUploaded) => {
    const newImages = {
      url: fileUploaded?.data?.payload?.file_url,
      type, // this is not type png or something
      role: `${type}-${fileUploaded?.data?.payload?.attachable_id}-${values.length + 1}`,
      description: `${type}-${fileUploaded?.data.payload?.attachable_id}`,
    };
    const newValues = [...values, newImages];
    if (newValues.length <= max) {
      onChangeValues(newValues);
    }
    setStatus({ state: "initial", message: "" });
  };

  const handleRemoveFile = (role) => () => onChangeValues(values.filter((v) => v.role !== role));

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(values, result.source.index, result.destination.index);

    // update list
    onChangeValues(items);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      position="relative"
      pt={1.5}
      style={{ opacity: status.state === "loading" ? 0.7 : 1 }}
    >
      {values.length ? (
        <DragDropContext onDragEnd={handleDragEnd} className={classes.droppable}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} className={classes.droppableBox} {...provided.droppableProps}>
                {values.map((item, index) => (
                  <Draggable key={item.role} draggableId={item.role} index={index} isDragDisabled={isDragDisabled}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                          widthPhoto,
                          heightPhoto,
                        )}
                      >
                        <Box className={classes.closeIcon} onClick={handleRemoveFile(item.role)}>
                          <CloseIcon width="12px" height="12px" />
                        </Box>
                        <Image
                          disableSpinner
                          color={theme.palette.darkGray}
                          src={item.url}
                          style={{
                            height: "100%",
                            padding: "0",
                            width: "100%",
                          }}
                          imageStyle={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          key={item.role}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <></>
      )}
      {values.length < max ? (
        <label
          className={classes.attachmentIcon}
          htmlFor={`upload-${type}`}
          style={{ opacity: status.state === "loading" ? 0.7 : 1, marginLeft: !values.length ? 0 : theme.spacing(1) }}
        >
          {modelToUpdateFile && companyId ? (
            <InputFileUpload
              id={`upload-${type}`}
              name={`upload-${type}`}
              maxSize={MAX_MB_IMAGES * 1024 * 1024} // 30MB
              accept="image/jpg,image/jpeg,image/png"
              style={{ display: "none" }}
              onStart={handleStartUpload}
              onComplete={handleUploadImageByModel}
              onError={handleErrorUpload}
              model={modelToUpdateFile}
              companyId={companyId}
            />
          ) : (
            <FileUpload
              id={`upload-${type}`}
              name={`upload-${type}`}
              maxSize={MAX_MB_IMAGES * 1024 * 1024} // 2MB
              accept="image/jpg,image/jpeg,image/png"
              style={{ display: "none" }}
              onPartialComplete={handlePartialComplete}
              onComplete={handleUploadImage}
              onStart={handleStartUpload}
              onError={handleErrorUpload}
            />
          )}
          <Box className={classes.pointer}>
            <AddIcon />
          </Box>
        </label>
      ) : (
        <></>
      )}
      <Box display="flex" alignItems="flex-end" ml={1}>
        <Typography
          variant="subtitle1"
          style={{
            color: "rgba(0, 0, 0, 0.26)",
            textAlign: "center",
          }}
        >
          <Trans>Max.</Trans> {MAX_MB_IMAGES}MB
        </Typography>
      </Box>
      {status.state === "error" && (
        <Typography variant="body1" className={classes.errorText}>
          {status.message === "invalid_type" && <Trans>Formato de archivo no soportado</Trans>}
          {status.message === "maxSize" && <Trans>Tamaño máx 2MB</Trans>}
        </Typography>
      )}
    </Box>
  );
};

export default GalleryComponent;
