import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Assets({ fill = "black", ...rest }) {
  return (
    <SvgIcon width="34" height="34" viewBox="0 0 100 100" fill="none" {...rest}>
		<g>
	<g>
	<path fill={fill} d="M84,36c0-3.866-3.134-7-7-7h-3c-3.866,0-7,3.134-7,7v37c0,3.866,3.134,7,7,7h3c3.866,0,7-3.134,7-7V36z
		 M79.121,74.871C78.572,75.418,77.835,76,77,76h-3c-0.835,0-1.572-0.582-2.121-1.129C71.333,74.322,71.001,73.335,71,72.5v-37
		c0.001-0.835,0.332-1.322,0.879-1.871C72.428,33.083,73.165,33,74,33h3c0.835,0,1.572,0.083,2.121,0.629
		c0.546,0.549,0.878,1.036,0.879,1.871v37C79.999,73.335,79.667,74.322,79.121,74.871z"/>
	<path fill={fill} d="M33,57c0-3.866-3.134-7-7-7h-3c-3.866,0-7,3.134-7,7v16c0,3.866,3.134,7,7,7h3c3.866,0,7-3.134,7-7V57z
		 M28.121,74.871C27.572,75.418,26.835,76,26,76h-3c-0.835,0-1.572-0.582-2.121-1.129C20.333,74.322,20.001,73.335,20,72.5v-16
		c0.001-0.835,0.333-1.322,0.879-1.871C21.428,54.082,22.165,54,23,54h3c0.835,0,1.572,0.082,2.121,0.629
		c0.546,0.549,0.878,1.036,0.879,1.871v16C28.999,73.335,28.667,74.322,28.121,74.871z"/>
	<path fill={fill} d="M58,28c0-3.866-3.134-7-7-7h-3c-3.866,0-7,3.134-7,7v45c0,3.866,3.134,7,7,7h3c3.866,0,7-3.134,7-7V28z
		 M53.121,74.871C52.572,75.418,51.835,76,51,76h-3c-0.835,0-1.572-0.582-2.121-1.129C45.333,74.322,45.001,73.335,45,72.5v-45
		c0.001-0.835,0.332-1.322,0.879-1.871C46.428,25.083,47.165,25,48,25h3c0.835,0,1.572,0.083,2.121,0.629
		c0.546,0.549,0.878,1.036,0.879,1.871v45C53.999,73.335,53.667,74.322,53.121,74.871z"/>
	</g>
</g>
</SvgIcon>
  );
}
