import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import CustomCheckbox from "../custom-checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "../../icons/search-icon";
import { CircularProgress, Typography } from "@material-ui/core";
import theme from "../../theme-admin";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import { sanitizeMultiSelectOptions } from "../../utils";

const useStyles = makeStyles((theme) => ({
  popper: {
    border: "1px solid rgba(27,31,35,.15)",
    boxShadow: "0 3px 12px rgba(27,31,35,.15)",
    borderRadius: 3,
    zIndex: 130,
    width: 250,
    "& .MuiAutocomplete-listbox": {
      maxHeight: 200,
    },
  },
  header: {
    borderBottom: theme.borders[0],
    backgroundColor: theme.palette.grayC4,
    padding: "8px 10px",
  },
  searchIcon: {
    position: "absolute",
    top: 10,
    right: 12,
  },
  inputBase: {
    padding: 5,
    paddingLeft: 10,
    width: "100%",
    background: theme.palette.white,
    borderBottom: theme.borders[0],
  },
  paper: {
    boxShadow: "none",
    margin: 0,
    "& ul": {
      paddingTop: 0,
    },
    "& ul li": {
      paddingLeft: 0,
    },
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    paddingLeft: 2,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent",
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: "relative",
  },
  option: {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  valueSelected: {
    marginLeft: 3,
    marginRight: 2,
    fontWeight: 500,
  },
  valueSelectedText: {
    fontWeight: 500,
  },
  button: {
    height: "35px",
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
}));

export default function MultiselectSearchPopup({
  title = "",
  placeholder = "",
  anchorEl,
  allOptions,
  pendingValue,
  onClose,
  onClear,
  onAccept,
  onChange,
  PopoverProps = {},
}) {
  const classes = useStyles(theme);
  const { i18n } = useLingui();

  const ref = useRef(null);

  const open = Boolean(anchorEl);
  const id = open ? title : undefined;

  return (
    <React.Fragment>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        placement="bottom-start"
        className={classes.popper}
        onClose={onClose}
        ref={ref}
        {...PopoverProps}
      >
        {title && (
          <Box className={classes.header}>
            <Typography variant={"subtitle1"}>{title}</Typography>
          </Box>
        )}
        <Autocomplete
          open
          multiple
          classes={{
            paper: classes.paper,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal,
          }}
          value={pendingValue}
          onChange={onChange}
          getOptionSelected={(option, value) => {
            //console.log(option, value)
            //return pendingValue.some((e) => e.id === option.id);
            return option.id === value.id;
          }}
          disableCloseOnSelect
          disablePortal
          renderTags={() => null}
          noOptionsText={"..."}
          renderOption={(option) => {
            const isSelected = pendingValue.some((opt) => opt.id === option.id);
            return allOptions.length > 0 ? (
              <Box display={"flex"} alignItems={"center"}>
                <CustomCheckbox checked={isSelected} />
                <Typography variant={"body2"} className={classes.option}>
                  {i18n._(t`${option.name}`)}
                </Typography>
              </Box>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                <CircularProgress size={30} />
              </Box>
            );
          }}
          options={sanitizeMultiSelectOptions(allOptions)}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <Box position={"relative"}>
              <InputBase
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                autoFocus
                className={classes.inputBase}
                placeholder={placeholder || i18n._(t`Buscar...`)}
              />
              <SearchIcon fill={theme.palette.grayC4} className={classes.searchIcon} />
            </Box>
          )}
        />
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          gridGap={3}
          p={1}
          style={{ backgroundColor: theme.palette.white }}
        >
          <Button color={"default"} onClick={onClear}>
            <Trans>Limpiar</Trans>
          </Button>
          <Button variant={"contained"} color={"default"} onClick={onClose}>
            <Trans>Cancelar</Trans>
          </Button>
          <Button variant={"contained"} color={"primary"} onClick={onAccept}>
            <Trans>Aceptar</Trans>
          </Button>
        </Box>
      </Popover>
    </React.Fragment>
  );
}
