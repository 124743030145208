import React from "react";
import { Trans } from "@lingui/macro";
import { Box } from "@material-ui/core";
import SecondaryButton from "../secondary-button";

const BackBtn = ({ onBack }) => {
  return (
    <Box display="flex" alignItems="center" width="100%">
      <SecondaryButton onClick={onBack}>
        <Trans>Volver</Trans>
      </SecondaryButton>
    </Box>
  );
};

export default BackBtn;
