import React from "react";
// config
import config from "../../config.js";
import { removeAWSSpecialCharacters, slugifyFileNames } from "../../utils";

/**
 * Retrieve pre-signed POST data from a dedicated API endpoint.
 * @param selectedFile
 * @returns {Promise<any>}
 */
const getPresignedPostData = (selectedFile) => {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();

    // Set the proper URL here.
    const url = config.UPLOAD_URL;

    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(
      JSON.stringify({
        name: slugifyFileNames(removeAWSSpecialCharacters(selectedFile.name)),
        type: selectedFile.type,
      }),
    );
    xhr.onload = function () {
      resolve(JSON.parse(this.responseText));
    };
  });
};

/**
 * Upload file to S3 with previously received pre-signed POST data.
 * @param presignedPostData
 * @param file
 * @returns {Promise<any>}
 */
const uploadFileToS3 = (presignedPostData, file) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    Object.keys(presignedPostData.fields).forEach((key) => {
      formData.append(key, presignedPostData.fields[key]);
    });

    // Actual file has to be appended last.
    formData.append("file", file);

    const xhr = new XMLHttpRequest();
    xhr.open("POST", presignedPostData.url, true);
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader("Access-Control-Allow-Credentials", true);
    xhr.send(formData);
    xhr.onload = function () {
      this.status === 204 ? resolve() : reject(this.responseText);
    };
  });
};

/**
 * Component renders a simple "Select file..." button which opens a file browser.
 * Once a valid file has been selected, the upload process will start.
 * @returns {*}
 * @constructor
 */
const FileUploadButton = ({
  onComplete = () => {},
  onPartialComplete = () => {},
  onStart = () => {},
  onError = () => {},
  maxSize = 4 * 1024 * 1024, // 4MB
  accept = "",
  ...rest
}) => (
  <input
    type="file"
    multiple
    accept={accept}
    onChange={async (e) => {
      const files = Array.from(e.target.files);
      const results = [];

      for (let i = 0; i < files.length; i++) {
        if (accept.split(",").indexOf(files[i].type) < 0) {
          return onError("invalid_type");
        }
      }

      onStart();

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // Step 1 - get pre-signed POST data.
        if (!file) return;
        if (file.size > maxSize) {
          return onError("maxSize");
        }
        const { data: presignedPostData } = await getPresignedPostData(file);

        // Step 2 - upload the file to S3.
        try {
          await uploadFileToS3(presignedPostData, file);
          const url = `${config.CLOUDFRONT_URL}/${presignedPostData.fields.key}`;
          results.push({
            url,
            type: file.type,
            file,
          });
          onPartialComplete(results);
        } catch (e) {
          console.log("An error occurred!", e.message);
        }
      }
      onComplete(results);
    }}
    {...rest}
  />
);

export default FileUploadButton;
