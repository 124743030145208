/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLingui } from "@lingui/react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Trans, t } from "@lingui/macro";
import {
  Box,
  Grid,
  CircularProgress,
  Button,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import { SeasonBaseModal } from "./season-base-modal";
import { formatDateUtc, dateToDayjs } from "../../../utils/dateUtils";
import SeasonalCalendar from "./calendar";
import TextField from "../../text-field";
import TrashIcon from "../../../icons/trash-icon";
import CheckIcon from "../../../icons/success-circle-icon";

// theme
import theme from "../../../theme";

const useStyles = makeStyles((th) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    color: th.palette.text.primary,
    paddingLeft: th.spacing(2),
    paddingRight: th.spacing(2),
    overflowY: "hidden",
  },
  fieldsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
}));

// const INITIAL_SEASON = { start: null, end: null, rate: null, label: null, interval: [], serie: null };
const INITIAL_SEASON = { start: null, end: null, rate: null, label: null, interval: null, serie: null };

export const RecurrentSeasonModal = ({ open, seasons, status, onCreateRecurrentSeason, onClose }) => {
  const classes = useStyles(theme);
  const { i18n } = useLingui();
  const formRef = useRef();
  const [season, setSeason] = useState(INITIAL_SEASON);
  const [formValid, setFormValid] = useState(false);

  const canSubmit = season.start && season.end && season.rate && season.label && season.interval && formValid;

  useEffect(() => {
    const validateForm = async () => {
      if (formRef.current) {
        const isValid = await formRef.current.isFormValid();
        setFormValid(isValid);
      }
    };

    validateForm();
  }, [season, formRef]);

  const handleInputChange =
    (field) =>
    ({ target }) => {
      setSeason((prevSeason) => ({ ...prevSeason, [field]: target.value }));
    };

  const handleOnDateChange = (values) => {
    const formattedDateUtc = formatDateUtc(values[1]);
    setSeason((v) => ({ ...v, start: values[0], end: dateToDayjs(formattedDateUtc).utc().toDate() }));
  };

  const handleResetDates = () => {
    setSeason((v) => ({ ...v, start: null, end: null }));
  };

  const handleCreateRecurrentSeason = () => {
    const { start, end, rate, label, interval } = season;

    onCreateRecurrentSeason({
      start: formatDateUtc(start),
      end: formatDateUtc(end),
      rate,
      label,
      interval,
    });
  };

  // const handleCheckboxChange = (e, day) => {
  //   if (e.target.checked) {
  //     setSeason((prevSeason) => ({
  //       ...prevSeason,
  //       interval: [...prevSeason.interval, day],
  //     }));
  //   } else {
  //     setSeason((prevSeason) => ({
  //       ...prevSeason,
  //       interval: prevSeason.interval.filter((selectedDay) => selectedDay !== day),
  //     }));
  //   }
  // };

  const handleOnClose = () => {
    onClose();
    setSeason(INITIAL_SEASON);
  };

  return (
    <SeasonBaseModal title={i18n._(t`Crear nuevo período recurrente`)} open={open} width={750} onClose={handleOnClose}>
      {status === "success" && (
        <Box display="flex" flexDirection="column" alignItems="center" alignSelf="center" width={400}>
          <CheckIcon fill={theme.palette.successGreen} width={56} height={56} />
          <Box mb={3}>
            <Typography variant="subtitle1" style={{ color: theme.palette.successGreen }}>
              <Trans>El período recurrente se ha creado con éxito.</Trans>
            </Typography>
          </Box>
          <Button variant="contained" color="primary" style={{ width: 150 }} onClick={handleOnClose}>
            <Trans>Aceptar</Trans>
          </Button>
        </Box>
      )}

      {status === "error" && (
        <Box display="flex" flexDirection="column">
          <Box mb={3} width="100%" style={{ wordBreak: "break-word" }}>
            <Typography variant="subtitle1" style={{ color: theme.palette.error.main }}>
              <Trans>Oops! Lo sentimos, hubo un inconveniente:</Trans>{" "}
              <Trans>
                Falló la creación del período recurrente. Revisa que no haya conflictos con otros períodos existentes.
              </Trans>
            </Typography>
          </Box>
          <Button variant="text" color="primary" onClick={handleOnClose}>
            <Trans>Salir</Trans>
          </Button>
        </Box>
      )}

      {(status === "initial" || status === "loading") && (
        <>
          <Box className={classes.container} mt={2}>
            <RadioGroup
              value={season.interval}
              onChange={(e) => {
                setSeason((val) => ({ ...val, interval: e.target.value }));
              }}
            >
              <Box display="flex" flexDirection="row" mb={2}>
                <FormControlLabel
                  value="Monday"
                  label={i18n._(t`Lunes`)}
                  control={<Radio color="primary" style={{ transform: "translateX(1px)" }} />}
                  style={{ marginRight: theme.spacing(2) }}
                />
                <FormControlLabel
                  value="Tuesday"
                  label={i18n._(t`Martes`)}
                  control={<Radio color="primary" style={{ transform: "translateX(1px)" }} />}
                  style={{ marginRight: theme.spacing(2) }}
                />
                <FormControlLabel
                  value="Wednesday"
                  label={i18n._(t`Miércoles`)}
                  control={<Radio color="primary" style={{ transform: "translateX(1px)" }} />}
                  style={{ marginRight: theme.spacing(2) }}
                />
                <FormControlLabel
                  value="Thursday"
                  label={i18n._(t`Jueves`)}
                  control={<Radio color="primary" style={{ transform: "translateX(1px)" }} />}
                  style={{ marginRight: theme.spacing(2) }}
                />
                <FormControlLabel
                  value="Friday"
                  label={i18n._(t`Viernes`)}
                  control={<Radio color="primary" style={{ transform: "translateX(1px)" }} />}
                  style={{ marginRight: theme.spacing(2) }}
                />
                <FormControlLabel
                  value="Saturday"
                  label={i18n._(t`Sábado`)}
                  control={<Radio color="primary" style={{ transform: "translateX(1px)" }} />}
                  style={{ marginRight: theme.spacing(2) }}
                />
                <FormControlLabel
                  value="Sunday"
                  label={i18n._(t`Domingo`)}
                  control={<Radio color="primary" style={{ transform: "translateX(1px)" }} />}
                  style={{ marginRight: theme.spacing(2) }}
                />
              </Box>
            </RadioGroup>
            {/* <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={season.interval.includes("Monday")}
                    onChange={(e) => handleCheckboxChange(e, "Monday")}
                    color="primary"
                    style={{ color: "rgba(0, 0, 0, 0.54)", transform: "translateX(1px)" }}
                  />
                }
                label={i18n._(t`Lunes`)}
                style={{ marginRight: theme.spacing(2) }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={season.interval.includes("Tuesday")}
                    onChange={(e) => handleCheckboxChange(e, "Tuesday")}
                    color="primary"
                    style={{ color: "rgba(0, 0, 0, 0.54)", transform: "translateX(1px)" }}
                  />
                }
                label={i18n._(t`Martes`)}
                style={{ marginRight: theme.spacing(2) }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={season.interval.includes("Wednesday")}
                    onChange={(e) => handleCheckboxChange(e, "Wednesday")}
                    color="primary"
                    style={{ color: "rgba(0, 0, 0, 0.54)", transform: "translateX(1px)" }}
                  />
                }
                label={i18n._(t`Miércoles`)}
                style={{ marginRight: theme.spacing(2) }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={season.interval.includes("Thursday")}
                    onChange={(e) => handleCheckboxChange(e, "Thursday")}
                    color="primary"
                    style={{ color: "rgba(0, 0, 0, 0.54)", transform: "translateX(1px)" }}
                  />
                }
                label={i18n._(t`Jueves`)}
                style={{ marginRight: theme.spacing(2) }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={season.interval.includes("Friday")}
                    onChange={(e) => handleCheckboxChange(e, "Friday")}
                    color="primary"
                    style={{ color: "rgba(0, 0, 0, 0.54)", transform: "translateX(1px)" }}
                  />
                }
                label={i18n._(t`Viernes`)}
                style={{ marginRight: theme.spacing(2) }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={season.interval.includes("Saturday")}
                    onChange={(e) => handleCheckboxChange(e, "Saturday")}
                    color="primary"
                    style={{ color: "rgba(0, 0, 0, 0.54)", transform: "translateX(1px)" }}
                  />
                }
                label={i18n._(t`Sábado`)}
                style={{ marginRight: theme.spacing(2) }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={season.interval.includes("Sunday")}
                    onChange={(e) => handleCheckboxChange(e, "Sunday")}
                    color="primary"
                    style={{ color: "rgba(0, 0, 0, 0.54)", transform: "translateX(1px)" }}
                  />
                }
                label={i18n._(t`Domingo`)}
                style={{ marginRight: theme.spacing(2) }}
              />
            </FormGroup> */}

            <Box mt={2} mb={1}>
              <Typography variant="subtitle1" style={{ color: theme.palette.grey }}>
                <Trans>Selecciona el rango del período recurrente</Trans>
              </Typography>
            </Box>

            <SeasonalCalendar
              seasons={seasons}
              datesSelected={[season.start, season.end]}
              onChange={handleOnDateChange}
              onSeasonSelected={() => {}}
              showSeasonInformation={false}
              selectRange
              blockDates
            />

            <Box
              onClick={handleResetDates}
              style={{ cursor: "pointer", width: "100%", justifyContent: "flex-end" }}
              display="flex"
              flexDirection="row"
              alignItems="center"
              mt={1}
              mb={1}
            >
              <Typography variant="body1" style={{ textDecoration: "underline" }}>
                <Trans>Limpiar fechas</Trans>
              </Typography>
              <TrashIcon style={{ width: "11px", marginLeft: theme.spacing(1 / 2) }} />
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" mt={2}>
            <ValidatorForm instantValidate ref={formRef} onSubmit={() => {}}>
              <Box className={classes.fieldsContainer}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      description={`${i18n._(t`Nombre`)}`}
                      placeholder={`${i18n._(t`Nombre`)}`}
                      errorMessages={[`${i18n._(t`Por favor indique el nombre del período`)}`]}
                      validators={["minStringLength:1", "maxStringLength:20"]}
                      value={season.label || ""}
                      onChange={handleInputChange("label")}
                      style={{ marginTop: theme.spacing(1), height: 76, width: 200 }}
                      disabled={false}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      description={`${i18n._(t`Multiplicador`)}`}
                      placeholder={`${i18n._(t`Multiplicador`)}`}
                      errorMessages={[`${i18n._(t`Por favor indique un multiplicador válido`)}`]}
                      validators={["isPositive", "isFloat"]}
                      value={season.rate || ""}
                      onChange={handleInputChange("rate")}
                      style={{ marginTop: theme.spacing(1), height: 76, width: 200 }}
                      disabled={false}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box display="flex" justifyContent="flex-end">
                <Button
                  onClick={handleCreateRecurrentSeason}
                  style={{ height: "33px" }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!canSubmit}
                >
                  {status === "loading" ? <CircularProgress size={20} /> : <Trans>Crear período recurrente</Trans>}
                </Button>
              </Box>
            </ValidatorForm>
          </Box>
        </>
      )}
    </SeasonBaseModal>
  );
};
